import { ReactNode, memo } from "react";
import { Button, SemiBoldText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface ReviewAndEditProps {
  label: string;
  value: ReactNode;
  onClickEdit: () => void;
}

const styles = createStyles({
  container: {
    display: "flex",
    "@media(max-width: 800px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },

    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 20,
    marginBottom: 20,
    borderBottom: "solid",
    borderBottomWidth: 0.5,
    borderBottomColor: colors.LINES,

    "&:last-child": {
      marginBottom: 0,
      paddingBottom: 0,
      border: "none",
    },
  },
  valueAndButtonContainer: {
    display: "flex",
    alignItems: "center",
    "@media(max-width: 800px)": {
      width: "100%",
      justifyContent: "space-between",
    },
    width: "60%",
    justifyContent: "space-between",
  },
  button: {
    color: colors.palette.GREEN,
    marginLeft: 120,
  },
  label: {
    color: colors.LIGHT_TEXT,
    fontSize: 14,
    "@media(max-width: 800px)": {
      paddingBottom: 5,
    },
  },
  textValue: {
    color: colors.TEXT,
    fontSize: 14,
  },
});

const ReviewAndEdit = ({ label, value, onClickEdit }: ReviewAndEditProps) => (
  <div css={styles.container}>
    <SemiBoldText style={styles.label}>{label}</SemiBoldText>
    <div css={styles.valueAndButtonContainer}>
      {typeof value === "string" ? <SemiBoldText style={styles.textValue}>{value}</SemiBoldText> : value}
      <Button type="ghost" textCss={styles.button} align="right" size="small" onClick={onClickEdit}>
        Edit
      </Button>
    </div>
  </div>
);

export default memo(ReviewAndEdit);
