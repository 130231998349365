import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

const styles = createStyles({
  circle: {
    height: 10,
    width: 10,
    backgroundColor: colors.ALERT,
    borderRadius: 10,
  },
});

const AlertIndicator = () => <div css={styles.circle} />;

export default AlertIndicator;
