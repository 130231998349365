import EmployeeCard from "src/components/EmployeeCard";
import Skeleton from "src/components/Skeleton";
import { useFractionContacts } from "src/hooks/useFractionContacts";
import { cn } from "src/utilities/shadcnUtils";

export const BDMCard = ({ className }: { className?: string }) => {
  const { data: contacts, isLoading } = useFractionContacts();

  if (isLoading) {
    return <Skeleton className="w-[600px] h-40" />;
  }

  if (!contacts?.bdm) {
    return null;
  }

  return (
    <div
      className={cn(
        "bg-green-100 p-4 rounded-md flex flex-col items-start justify-center sm:w-fit w-full",
        className
      )}
    >
      <EmployeeCard
        contact={contacts.bdm}
        buttonClassName="w-full mt-1"
        title={`Your BDM: ${contacts.bdm?.user?.firstName} ${contacts.bdm?.user?.lastName}`}
      />
    </div>
  );
};
