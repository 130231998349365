import { Route, Routes, useParams } from "react-router-dom";
import { AppChat } from "src/components/AppChat";
import { AppDetailsOverview } from "src/components/AppDetailsOverview";
import { AppDocumentUpload } from "src/components/AppDocumentUpload";
import { AppOverviewShell } from "src/components/AppOverviewShell";
import { useApplicationAuthed } from "src/hooks/useApplication";

/**
 * inner tabs w/ routes
 */
export function ConveyancerAppOverviewShell() {
  const { id } = useParams();

  const { data: app, isLoading: loading } = useApplicationAuthed({
    id,
    initialRefetch: true,
    refetch: true,
  });

  return (
    <>
      <AppOverviewShell app={app || undefined} loading={loading} tabs={["overview", "doc-upload"]} />
      <AppChat
        textBoxClassName="h-[400px]"
        className="z-10 shadow fixed bottom-0 right-20"
        applicationId={app?.id}
      />
    </>
  );
}

export function AppDetailsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ConveyancerAppOverviewShell />}>
        <Route path="/" element={<AppDetailsOverview />} />
        <Route path="/doc-upload" element={<AppDocumentUpload />} />
      </Route>
    </Routes>
  );
}
