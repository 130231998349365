import { createStyles } from "src/styles";

const styles = createStyles({
  modalContainer: {
    overflowY: "hidden",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: "none",
    maxWidth: "100%",
  },
});

const ModalRoot = ({ children }: any) => (
  <div id="root-layout" css={styles.modalContainer}>
    {children}
  </div>
);

export default ModalRoot;
