import { entities, formatters } from "@fraction/shared";
import { Badge } from "./ui/badge";

export function AssetList({ assets }: { assets?: entities.FinancialAsset[] }) {
  return (
    <div>
      {assets?.map((asset) => (
        <div
          className="flex flex-row hover:bg-gray-200 odd:bg-gray-100 p-2 odd:rounded gap-x-4"
          key={asset.id}
        >
          <div>
            <b className="text-lg">{asset.name || asset.description}</b>
            {asset.accountNumberLast4Digits ? (
              <p className="text-base text-gray-600">•••• •••• {asset.accountNumberLast4Digits}</p>
            ) : null}
          </div>
          <div>
            {asset.value ? (
              <Badge variant="gray" className="text-xs rounded-full font-medium">
                {formatters.number.getCurrencyFromNumber(asset.value)}
              </Badge>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}
