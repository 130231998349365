import { SerializedStyles } from "@emotion/react";
import Skeleton from "react-loading-skeleton";
import Link from "src/components/Link";
import { BoldText, SemiBoldText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface InfoColumnProps {
  header: string;
  value?: string;
  link?: string;
  href?: string;
  onClick?: () => void;
  style?: SerializedStyles;
}

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  header: {
    fontSize: 14,
    color: colors.LIGHT_TEXT,
    marginBottom: 12,
  },
  value: {
    fontSize: 20,
    color: colors.TEXT,
  },
  link: {
    color: colors.LINK,
    fontSize: 14,
    marginTop: 12,
  },
});

const InfoColumn = ({ style, header, value, link, href, onClick }: InfoColumnProps) => (
  <div css={[styles.container, style]}>
    <SemiBoldText style={styles.header}>{header}</SemiBoldText>
    <BoldText style={styles.value}>{value || <Skeleton width={150} />}</BoldText>
    {!!link && (!!href || onClick) && (
      <Link style={styles.link} href={href} onClick={onClick}>
        {value ? link : <Skeleton width={100} />}
      </Link>
    )}
  </div>
);

export default InfoColumn;
