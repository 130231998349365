import { entities } from "@fraction/shared";
import { formatDistanceToNow } from "date-fns";
import { useParams } from "react-router-dom";
import { useComm } from "src/apps/LOS/hooks/queries";
import { Skeleton } from "src/components";
import { BackButton } from "src/components/BackButton";

export function MailPage() {
  const { id } = useParams();
  const { data: comm, isLoading } = useComm(id);

  if (!id) {
    return null;
  }

  if (isLoading) {
    return <Skeleton className="w-full h-full" />;
  }

  if (!comm) {
    return <p>Mail not found</p>;
  }

  return (
    <div className="bg-white rounded-xl w-[80vw] p-4">
      <div className="flex flex-row mb-3">
        <BackButton />
      </div>
      <Mail comm={comm} />
    </div>
  );
}

export function Mail({ comm, className }: { comm?: entities.Communication; className?: string }) {
  return (
    <div className={className}>
      <div className="flex flex-row justify-between">
        <p className="text-xl font-semibold">{comm?.subject}</p>
        {comm?.createdAt ? (
          <p className="text-sm text-gray-700">{formatDistanceToNow(comm?.createdAt)} ago</p>
        ) : null}
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-sm text-gray-700">From: {comm?.rawSender}</p>
      </div>
      <div className="mt-4">{comm ? <MailContent comm={comm} /> : null}</div>
    </div>
  );
}

function MailContent({ comm }: { comm: entities.Communication }) {
  if (comm?.html) {
    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
    return <div dangerouslySetInnerHTML={{ __html: comm.html }} />;
  }

  return <p className="whitespace-pre-wrap">{comm?.content}</p>;
}

export default Mail;
