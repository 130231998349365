import { Reducer, useCallback, useReducer } from "react";
import { Logger } from "src/log";

const LOGGER = new Logger("hooks.useStepper");

export enum ActionType {
  GO = "go", // navigates forward or back by a given delta
  REPLACE = "replace", // replace the steps and step with provided values
  PUSH = "push", // pushes new step(s) onto the steps stack, removes anything after the current step
  REMOVE = "remove", // removes step(s) from the steps stack
  INSERT = "insert", // inserts new step(s) after the current step
}

type ReplaceAction<T> = {
  type: ActionType.REPLACE;
  payload: StepperState<T>;
};

type UpdateAction<T> = {
  type: ActionType.PUSH | ActionType.INSERT | ActionType.REMOVE;
  payload: T[];
};

type NavigateAction = {
  type: ActionType.GO;
  payload: number;
};

type Action<T> = ReplaceAction<T> | NavigateAction | UpdateAction<T>;

interface StepperState<T> {
  step: T;
  steps: T[];
}

const findStepIndex = <T>({ steps, step }: StepperState<T>) => steps.findIndex((order) => order === step);

const stepReducer = <T>(state: StepperState<T>, action: Action<T>): StepperState<T> => {
  switch (action.type) {
    case ActionType.GO: {
      const currIndex = findStepIndex(state);
      const newIndex = currIndex + action.payload;
      const ableToNavigate = newIndex >= 0 && newIndex < state.steps.length;

      if (!ableToNavigate) {
        LOGGER.log(`Unable to navigate from: ${state.step}`);
        return state;
      }

      return {
        ...state,
        step: state.steps[newIndex],
      };
    }

    case ActionType.PUSH: {
      const currIndex = findStepIndex(state);
      return {
        steps: [...state.steps.slice(0, currIndex + 1), ...action.payload],
        step: action.payload[0],
      };
    }

    case ActionType.INSERT: {
      const currIndex = findStepIndex(state);
      return {
        steps: [
          ...state.steps.slice(0, currIndex + 1),
          ...action.payload,
          ...state.steps.slice(currIndex + 1),
        ],
        step: action.payload[0],
      };
    }

    case ActionType.REMOVE: {
      const toRemove = action.payload;
      const currIndex = findStepIndex(state);

      // find the newStep after removel
      const newStep = state.steps
        .slice(0, currIndex + 1)
        .reverse()
        .find((step) => !toRemove.includes(step));

      if (!newStep) {
        LOGGER.log(`Unable to remove steps: ${toRemove}`);
        return state;
      }

      return {
        steps: state.steps.filter((step) => !toRemove.includes(step)),
        step: newStep,
      };
    }

    case ActionType.REPLACE: {
      return action.payload;
    }

    default: {
      LOGGER.warn("Unknown action type", action);
      return state;
    }
  }
};

/**
 * Step wizard that allows navigating through a set of steps
 * Takes some inspiration from how the browser history package functions (used in react-router for example):
 * https://github.com/remix-run/history
 *
 * @param {T[]} initialSteps array of all the possible steps to start with
 * @param {T} initialStep the starting point, if not provided, the first step will be used
 *
 * @returns The current stepper state and methods to alter and navigate through the steps
 */
export default function useStepper<T>(initialSteps: T[], initialStep: T = initialSteps[0]) {
  const [state, dispatch] = useReducer<Reducer<StepperState<T>, Action<T>>>(stepReducer, {
    steps: initialSteps,
    step: initialStep,
  });

  /**
   * Navigate forward by one step (if possible)
   */
  const goForward = useCallback(() => {
    dispatch({
      type: ActionType.GO,
      payload: 1,
    });
  }, []);

  /**
   * Navigate back by one step (if possible)
   */
  const goBack = useCallback(() => {
    dispatch({
      type: ActionType.GO,
      payload: -1,
    });
  }, []);

  /**
   * Navigate to a specific step (if possible)
   * @param {T} to the step to navigate to
   */
  const go = useCallback(
    (to: T) => {
      const currIndex = findStepIndex(state);
      const newIndex = findStepIndex({ steps: state.steps, step: to });

      dispatch({
        type: ActionType.GO,
        payload: newIndex - currIndex,
      });
    },
    [state]
  );

  /**
   * Appends new step(s) after the current step and navigates to the first new step
   * Similar to history.push, it will remove all steps after the current step
   *
   * @param {T | [T]} steps to push onto the steps stack
   */
  const push = useCallback((steps: T | T[]) => {
    dispatch({
      type: ActionType.PUSH,
      payload: Array.isArray(steps) ? steps : [steps],
    });
  }, []);

  /**
   * Similar to push, but inserts new step(s) after the current step, and doesn't
   * remove anything after the current step
   *
   * @param {T | [T] | undefined} steps to insert into the steps stack
   */
  const insert = useCallback((steps: T | T[]) => {
    dispatch({
      type: ActionType.INSERT,
      payload: Array.isArray(steps) ? steps : [steps],
    });
  }, []);

  /**
   * Removes step(s) from the steps stack
   *
   * If no argument provided, removes the current step.
   * If the removed steps includes the current step, navigates to the first
   * previous step that was not removed (if possible)
   *
   * @param {T | [T] | undefined} step to remove into the steps stack
   */
  const remove = useCallback(
    (steps?: T | T[]) => {
      dispatch({
        type: ActionType.REMOVE,
        payload: Array.isArray(steps) ? steps : [steps || state.step],
      });
    },
    [state.step]
  );

  /**
   * Resets to the initial state
   */
  const reset = useCallback(() => {
    dispatch({
      type: ActionType.REPLACE,
      payload: { step: initialStep, steps: initialSteps },
    });
  }, [initialSteps, initialStep]);

  /**
   * Replaces the stepper state with the provided one
   *
   * @param {StepperState<T>} newState replaces steps and step
   */
  const replace = useCallback((newState: StepperState<T>["steps"]) => {
    dispatch({
      type: ActionType.REPLACE,
      payload: { step: newState[0], steps: newState },
    });
  }, []);

  return {
    ...state,
    dispatch,
    goForward,
    goBack,
    next: state.steps[findStepIndex(state) + 1],
    go,
    push,
    insert,
    remove,
    reset,
    replace,
  };
}
