import { InputModalContent } from "src/components/InnerModals";
import { UseFormReducerResult } from "src/hooks/useFormReducer";
import { createStyles } from "src/styles";

import { formatters } from "@fraction/shared";

import ReviewAndEdit from "../../../../components/ReviewAndEdit";

const styles = createStyles({
  reviewModalFooter: {
    marginTop: 32,
  },
});

const ReviewAddBankAccountScreen = ({
  formValues,
  onContinue,
  onBack,
  isLoading,
}: UseFormReducerResult & { onContinue: () => void; onBack: () => void; isLoading?: boolean }) => (
  <InputModalContent
    header="ACCOUNT REVIEW"
    paragraph="Below is a review of your bank account information."
    footerStyle={styles.reviewModalFooter}
    isLoading={isLoading}
    actions={[
      {
        text: "Back",
        action: onBack,
      },
      {
        text: "Create Account",
        action: onContinue,
      },
    ]}
  >
    <ReviewAndEdit label="Account nickname" value={formValues.name} onClickEdit={onBack} />
    <ReviewAndEdit
      label="Account type"
      value={formatters.string.capitalize(formValues.accountType)}
      onClickEdit={onBack}
    />
    <ReviewAndEdit label="Account number" value={formValues.accountNumber} onClickEdit={onBack} />
    <ReviewAndEdit label="ABA Routing number" value={formValues.routingNumber} onClickEdit={onBack} />
  </InputModalContent>
);

export default ReviewAddBankAccountScreen;
