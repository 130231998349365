import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function useEventualState<T>(state: T): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(state);

  useEffect(() => {
    setValue(state);
  }, [state]);

  return [value, setValue];
}
