import _ from "lodash";
import { useMutation } from "src/lib";
import { downloadFile } from "src/utilities/file/download";

export function useCsvDownload<T extends object>(data: T[], filename: string, rowFormatter: (row: T) => any) {
  return useMutation({
    mutationFn: async (event: any) => {
      const csvData = `${Object.keys(data[0])
        .map(_.startCase)
        .join(",")}\n${data.map((row) => Object.values(rowFormatter(row)).join(",")).join("\n")}`;

      const csv = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(csv);
      await downloadFile(url, filename, "text/csv");
    },
  });
}
