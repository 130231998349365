import {
  GroupingOverTimeTabs,
  groupingOverTimeTabNameMap,
} from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";

export const OverTimeTooltip = ({
  point,
}: {
  point: {
    serieId: string;
    data: {
      xFormatted: string;
      yFormatted: string;
      activeTab: GroupingOverTimeTabs;
      count: number;
      meta?: Record<string, string | number>;
    };
  };
}) => {
  return (
    <div className="shadow p-2 bg-white rounded">
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        {!point.data.meta
          ? null
          : Object.entries(point.data.meta)
              .filter(([, value]) => value)
              .map(([key, value]) => (
                <p key={key} className="text-sm text-gray-800 mt-0.5 font-sans">
                  <b>{key}: </b>
                  {value}
                </p>
              ))}
        <b>{point.serieId === "Washington" ? "State" : groupingOverTimeTabNameMap[point.data.activeTab]}: </b>
        {point.serieId}
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Date: </b>
        {point.data.xFormatted}
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Count: </b>
        {point.data.count}
      </p>
    </div>
  );
};
