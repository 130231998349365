import React, { ReactNode } from "react";
import { HeaderText } from "src/components/v1";
import { createStyles } from "src/styles";

import { OldDashboardHeader as DashboardHeader } from "../DashboardHeader";

export interface UnauthenticatedLayoutProps {
  header: ReactNode;
  children: ReactNode;
}

const styles = createStyles({
  centered: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    marginTop: 140,

    "@media(max-width: 600px)": {
      marginTop: 32,
      padding: "0px 32px",
    },
  },
  header: {
    fontWeight: 700,
    marginBottom: 40,
    "@media(max-width: 600px)": {
      fontSize: 28,
    },
    textAlign: "center",
  },
  content: {
    width: 390,
    "@media(max-width: 600px)": {
      width: "100%",
    },
  },
});

const UnauthenticatedLayout = ({ header, children }: UnauthenticatedLayoutProps) => (
  <>
    <DashboardHeader />
    <div css={styles.centered}>
      <HeaderText style={styles.header}>{header}</HeaderText>
      <div css={styles.content}>{children}</div>
    </div>
  </>
);

export default React.memo(UnauthenticatedLayout);
