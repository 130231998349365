import { ReactNode, memo } from "react";
import { cn } from "src/utilities/shadcnUtils";

const Avatar = ({
  active,
  children,
  src,
  className,
  title,
}: { active?: boolean; children: string | ReactNode; src?: string; className?: string; title?: string }) => {
  const name =
    typeof children === "string"
      ? children.length === 2
        ? children
        : children
            .split(" ")
            .map((name: string) => name[0]?.toUpperCase())
            .join("")
      : "avatar";

  const style = cn(
    "w-[40px] h-[40px] bg-green-100 rounded-full border-gray-200 flex items-center justify-center box-border text-sm",
    active && "border-2 border-green-200",
    className
  );

  if (src) {
    return <img title={title} className={style} alt={name} src={src} />;
  }

  return (
    <div title={title} className={style}>
      {typeof children === "string" ? <p className="font-bold text-center">{name}</p> : children}
    </div>
  );
};
export default memo(Avatar);
