import { ReactNode, memo, useCallback } from "react";
import { ModalBox } from "src/components";
import { PopupProps } from "src/components/Popup";
import { createStyles } from "src/styles";
import { z } from "zod";

import { entities, enums, parsers } from "@fraction/shared";

import ConfirmationModalScreen from "../../../../components/ConfirmationModalScreen";
import { PaymentScreen, usePaymentsModal } from "../../hooks/usePaymentsModal";
import CustomPaymentAmountScreen from "../sharedModalScreens/CustomPaymentAmount";
import PaymentAmountScreen from "../sharedModalScreens/PaymentAmountScreen";
import PaymentReviewScreen from "../sharedModalScreens/PaymentReviewScreen";

const styles = createStyles({
  confirmationModal: {
    padding: "56px 48px",
  },
});

export interface LoanDrawModalProps extends Omit<PopupProps, "children"> {
  onClose: () => void;
  isLoading?: boolean;
  bankAccounts?: entities.BankAccount[];
  availableToDraw: number;
  loan?: entities.LoanT;
}

const LoanDrawModal = ({ isLoading, bankAccounts, availableToDraw, loan, ...props }: LoanDrawModalProps) => {
  const {
    step,
    stepper,
    bankSetupScreens,
    currentBankAccount,
    paymentForm,
    paymentsMutation,
    handleSubmitPaymentOrder,
    onClose,
    handleSelectCustomAmount,
  } = usePaymentsModal({
    onClose: props.onClose,
    isLoading,
    bankAccounts,
    loan,
  });

  const schema = z.object({
    amount: parsers.types.positiveNumberString.refine(
      (n) => Number(n) <= availableToDraw / 100,
      "Amount must be less than remaining limit"
    ),
  });

  const handleSelectTotalAmount = useCallback(() => {
    paymentForm.updateField(availableToDraw / 100, "amount");
    stepper.push([PaymentScreen.PAYMENT_REVIEW, PaymentScreen.PAYMENT_CONFIRMATION]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.push, paymentForm.updateField, availableToDraw]);

  const submitLoanDraw = useCallback(
    () => handleSubmitPaymentOrder(enums.TransactionType.DRAW),
    [handleSubmitPaymentOrder]
  );

  // Mapping of possible modal states, returns a component to render and optional width override in the format: [component, width]
  const modalStates: Record<string, [ReactNode] | [ReactNode, number]> = {
    ...bankSetupScreens,
    [PaymentScreen.PAYMENT_AMOUNT]: [
      <PaymentAmountScreen
        onBack={stepper.goBack}
        header="ENTER DRAW AMOUNT"
        paragraph="You can draw the maximum amount or enter an exact value."
        buttonText="Total draw available"
        maxAmount={availableToDraw}
        handleSelectTotalAmount={handleSelectTotalAmount}
        handleSelectCustomAmount={handleSelectCustomAmount}
      />,
      428,
    ],
    [PaymentScreen.CUSTOM_PAYMENT_AMOUNT]: [
      <CustomPaymentAmountScreen
        onBack={stepper.goBack}
        onContinue={stepper.goForward}
        paragraph="Enter your custom draw amount."
        formValues={paymentForm.formValues}
        handleTextChange={paymentForm.handleTextChange}
        schema={schema}
      />,
      428,
    ],
    [PaymentScreen.PAYMENT_REVIEW]: [
      <PaymentReviewScreen
        bankAccount={currentBankAccount}
        header="LOAN DRAW REVIEW"
        paragraph="Below is a review of your draw."
        amountLabel="Draw amount"
        onContinue={submitLoanDraw}
        onBack={stepper.goBack}
        handleGoToScreen={stepper.go}
        formValues={paymentForm.formValues}
        isLoading={paymentsMutation.isPending}
      />,
      770,
    ],
    [PaymentScreen.PAYMENT_CONFIRMATION]: [
      <ConfirmationModalScreen
        style={styles.confirmationModal}
        header="LOAN DRAW PENDING"
        text="Your draw request has been recieved. Once your draw request has been approved, we will send you a confirmation email."
        onClose={onClose}
      />,
    ],
  };
  const [content, width] = modalStates[step];

  return (
    <ModalBox width={width || 448} {...props} onClose={onClose}>
      {content}
    </ModalBox>
  );
};

export default memo(LoanDrawModal);
