// @ts-nocheck

export async function initializeTwitterScript(key: string) {
  return new Promise((resolve) => {
    !(function (e, t, n, s, u, a) {
      e.twq ||
        // biome-ignore lint/suspicious/noAssignInExpressions: twitter script
        ((s = e.twq =
          function () {
            // biome-ignore lint/style/noArguments: twitter script
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
          }),
        // biome-ignore lint/suspicious/noAssignInExpressions: twitter script
        (s.version = "1.1"),
        // biome-ignore lint/suspicious/noAssignInExpressions: twitter script
        (s.queue = []),
        // biome-ignore lint/suspicious/noAssignInExpressions: twitter script
        (u = t.createElement(n)),
        // biome-ignore lint/suspicious/noAssignInExpressions: twitter script
        (u.async = !0),
        // biome-ignore lint/suspicious/noAssignInExpressions: twitter script
        (u.src = "https://static.ads-twitter.com/uwt.js"),
        // note - josh added this line to resolve the promise
        // biome-ignore lint/suspicious/noAssignInExpressions: twitter script
        (u.onload = () => resolve(twq)),
        // biome-ignore lint/suspicious/noAssignInExpressions: twitter script
        // biome-ignore lint/style/noCommaOperator: twitter script
        (a = t.getElementsByTagName(n)[0]),
        a.parentNode.insertBefore(u, a));
    })(window, document, "script");
    twq("config", key);

    return twq;
  });
}
