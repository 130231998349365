import { SerializedStyles } from "@emotion/react";
import { Ref, useCallback } from "react";
import RadioButton from "src/components/RadioButton";
import RadioGroup from "src/components/RadioGroup";
import { createStyles } from "src/styles";

export interface BooleanRadioOptionsProps {
  value?: boolean;
  onChange: (value: boolean) => void;
  style?: SerializedStyles;
  focusRef?: Ref<HTMLDivElement>;
}

const styles = createStyles({
  firstButton: {
    marginRight: 40,
  },
});

const BooleanRadioOptions = ({ value, onChange, style, focusRef }: BooleanRadioOptionsProps) => {
  const handleChange = useCallback(
    (change: string) => {
      onChange(change === "yes");
    },
    [onChange]
  );

  let textValue: string | undefined;
  if (value) {
    textValue = "yes";
  } else if (value === false) {
    textValue = "no";
  }

  return (
    <RadioGroup focusRef={focusRef} onChange={handleChange} value={textValue} style={style}>
      <RadioButton style={styles.firstButton} value="yes" label="Yes" />
      <RadioButton value="no" label="No" />
    </RadioGroup>
  );
};

export default BooleanRadioOptions;
