const logBase = (n: number, base: number) => Math.log(n) / Math.log(base);

export function logAllKeysExcept<
  ExceptKey extends string,
  Arr extends Omit<Record<string, number>, ExceptKey>
>(arr: Arr[], exceptKeys: string, base = 10): Omit<Record<keyof Arr, number>, typeof exceptKeys>[] {
  const exceptKeysArr = Array.isArray(exceptKeys) ? exceptKeys : [exceptKeys];
  return arr.map((item): Record<keyof Arr, number> => {
    // @ts-ignore
    return Object.keys(item).reduce((acc, key: keyof typeof item) => {
      if (exceptKeysArr.includes(key as string)) {
        acc[key] = item[key];
        return acc;
      }
      if (item[key] === 0) {
        acc[key] = 0;
        return acc;
      }
      acc[key] = base === 10 ? Math.log10(item[key]) : logBase(item[key], base);
      return acc;
    }, {} as Partial<Record<keyof Arr, number>>);
  });
}
