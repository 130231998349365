import { Outlet, Route, Routes } from "react-router-dom";
import { PortfolioDashboardHeader } from "src/apps/PortfolioDashboard/components/Header";
import { PropertyInfoDrawer } from "src/apps/PortfolioDashboard/components/PropertyInfoDrawer";
import { PortfolioAnalyticsScreen } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics";
import { PortfolioDataScreen } from "src/apps/PortfolioDashboard/screens/PortfolioData";
import PortfolioLoanTape from "src/apps/PortfolioDashboard/screens/PortfolioLoanTape";
import { PortfolioMapScreen } from "src/apps/PortfolioDashboard/screens/PortfolioMap";
import usePortfolioDashStore from "src/apps/PortfolioDashboard/store";
import { EditProfileScreen } from "src/apps/shared/EditProfile";
import { useAuth } from "src/auth";
import { DashboardShell, Heading, Loading } from "src/components";
import EnhanceRoute from "src/components/root/routeHelpers/EnhanceRoute";

const DoesNotHaveAPortfolio = () => {
  return (
    <div className="flex w-full h-full justify-center items-center pb-10">
      <Heading>Sorry, you do not have access to any mortgage portfolios</Heading>
    </div>
  );
};

const ServicingDashboardLayout = () => {
  const { user, isLoadingUser } = useAuth();
  const closeNavDrawer = usePortfolioDashStore((state) => state.closeNavDrawer);

  if (isLoadingUser) {
    return <Loading />;
  }

  return (
    <DashboardShell onClick={closeNavDrawer} header={<PortfolioDashboardHeader />}>
      {user?.servicingCustomer?.portfoliosManaging?.length ? <Outlet /> : <DoesNotHaveAPortfolio />}
      <PropertyInfoDrawer />
    </DashboardShell>
  );
};

export function PortfolioDashboardRoutes() {
  return (
    <Routes>
      <Route element={<ServicingDashboardLayout />}>
        <Route path="profile" element={<EditProfileScreen />} />,
        <Route
          key="/portfolio/*"
          path="portfolio/*"
          element={
            <EnhanceRoute title="Servicing overview | Fraction">
              <PortfolioMapScreen />
            </EnhanceRoute>
          }
        />
        <Route
          key="/portfolio/analytics"
          path="portfolio/analytics"
          element={
            <EnhanceRoute title="Portfolio Analytics | Fraction">
              <PortfolioAnalyticsScreen />
            </EnhanceRoute>
          }
        />
        <Route
          key="/portfolio/analytics"
          path=""
          element={
            <EnhanceRoute title="Portfolio Analytics | Fraction">
              <PortfolioAnalyticsScreen />
            </EnhanceRoute>
          }
        />
        <Route
          key="/portfolio/loan-tape"
          path="portfolio/loan-tape"
          element={
            <EnhanceRoute title="Portfolio Loan Tape | Fraction">
              <PortfolioLoanTape />
            </EnhanceRoute>
          }
        />
        <Route
          key="/portfolio/data"
          path="portfolio/data"
          element={
            <EnhanceRoute title="Portfolio Data | Fraction">
              <PortfolioDataScreen />
            </EnhanceRoute>
          }
        />
      </Route>
    </Routes>
  );
}
