import { ReactNode, cloneElement, useState } from "react";
import { TextInput } from "src/components";
import ModalBox from "src/components/ModalBox";
import { Button, RegularText } from "src/components/v1";
import { ButtonType } from "src/components/v1/Button";
import { useMutation } from "src/lib";
import { createStyles } from "src/styles";

import { PopupProps } from "../Popup";

export interface ModalAction {
  promptInput?: string;
  promptRequired?: boolean;
  text: string;
  type?: ButtonType;
  action?: (promptInput?: string) => any | Promise<any>;
  submit?: boolean;
  secondary?: boolean;
  close?: boolean;
  onClose?: () => void;
  disabled?: boolean;
  loading?: boolean;
  size?: "standard" | "small";
}

export interface ModalProps extends Omit<PopupProps, "children"> {
  message: ReactNode;
  header?: ReactNode;
  prompt?: string;
  actions?: ModalAction[];
  onClose?: () => void;
  children?: ReactNode;
}

const styles = createStyles({
  container: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 24,
    paddingBottom: 24,
  },
  text: {
    fontSize: 16,
  },
  button: {
    marginLeft: 16,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 12,
  },
  buttonContainerWithPrompt: {
    marginTop: 6,
  },
});

const ActionButton = ({
  text,
  action,
  type,
  onClose,
  submit,
  disabled,
  loading,
  promptInput,
  size,
}: ModalAction) => {
  const handleClickAction = useMutation({
    mutationFn: async (e: any) => {
      await action?.(promptInput);
      onClose?.();
    },
  });

  return (
    <Button
      style={styles.button}
      onClick={handleClickAction.mutate}
      type={type}
      submit={submit}
      loading={loading || handleClickAction.isPending}
      disabled={disabled}
      size={size}
    >
      {text}
    </Button>
  );
};

export const ModalInnards = ({
  onClose,
  message,
  header,
  actions,
  prompt,
  children,
  className,
}: ModalProps) => {
  const [modalInput, setModalInput] = useState("");
  return (
    <div css={styles.container} className={className}>
      {header ? <p className="text-2xl font-bold">{header}</p> : null}
      <RegularText style={styles.text} paragraph>
        {/* @ts-ignore */}
        {!message || typeof message === "string" ? message : cloneElement(message, { onClose })}
      </RegularText>
      {prompt ? (
        <TextInput
          containerClassName="mt-4"
          label={prompt}
          defaultValue={modalInput}
          onChange={setModalInput}
        />
      ) : null}
      {children}
      <div css={[styles.buttonContainer, prompt && styles.buttonContainerWithPrompt]}>
        {actions?.map((action, index) => (
          <ActionButton
            key={index}
            action={action.action}
            text={action.text}
            type={action.type}
            size={action.size}
            onClose={action.close === false ? undefined : onClose}
            promptInput={modalInput}
            disabled={action.disabled || (action.promptRequired && !modalInput?.trim()?.length)}
            loading={action.loading}
          />
        ))}
        {!actions && <ActionButton text="Okay" onClose={onClose} />}
      </div>
    </div>
  );
};

const Modal = ({ message, header, actions, prompt, ...props }: ModalProps) => (
  <ModalBox closeButton={false} {...props}>
    <ModalInnards header={header} prompt={prompt} message={message} actions={actions} />
  </ModalBox>
);

export default Modal;
