import { Saved, entities, utilities } from "@fraction/shared";
import _ from "lodash";

export interface FileTreeNode {
  [key: string]: FileTreeNode | Saved<entities.S3Document>;
}

/**
 * construct the directory structure from the s3Key.
 * e.g. /2023/08/21/foo.zip would be in the folders 2023, then 08, then 21.
 */
export function constructFileTreeSorted(
  documents: Saved<entities.S3Document>[],
  root: FileTreeNode = {} as FileTreeNode
): FileTreeNode {
  return utilities.object.deepSort(constructFileTree(documents, root), (a, b) => (a > b ? 1 : -1));
}

export function constructFileTree(
  documents: Saved<entities.S3Document & { __routingKey?: string }>[],
  root: FileTreeNode = {} as FileTreeNode
): FileTreeNode {
  const docs = documents.map((document) => ({
    path: (document.__routingKey || document.s3Key)?.split("/") || [],
    document,
  }));
  const node: FileTreeNode = root as FileTreeNode;

  for (const doc of docs) {
    const { path, document } = doc;
    const currentPath = path[0];

    if (path.length > 1) {
      if (!node[currentPath]) {
        node[currentPath] = {};
      }

      node[currentPath] = {
        // @ts-ignore
        ...(node[currentPath] || {}),
        ...constructFileTree(
          [
            {
              ...document,
              __routingKey: (document.__routingKey || document.s3Key)?.split("/").slice(1).join("/"),
            },
          ],
          // @ts-ignore
          node[currentPath]
        ),
      };
    } else if (currentPath) {
      // leaf node
      node[currentPath] = _.omit(document, "__routingKey");
    }
  }

  return root;
}
