import { ReactNode } from "react";
import { InputModalContent } from "src/components/InnerModals";
import { SemiBoldText } from "src/components/v1";
import { createStyles } from "src/styles";

import { constants, colors, enums } from "@fraction/shared";

const FRACTION_BANK_INFO = {
  swift: "GSCRUS33",
  aba: "026015079",
  bankName: "Goldman Sachs Bank USA",
  bankAddress: "200 West St, New York, NY, 10282",
  beneficiaryName: "FRACTION LENDING US INC",
  officeAddress: constants.AMERICAN_ADDRESS,
};

export interface WireInstructionsScreenProps {
  virtualAccountNumber?: string;
  isLoading?: boolean;
  onContinue: () => void;
  onBack: () => void;
  country?: string;
  portfolio?: enums.Portfolio;
}

const styles = createStyles({
  labelContainer: {
    display: "flex",
    flexDirection: "column",
    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: "160%",
    color: colors.palette.GREY_500,
  },
  text: {
    fontSize: 14,
    color: colors.palette.GREY_800,
    lineHeight: "160%",
  },
});

const TextWithLabel = ({ label, text }: { label: string; text: ReactNode }) => (
  <div css={styles.labelContainer}>
    <SemiBoldText style={styles.label}>{label}</SemiBoldText>
    <SemiBoldText style={styles.text}>{text}</SemiBoldText>
  </div>
);

const WireInstructionsScreen = ({
  country,
  virtualAccountNumber,
  isLoading,
  onBack,
  onContinue,
  portfolio,
}: WireInstructionsScreenProps) => {
  const wireInstructions = portfolio ? constants.getPaymentInstructions(portfolio) : undefined;
  const beneficiary = portfolio ? constants.getBeneficiary(portfolio) : undefined;

  return (
    <InputModalContent
      header="WIRE PAYMENT"
      paragraph="Log in to your online banking and select the wire payment option. Input the account information below."
      isLoading={isLoading}
      actions={[
        {
          text: "Back",
          action: onBack,
        },
        {
          text: "Okay",
          action: onContinue,
        },
      ]}
    >
      {country === "US" ? (
        <>
          <TextWithLabel label="ABA Routing" text={FRACTION_BANK_INFO.aba} />
          <TextWithLabel label="Beneficiary Bank" text={FRACTION_BANK_INFO.bankName} />
          <TextWithLabel label="Bank Address" text={FRACTION_BANK_INFO.bankAddress} />
          <TextWithLabel label="Beneficiary Name" text={FRACTION_BANK_INFO.beneficiaryName} />
          <TextWithLabel label="Beneficiary Account Number" text={virtualAccountNumber} />
        </>
      ) : (
        <>
          <TextWithLabel label="Beneficiary Bank" text={wireInstructions?.bankName} />
          <TextWithLabel label="Bank Address" text={wireInstructions?.bankAddress} />
          <TextWithLabel label="Beneficiary Name" text={beneficiary?.name} />
          <TextWithLabel
            label="Beneficiary Address"
            text={`${beneficiary?.addressLine1}, ${beneficiary?.addressLine2}`}
          />
          <TextWithLabel label="Beneficiary Account Number" text={wireInstructions?.account} />
          <TextWithLabel label="Institution" text={wireInstructions?.institution} />
          <TextWithLabel label="Transit/Branch Code" text={wireInstructions?.transit} />
          <TextWithLabel label="SWIFT Code" text={wireInstructions?.swift} />
          <TextWithLabel label="CC" text={wireInstructions?.cc} />
        </>
      )}
    </InputModalContent>
  );
};

export default WireInstructionsScreen;
