import { SerializedStyles } from "@emotion/react";
import { ReactElement, ReactNode } from "react";
import { SLIDER_THUMB_SIZE } from "src/components/Slider";
import { createStyles } from "src/styles";

export interface SliderWrapperProps {
  children: ReactElement | Array<ReactElement> | ReactNode;
  style?: SerializedStyles;
}

const styles = createStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    width: `calc(100% - ${SLIDER_THUMB_SIZE}px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

/**
 * The reason for having the SliderWrapper is because the thumb goes outside the width.
 * We don't necessarily want to set the width to include the thumb, because that messes
 * up alignment with any labels we may have. So this SliderWrapper wraps around it
 * with the width accounted for.
 */
const SliderWrapper = ({ children, style }: SliderWrapperProps) => (
  <div css={styles.container}>
    <div css={[styles.wrapper, style]}>{children}</div>
  </div>
);

export default SliderWrapper;
