import fraction from "src/api/fraction";
import { useMutation } from "src/lib";

import { BdmBrokerageSearchLayout } from "./Layout";

export default function BdmBrokerageSearch() {
  const brokerageSearch = useMutation({
    mutationFn: async (brokerage: string) => fraction.searchForBrokerage(brokerage),
  });

  const bdm = useMutation({
    mutationFn: async (brokerageId: string) => fraction.getBdmForBrokerage(brokerageId),
  });

  return (
    <BdmBrokerageSearchLayout
      onChooseBrokerage={bdm.mutate}
      brokerages={brokerageSearch?.data}
      onSubmit={brokerageSearch.mutate}
      loading={brokerageSearch.isPending}
      errorMessage={brokerageSearch.error?.message}
    />
  );
}
