import { useCallback, useEffect } from "react";
import { Logger } from "src/log";
import { z } from "zod";

import { IframeMessageType } from "@fraction/shared";

const LOGGER = new Logger("useAdminIframeMessages");

const IFRAME_MESSAGE_PARSER = z.object({
  type: z.nativeEnum(IframeMessageType),
  payload: z.any(),
});

const acceptableIframeMessageTypes = Object.values(IframeMessageType);

export type IframeMessage = z.infer<typeof IFRAME_MESSAGE_PARSER>;

const useIframeParentMessages = ({ onMessage }: { onMessage: (data: IframeMessage) => void }) => {
  const postMessage = useCallback((message: any) => {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(message, "*");
  }, []);

  useEffect(() => {
    // Create the listener for the iframe's parent events
    const listener = (event: MessageEvent) => {
      // Intercepting React dev tools internal events and our own "ready"
      if (event.data.source || event.data === "ready") {
        return;
      }
      try {
        LOGGER.log(`Received message with type ${event.data?.type}.`);
        if (!acceptableIframeMessageTypes.includes(event.data?.type)) {
          LOGGER.log(`We are not interested in message type ${event.data?.type}. Skipping.`);
          return;
        }
        LOGGER.log(`Message type ${event.data?.type} is acceptable. Parsing: ${JSON.stringify(event.data)}`);
        const iframeMessage = IFRAME_MESSAGE_PARSER.parse(event.data);
        LOGGER.log("Received message.");
        onMessage(iframeMessage);
      } catch (error) {
        LOGGER.exception(error, "Could not parse iframe message");
      }
    };
    window.addEventListener("message", listener);

    // Let the parent know we're listening
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage("ready", "*");

    // Destruct the listener on unmount
    return () => {
      window.removeEventListener("message", listener);
    };
  }, [onMessage]);

  return { postMessage };
};

export default useIframeParentMessages;
