import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import fraction from "src/api/fraction";
import useIframeParentMessages, { IframeMessage } from "src/hooks/useIframeParentMessages";
import useQueryParams from "src/hooks/useQueryParams";
import { Logger } from "src/log";

import { ChecklistType, IframeMessageType, verify } from "@fraction/shared";

const LOGGER = new Logger("IframeIOContext");

const IframeIOContext = createContext<IframeIOContextValue | null>(null);
IframeIOContext.displayName = "IframeIOContext";

export interface IframeIOContextValue {
  postMessage: (message: any) => void;
  checklistType?: ChecklistType;
  crmId?: string;
  secretSet: boolean;
}

export interface IframeIOProviderProviderProps {
  children: React.ReactNode;
}

const IframeIOProvider = ({ children }: IframeIOProviderProviderProps) => {
  const [checklistType, setChecklistType] = useState<ChecklistType>();
  const [crmId, setCrmId] = useState<string>();
  const [secretSet, setSecretSet] = useState<boolean>(false);
  const params = useQueryParams();

  // these are overrides if we want to set via url params (mostly for development)
  useEffect(() => {
    setCrmId(params.crmId);
  }, [params.crmId]);

  useEffect(() => {
    fraction.setHeaderSecret(params.secret);
    setTimeout(() => setSecretSet(true), 100);
  }, [params.secret]);

  const onMessage = useCallback(({ type, payload }: IframeMessage) => {
    if (typeof payload !== "string") {
      LOGGER.warn(`Invalid payload type: ${typeof payload}`);
      return;
    }
    switch (type) {
      case IframeMessageType.FRACTION_SECRET:
        if (payload) {
          fraction.setHeaderSecret(payload);
          setTimeout(() => setSecretSet(true), 100);
        }
        break;
      case IframeMessageType.CRX_SECRET:
        if (payload) {
          fraction.setHeaderSecret(payload);
          setTimeout(() => setSecretSet(true), 100);
        }
        break;
      case IframeMessageType.CHECKLIST_TYPE:
        if (verify.isEnum(ChecklistType, payload)) {
          setChecklistType(payload);
        }
        break;
      case IframeMessageType.CRM_ID:
        setCrmId(payload);
        break;
      default:
        LOGGER.warn(`Unknown message type: ${type}`);
        break;
    }
  }, []);

  const { postMessage } = useIframeParentMessages({ onMessage });

  const value = useMemo(
    () => ({
      checklistType,
      crmId,
      postMessage,
      secretSet,
    }),
    [checklistType, crmId, postMessage, secretSet]
  );

  return <IframeIOContext.Provider value={value}>{children}</IframeIOContext.Provider>;
};

function useIframeIO() {
  const context = useContext(IframeIOContext);
  if (!context) {
    throw new Error("Must be used within IFrameIOProvider");
  }
  return context;
}

function useIframeIOUnsafe() {
  return useContext(IframeIOContext);
}

const IframeIOConsumer = IframeIOContext.Consumer;

export { IframeIOProvider, useIframeIO, IframeIOConsumer, useIframeIOUnsafe };
