import { memo, useCallback } from "react";
import TextInput from "src/components/TextInput";
import { Button } from "src/components/v1";
import { SemiBoldText } from "src/components/v1/Text";
import { StatusChip } from "src/embeds/DocumentApprovalTool/components/StatusChip";
import { createStyles } from "src/styles";

import { enums } from "@fraction/shared";

const styles = createStyles({
  creationForm: {
    padding: 32,
  },
  modalTitle: {
    marginBottom: 30,
  },
  modalSubmit: {
    marginTop: 10,
    marginLeft: "auto",
  },
});

const ChangeDocumentStatusForm = ({
  onSubmit,
  previousStatus,
  targetStatus,
  defaultNotes,
}: {
  onSubmit: (opts: { status: enums.ApplicationTaskApprovalStatus; notes: string }) => void;
  previousStatus?: enums.ApplicationTaskApprovalStatus;
  targetStatus?: enums.ApplicationTaskApprovalStatus;
  defaultNotes?: string;
}) => {
  const handleSubmit = useCallback(
    (evt: any) => {
      evt.preventDefault();
      if (!targetStatus) {
        return;
      }
      onSubmit({ status: targetStatus, notes: evt.target.elements.notes.value });
    },
    [onSubmit, targetStatus]
  );

  return (
    <form onSubmit={handleSubmit} css={styles.creationForm}>
      <div css={styles.modalTitle}>
        {previousStatus && targetStatus && (
          <SemiBoldText>
            Change status from <StatusChip status={previousStatus} /> to <StatusChip status={targetStatus} />?
          </SemiBoldText>
        )}
      </div>
      <TextInput defaultValue={defaultNotes} name="notes" variant="v2" multiline label="Notes" />
      <Button submit style={styles.modalSubmit}>
        Submit
      </Button>
    </form>
  );
};

export default memo(ChangeDocumentStatusForm);
