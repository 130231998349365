import { enums, formatters } from "@fraction/shared";
import { useCallback } from "react";
import { useAuth } from "src/auth";
import { MultiSelect, Option } from "src/components/ui/multi-select";
import { cn } from "src/utilities/shadcnUtils";

const mapper = (portfolio: enums.Portfolio) => ({
  label: formatters.string.fromSnakeCase(portfolio),
  value: portfolio,
});

export function PortfolioSelect({
  onValueChange,
  className,
  values,
}: {
  values?: enums.Portfolio[];
  onValueChange?: (value: enums.Portfolio[]) => void;
  className?: string;
}) {
  const { user } = useAuth();

  const handleChange = useCallback(
    (opts: Option<enums.Portfolio>[]) => {
      onValueChange?.(opts.map(({ value }) => value));
    },
    [onValueChange]
  );

  const options = user?.servicingCustomer?.portfoliosManaging?.map(mapper) || [];

  return (
    <MultiSelect
      buttonClassName="h-8 items-start"
      className={cn("col-span-2", className)}
      onChange={handleChange}
      options={options}
      defaultValues={options}
      values={values?.map(mapper)}
    />
  );
}
