import { formatters } from "@fraction/shared";
import { DollarSignIcon, HashIcon, LucideIcon } from "lucide-react";
import { usePortfolioOverview } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";
import { Card, CardContent, CardHeader, CardTitle } from "src/components/ui/card";
import { cn } from "src/utilities/shadcnUtils";

const StyledCard = ({
  title,
  Icon,
  content,
  subtitle,
  loading,
  short,
}: {
  title: string;
  Icon: LucideIcon;
  content: string;
  subtitle?: string;
  loading?: boolean;
  short?: boolean;
}) =>
  loading ? (
    <Skeleton height={116} width={300} />
  ) : (
    <Card className={cn("w-full max-w-[300px]", short && "h-fit rounded-lg")}>
      <CardHeader
        className={cn("flex flex-row items-center justify-between space-y-0 pb-0.5", short && "pt-3")}
      >
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <Icon className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent className={short ? "pb-3" : undefined}>
        <div className="text-2xl font-bold">{content}</div>
        {subtitle ? <p className="text-xs text-muted-foreground">{subtitle}</p> : null}
      </CardContent>
    </Card>
  );

const getChangePercentage = (current?: number, previous?: number) => {
  if (current === undefined || previous === undefined) {
    return "";
  }
  const sign = current - previous > 0 ? "+" : "";
  return `${sign}${formatters.number.getPercentageFromDecimal((current - previous) / previous)}`;
};

export const PortfolioOverviewCards = () => {
  const { data, isLoading } = usePortfolioOverview();

  return (
    <div className="w-full col-span-full gap-2 flex flex-col">
      <div className="flex flex-row w-full h-fit gap-2 col-span-full flex-wrap">
        <StyledCard
          loading={isLoading}
          title="Portfolio Balance"
          content={formatters.number.getCurrencyFromNumber(data?.portfolioBalance || 0)}
          Icon={DollarSignIcon}
          subtitle={`${getChangePercentage(
            data?.portfolioBalance || 0,
            data?.portfolioBalanceLastMonth || 0
          )} from last month`}
        />
        <StyledCard
          loading={isLoading}
          title="Portfolio Principal"
          content={formatters.number.getCurrencyFromNumber(data?.portfolioPrincipal || 0)}
          Icon={DollarSignIcon}
          subtitle={`${getChangePercentage(
            data?.portfolioPrincipal || 0,
            data?.portfolioPrincipalLastMonth || 0
          )} from last month`}
        />
        <StyledCard
          loading={isLoading}
          title="Total Property Value"
          content={formatters.number.getCurrencyFromNumber(data?.totalPropertyValue || 0)}
          Icon={DollarSignIcon}
          subtitle={`${getChangePercentage(
            data?.totalPropertyValue || 0,
            data?.totalPropertyValueLastMonth || 0
          )} from last month`}
        />
        <StyledCard
          loading={isLoading}
          title="Total Payouts"
          content={formatters.number.getCurrencyFromNumber(data?.totalPayouts || 0)}
          Icon={DollarSignIcon}
          subtitle={`${getChangePercentage(
            data?.totalPayouts || 0,
            data?.totalPayoutsLastMonth || 0
          )} from last month`}
        />
        <StyledCard
          loading={isLoading}
          title="Total Dollars Invested"
          content={formatters.number.getCurrencyFromNumber(data?.totalDollarsInvested || 0)}
          Icon={DollarSignIcon}
          subtitle={`Balance + Payouts: ${formatters.number.getCurrencyFromNumber(
            (data?.portfolioBalance || 0) + (data?.totalPayouts || 0)
          )}`}
        />
      </div>
      <div className="flex flex-row w-full h-fit gap-2 col-span-full flex-wrap">
        <StyledCard
          short
          loading={isLoading}
          title="Total Number of Loans Funded"
          content={(data?.totalNumberOfLoans || 0).toString()}
          Icon={HashIcon}
        />
        <StyledCard
          short
          loading={isLoading}
          title="Total Number of Payouts"
          content={(data?.totalNumberOfPayouts || 0).toString()}
          Icon={HashIcon}
        />
        <StyledCard
          short
          loading={isLoading}
          title="Total Number of Active Loans"
          content={(data?.totalNumberOfActiveLoans || 0).toString()}
          Icon={HashIcon}
        />
        <StyledCard
          short
          loading={isLoading}
          title="Total Number of Payouts in Progress"
          content={(data?.totalNumberOfPayoutsInProgress || 0).toString()}
          Icon={HashIcon}
        />
      </div>
    </div>
  );
};
