import { ReactNode } from "react";
import { createStyles } from "src/styles";

const styles = createStyles(({ spacing, colors }) => ({
  container: {
    width: 290,
    height: `calc(100vh - ${spacing[8]}px)`, // full height minus header height
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: spacing[3],
    borderRight: "solid 1px",
    borderColor: colors.darkgrey200,
  },
  divider: {
    width: "100%",
    border: "none",
    borderTop: "solid 1px",
    borderColor: colors.darkgrey200,
    margin: `${spacing[1.5]}px 0`,
  },
}));

const SideBar = ({
  children,
  footer,
  divider,
}: {
  children: ReactNode;
  footer?: ReactNode;
  divider?: boolean;
}) => {
  return (
    <nav css={styles.container}>
      <div>{children}</div>
      <div>
        {divider && <hr css={styles.divider} />}
        {footer && <div>{footer}</div>}
      </div>
    </nav>
  );
};

export default SideBar;
