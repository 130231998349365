import { calculators, notUndefinedOrNull, parsers } from "@fraction/shared";
import { useMemo } from "react";
import {
  useBankAccountsQuery,
  useLoanQuery,
  usePaymentSubscriptionQuery,
} from "src/apps/PostFundedDashboard/queries";
import BankAccountsModal from "src/apps/PostFundedDashboard/v1/PostFundedDashboard/components/BankAccountsModal";
import LoanDrawModal from "src/apps/PostFundedDashboard/v1/PostFundedDashboard/components/LoanDrawModal";
import PrepaymentModal from "src/apps/PostFundedDashboard/v1/PostFundedDashboard/components/PrepaymentModal";
import RecurringPaymentModal from "src/apps/PostFundedDashboard/v1/PostFundedDashboard/components/RecurringPaymentModal";
import { Toggle } from "src/hooks/useToggle";
import { Logger } from "src/log";

const logger = new Logger("PostFundedDashboard.PaymentModals");

export function PaymentModals({
  prepaymentToggle,
  loanDrawToggle,
  recurringPaymentToggle,
  paymentSubscriptionCreateToggle,
  bankAccountToggle,
}: {
  prepaymentToggle: Toggle;
  loanDrawToggle: Toggle;
  recurringPaymentToggle: Toggle;
  bankAccountToggle: Toggle;
  paymentSubscriptionCreateToggle: Toggle;
}) {
  const { data: loan } = useLoanQuery();
  const bankAccountsQuery = useBankAccountsQuery();
  const subscriptionsQuery = usePaymentSubscriptionQuery(loan?.id || "");

  const { availableToDraw, availableToPay } = useMemo(() => {
    if (notUndefinedOrNull(loan)) {
      try {
        const parsedLoan = parsers.loan.parseAvailableToDrawLoan(loan);
        return {
          availableToDraw: calculators.loan.getAvailableDrawAmount(parsedLoan),
          availableToPay: calculators.loan.getAvailableToPayAmount(parsedLoan),
        };
      } catch (err) {
        logger.exception(err, `Error calculating available to draw with ${loan}`);
      }
    }
    return {
      availableToDraw: 0,
      availableToPay: 0,
    };
  }, [loan]);

  const subscriptions = subscriptionsQuery.data;

  return (
    <>
      <BankAccountsModal
        open={bankAccountToggle.on}
        onClose={bankAccountToggle.setOff}
        bankAccounts={bankAccountsQuery.data}
        isLoading={bankAccountsQuery.isLoading}
        loan={loan}
      />
      <LoanDrawModal
        open={loanDrawToggle.on}
        onClose={loanDrawToggle.setOff}
        bankAccounts={bankAccountsQuery.data}
        isLoading={bankAccountsQuery.isLoading}
        availableToDraw={availableToDraw}
        loan={loan}
      />
      <PrepaymentModal
        open={prepaymentToggle.on}
        onClose={prepaymentToggle.setOff}
        bankAccounts={bankAccountsQuery.data}
        isLoading={bankAccountsQuery.isLoading || subscriptionsQuery.isLoading}
        loan={loan}
        availableToPay={availableToPay}
        virtualAccountNumber={loan?.virtualAccount?.accountNumber || "N/A"}
        subscriptions={subscriptions}
      />
      <RecurringPaymentModal
        open={recurringPaymentToggle.on}
        onClose={recurringPaymentToggle.setOff}
        subscription={subscriptions?.[0]}
      />
      <PrepaymentModal
        open={paymentSubscriptionCreateToggle.on}
        onClose={paymentSubscriptionCreateToggle.setOff}
        bankAccounts={bankAccountsQuery.data}
        isLoading={bankAccountsQuery.isLoading || subscriptionsQuery.isLoading}
        loan={loan}
        availableToPay={availableToPay}
        virtualAccountNumber={loan?.virtualAccount?.accountNumber || "N/A"}
        subscriptions={subscriptions}
        mode="recurring"
      />
    </>
  );
}
