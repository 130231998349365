import { useState } from "react";
import fraction from "src/api/fraction";
import { PrimaryButton } from "src/components";
import { BdmBrokerageSearchLayout } from "src/embeds/BdmBrokerageSearch/Layout";
import { Logo } from "src/images";
import { useMutation } from "src/lib";

import { BdmCalendarLayout } from "./Layout";

export default function BdmCalendar() {
  const postalCodeQuery = useMutation({
    mutationFn: async (postalCode: string) => fraction.getPostalScore(postalCode),
    onSuccess: ({ bdm }) => {
      if (bdm?.calendarLink) {
        window.location.href = bdm.calendarLink;
      }
    },
  });

  const brokerageSearch = useMutation({
    mutationFn: async (brokerage: string) => fraction.searchForBrokerage(brokerage),
  });

  const bdm = useMutation({
    mutationFn: async (brokerageId: string) => fraction.getBdmForBrokerage(brokerageId),
    onSuccess: (bdm) => {
      if (bdm?.calendarLink) {
        window.location.href = bdm.calendarLink;
      }
    },
  });

  const [usePostalCode, setUsePostalCode] = useState(false);

  return (
    <div className="flex flex-col gap-5 items-center h-[90svh] w-full p-5 absolute top-[10%]">
      {usePostalCode ? (
        <BdmCalendarLayout
          onSubmit={postalCodeQuery.mutate}
          loading={postalCodeQuery.isPending}
          errorMessage={postalCodeQuery.error?.message}
        />
      ) : null}
      {!usePostalCode ? (
        <div className="flex flex-col gap-5 items-center justify-center">
          <Logo width={120} height={25} />
          <BdmBrokerageSearchLayout
            onChooseBrokerage={bdm.mutate}
            brokerages={brokerageSearch?.data}
            onSubmit={brokerageSearch.mutate}
            loading={brokerageSearch.isPending || bdm.isPending}
            errorMessage={brokerageSearch.error?.message}
          />
        </div>
      ) : null}
      {!usePostalCode ? (
        <PrimaryButton onClick={() => setUsePostalCode(true)} variant="inverted" size="small">
          Can't find your brokerage?
        </PrimaryButton>
      ) : null}
    </div>
  );
}
