import { formatters } from "@fraction/shared";
import { ResponsivePie } from "@nivo/pie";
import { useCallback, useMemo } from "react";
import { Skeleton } from "src/components";
import { useTabs } from "src/hooks";
import useWindowSize from "../../../../../hooks/useWindowSize";

import { ChartCard } from "../ChartCard";
import { useCollateralGroupings } from "../hooks";

const MARGIN = { top: 40, right: 70, bottom: 20, left: 70 };
const ARCLABEL_TEXT_COLOR = {
  from: "color",
  modifiers: [["darker", 2]],
};
const ARCLINK_LABELS_COLOR = { from: "color" };
const BORDER_COLOR = {
  from: "color",
  modifiers: [["darker", 0.2]],
};

export function CollateralGroupings() {
  const { data, isLoading } = useCollateralGroupings();
  const { width } = useWindowSize();
  const isMobile = !!width && width < 480;

  const { Tabs, activeTab } = useTabs({
    className: "flex-wrap flex w-fit h-min",
    containerClassName: "flex flex-col items-end",
    tabsPerRow: isMobile ? 2 : 3,
    options:
      data?.map((d) => ({
        label: d.group === "propertyValuation" ? "Value" : formatters.string.fromCamelCase(d.group),
        value: d.group,
      })) || [],
    defaultValue: "type",
  });

  const renderTooltip = useCallback((props: any) => {
    return (
      <div className="shadow p-2 bg-white rounded">
        <p className="text-sm text-gray-800 mt-0.5 font-sans">
          <b>{props.datum?.label}</b>
        </p>
        <p className="text-sm text-gray-800 mt-0.5 font-sans">
          <b>Count:</b> {props.datum.data.value}
        </p>
        <p className="text-sm text-gray-800 mt-0.5 font-sans">
          <b>Total Amount:</b> {formatters.number.getCurrencyWords(props.datum.data.fundedAmount)}
        </p>
      </div>
    );
  }, []);

  const activeData = useMemo(() => {
    const selectedData = data?.find((datum) => datum.group === activeTab);

    return selectedData?.data?.map((d) => {
      const label =
        selectedData.format === "currency"
          ? formatters.number.getCurrencyWords(d.group)
          : selectedData.format === "sqft"
          ? `${d.group} sqft`
          : selectedData.format === "snake"
          ? formatters.string.fromSnakeCase(d.group)
          : d.group;

      return {
        id: label,
        label,
        value: d.count,
        fundedAmount: d.fundedAmount,
        format: selectedData.format,
      };
    });
  }, [activeTab, data]);

  if (isLoading) {
    return (
      <div className="col-span-1">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  if (!activeData) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={activeData.length === 0}
      headerComponents={<Tabs />}
      className="min-h-[300px]"
      header="Collateral"
    >
      <ResponsivePie
        // @ts-ignore
        data={activeData}
        height={300}
        devicePixelRatio={2}
        margin={MARGIN}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        // @ts-ignore
        borderColor={BORDER_COLOR}
        tooltip={renderTooltip}
        // @ts-ignore
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={ARCLINK_LABELS_COLOR}
        arcLinkLabelsSkipAngle={10}
        arcLabelsSkipAngle={10}
        // @ts-ignore
        arcLabelsTextColor={ARCLABEL_TEXT_COLOR}
      />
    </ChartCard>
  );
}
