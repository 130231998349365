export default async function loadScript({
  url,
  body,
  id,
}: {
  url?: string;
  body?: string;
  id?: string;
}): Promise<void> {
  if (url) {
    return loadScriptUrl(url);
  } else if (body && id) {
    return loadScriptBody(body, id);
  }
}

function loadScriptUrl(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!document.querySelectorAll(`[src="${url}"]`).length) {
      document.body.appendChild(
        Object.assign(document.createElement("script"), {
          type: "text/javascript",
          src: url,
          onload: resolve,
        })
      );
    } else {
      resolve();
    }
  });
}

function loadScriptBody(body: string, id: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!document.getElementById(id)) {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        onload: resolve,
      });
      const inlineScript = document.createTextNode(body);
      script.appendChild(inlineScript);
      document.body.appendChild(script);
    } else {
      resolve();
    }
  });
}
