import { HTMLAttributes, ReactNode } from "react";
import { useClipboard } from "src/hooks/useClipboard";
import { cn } from "src/utilities/shadcnUtils";

export const ClipboardText = ({
  className,
  children,
  toCopy,
  ...props
}: { children: ReactNode; toCopy?: string; className?: string } & HTMLAttributes<HTMLParagraphElement>) => {
  const onClick = useClipboard(toCopy || (typeof children === "string" ? children : ""));

  return (
    <button className="w-fit text-left" onClick={onClick}>
      <p className={cn("text-black hover:font-semibold", className)} {...props}>
        {children}
      </p>
    </button>
  );
};
