import { entities, enums, formatters, isStatusBeforeStatus, selectors, utilities } from "@fraction/shared";
import { isSameDay, parseISO } from "date-fns";
import _ from "lodash";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { AppStatusBadge } from "src/components/AppStatusBadge";
import { KeyValue } from "src/components/KeyValue";
import { ModifiableKeyValue } from "src/components/ModifiableKeyValue";
import { ModifiableTextArea } from "src/components/ModifiableTextArea";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { cn } from "src/utilities/shadcnUtils";

const AmountValue = ({ value, valuation }: { value: number; valuation?: number }) => {
  return (
    <p>
      {formatters.number.getCurrencyFromNumber(value)}
      {valuation ? (
        <span className="text-[8px] font-medium bg-gray-400 rounded-full p-1 px-2 ml-1 align-middle">
          {formatters.number.getPercentageFromDecimal(value / valuation)} LTV
        </span>
      ) : (
        ""
      )}
    </p>
  );
};

export const ApplicationOverview = ({
  loading,
  app,
  className,
  header,
  showHeader = true,
}: {
  loading?: boolean;
  app?: entities.ApplicationT;
  className?: string;
  header?: string;
  showHeader?: boolean;
}) => {
  const { preference } = useAccountViewPreference();

  const handleCloseDateChange = useCallback((dateStr: string) => {
    const date = parseISO(dateStr);

    const { date: closestPreviousTuesdayOrThursday, day } =
      utilities.bankHolidays.findClosestPreviousTuesdayOrThursday(date);

    if (date && closestPreviousTuesdayOrThursday && !isSameDay(date, closestPreviousTuesdayOrThursday)) {
      toast.info(
        `Fraction only funds on Tuesdays and Thursdays that are not bank holidays. Setting the requested close date to the previous ${day}.`
      );
    }

    return formatters.date.iso8601(closestPreviousTuesdayOrThursday);
  }, []);

  const canModifyEstimatedCloseDate =
    preference === "employee" ||
    (preference === "conveyancer" &&
      app?.status &&
      isStatusBeforeStatus(app?.status, enums.ApplicationStatus.READY_FOR_CAPITAL_CALL, true));

  const valuation = app ? selectors.application.selectBestValuation(app)?.value : undefined;

  return (
    <div
      className={cn(
        "border p-6 border-gray-400 rounded max-w-full md:max-w-3xl overflow-x-scroll",
        className
      )}
    >
      {showHeader ? (
        <div>
          <div className="flex flex-row flex-wrap gap-2 mb-3 justify-between items-center">
            <p className="text-2xl font-bold">{header ? header : "Application overview"}</p>
            <AppStatusBadge appId={app?.id} className="float-right" loading={loading} status={app?.status} />
          </div>
          <p className="text-md mb-3">
            This is an overview of your loan application details. If anything looks incorrect, please let us
            know.
          </p>
        </div>
      ) : null}
      <div className="flex flex-col [&>*:nth-child(odd)]:bg-gray-200 [&>*:nth-child(even)]:white hover:[&>*]:bg-gray-300">
        <KeyValue label="Application ID" clipboard loading={loading} value={app?.eid} />
        {app?.declineReason ? (
          <KeyValue label="Decline reason(s)" loading={loading} value={app.declineReason} />
        ) : null}
        <KeyValue
          label="Property address"
          loading={loading}
          value={formatters.property.formattedAddress(app?.property)}
        />
        {app?.property?.type ? (
          <KeyValue
            label="Property type"
            loading={loading}
            value={formatters.enums.FULL_PROPERTY_TYPE_MAP[app?.property?.type]}
          />
        ) : null}
        {app?.property?.usage ? (
          <KeyValue
            label="Property usage"
            loading={loading}
            value={formatters.enums.PROPERTY_USAGE_MAP[app?.property?.usage]}
          />
        ) : null}
        <ModifiableKeyValue
          appId={app?.id}
          label="Solicitor name"
          tooltip="The name of the client's solicitor/lawyer for their ILR/ILA."
          inputClassName="w-full"
          loading={loading}
          path="solicitor.name"
          type="text"
        />
        <ModifiableKeyValue
          appId={app?.id}
          inputClassName="w-full"
          tooltip="The law firm of the client's solicitor."
          label="Solicitor firm"
          loading={loading}
          path="solicitor.lawFirmName"
          type="text"
        />
        <ModifiableKeyValue
          appId={app?.id}
          label="Solicitor email"
          tooltip="The email our conveyancer can contact the solicitor at."
          inputClassName="w-full"
          loading={loading}
          path="solicitor.email"
          type="email"
        />
        <ModifiableKeyValue
          appId={app?.id}
          label="Solicitor phone"
          tooltip="The phone number our conveyancer can contact the solicitor at."
          inputClassName="w-full"
          loading={loading}
          path="solicitor.phone"
          type="tel"
        />
        <KeyValue
          label="Use of funds"
          loading={loading}
          value={
            app?.useOfFundsDescription
              ? app?.useOfFundsDescription
              : app?.useOfFundsChoices?.map(_.startCase).filter(Boolean)?.join(", ")
          }
        />
        {app?.maximumLTV ? (
          <KeyValue
            label="Max LTV"
            loading={loading}
            tooltip="The maximum loan-to-value ratio Fraction is willing to offer for this property for the selected term length."
            value={formatters.number.getPercentageFromDecimal(app?.maximumLTV)}
          />
        ) : null}
        {app && selectors.application.selectLatestAppraisal(app)?.value ? (
          <KeyValue
            label="Appraised value"
            loading={loading}
            value={formatters.number.getCurrencyFromNumber(
              selectors.application.selectBestValuation(app)?.value
            )}
          />
        ) : null}
        {app?.confirmedLoanAmount ? (
          <KeyValue
            label="Confirmed loan amount"
            loading={loading}
            value={<AmountValue valuation={valuation} value={app?.confirmedLoanAmount} />}
          />
        ) : app?.approvedAmount ? (
          <KeyValue
            label="Approved loan amount"
            loading={loading}
            value={<AmountValue valuation={valuation} value={app?.approvedAmount} />}
          />
        ) : null}
        <KeyValue
          label="Total loan amount w/ origination fee"
          loading={loading}
          value={formatters.number.getCurrencyFromNumber(
            selectors.application.selectApplicationTotalAmount(app)
          )}
        />
        {app?.appraisalPaymentToAppraiserDate ? (
          <KeyValue
            label="Appraisal ordered"
            loading={loading}
            value={formatters.date.formatDateNoYearAndDistance(app.appraisalPaymentToAppraiserDate, {
              addSuffix: true,
            })}
          />
        ) : null}
        {app?.appraisalAppointmentDate ? (
          <KeyValue
            label="Appraisal appointment"
            loading={loading}
            value={formatters.date.formatDateNoYearAndDistance(app.appraisalAppointmentDate, {
              addSuffix: true,
            })}
          />
        ) : null}
        <KeyValue
          label="Origination fee"
          loading={loading}
          tooltip="The origination fee is a one-time fee charged by Fraction to cover the costs of processing the loan."
          value={
            app?.originationFeeInCents ? (
              <p>
                {formatters.number.getCurrencyFromNumber(app?.originationFeeInCents)}{" "}
                <span className="text-[8px] font-medium bg-gray-400 rounded-full py-1 px-2 align-middle">
                  {formatters.number.getPercentageFromDecimal(
                    app?.originationFeeInCents / selectors.application.selectApplicationAmount(app)
                  )}{" "}
                  of loan
                </span>
              </p>
            ) : (
              "Not yet set"
            )
          }
        />
        {preference !== "conveyancer" ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Requested close date"
            loading={loading}
            path="requestedCloseDate"
            type="date"
            formatValue={formatters.date.iso8601}
            mutateValueBeforeUpdate={handleCloseDateChange}
          />
        ) : null}
        {preference !== "conveyancer" ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Requested loan amount"
            loading={loading}
            path="requestedAmount"
            cents
          />
        ) : null}
        <ModifiableTextArea
          label="Exit plan notes"
          loading={loading}
          appId={app?.id}
          path="exitPlanNotes"
          value={app?.exitPlanNotes}
          tooltip="A brief description of the exit plan for the loan by the end of term, i.e. how the Fraction mortgage will be paid out."
        />

        {app?.estimatedCloseDate && canModifyEstimatedCloseDate ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Estimated close date"
            loading={loading}
            path="estimatedCloseDate"
            type="date"
            formatValue={formatters.date.iso8601}
            mutateValueBeforeUpdate={handleCloseDateChange}
          />
        ) : null}
        {["employee", "conveyancer"].includes(preference) ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Signing appointment date"
            loading={loading}
            path="targetILRAppointmentDate"
            type="date"
            formatValue={formatters.date.iso8601}
          />
        ) : app?.targetILRAppointmentDate ? (
          <KeyValue
            label="Signing appointment date"
            loading={loading}
            value={formatters.date.formatDate(app.targetILRAppointmentDate)}
          />
        ) : null}
        {app?.estimatedCloseDate && !canModifyEstimatedCloseDate ? (
          <KeyValue
            label="Estimated close date"
            loading={loading}
            value={formatters.date.formatDate(app.estimatedCloseDate)}
          />
        ) : null}
        {app?.advanceDate && ["employee"].includes(preference) ? (
          <ModifiableKeyValue
            appId={app?.id}
            label="Advance date"
            loading={loading}
            path="advanceDate"
            type="date"
            formatValue={formatters.date.iso8601}
          />
        ) : null}
        {app?.advanceDate && !["employee"].includes(preference) ? (
          <KeyValue
            label="Advance date"
            loading={loading}
            value={formatters.date.formatDate(app.advanceDate)}
          />
        ) : null}

        {app?.closeDate ? (
          <KeyValue
            label="Official close date"
            loading={loading}
            value={formatters.date.formatDate(app.closeDate)}
          />
        ) : null}
        {_.sortBy(app?.applicants, "priority")?.map((applicant, index) => (
          <KeyValue
            key={applicant.id}
            label={`Applicant #${index + 1}`}
            loading={loading}
            value={`${formatters.user.userName(applicant.user)} ${
              applicant.user?.email ? `(${applicant.user.email})` : ""
            }`}
          />
        ))}
        {preference === "employee" ? (
          <KeyValue label="Conveyancer" loading={loading} value={app?.conveyancerGroup?.name} />
        ) : null}
        {["conveyancer", "employee"].includes(preference) ? (
          <ModifiableTextArea
            label="Conveyancer notes"
            loading={loading}
            appId={app?.id}
            path="conveyancerNotes"
            value={app?.conveyancerNotes}
          />
        ) : null}
      </div>
    </div>
  );
};
