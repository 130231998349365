import { useCallback, useState } from "react";

interface FocusTracker {
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export default function useFocusTracker(props?: FocusTracker) {
  const [focused, setFocused] = useState(false);

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFocused(true);
      props?.onFocus?.(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.onFocus]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFocused(false);
      props?.onBlur?.(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.onBlur]
  );

  return {
    focused,
    onBlur: handleBlur,
    onFocus: handleFocus,
  };
}
