import { useMemo } from "react";
import { useDocumentsQuery } from "src/apps/PostFundedDashboard/queries";
import DashboardPage from "src/components/DashboardPage";
import { DocumentRow } from "src/components/DocumentRow";
import { useApplicationsQuery } from "src/hooks/useApplication";
import { Document } from "src/images";

export function Documents() {
  const { data: apps } = useApplicationsQuery({ accountType: "applicant", status: "active" });
  const app = useMemo(() => apps?.[0], [apps]);
  const { data, isLoading } = useDocumentsQuery(app?.id || "");

  return (
    <DashboardPage
      heading="Documents"
      subHeading="View and download documents associated to your application"
    >
      {data?.map((document) => (
        <DocumentRow key={document.id} document={document} />
      ))}
      {!isLoading && !data?.length ? (
        <div className="mt-4 max-w-3xl flex flex-row items-center gap-x-8 bg-gray-200 rounded-xl justify-center p-8 border-gray-300 border">
          <Document className="h-[125px] w-[125px]" />
          <p className="text-lg font-medium">
            It looks like you haven't
            <br />
            uploaded any documents yet.
          </p>
        </div>
      ) : null}
    </DashboardPage>
  );
}
