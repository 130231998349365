import { ResponsiveRadar } from "@nivo/radar";
import { useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { usePropertyTypes } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { useToggler } from "src/hooks";
import { logAllKeysExcept } from "src/stats";

import { formatters } from "@fraction/shared";

import { ChartCard } from "../ChartCard";

const LEGENDS = [
  {
    anchor: "top-left",
    direction: "column",
    translateX: -100,
    translateY: -50,
    itemWidth: 80,
    itemHeight: 20,
    itemTextColor: "#999",
    symbolSize: 12,
    symbolShape: "circle",
    effects: [
      {
        on: "hover",
        style: {
          itemTextColor: "#000",
        },
      },
    ],
  } as const,
];

const COLORS = { scheme: "set1" } as const;
const DOT_COLOR = { theme: "background" };
const MARGIN = { top: 50, right: 100, bottom: 40, left: 120 };
const BORDER_COLOR = { from: "color" };
const KEYS = ["British Columbia", "Ontario", "Washington", "All"];

export function PropertyTypeChart() {
  const { data: propertyTypes, isLoading } = usePropertyTypes();
  const { Toggle, on: propertyTypesLog } = useToggler({
    id: "logPropertyTypes",
    label: "Logarithmic",
    defaultValue: true,
  });

  const valueFormatter = useCallback(
    (value: number, label: string) => {
      const trueValue = propertyTypesLog ? (value === 0 ? 0 : 10 ** value).toFixed(0) : value.toFixed(0);
      // finding the percentage is a massive hack since they just give us the inner label for "label", so
      // we need to find the corresponding percentage label and use that instead
      const percentage = propertyTypes?.find(
        (propertyType) => propertyType[label]?.toFixed(0) === trueValue
      )?.[`${label}-percent`];

      return (
        <>
          <b>{trueValue}</b> ({formatters.number.getPercentageFromDecimal(percentage)})
        </>
      );
    },
    [propertyTypes, propertyTypesLog]
  );

  if (isLoading) {
    return <Skeleton width="100%" height={389} />;
  }

  if (!propertyTypes) {
    return null;
  }

  const data = propertyTypesLog ? logAllKeysExcept(propertyTypes, "type") : propertyTypes;
  // @ts-ignore
  const isEmpty = data?.every((value) => value.All === 0);

  return (
    <ChartCard
      isEmpty={isEmpty}
      header="Property types"
      className="min-h-[300px]"
      headerComponents={
        <>
          <Toggle />
        </>
      }
    >
      <ResponsiveRadar
        data={data}
        keys={KEYS}
        indexBy="type"
        // @ts-ignore - bad types, we can pass jsx here
        valueFormat={valueFormatter}
        margin={MARGIN}
        borderColor={BORDER_COLOR}
        gridLabelOffset={36}
        dotSize={10}
        dotColor={DOT_COLOR}
        dotBorderWidth={2}
        colors={COLORS}
        blendMode="multiply"
        motionConfig="wobbly"
        // @ts-ignore
        legends={LEGENDS}
      />
    </ChartCard>
  );
}
