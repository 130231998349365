import { useBrokerVsDirect } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";

import { formatters } from "@fraction/shared";
import { PieTooltipProps, ResponsivePie } from "@nivo/pie";
import { useCallback, useMemo } from "react";
import { ChartCard } from "../ChartCard";

const MARGIN = { top: 20, right: 70, bottom: 20, left: 70 };
const ARCLABEL_TEXT_COLOR = {
  from: "color",
  modifiers: [["darker", 2]],
};
const ARCLINK_LABELS_COLOR = { from: "color" };
const BORDER_COLOR = {
  from: "color",
  modifiers: [["darker", 0.2]],
};

export function BrokerVsDirect() {
  const { data, isLoading } = useBrokerVsDirect();

  const mapped = useMemo(
    () => data?.map((datum) => ({ id: formatters.string.capitalize(datum.type), value: datum.count })),
    [data]
  );

  const renderTooltip = useCallback((props: PieTooltipProps<any>) => {
    return (
      <div className="shadow p-2 bg-white rounded">
        <p className="text-sm text-gray-800 mt-0.5 font-sans">
          <b>{props.datum?.label}</b>
        </p>
        <p className="text-sm text-gray-800 mt-0.5 font-sans">
          <b>Count:</b> {props.datum.data.value}
        </p>
      </div>
    );
  }, []);

  if (isLoading) {
    return (
      <div className="col-span-1">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  if (!mapped) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={mapped.length === 0}
      cardClassName="col-span-1"
      className="min-h-[300px]"
      header="Broker vs Direct"
    >
      <ResponsivePie
        // @ts-ignore
        data={mapped}
        margin={MARGIN}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        // @ts-ignore
        borderColor={BORDER_COLOR}
        tooltip={renderTooltip}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={ARCLINK_LABELS_COLOR}
        arcLinkLabelsSkipAngle={5}
        arcLabelsSkipAngle={5}
        // @ts-ignore
        arcLabelsTextColor={ARCLABEL_TEXT_COLOR}
      />
    </ChartCard>
  );
}
