import { entities, formatters } from "@fraction/shared";
import { useCallback } from "react";
import Avatar from "src/components/Avatar";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { cn } from "src/utilities/shadcnUtils";

export const SalesSelector = ({
  value,
  onChange,
  options,
  className,
}: {
  className: string;
  value?: entities.InternalEmployee;
  onChange: (employee?: entities.InternalEmployee) => void;
  options: entities.InternalEmployee[];
}) => {
  const handleValueChange = useCallback(
    (id: string) => {
      const employee = options?.find((x) => x.id === id);
      onChange(employee);
    },
    [onChange, options]
  );

  return (
    <Select value={value?.id} onValueChange={handleValueChange}>
      <SelectTrigger
        className={cn("bg-white rounded-full p-0 flex items-center justify-center z-10", className)}
      >
        <SelectValue
          placeholder={<Avatar className="h-[35px] min-h-[35px] w-[35px] min-w-[35px]">??</Avatar>}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {options.map((employee) => (
            <SelectItem
              extra={
                <div className="ml-2 text-left">
                  <b>{formatters.user.userName(employee?.user)}</b>
                  <p>{employee?.user?.email}</p>
                </div>
              }
              key={employee.id}
              value={employee.id || "??"}
            >
              <Avatar
                className="h-[35px] min-h-[35px] w-[35px] min-w-[35px]"
                src={employee?.photoLink}
                title={formatters.user.userName(employee?.user)}
              >
                {formatters.user.userName(employee?.user) || "??"}
              </Avatar>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
