import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "src/auth";

import { Loading } from "./Loading";

export const RequireAuth = () => {
  const { user, token, isAuthenticated, isLoadingUser, loginFromToken, isLoggingIn } = useAuth();

  const location = useLocation();

  useEffect(() => {
    if (token && !isAuthenticated) {
      loginFromToken(token);
    }
  }, [token, isAuthenticated]);

  if (isLoadingUser || isLoggingIn) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    if (token) {
      return <Navigate state={{ from: location }} to={`/register?token=${token}&email=${user?.email}`} />;
    } else {
      return <Navigate state={{ from: location }} to="/login" />;
    }
  }

  return <Outlet />;
};
