import { enums, formatters } from "@fraction/shared";
import Skeleton from "src/components/Skeleton";
import { useRates } from "src/hooks/useRates";

export const FractionRates = () => {
  const { data: rates, isLoading } = useRates({
    onlyCurrent: true,
    regions: [enums.Region.ONTARIO],
  });

  return (
    <div className="p-4 bg-green-100 rounded flex flex-row lg:max-w-[360px] gap-2 items-center sm:w-fit w-full">
      <div className="h-fit ">
        <h2 className="font-serif-deck text-md text-wrap">Fraction's current rates</h2>
        <p className="text-xs mb-2">
          These rates are current as of{" "}
          {!!rates?.[0]?.activeFrom ? (
            `${formatters.date.formatDate(rates?.[0]?.activeFrom)}.`
          ) : (
            <Skeleton className="w-20 h-full inline-flex" />
          )}
        </p>
        <div className="flex flex-row gap-x-8">
          {isLoading
            ? [1, 2, 3].map((_, i) => <Skeleton key={`#${i}`} width={84} height={48} />)
            : rates?.map((rate) => (
                <div key={rate?.id} className="flex flex-col text-sm">
                  <b>{rate?.termLength} Year</b>
                  <p className="text-xs">
                    <b>Rate:</b> {formatters.number.getPercentageFromDecimal(rate?.minimumRate)}
                  </p>
                  <p className="text-xs">
                    <b>Max LTV:</b>{" "}
                    {formatters.number.getPercentageFromDecimal(rate?.maximumLTV, { digits: 1 })}
                  </p>
                </div>
              ))}
        </div>
        <p className="text-[10px] font-semibold mt-1">Broker commission: 100bps</p>
      </div>
    </div>
  );
};
