import { debounce } from "@fraction/shared";
import { useEffect, useRef } from "react";

const saveScrollPosition = debounce((e) => {
  if (!e.target || e.target.scrollLeft === 0) {
    return;
  }
  sessionStorage.setItem(e.target.id, e.target.scrollLeft.toString());
}, 50);

export function useKeepScrollPositionOnPageNav(
  elementName: string,
  existingRef?: React.RefObject<HTMLDivElement>
) {
  const ref = existingRef || useRef<HTMLDivElement>(null);
  const key = `scrollPosition-${elementName}`;

  useEffect(() => {
    const item = ref.current;
    if (!item) {
      return;
    }

    // Load the saved scroll position
    const savedScrollPosition = sessionStorage.getItem(key);
    if (savedScrollPosition) {
      setTimeout(() => {
        item.scrollTo({
          left: parseInt(savedScrollPosition, 10),
          top: 0,
        });
        sessionStorage.removeItem(key);
      }, 10);
    }

    item.addEventListener("scroll", saveScrollPosition);

    // Cleanup the event listener on component unmount
    return () => {
      item.removeEventListener("scroll", saveScrollPosition);
    };
  }, [ref.current]);

  return { ref, id: key };
}
