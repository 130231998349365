import { Font } from "@react-pdf/renderer";
import { createTw } from "react-pdf-tailwind";

import theme from "src/tailwind.json";

import Black from "src/documents/fonts/HankenGrotesk/HankenGrotesk-Black.ttf";
import BlackItalic from "src/documents/fonts/HankenGrotesk/HankenGrotesk-BlackItalic.ttf";
import Bold from "src/documents/fonts/HankenGrotesk/HankenGrotesk-Bold.ttf";
import BoldItalic from "src/documents/fonts/HankenGrotesk/HankenGrotesk-BoldItalic.ttf";
import ExtraBold from "src/documents/fonts/HankenGrotesk/HankenGrotesk-ExtraBold.ttf";
import ExtraBoldItalic from "src/documents/fonts/HankenGrotesk/HankenGrotesk-ExtraBoldItalic.ttf";
import ExtraLight from "src/documents/fonts/HankenGrotesk/HankenGrotesk-ExtraLight.ttf";
import ExtraLightItalic from "src/documents/fonts/HankenGrotesk/HankenGrotesk-ExtraLightItalic.ttf";
import Italic from "src/documents/fonts/HankenGrotesk/HankenGrotesk-Italic.ttf";
import Light from "src/documents/fonts/HankenGrotesk/HankenGrotesk-Light.ttf";
import LightItalic from "src/documents/fonts/HankenGrotesk/HankenGrotesk-LightItalic.ttf";
import Medium from "src/documents/fonts/HankenGrotesk/HankenGrotesk-Medium.ttf";
import MediumItalic from "src/documents/fonts/HankenGrotesk/HankenGrotesk-MediumItalic.ttf";
import Regular from "src/documents/fonts/HankenGrotesk/HankenGrotesk-Regular.ttf";
import SemiBold from "src/documents/fonts/HankenGrotesk/HankenGrotesk-SemiBold.ttf";
import SemiBoldItalic from "src/documents/fonts/HankenGrotesk/HankenGrotesk-SemiBoldItalic.ttf";
import Thin from "src/documents/fonts/HankenGrotesk/HankenGrotesk-Thin.ttf";
import ThinItalic from "src/documents/fonts/HankenGrotesk/HankenGrotesk-ThinItalic.ttf";

import CanelaDeck from "src/documents/fonts/CanelaDeck/canela-deck-bold.ttf";

const fonts = [
  { src: Black, fontWeight: "black" },
  { src: BlackItalic, fontWeight: "black" },
  { src: ExtraBold, fontWeight: "extrabold" },
  { src: ExtraBoldItalic, fontWeight: "extrabold", fontStyle: "italic" },
  { src: Bold, fontWeight: "bold" },
  { src: BoldItalic, fontWeight: "bold", fontStyle: "italic" },
  { src: ExtraLight, fontWeight: "extralight" },
  { src: ExtraLightItalic, fontWeight: "extralight", fontStyle: "italic" },
  { src: Italic, fontWeight: "regular", fontStyle: "italic" },
  { src: Regular, fontWeight: "regular" },
  { src: Light, fontWeight: "light" },
  { src: LightItalic, fontWeight: "light", fontStyle: "italic" },
  { src: Medium, fontWeight: "medium" },
  { src: MediumItalic, fontWeight: "medium", fontStyle: "italic" },
  { src: SemiBold, fontWeight: "semibold" },
  { src: SemiBoldItalic, fontWeight: "semibold", fontStyle: "italic" },
  { src: Thin, fontWeight: "thin" },
  { src: ThinItalic, fontWeight: "thin", fontStyle: "italic" },
];

// @ts-ignore
Font.register({ family: "Hanken Grotesk", fonts });
Font.register({ family: "Canela", src: CanelaDeck });

export const tw = createTw(theme);
