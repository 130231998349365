import { Bullet } from "src/components/Bullet";
import { cn } from "src/utilities/shadcnUtils";

export const MaskedAccountNumber = ({ children, className }: { children: string; className?: string }) => {
  return (
    <div className={cn("flex flex-row items-center gap-0.5", className)}>
      <Bullet />
      <Bullet />
      <Bullet />
      <Bullet />
      <p className="ml-0.5">{children.slice(-4)}</p>
    </div>
  );
};
