import { Loader } from "lucide-react";
import { ReactNode, useCallback } from "react";
import Dropzone, { FileWithEnumType } from "src/components/Dropzone";
import { Badge } from "src/components/ui/badge";
import { useUploadDocuments } from "src/hooks/useSimpleUploadDocuments";
import { useMutation } from "src/lib";

import { DocumentType, entities, types } from "@fraction/shared";

export function DocumentDropzone({
  onUploadSuccess,
  applicationId,
  document,
  applicantId,
  debtId,
  incomeId,
  disabled,
  label = "Upload doc",
  className,
  variant,
  pending,
}: {
  onUploadSuccess?: (file: entities.UploadedFile) => Promise<any> | any;
  applicationId?: string;
  document: DocumentType;
  applicantId?: string;
  debtId?: string;
  incomeId?: string;
  disabled?: boolean;
  label?: string | ReactNode;
  className?: string;
  variant?: "default" | "blue";
  loading?: boolean;
  pending?: boolean;
}) {
  const refetchFromUploadMutation = useMutation({
    mutationFn: async (file: entities.UploadedFile) => {
      return onUploadSuccess?.(file);
    },
  });

  const { mutateAsync: uploadDocuments, isPending: uploadPending_ } = useUploadDocuments({
    onUploadSuccess: refetchFromUploadMutation.mutateAsync,
  });

  const uploadPending = pending || uploadPending_ || refetchFromUploadMutation.isPending;

  const handleDropDocument = useCallback(
    (docs: FileWithEnumType[]) => {
      if (!applicationId) {
        throw new Error("No applicationId provided");
      }
      uploadDocuments({
        assets: docs,
        applicationId,
        documentTypeName: document,
        applicantId: applicantId,
        debtId: debtId,
        incomeId: incomeId,
      });
    },
    [applicationId, uploadDocuments, applicantId, debtId, incomeId, document]
  );

  return (
    <Dropzone
      accept={[types.MimeType.PDF, types.MimeType.PNG, types.MimeType.JPEG, types.MimeType.HEIC]}
      disabled={disabled}
      multi
      className={className}
      onDrop={handleDropDocument}
      dragActiveChildren={
        <Badge className="w-full" variant={variant || "default"} loading={uploadPending}>
          Drag file here
        </Badge>
      }
    >
      <Badge variant={disabled ? "gray" : variant || "default"}>
        {uploadPending ? (
          <>
            <Loader height={14} className="text-white animate-spin" /> Uploading...
          </>
        ) : (
          label
        )}
      </Badge>
    </Dropzone>
  );
}
