import { entities, enums, formatters } from "@fraction/shared";
import _ from "lodash";
import { CheckCircle2, CircleSlash, Loader } from "lucide-react";
import { ChangeEvent, ReactNode, useCallback, useState } from "react";
import { useAuth } from "src/auth";
import { BrokerInviteModalButton } from "src/components/BrokerInviteModalButton";
import { ChannelVerificationModal, ChannelVerificationState } from "src/components/ChannelVerificationModal";
import Chip from "src/components/Chip";
import { ClipboardBadge } from "src/components/ClipboardBadge";
import Skeleton from "src/components/Skeleton";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { Badge } from "src/components/ui/badge";
import { Input } from "src/components/ui/input";
import config from "src/config";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { useInvites } from "src/hooks/useInvite";
import { cn } from "src/utilities/shadcnUtils";
import { useDebouncedCallback } from "use-debounce";

export function EditProfileScreen() {
  useDocumentTitle("Settings & Profile");

  const { user, isLoadingUser, updateUser, updatingUserFields, channels, makeChannelPrimary, deleteChannel } =
    useAuth({
      initialRefetch: true,
    });

  const { data: invites, removeInvite, inviteRemoving } = useInvites();

  const [modalState, setModalState] = useState<{
    open: boolean;
    channel: undefined | string;
    type: "email" | "phone" | undefined;
    state: ChannelVerificationState;
  }>({
    open: false,
    channel: undefined,
    type: undefined,
    state: "enter-channel",
  });
  const { preference } = useAccountViewPreference();

  const resetModalState = useCallback(() => {
    setModalState({ open: false, channel: undefined, type: undefined, state: "enter-channel" });
  }, []);

  const addEmail = useCallback(() => {
    setModalState({ open: true, channel: undefined, type: "email", state: "enter-channel" });
  }, []);
  const addPhone = useCallback(() => {
    setModalState({ open: true, channel: undefined, type: "phone", state: "enter-channel" });
  }, []);
  const verifyExistingEmail = useCallback((to?: string) => {
    if (!to) {
      return;
    }
    setModalState({ open: true, channel: to, type: "email", state: "verify-channel" });
  }, []);
  const verifyExistingPhone = useCallback((to?: string) => {
    if (!to) {
      return;
    }
    setModalState({ open: true, channel: to, type: "phone", state: "verify-channel" });
  }, []);

  return (
    <div className="p-6 flex flex-col gap-1">
      <h1 className="font-serif-deck text-2xl">Settings</h1>

      <div className="flex flex-col mt-2">
        <Row title="Profile" label="Your personal information and contact information settings.">
          <div className="divide divide-y divide-0.5 divide-gray-500">
            {isLoadingUser ? (
              <div className="gap-y-1">
                <Skeleton height={40} />
                <Skeleton height={40} />
              </div>
            ) : (
              <form className="mt-2 max-w-md flex flex-row flex-wrap gap-3">
                <UserInput
                  onChange={updateUser.mutateAsync}
                  pending={!!updatingUserFields.firstName}
                  user={user}
                  property="firstName"
                />
                <UserInput
                  onChange={updateUser.mutateAsync}
                  pending={!!updatingUserFields.lastName}
                  user={user}
                  property="lastName"
                />
              </form>
            )}
          </div>
          <div className="max-w-md flex flex-col gap-0 mt-6">
            <div className="flex flex-row justify-between items-center">
              <h3 className="text-md font-serif-deck">Emails</h3>
              <button onClick={addEmail}>
                <Badge variant="default" className="rounded-full">
                  Add new email
                </Badge>
              </button>
            </div>
            <div className="flex flex-col gap-2">
              {channels?.emails?.map((email) => (
                <Channel
                  id={email.id}
                  primary={email.primary}
                  key={email.key}
                  value={email.value}
                  verified={email.verified}
                  onPressVerify={verifyExistingEmail}
                  onPressMakePrimary={makeChannelPrimary.mutateAsync}
                  makePrimaryPending={makeChannelPrimary.isPending}
                  onPressRemove={deleteChannel.mutateAsync}
                  removalPending={deleteChannel.isPending}
                />
              ))}
            </div>
          </div>
          <div className="max-w-md flex flex-col gap-0 mt-8">
            <div className="flex flex-row justify-between items-center">
              <h3 className="text-md font-serif-deck">Phone numbers</h3>
              <button onClick={addPhone}>
                <Badge variant="default" className="rounded-full">
                  Add new phone
                </Badge>
              </button>
            </div>
            <div className="flex flex-col gap-2">
              {channels?.phones?.map((phone) => (
                <Channel
                  id={phone.id}
                  primary={phone.primary}
                  key={phone.key}
                  value={formatters.phone.formatPhoneNumber(phone.value, { stripInternationalCode: true })}
                  verified={phone.verified}
                  onPressVerify={verifyExistingPhone}
                  onPressMakePrimary={makeChannelPrimary.mutateAsync}
                  makePrimaryPending={makeChannelPrimary.isPending}
                  onPressRemove={deleteChannel.mutateAsync}
                  removalPending={deleteChannel.isPending}
                />
              ))}
            </div>
          </div>
        </Row>

        {preference === "broker" ? (
          <Row
            title="Your deal team"
            label="Add in your team members who will be added to every deal that you submit to Fraction. These
                will often be your underwriters, assistants, or co-brokers."
          >
            <BrokerInviteModalButton className="self-start" type={enums.InviteType.BROKER_TEAM_CONNECTION}>
              Invite new team members
            </BrokerInviteModalButton>
            {invites?.length ? (
              <div className="border border-gray-400 rounded max-w-md mt-2">
                {invites
                  ?.filter((invite) => invite.type === enums.InviteType.BROKER_TEAM_CONNECTION)
                  ?.map((invite) => (
                    <div
                      key={invite.id}
                      className="p-2 odd:bg-gray-200 first:rounded-t last:rounded-b bg-white flex flex-row w-full gap-4 justify-between items-center"
                    >
                      <div>
                        <p className="text-xs font-medium">{formatters.user.userName(invite?.invitee)}</p>
                        <p className="text-xs text-gray">{invite?.invitee?.email}</p>
                      </div>

                      <button onClick={() => removeInvite.mutateAsync(invite)}>
                        <Badge
                          pending={inviteRemoving === invite.id}
                          variant={
                            invite.status === enums.InviteStatus.INVITED
                              ? "secondary"
                              : invite.status === enums.InviteStatus.ACCEPTED
                              ? "success"
                              : "destructive"
                          }
                          className="group rounded-full relative w-[75px] hover:bg-red duration-75 hover:text-white"
                        >
                          <p className="duration-75 opacity-100 group-hover:opacity-0">
                            {_.startCase(invite.status)}
                          </p>
                          <p className="duration-75 absolute opacity-0 group-hover:opacity-100 left-0 right-0">
                            Remove
                          </p>
                        </Badge>
                      </button>
                    </div>
                  ))}
              </div>
            ) : null}
          </Row>
        ) : null}

        <Row
          title="Your referral code"
          label="Want to have one of your applicants fill out their application via Fraction's website? Share this link and the application will be connected to your profile when the applicant hits submit."
        >
          <div className="flex h-full">
            <ClipboardBadge
              variant="default"
              toCopy={`${config.frontendBaseURL}/application?broker_id=${user?.brokerAccount?.id}`}
            >
              Copy referral code
            </ClipboardBadge>
          </div>
        </Row>
      </div>

      {modalState.type ? (
        <ChannelVerificationModal
          startState={modalState.state}
          type={modalState.type}
          open={modalState.open}
          defaultChannel={modalState.channel}
          onClose={resetModalState}
        />
      ) : null}
    </div>
  );
}

const Row = ({
  title,
  label,
  children,
  className,
}: { title: string; label: string; children: ReactNode; className?: string }) => {
  return (
    <div className={cn("flex flex-col md:flex-row border-y py-4", className)}>
      <div className="w-[400px] max-w-full">
        <p>{title}</p>
        <p className="text-xs text-gray font-light">{label}</p>
      </div>
      <div className="md:ml-8 w-full flex-col gap-1">{children}</div>
    </div>
  );
};

function Channel({
  id,
  value,
  verified,
  primary,
  className,
  onPressVerify,
  onPressMakePrimary,
  onPressRemove,
  removalPending,
  makePrimaryPending,
}: {
  className?: string;
  value?: string;
  verified?: boolean;
  primary?: boolean;
  id?: string;
  onPressVerify: (to?: string) => any;
  onPressMakePrimary?: (id?: string) => any;
  onPressRemove?: (id?: string) => any;
  removalPending?: boolean;
  makePrimaryPending?: boolean;
}) {
  return (
    <div className={className}>
      <div className="flex flex-row items-center justify-between mb-1 flex-wrap">
        <div className="flex-col">
          <p className="text-start font-semibold text-sm">{value}</p>
          {verified ? (
            <div className="flex flex-row items-center mt-0.5">
              <CheckCircle2 height={14} width={14} className="text-green mr-1" />
              <p className="text-start text-xs font-semibold text-green">Verified</p>
            </div>
          ) : (
            <div className="flex flex-row items-center mt-0.5">
              <CircleSlash height={14} width={14} className="text-yellow mr-1" />
              <p className="text-start text-xs font-semibold text-yellow">Unverified</p>
            </div>
          )}
        </div>
        <Chip variant={primary ? "green" : "yellow"}>{primary ? "Primary" : "Secondary"}</Chip>
      </div>
      {!primary || !verified ? (
        <div className="flex flex-row items-center gap-x-1 mt-1.5">
          {!verified ? (
            <button onClick={() => onPressVerify?.(value)}>
              <Badge variant="secondary" className="rounded-full">
                Verify
              </Badge>
            </button>
          ) : null}
          {!primary && verified ? (
            <button onClick={() => onPressMakePrimary?.(id)}>
              <Badge pending={makePrimaryPending} variant="success" className="rounded-full">
                Make primary
              </Badge>
            </button>
          ) : null}
          {!primary ? (
            <button onClick={() => onPressRemove?.(id)}>
              <Badge pending={removalPending} variant="destructive" className="rounded-full">
                Remove channel
              </Badge>
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

const UserInput = ({
  user,
  property,
  placeholder,
  pending,
  loading,
  onChange,
}: {
  user?: entities.UserT | null;
  loading?: boolean;
  pending?: boolean;
  property: keyof entities.UserT;
  placeholder?: string;
  onChange?: (value: Partial<entities.UserT>) => void;
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.({ [property]: event.target.value } as Partial<entities.UserT>);
    },
    [onChange]
  );

  const debouncedHandleChange = useDebouncedCallback(handleChange, 200);

  return (
    <div className="flex flex-col gap-1 w-full md:w-auto">
      <label className="text-sm font-bold" htmlFor={property}>
        {placeholder || _.startCase(property)}
        {pending ? <Loader height={14} className="animate-spin inline mb-0.5" /> : ""}
      </label>
      <Input
        onChange={debouncedHandleChange}
        type="text"
        id={property}
        placeholder={placeholder || _.startCase(property)}
        defaultValue={user?.[property] as string}
        className="w-full md:w-auto"
      />
    </div>
  );
};
