import { useEffect } from "react";
import analytics from "src/analytics";

import EnhanceRoute, { EnhanceRouteProps } from "./EnhanceRoute";

const TrackRoute = (props: EnhanceRouteProps) => {
  useEffect(() => {
    analytics.page();
  }, []);

  return <EnhanceRoute {...props} />;
};

export default TrackRoute;
