import { IncorrectInputsError, entities, enums } from "@fraction/shared";
import _ from "lodash";
import { useState } from "react";
import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { useCachedQuery, useCachedQueryClient } from "src/hooks/useCache";
import { useMutation } from "src/lib";

export const useInvite = (id?: string) => {
  const { user, isAuthenticated } = useAuth();

  const queryClient = useCachedQueryClient();

  const inviteQ = useCachedQuery({
    queryKey: ["invite", id],
    initialRefetch: true,
    enabled: isAuthenticated && !!id,
    queryFn: async () => {
      try {
        return id ? await fraction.getInvite(id) : null;
      } catch (err) {
        return null;
      }
    },
  });

  const acceptInvite = useMutation({
    mutationFn: async () => {
      if (!id) {
        throw new IncorrectInputsError("Invalid invite id");
      }
      await fraction.acceptInvite(id);
      queryClient.setQueryData<entities.InviteT[]>(["invites", user?.id], (prev) =>
        (prev || []).map((i) => {
          if (i.id === id || i.eid === id) {
            return { ...i, status: enums.InviteStatus.ACCEPTED };
          }
          return i;
        })
      );
    },
  });

  const rejectInvite = useMutation({
    mutationFn: async () => {
      if (!id) {
        throw new IncorrectInputsError("Invalid invite id");
      }
      await fraction.rejectInvite(id);
      queryClient.setQueryData<entities.InviteT[]>(["invites", user?.id], (prev) =>
        (prev || []).map((i) => {
          if (i.id === id || i.eid === id) {
            return { ...i, status: enums.InviteStatus.REJECTED };
          }
          return i;
        })
      );
    },
  });

  return {
    ...inviteQ,
    invite: inviteQ?.data,
    isPending: acceptInvite.isPending || rejectInvite.isPending,
    acceptInvite,
    rejectInvite,
  };
};

export const useInvites = () => {
  const { user, isAuthenticated } = useAuth();

  const queryClient = useCachedQueryClient();

  const invitesQ = useCachedQuery({
    queryKey: ["invites", user?.id],
    initialRefetch: true,
    enabled: isAuthenticated,
    queryFn: () => fraction.getInvites(),
  });

  const sendInviteToApplicant = useMutation({
    mutationFn: async ({
      inviteeId,
      channels,
    }: Partial<entities.Invite> & { channels: enums.ChannelType[] }) => {
      const invite = await fraction.sendInviteToApplicant({ inviteeId, channels });
      if (invite) {
        queryClient.setQueryData<entities.Invite[]>(["invites", user?.id], (prev) =>
          _.uniqBy([...(prev || []), invite], "id")
        );
      }
    },
  });

  const sendBrokerToBrokerInvite = useMutation({
    mutationFn: async (opts: { emails: string[]; applicationId?: string; type?: enums.InviteType }) => {
      let invites: entities.InviteT[];
      const applicationId = opts.applicationId;
      if (applicationId) {
        invites = await fraction.brokerToBrokerAppInvite({ ...opts, applicationId });
      } else if (opts.type === enums.InviteType.BROKER_TEAM_CONNECTION) {
        invites = await fraction.brokerToBrokerTeamInvite(opts);
      } else {
        throw new IncorrectInputsError("Invalid invite type");
      }
      if (invites?.length) {
        queryClient.setQueryData<entities.InviteT[]>(["invites", user?.id], (prev) =>
          _.uniqBy([...(prev || []), ...(invites || [])], "id")
        );
      }
      return invites;
    },
  });

  const [inviteRemoving, setInviteRemoving] = useState<null | string>();
  const removeInvite = useMutation({
    mutationFn: async (invite: entities.InviteT) => {
      if (!invite.id) {
        throw new IncorrectInputsError("Invalid invite id");
      }
      setInviteRemoving(invite.id);
      try {
        await fraction.deleteInvite({ inviteId: invite.id });
        queryClient.setQueryData<entities.InviteT[]>(["invites", user?.id], (prev) =>
          (prev || []).filter((i) => i.id !== invite.id)
        );
      } finally {
        setInviteRemoving(null);
      }
    },
  });

  return {
    ...invitesQ,
    invites: invitesQ?.data,
    isPending: sendInviteToApplicant.isPending,
    sendInviteToApplicant: sendInviteToApplicant.mutateAsync,
    sendBrokerToBrokerInvite,
    removeInvite,
    inviteRemoving: removeInvite.isPending ? inviteRemoving : null,
  };
};
