import { useCallback, useEffect, useState } from "react";
import { CountAndStats } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/CountAndStats";
import { useApplicantCreditScores } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Tabs, TabsList, TabsTrigger } from "src/components/ui/tabs";

import { parsers } from "@fraction/shared";

const valueFormatter = (val: number) => val.toFixed(0);

export function BorrowerCreditScoreChart() {
  const { data, isLoading } = useApplicantCreditScores();
  const { Tabs: ToggleTabs, data: activeData } = useTogglesAndData({
    data: data?.data,
    toggles: data?.toggles,
  });

  return (
    <CountAndStats
      statValueFormatter={valueFormatter}
      xAxisLabel="Credit score"
      data={activeData}
      isLoading={isLoading}
      header="Credit scores"
      id="creditScores"
      HeaderComponents={<ToggleTabs />}
      labelDigits={0}
    />
  );
}

const useTogglesAndData = ({ toggles, data }: Partial<parsers.portfolio.ToggleableDataAndStats>) => {
  const [activeToggles, setActiveToggles] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!toggles) {
      return;
    }
    setActiveToggles((prev) => ({
      ...prev,
      ...toggles.reduce(
        (acc: Record<string, boolean>, toggle) => ({
          ...acc,
          [toggle.defaultValue]: true,
        }),
        {}
      ),
    }));
  }, [toggles]);

  const handleValueChange = useCallback((value: Record<string, boolean>) => {
    setActiveToggles((prev) => ({ ...prev, ...value }));
  }, []);

  const activeData = data?.find((datum) => datum.options.every((option) => activeToggles[option]));

  return {
    data: activeData,
    activeToggles,
    setActiveToggles,
    Tabs: ({ className }: { className?: string }) => (
      <div className="gap-3 flex row flex-wrap">
        {toggles?.map((toggle) => (
          <Tabs
            key={toggle.options[0].value}
            value={toggle.options.find((option) => activeToggles[option.value])?.value}
            onValueChange={(value) =>
              handleValueChange(
                toggle.options.reduce(
                  (acc, option) => ({
                    ...acc,
                    [option.value]: value === option.value,
                  }),
                  {}
                )
              )
            }
            defaultValue={toggle.options[0].value}
            className={className}
          >
            <TabsList>
              {toggle.options.map((option) => (
                <TabsTrigger key={option.value} value={option.value}>
                  {option.label}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        ))}
      </div>
    ),
  };
};
