import { NavLink, NavLinkProps } from "react-router-dom";
import { createStyles } from "src/styles";

import NavItem, { NavItemProps } from "../NavItem";

type RouterNavLinkProps = NavLinkProps & NavItemProps;

const styles = createStyles(() => ({
  link: {
    textDecoration: "none",
  },
}));

/**
 * This renders a NavItem except it's also tied to react-router. So clicking it will change the url
 * and if the current route matches the "to" prop, then the NavItem will be set to active.
 */
const RouterNavLink = ({
  to,
  end,
  caseSensitive,
  reloadDocument,
  replace,
  state,
  preventScrollReset,
  relative,
  ...rest
}: RouterNavLinkProps) => (
  <NavLink
    to={to}
    end={end}
    caseSensitive={caseSensitive}
    reloadDocument={reloadDocument}
    replace={replace}
    state={state}
    preventScrollReset={preventScrollReset}
    relative={relative}
    css={styles.link}
  >
    {({ isActive }) => <NavItem active={isActive} {...rest} />}
  </NavLink>
);

export default RouterNavLink;
