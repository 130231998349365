import config from "src/config";
import { Logger } from "src/log";
import loadScript from "src/utilities/loadScript";

import { sleep } from "@fraction/shared";

const LOGGER = new Logger("api.google");

const MAX_SETUP_ATTEMPTS = 3;

export class Google {
  public autocompleteService!: google.maps.places.AutocompleteService;
  public placesService!: google.maps.places.PlacesService;

  public setupPromise!: Promise<void>;
  public google!: { maps: typeof google.maps };

  private settingUp: boolean = false;
  private fullySetup: boolean = false;
  private setupAttempts: number = 0;

  public setup = async () => {
    if (this.fullySetup) {
      return true;
    }

    if (this.settingUp) {
      while (!this.fullySetup) {
        await sleep(200);
      }
      return true;
    }

    this.setupAttempts++;
    this.settingUp = true;

    try {
      this.setupPromise = this.loadGoogle();
      return true;
    } catch (err) {
      LOGGER.exception(err, "Unable to set up Google API");
      this.settingUp = false;
      if (this.setupAttempts <= MAX_SETUP_ATTEMPTS) {
        await sleep(200 * this.setupAttempts);
        this.setup();
      } else {
        LOGGER.warn("Retried google API setup too many times. Stopping any more retries.");
      }
      return false;
    }
  };

  public getMap = async (
    element: HTMLElement,
    options: google.maps.MapOptions = {}
  ): Promise<google.maps.Map> => {
    return new google.maps.Map(element, {
      center: { lat: 49.26741819999999, lng: -123.1586045 },
      zoom: 11,
      mapId: "ac04ef6d1514bf89",
      ...options,
    });
  };

  public getPlacePredictions = async (
    options: google.maps.places.AutocompletionRequest
  ): Promise<google.maps.places.AutocompletePrediction[]> => {
    await this.setupPromise;

    if (options.location) {
      // @ts-ignore
      options.location = new google.maps.LatLng(options.location.lat, options.location.lng);
    }

    return new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictions(
        options,
        (
          resp: google.maps.places.AutocompletePrediction[] | null,
          status: google.maps.places.PlacesServiceStatus
        ) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return reject(resp);
          }
          resolve(resp || []);
        }
      );
    });
  };

  public fetchFields = async (placeID: string): Promise<google.maps.places.Place> => {
    await this.setupPromise;
    const place = new this.google.maps.places.Place({
      id: placeID,
    });
    await place.fetchFields({ fields: ["displayName", "formattedAddress", "location"] });
    return place;
  };

  public textSearch = async (
    request: google.maps.places.TextSearchRequest
  ): Promise<google.maps.places.PlaceResult[]> => {
    await this.setupPromise;

    return new Promise((resolve, reject) => {
      this.placesService.textSearch(
        request,
        (resp: google.maps.places.PlaceResult[] | null, status: google.maps.places.PlacesServiceStatus) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return reject(resp);
          }
          resolve(resp || []);
        }
      );
    });
  };

  public getPlaceDetails = async (placeID: string): Promise<google.maps.places.PlaceResult | undefined> => {
    await this.setupPromise;

    return new Promise((resolve, reject) => {
      this.placesService.getDetails(
        { placeId: placeID },
        (resp: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return reject(resp);
          }
          resolve(resp || undefined);
        }
      );
    });
  };

  public getPlaceDetailsAsIfAutocomplete = async (
    placeID: string
  ): Promise<Partial<google.maps.places.AutocompletePrediction>> => {
    await this.setupPromise;
    // @ts-ignore
    return new Promise((resolve, reject) => {
      this.placesService.getDetails(
        {
          placeId: placeID,
        },
        (resp: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return reject(resp);
          }
          resolve({
            description: resp?.formatted_address,
            place_id: resp?.place_id,
          });
        }
      );
    });
  };

  private loadGoogle = async () => {
    await loadScript({
      url: `https://maps.googleapis.com/maps/api/js?v=beta&libraries=places,maps&key=${config.googlePlacesAPIKey}`,
    });
    this.google = google;
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement("div"));
    this.fullySetup = true;
  };
}

const googleSingleton: Google = new Google();
googleSingleton.setup();

export default googleSingleton;
