import { entities } from "@fraction/shared";
import { useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import fraction from "src/api/fraction";
import { useBankAccountsQuery } from "src/apps/PostFundedDashboard/queries";
import { useAuth } from "src/auth";
import { useMutation, useQuery } from "src/lib";

export function usePlaid({
  applicationId,
  purpose,
  onSuccess,
}: {
  applicationId?: string;
  purpose: "underwriting" | "payments";
  onSuccess?: (accounts: entities.BankAccount[] | undefined) => void;
}) {
  const { refetch: refetchBankAccounts } = useBankAccountsQuery();
  const { updateLocalUser, token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [plaidToken, setPlaidToken] = useState<string | null>(null);

  const incomes = useMutation({
    mutationFn: fraction.getPlaidIncome,
  });

  const registerMutation = useMutation({
    mutationFn: async (token: string) => {
      if (!applicationId) {
        throw new Error("No active application");
      }
      return fraction.registerPlaidBank(token, applicationId);
    },
    onSuccess: async ({ plaidToken }) => {
      updateLocalUser({ plaidToken });
      if (purpose === "underwriting") {
        await Promise.all([incomes.mutateAsync()]);
      }
      const result = await refetchBankAccounts();
      onSuccess?.(result?.data);
    },
  });

  const linkTokenMutation = useMutation({
    mutationFn: async () => {
      const data = await fraction.getPlaidLinkToken(purpose);
      setPlaidToken(data);
      return data;
    },
    onSuccess: () => {
      setLoading(true);
    },
  });

  const link = usePlaidLink({
    token: linkTokenMutation.data || null,
    onLoad: () => {
      setLoading(false);
    },
    onSuccess: async (public_token, metadata) => {
      setLoading(false);
      // send public_token to server to exchange for access_token
      await registerMutation.mutateAsync(public_token);
    },
  });

  const start = useMutation({
    mutationFn: async () => {
      if (plaidToken) {
        await link.open();
      } else {
        await linkTokenMutation.mutateAsync();
      }
    },
  });

  useQuery({
    queryKey: ["linkOpen", linkTokenMutation.data, link.ready],
    enabled: !!linkTokenMutation.data && link.ready,
    queryFn: async () => {
      await link.open();
      return null;
    },
  });

  return {
    ...link,
    ready: !!token,
    isLoading: loading || linkTokenMutation.isPending || registerMutation.isPending || start.isPending,
    start: start.mutateAsync,
    registerMutation,
    incomes,
  };
}
