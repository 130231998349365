import { Outlet, Route, Routes } from "react-router-dom";
import { LOSHeader } from "src/apps/LOS/components/Header";
import { CalendarPage } from "src/apps/LOS/pages/Calendar";
import { DocApproval } from "src/apps/LOS/pages/DocApproval";
import { FundedPipeline } from "src/apps/LOS/pages/FundedPipeline";
import { Mail } from "src/apps/LOS/pages/Mail";
import { MailPage } from "src/apps/LOS/pages/MailPage";
import { Pipeline } from "src/apps/LOS/pages/Pipeline";
import { EditProfileScreen } from "src/apps/shared/EditProfile";
import { useAuth } from "src/auth";
import { DashboardShell, Loading, RequireAuth } from "src/components";
import EnhanceRoute from "src/components/root/routeHelpers/EnhanceRoute";
import { AppDetailsRoutes } from "./pages/AppDetails";

const LOSLayout = () => {
  const { isLoadingUser } = useAuth();

  if (isLoadingUser) {
    return <Loading />;
  }

  return (
    <DashboardShell header={<LOSHeader />}>
      <Outlet />
    </DashboardShell>
  );
};

export function LOSRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth />}>
        <Route element={<LOSLayout />}>
          <Route
            path="/"
            element={
              <EnhanceRoute title="Apps overview | Fraction">
                <div className="flex flex-row py-6 pl-6">
                  <Pipeline />
                </div>
              </EnhanceRoute>
            }
          />
          <Route
            path="/funded"
            element={
              <EnhanceRoute title="Apps overview | Fraction">
                <div className="flex flex-row py-6 pl-6">
                  <FundedPipeline />
                </div>
              </EnhanceRoute>
            }
          />
          <Route path="/app/:id/*" element={<AppDetailsRoutes />} />
          <Route
            path="/mail"
            element={
              <EnhanceRoute title="Mail | Fraction">
                <div className="flex flex-row p-6">
                  <Mail />
                </div>
              </EnhanceRoute>
            }
          />
          <Route
            path="/mail/:id"
            element={
              <EnhanceRoute title="Mail | Fraction">
                <div className="flex flex-row p-6">
                  <MailPage />
                </div>
              </EnhanceRoute>
            }
          />
          <Route
            path="/doc-approval"
            element={
              <EnhanceRoute title="Doc Approval | Fraction">
                <div className="flex flex-row p-6">
                  <DocApproval />
                </div>
              </EnhanceRoute>
            }
          />
          <Route
            path="/calendar"
            element={
              <EnhanceRoute title="Calendar | Fraction">
                <div className="flex flex-row p-6">
                  <CalendarPage accountType="employee" />
                </div>
              </EnhanceRoute>
            }
          />
          <Route path="profile" element={<EditProfileScreen />} />;
        </Route>
      </Route>
    </Routes>
  );
}
