import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface DividerProps {
  style?: SerializedStyles;
  lineStyle?: SerializedStyles;
}

const styles = createStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    height: 1,
    backgroundColor: colors.palette.GREY_400,
    width: "100%",
  },
});

const Divider = ({ style, lineStyle }: DividerProps) => (
  <div css={[styles.container, style]}>
    <div css={[styles.line, lineStyle]} />
  </div>
);

export default memo(Divider);
