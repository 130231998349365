import { memo } from "react";
import { IllustratedModalContent } from "src/components/InnerModals";
import { PopupProps } from "src/components/Popup";
import { MediumText } from "src/components/v1";
import hourglass from "src/images/hourglass.png";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

const styles = createStyles({
  illustratedParagraph: {
    marginBottom: 8,
    marginTop: 8,
    textAlign: "center",
    fontSize: 14,
    color: colors.palette.GREY_500,
    lineHeight: "160%",
  },
  break: {
    display: "block",
    margin: "16px 0px",
    content: '" "',
  },
  illustration: {
    height: 200,
    "@media(max-width: 600px)": {
      height: 152,
    },
  },
});

const ExpiredVerificationScreen = ({ onClose }: Omit<PopupProps, "children">) => (
  <IllustratedModalContent
    actions={[
      {
        type: "primary",
        text: "I understand",
        action: onClose,
      },
    ]}
    header="Your account verification has expired."
    image={<img alt="Hourglass" src={hourglass} css={styles.illustration} />}
  >
    <MediumText style={styles.illustratedParagraph}>
      The verification deposits we sent to your bank account have expired.
      <br css={styles.break} />
      Not to worry, over the next 1-2 days, you’ll receive another set of two deposits of $0.01 - $0.99.
    </MediumText>
  </IllustratedModalContent>
);

export default memo(ExpiredVerificationScreen);
