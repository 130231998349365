import { memo } from "react";
import fraction from "src/api/fraction";
import NoDataScreen from "src/apps/PostFundedDashboard/components/NoDataScreen";
import { DashboardPage, Skeleton } from "src/components";
import { FileTree } from "src/components/FileTable";
import { Document } from "src/images";
import { useQuery } from "src/lib";
import { createStyles } from "src/styles";
import { downloadFile } from "src/utilities/file/download";

import { useAuth } from "src/auth";

const styles = createStyles(() => ({
  noDataIllustration: {
    height: 100,
    width: 100,
  },
  outerContainer: { justifyContent: "flex-start" },
}));

const downloadDocument = async (id: string) => {
  const { fileURL, key } = await fraction.getPortfolioDocumentSignedUrl(id);
  await downloadFile(fileURL, key);
};

const PortfolioLoanTape = () => {
  const { isAuthenticated } = useAuth();
  const { data, isLoading } = useQuery({
    queryKey: ["portfolio-documents"],
    queryFn: () => fraction.getPortfolioDocuments(),
    enabled: isAuthenticated,
  });

  return (
    <DashboardPage
      outerContainerStyle={styles.outerContainer}
      heading="Loan tape"
      subHeading="View and download loan tapes"
    >
      {isLoading ? <Skeleton className="w-full h-[50px]" /> : null}
      {!data?.length && !isLoading ? (
        <NoDataScreen text="It doesn't look like you have any documents yet.">
          <Document css={styles.noDataIllustration} />
        </NoDataScreen>
      ) : (
        <FileTree combineOneFileDirectories onDownload={downloadDocument} documents={data || []} />
      )}
    </DashboardPage>
  );
};

export default memo(PortfolioLoanTape);
