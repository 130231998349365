import { useMemo } from "react";
import {
  MutationOptions,
  useCreateBankAccountMutation,
  useDeleteBankAccountMutation,
  useEditBankAccountMutation,
  useVerificationMutation,
} from "src/apps/PostFundedDashboard/queries";

import { entities } from "@fraction/shared";

/**
 * Encapulates logic involving server side changes to a single bank account.
 */
const useBankAccountMutation = ({
  bankAccount,
  verifyOptions,
}: {
  bankAccount?: entities.BankAccount;
  verifyOptions: MutationOptions & { onExpiredDepositsError: () => void };
}) => {
  const id = bankAccount?.id;
  const createBankAccountMutation = useCreateBankAccountMutation();
  const editBankAccountMutation = useEditBankAccountMutation(id);
  const deleteBankAccountMutation = useDeleteBankAccountMutation(id);
  const verificationMutation = useVerificationMutation(id, verifyOptions);

  return useMemo(
    () => ({
      isLoading:
        createBankAccountMutation.isPending ||
        deleteBankAccountMutation.isPending ||
        editBankAccountMutation.isPending ||
        verificationMutation.isPending,
      createAccount: createBankAccountMutation.mutate,
      editAccount: editBankAccountMutation.mutate,
      deleteAccount: deleteBankAccountMutation.mutate,
      verifyAccount: verificationMutation.mutate,
    }),
    [
      createBankAccountMutation.isPending,
      deleteBankAccountMutation.isPending,
      editBankAccountMutation.isPending,
      verificationMutation.isPending,
      createBankAccountMutation.mutate,
      deleteBankAccountMutation.mutate,
      editBankAccountMutation.mutate,
      verificationMutation.mutate,
    ]
  );
};

export default useBankAccountMutation;
