import { Loader } from "lucide-react";
import { useCallback } from "react";
import { BigButton, Chip, Skeleton } from "src/components";
import { Variant } from "src/components/BigButton";
import { Variant as ChipVariant } from "src/components/Chip";
import { InputModalForm } from "src/components/InnerModals";
import { MediumText } from "src/components/v1";
import { ChevronRight } from "src/icons";
import { createStyles } from "src/styles";

import { colors, entities, schemas } from "@fraction/shared";

const styles = createStyles({
  bankButton: {
    marginBottom: 16,
  },
  buttonText: {
    color: colors.palette.GREY_700,
    fontSize: 14,
    wordBreak: "break-word",
    textAlign: "left",
  },
  accountNumberText: {
    marginTop: 5,
  },
  bankButtonLayout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bankButtonTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    lineHeight: 1.2,
  },
  icon: {
    color: colors.palette.GREY_700,
    width: 16,
  },
  chip: {
    marginLeft: 8,
  },
  skeleton: {
    marginBottom: 16,
  },
});

const MappedBankButton = ({
  bankAccount,
  handleSelectBankAccount,
}: {
  bankAccount: entities.BankAccount;
  handleSelectBankAccount: (bankAccount: entities.BankAccount) => void;
}) => {
  const { verificationStatus, name, accountNumberLast4Digits } = bankAccount;

  const onClickBankAccount = useCallback(() => {
    handleSelectBankAccount(bankAccount);
  }, [bankAccount, handleSelectBankAccount]);

  return (
    <BigButton
      onClick={onClickBankAccount}
      style={styles.bankButton}
      variant={
        verificationStatus === schemas.modernTreasury.VerificationStatus.VERIFIED ? Variant.GREEN : undefined
      }
    >
      <div css={styles.bankButtonLayout}>
        <div css={styles.bankButtonTextContainer}>
          <MediumText style={styles.buttonText}>{name}</MediumText>
          <MediumText style={[styles.buttonText, styles.accountNumberText]}>
            XXXX{accountNumberLast4Digits}
          </MediumText>
        </div>
        {verificationStatus === schemas.modernTreasury.VerificationStatus.VERIFIED ? (
          <Chip variant={ChipVariant.GREEN} style={styles.chip}>
            Verified
          </Chip>
        ) : (
          <Chip variant={ChipVariant.RED} style={styles.chip}>
            Unverified
          </Chip>
        )}
      </div>
    </BigButton>
  );
};

const SelectBankAccountScreen = ({
  bankAccounts,
  onAddBank,
  handleSelectBankAccount,
  isLoading,
  isPending,
  paragraph,
}: {
  bankAccounts?: entities.BankAccount[];
  onAddBank?: () => void;
  handleSelectBankAccount: (bankAccount: entities.BankAccount) => void;
  isLoading?: boolean;
  isPending?: boolean;
  paragraph: string;
}) => (
  <InputModalForm header="SELECT YOUR ACCOUNT" paragraph={paragraph}>
    {isLoading ? (
      <>
        <Skeleton height={71} style={styles.skeleton} />
        <Skeleton height={49} />
      </>
    ) : (
      <>
        <div>
          {bankAccounts?.map((bankAccount) => (
            <MappedBankButton
              key={bankAccount.id}
              bankAccount={bankAccount}
              handleSelectBankAccount={handleSelectBankAccount}
            />
          ))}
        </div>
        <BigButton disabled={!onAddBank} onClick={onAddBank}>
          <div css={styles.bankButtonLayout}>
            <MediumText style={styles.buttonText}>Add a new account </MediumText>
            {isPending ? (
              <Loader height={19} className="inline-block ml-1 animate-spin" />
            ) : (
              <ChevronRight css={styles.icon} />
            )}
          </div>
        </BigButton>
      </>
    )}
  </InputModalForm>
);

export default SelectBankAccountScreen;
