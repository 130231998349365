import { RefObject, useEffect } from "react";

/**
 * Pass a ref for an element, if the user clicks outside of that element, the callback will be called.
 */
const useClickedOutsideElement = (ref: RefObject<HTMLElement>, onClickOutside?: () => unknown) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref?.current && !ref.current?.contains(event.target as HTMLElement)) {
        onClickOutside?.();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};

export default useClickedOutsideElement;
