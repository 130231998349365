import { polyfill as seamlessScrollPolyfill } from "seamless-scroll-polyfill";

/**
 * This needs to be imported before proxy-polyfill,
 * but it lets us know if Proxy has been polyfilled.
 */
class PolyfillTracker {
  public proxy = false;

  public constructor() {
    this.proxy = typeof Proxy === "undefined";

    // This is a polyfill for native smooth scrolling which is not yet supported on Safari or IE (https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior)
    seamlessScrollPolyfill();
  }
}

const POLYFILL_TRACKER = new PolyfillTracker();

export default POLYFILL_TRACKER;
