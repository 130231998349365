import { CountAndStats } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/CountAndStats";
import { useApplicantCreditScoreDeltas } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";

const valueFormatter = (val: number) => val.toFixed(0);

export function BorrowerCreditScoreDeltaChart() {
  const { data, isLoading } = useApplicantCreditScoreDeltas();

  return (
    <CountAndStats
      statValueFormatter={valueFormatter}
      xAxisLabel="Credit score delta"
      data={data?.data}
      isLoading={isLoading}
      header="Credit score deltas"
      id="creditScoreDeltas"
      labelDigits={0}
    />
  );
}
