import { parsers } from "@fraction/shared";
import fraction from "src/api/fraction";
import { useCachedQuery } from "src/hooks/useCache";

export function useRates(options: parsers.rate.FetchRateArgs) {
  return useCachedQuery({
    queryKey: ["rates"],
    deserialize: parsers.rate.parseRates,
    queryFn: async () => {
      return fraction.getRates(options);
    },
  });
}
