import { enums } from "@fraction/shared";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { cn } from "src/utilities/shadcnUtils";

export function CountrySelect({
  onValueChange,
  className,
  value,
}: {
  value?: enums.Country;
  onValueChange?: (value: enums.Country) => void;
  className?: string;
}) {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className={cn("col-span-2", className)}>
        <SelectValue placeholder="Select a country" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Country</SelectLabel>
          <SelectItem value="US">USA</SelectItem>
          <SelectItem value="CA">Canada</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
