import { Stat } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/Stats";
import { useWeightedAverageMaturity } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";

import { useTabs } from "src/hooks";
import { ChartCard } from "../ChartCard";

export function WeightedAverageMaturity() {
  const { data, isLoading } = useWeightedAverageMaturity();
  const { Tabs, activeTab } = useTabs({
    options: [
      {
        value: "overall",
        label: "Overall",
      },
      {
        value: "year",
        label: "By year",
      },
    ],
    defaultValue: "overall",
  });

  if (isLoading) {
    return (
      <div className="col-span-1">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  const activeData = data?.find((datum) => datum.group === activeTab);

  if (!activeData) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={activeData.data.length === 0}
      cardClassName="col-span-1"
      className="min-h-[300px]"
      header="Weighted Average Maturity"
      headerComponents={
        <div className="flex row gap-3 items-center flex-wrap">
          <Tabs />
        </div>
      }
    >
      {activeData.data.map((datum) => (
        <div key={datum.year || datum.wam}>
          {datum.year && (
            <p className="text-md text-gray-900 font-bold font-serif text-center">{datum.year}</p>
          )}
          <div className="flex flex-wrap gap-2 w-full items-center justify-center h-fit">
            <Stat label={"WAM\n(by volume)"} value={datum.wam?.toFixed(2)} />
            <Stat label={"WAM\n(by count)"} value={datum.wam_count?.toFixed(2)} />
            <Stat label="Weighted avg. term length" value={datum.wa_termlength?.toFixed(2)} />
          </div>
        </div>
      ))}
    </ChartCard>
  );
}
