import { SerializedStyles } from "@emotion/react";
import { useCallback } from "react";
import { RegularText } from "src/components/v1";
import { createStyles } from "src/styles";

export interface Section {
  color?: string;
  value: number;
  label?: string;
}

export interface NormalizedSection extends Section {
  originalValue: number;
}

interface ModifiedSection extends NormalizedSection {
  displayValue: number;
}

export interface BarChartProps {
  sections: Section[];
  formatter?: (val: number) => string;
  style?: SerializedStyles;
  textStyle?: SerializedStyles;
}

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    paddingTop: 6,
    paddingBottom: 6,
    height: 35,
    width: "100%",
  },
  text: {
    fontSize: 16,
    color: "white",
  },
});

const PREFERRED_MINIMUM_SIZE = 15;

function normalizePercentages<T extends Section = Section>(
  sections: T[]
): Array<T & { originalValue: number }> {
  let totalValue = 0;
  for (const { value } of sections) {
    totalValue += value;
  }

  return sections.map(
    ({ color, value, ...other }) =>
      ({
        color,
        originalValue: value,
        value: (100 * value) / totalValue,
        ...other,
      }) as T & { originalValue: number }
  );
}

const defaultFormatter = (originalValue: number) => {
  let valueage = Math.round(originalValue);
  if (originalValue < 1 || originalValue > 99) {
    valueage = Number(originalValue.toFixed(2));
  }
  return `${valueage}%`;
};

const BarChart = ({ sections, formatter, style, textStyle }: BarChartProps) => {
  /**
   * Just to make things easier on the eyes, don't use real valueages if there are valueages under
   * PREFERRED_MINIMUM_SIZE. If the value === 0, then don't bump up the size because we just don't want to use it.
   */
  const expandSmallSectionsIfPossible = useCallback((s: NormalizedSection[]): ModifiedSection[] => {
    let totalAmountBumped = 0;
    const bumpedSections = s.map(({ color, value, ...other }) => {
      let displayValue = value;

      if (value > 0 && value < PREFERRED_MINIMUM_SIZE) {
        totalAmountBumped += PREFERRED_MINIMUM_SIZE - value;
        displayValue = PREFERRED_MINIMUM_SIZE;
      }

      return { color, value, displayValue, ...other };
    });
    // no changes, we are good
    if (totalAmountBumped === 0) {
      return bumpedSections;
    }
    return normalizePercentages(bumpedSections);
  }, []);

  const normalizedSections = normalizePercentages(sections);
  const modifiedSections = expandSmallSectionsIfPossible(normalizedSections);

  return (
    <div css={[styles.container, style]}>
      {modifiedSections.map(({ color, value, displayValue, originalValue, label }) => {
        // if there is only 30%, there's probably not enough space for our label
        if (displayValue < 30) {
          label = "";
        }

        return (
          <div
            key={`${value}-${color}`}
            css={styles.container}
            style={{ backgroundColor: color, flex: displayValue || 0 }}
          >
            {displayValue > 4 && (
              <RegularText style={[styles.text, textStyle]}>{`${
                formatter ? formatter(originalValue) : defaultFormatter(value)
              }${label ? ` - ${label}` : ""}`}</RegularText>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BarChart;
