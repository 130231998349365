import { SerializedStyles } from "@emotion/react";
import { memo, useCallback, useEffect, useRef } from "react";

export interface DoughnutChartProps {
  innerLabel?: string;
  values: number[];
  labels?: string[];
  colors: string[];
  size?: number;
  valueFormatter?: (val: number) => string;
  style?: SerializedStyles;
}

const valueFormatterDefault = (val: number) => `${val}%`;

const DoughnutChart = ({
  innerLabel,
  labels,
  values,
  colors,
  size = 480,
  style,
  valueFormatter = valueFormatterDefault,
}: DoughnutChartProps) => {
  const ref = useRef<HTMLCanvasElement>(null);

  const holeRadius = size / 8;
  const arcWidth = holeRadius / 2;
  const labelSize = 20;
  const labelFontsize = 9;

  const cx = size / 5.5 + labelSize / 2;
  const cy = size / 5.85;

  const width = size + labelSize * 2;

  useEffect(() => {
    const canvas = ref.current;
    const ctx = canvas?.getContext?.("2d");

    if (!ctx || !canvas) {
      return;
    }

    // Need to scale this to make it work properly
    canvas.height = size * 0.75;
    canvas.width = width * 0.75;
    canvas.style.width = `${width / 2}px`;
    canvas.style.height = `${size / 2}px`;
    ctx.scale(2, 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    // we need to redraw after the fonts are loaded
    setTimeout(() => {
      drawDoughnutChart();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    drawDoughnutChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, labels, ref.current]);

  const drawDoughnutChart = useCallback(() => {
    const canvas = ref.current;
    const ctx = canvas?.getContext?.("2d");

    if (!ctx || !canvas) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    let accum = 0;
    const pi = Math.PI;
    const pi2 = pi * 2;
    const offset = -pi / 2;
    const total = values.reduce((a, b) => a + b);

    ctx.lineWidth = arcWidth;

    for (let i = 0; i < values.length; i++) {
      // draw arc
      ctx.beginPath();
      const percentStart = accum / total;
      const percentEnd = (accum + values[i]) / total;
      const start = offset + pi2 * percentStart;
      const end = offset + pi2 * percentEnd;

      ctx.arc(cx, cy, holeRadius, start, end);
      ctx.strokeStyle = colors[i];
      ctx.stroke();
      accum += values[i];
    }

    accum = 0;

    for (let i = 0; i < values.length; i++) {
      if (values[i] / total > 0.05) {
        // draw labels
        const percentStart = accum / total;
        const percentEnd = (accum + values[i]) / total;

        // the angle that represents the halfway mark between the start and end of the arc
        const centerAngle = ((percentEnd + percentStart) / 2) * pi2 - offset;
        const arcLabelOffsetX = Math.cos(centerAngle) * (holeRadius + arcWidth / 2);
        const arcLabelOffsetY = Math.sin(centerAngle) * (holeRadius + arcWidth / 2);
        const arcLabelX = cx - arcLabelOffsetX;
        const arcLabelY = cy - arcLabelOffsetY;

        ctx.beginPath();
        ctx.arc(arcLabelX, arcLabelY, labelSize, 0, pi2);
        ctx.fillStyle = "white";
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;
        ctx.shadowColor = "rgba(0,0,0,0.15)";
        ctx.shadowBlur = 8;
        ctx.fill();

        const label = valueFormatter(values[i]);

        let nextY = arcLabelY - 1;
        if (labels?.[i]) {
          const { cy: next } = generateText(
            { text: `${labels[i]}\n `, fontSize: labelFontsize },
            arcLabelX,
            arcLabelY,
            labelSize * 0.75
          );
          nextY = next;
        }

        generateText(
          { text: label, fontWeight: 700, fontSize: labelFontsize },
          arcLabelX,
          nextY + 1.5,
          labelSize
        );
      }

      accum += values[i];
    }

    if (innerLabel) {
      generateText({ text: innerLabel, fontSize: 14 }, cx, cy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, values, innerLabel, labels]);

  const styleText = useCallback(
    (fontSize: number, fontWeight = 400) => {
      const canvas = ref.current;
      const ctx = canvas?.getContext?.("2d");

      if (!ctx) {
        return;
      }
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#4D4D4D";
      ctx.font = `${fontWeight} ${fontSize}px Montserrat`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current]
  );

  const generateText = useCallback(
    (
      { text, fontSize, fontWeight = 400 }: { text: string; fontSize: number; fontWeight?: number },
      textCx: number,
      textCy: number,
      maxWidth: number = Infinity
    ): { cx: number; cy: number } => {
      const canvas = ref.current;
      const ctx = canvas?.getContext?.("2d");

      if (!ctx) {
        return { cx: 0, cy: 0 };
      }

      const lines = text.split("\n");

      // determine font size based on maxWidth
      const largestLineLength = Math.max(...lines.map((line) => line.length));
      let largestLineWidth = largestLineLength * (fontSize / 2.5);
      let largestAllowableFontSize = fontSize;
      while (largestLineWidth > maxWidth) {
        largestAllowableFontSize--;
        largestLineWidth = largestLineLength * (largestAllowableFontSize / 2.5);
      }

      styleText(largestAllowableFontSize, fontWeight);

      const numLines = lines.length;
      const moveUp = textCy - ((numLines - 1) / 2) * largestAllowableFontSize;

      for (let i = 0; i < lines.length; i++) {
        ctx.fillText(lines[i], textCx, moveUp + i * (largestAllowableFontSize + 1));
      }

      return { cx: textCx, cy: moveUp + (lines.length - 1) * (largestAllowableFontSize + 1) };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current]
  );

  return <canvas css={style} ref={ref} width={width} height={size} />;
};

export default memo(DoughnutChart);
