import { RefObject, useEffect } from "react";

// queues a callback function that will be run after scrolling is completed
const useQueueAfterScroll = ({
  scrollElement,
  enabled = true,
  callback,
  threshold = 500,
}: {
  scrollElement: RefObject<any>;
  enabled?: boolean;
  callback: () => void;
  threshold?: number;
}) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const scrollTimeout = () =>
      setTimeout(() => {
        callback();
      }, threshold);

    let timer = scrollTimeout();

    const onScroll = () => {
      clearTimeout(timer);
      timer = scrollTimeout();
    };

    scrollElement.current.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollElement.current.removeEventListener("scroll", onScroll);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
};

export default useQueueAfterScroll;
