import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";
import { useCallback } from "react";
import { ExistingDocumentList } from "src/embeds/Classifier/components/ExistingDocumentList";
import { useCachedState } from "src/hooks/useCache";
import { cn } from "src/utilities/shadcnUtils";

export function ExistingDocumentListSlider({
  applicationId,
  enabled,
}: { applicationId?: string; enabled?: boolean }) {
  const [expanded, setExpanded] = useCachedState(false, "classifier-sidebar-expanded");

  const handleClickExpand = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  return (
    <div className="flex flex-row h-full">
      <aside
        className={cn(
          "p-3 h-full origin-right duration-200 w-0 min-w-0",
          expanded && "min-w-[400px] w-[400px]"
        )}
      >
        {expanded ? (
          <ExistingDocumentList applicationId={applicationId} enabled={enabled && expanded} />
        ) : null}
      </aside>
      <div className="h-full flex items-center justify-center pl-2">
        <button
          onClick={handleClickExpand}
          className="bg-gray-100 border border-gray-300 text-gray-600 w-6 h-12 flex flex-col items-center justify-center rounded-full hover:brightness-95"
        >
          {expanded ? <CaretLeftIcon className="h-6 w-6" /> : <CaretRightIcon className="h-6 w-6" />}
        </button>
      </div>
    </div>
  );
}
