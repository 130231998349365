import { SideBar } from "src/components";

import { NavItems } from "../NavItems";

const PostFundedSidebar = () => {
  return (
    <SideBar divider>
      <NavItems />
    </SideBar>
  );
};

export default PostFundedSidebar;
