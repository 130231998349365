import { formatters, types } from "@fraction/shared";
import fraction from "src/api/fraction";
import { useQuery } from "src/lib";

export function usePropertyCheck(propertyAddress?: types.ExtractedPropertyAddress) {
  const formattedAddress = propertyAddress
    ? formatters.property.shortFormattedAddress(propertyAddress)
    : undefined;

  return useQuery({
    enabled: !!formattedAddress,
    queryKey: ["propertyChecker", formattedAddress],
    queryFn: async () => {
      if (!propertyAddress) {
        return;
      }
      return await fraction.underwriteProperty(propertyAddress);
    },
  });
}
