import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";

export interface TooltipProps {
  children: React.ReactNode;
  text: string | React.ReactNode;
  className?: string;
  white?: boolean;
  clickable?: boolean;
  delay?: number;
  place?:
    | "top"
    | "top-start"
    | "top-end"
    | "right"
    | "right-start"
    | "right-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "left"
    | "left-start"
    | "left-end";
}

const Tooltip = ({
  children,
  className,
  text,
  white,
  clickable,
  delay,
  place,
  ...otherProps
}: TooltipProps) => {
  const props: any =
    typeof text === "string"
      ? { "data-tooltip-content": text, ...otherProps }
      : // @ts-ignore
        { "data-tooltip-html": renderToStaticMarkup(text), ...otherProps };

  if (className) {
    props.className = className;
  }

  return (
    <>
      {children ? (
        typeof children === "string" ? (
          // @ts-ignore
          <p
            {...props}
            id={clickable ? "clickable-tooltip" : ""}
            data-tooltip-id={white ? "white-tooltip" : "tooltip"}
            data-tooltip-place={place}
            data-tooltip-delay-show={delay}
          >
            {children}
          </p>
        ) : (
          // @ts-ignore
          React.cloneElement(children, {
            "data-tooltip-id": white ? "white-tooltip" : "tooltip",
            "data-tooltip-place": place,
            id: clickable ? "clickable-tooltip" : "",
            "data-tooltip-delay-show": delay,
            ...props,
          })
        )
      ) : null}
    </>
  );
};

export default React.memo(Tooltip);
