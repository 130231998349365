import { enums } from "@fraction/shared";
import _ from "lodash";
import { useMemo, useState } from "react";
import fraction from "src/api/fraction";
import { ConveyancerSelect } from "src/apps/LOS/components/ConveyancerSelect";
import { ProvinceSelect } from "src/apps/LOS/components/ProvinceSelect";
import { useChecklistModal } from "src/components/AppChecklistModal";
import { AppsPipelineView } from "src/components/AppsPipelineView";
import { useInfiniteApplicationsQuery } from "src/hooks/useApplication";
import useToggler from "src/hooks/useToggler";
import { useQuery } from "src/lib";

export function Pipeline() {
  const {
    data: apps,
    count,
    isFetching,
    dataUpdatedAt,
    refetch,
  } = useInfiniteApplicationsQuery({
    status: "active",
    accountType: "employee",
  });

  const { Toggle: SortByCloseDateToggle, on: sortByCloseDate } = useToggler({
    defaultValue: true,
    id: "sort-by-close-date",
    label: "Sort by close date",
    direction: "left",
  });
  const [provinceFilter, setProvinceFilter] = useState<string | null>(null);
  const [conveyancerFilter, setConveyancerFilter] = useState<enums.ConveyancerGroup | null>(null);
  const filteredApps = useMemo(
    () =>
      apps?.filter((app) => {
        if (!provinceFilter && !conveyancerFilter) {
          return app;
        }
        if (provinceFilter && conveyancerFilter) {
          return (
            app.property?.administrativeArea === provinceFilter &&
            app.conveyancerGroupId === conveyancerFilter
          );
        }
        if (provinceFilter) {
          return app.property?.administrativeArea === provinceFilter;
        }
        if (conveyancerFilter) {
          return app.conveyancerGroupId === conveyancerFilter;
        }
      }),
    [provinceFilter, conveyancerFilter, apps]
  );
  const sortedApps = useMemo(
    () => (sortByCloseDate ? _.sortBy(filteredApps, "estimatedCloseDate") : filteredApps),
    [filteredApps, sortByCloseDate]
  );
  const { setShowModal, ChecklistModal } = useChecklistModal();

  const { data: salesOptions } = useQuery({
    queryKey: ["salesOptions"],
    queryFn: () =>
      fraction.getEmployees([enums.InternalEmployeeType.BDM, enums.InternalEmployeeType.INSIDE_SALES]),
  });

  return (
    <AppsPipelineView
      salesOptions={salesOptions}
      showSalesPerson
      apps={sortedApps}
      isFetching={isFetching}
      handleRefetchClick={refetch}
      totalCount={count}
      dataUpdatedAt={dataUpdatedAt}
      setShowModal={setShowModal}
      groupBy="syntheticStatus"
      modal={<ChecklistModal showRetoolButton />}
      header={
        <div className="flex flex-row gap-x-8">
          <SortByCloseDateToggle className="w-[200px]" />
          <ProvinceSelect
            className="w-[300px]"
            onValueChange={setProvinceFilter}
            value={provinceFilter || undefined}
          />
          <ConveyancerSelect
            className="w-[300px]"
            value={conveyancerFilter || undefined}
            onValueChange={setConveyancerFilter}
          />
        </div>
      }
    />
  );
}
