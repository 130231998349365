import { useCallback, useState } from "react";

interface HoverTracker {
  onMouseEnter?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export default function useHoverTracker(props?: HoverTracker) {
  const [hovering, setHovering] = useState(false);

  const handleEnter = useCallback(
    (evt: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setHovering(true);
      props?.onMouseEnter?.(evt);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.onMouseEnter]
  );

  const handleLeave = useCallback(
    (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setHovering(false);
      props?.onMouseLeave?.(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.onMouseLeave]
  );

  return {
    hovering,
    onMouseLeave: handleLeave,
    onMouseEnter: handleEnter,
  };
}
