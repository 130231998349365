import { CountAndStats } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/CountAndStats";
import { useApplicantAges } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";

const valueFormatter = (val: number) => val.toFixed(1);

export function BorrowerAgeChart() {
  const { data, isLoading } = useApplicantAges();
  return (
    <CountAndStats
      statValueFormatter={valueFormatter}
      xAxisLabel="Age"
      // @ts-ignore
      data={data}
      isLoading={isLoading}
      header="Borrower ages"
      id="borrowerAges"
    />
  );
}
