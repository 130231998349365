import { useEffect, useRef } from "react";

const DEFAULT_OPTIONS = { preCheck: false, interval: 500 };

/**
 * Just like useEffectOnce, but run retries periodically.
 */
export default function useEffectRetry(
  cb: () => boolean | void,
  deps: any[] = [],
  options?: { preCheck: boolean; interval: number }
) {
  const { preCheck, interval } = { ...DEFAULT_OPTIONS, ...(options || {}) };
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (preCheck) {
      return;
    }
    if (timeout.current) {
      clearInterval(timeout.current);
    }
    timeout.current = setInterval(() => {
      let didItWork: any = false;
      try {
        didItWork = cb();
      } catch (err) {
        // swallow error
      }
      if (didItWork !== false && timeout.current) {
        clearInterval(timeout.current);
      }
    }, interval);

    return () => {
      if (timeout.current) {
        clearInterval(timeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
