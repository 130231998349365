import { entities } from "@fraction/shared";
import { Loader, Star, StarOff } from "lucide-react";
import { useCallback } from "react";
import Tooltip from "src/components/Tooltip";
import { cn } from "src/utilities/shadcnUtils";

export function BrokerPrimaryBadge({
  broker,
  className,
  onClickSetPrimary,
  pending,
}: {
  broker: entities.BrokerT;
  className?: string;
  onClickSetPrimary?: (broker: entities.BrokerT) => void;
  pending?: boolean;
}) {
  const handleClickSetPrimary = useCallback(() => {
    onClickSetPrimary?.(broker);
  }, [broker, onClickSetPrimary]);

  return broker.primary ? (
    <Tooltip
      text={
        <div>
          <b>Primary broker</b>
          <p className="text-sm">
            The primary broker is the broker who a) meets all licensing requirements and b) will be the
            recipient of the commission from Fraction.
          </p>
        </div>
      }
      white
    >
      <div
        className={cn(
          "text-left flex flex-row items-center bg-gray-200 w-fit border rounded-full px-3 py-1 gap-1 group cursor-help hover:brightness-95",
          className
        )}
      >
        <b className="line-clamp-1 truncate text-sm font-medium mr-1.5">Primary broker</b>
        <Star className="fill-yellow group-hover:scale-105 group-hover:brightness-110 inline h-6 w-6" />
      </div>
    </Tooltip>
  ) : (
    <Tooltip
      text={
        <div>
          <b>Secondary broker</b>
          <p className="text-sm">
            The primary broker is the broker who a) meets all licensing requirements and b) will be the
            recipient of the commission from Fraction.
          </p>
          <b className="text-sm mt-2">Click to set as the primary broker.</b>
        </div>
      }
      white
    >
      <button
        disabled={pending}
        onClick={handleClickSetPrimary}
        className={cn(
          "text-left flex flex-row items-center bg-gray-200 border rounded-full px-3 py-1 gap-1 group hover:brightness-95 relative",
          className
        )}
      >
        <b className="text-sm line-clamp-1 truncate font-medium mr-1.5 group-hover:font-bold group-hover:invisible">
          {pending ? "Setting as primary" : "Secondary broker"}
        </b>
        <b className="text-sm font-medium absolute left-3 invisible group-hover:visible">
          {pending ? "Setting as primary" : "Set primary broker"}
        </b>
        {pending ? (
          <Loader className="min-h-6 min-w-6 h-6 w-6 animate-spin" />
        ) : (
          <>
            <StarOff className="fill-yellow group-hover:hidden min-h-6 min-w-6 h-6 w-6" />
            <Star className="fill-yellow hidden group-hover:block group-hover:brightness-110 group-hover:scale-105 transition duration-100 min-h-6 min-w-6 h-6 w-6" />
          </>
        )}
      </button>
    </Tooltip>
  );
}
