import { entities, formatters } from "@fraction/shared";
import { useCallback } from "react";
import { useDocumentUrlQuery } from "src/apps/PostFundedDashboard/queries";
import { FileRow } from "src/components/FileTable";
import { downloadFile } from "src/utilities/file/download";

export const DocumentRow = ({
  document,
  buttonSize = "small",
}: {
  document: entities.UploadedFile;
  buttonSize?: "small" | "medium" | "standard" | "large";
}) => {
  const { refetch, isFetching } = useDocumentUrlQuery(document.id!, {
    onSuccess: ({ fileURL, key }) => {
      return downloadFile(fileURL, key);
    },
  });

  const handleDownload = useCallback(() => {
    refetch();
  }, [refetch]);

  const lastPartOfS3Key = document.s3Document?.s3Key.split("/").pop();

  return (
    <FileRow
      buttonSize={buttonSize}
      subtext={document.date ? formatters.date.formatDate(document.date) : undefined}
      text={document?.type?.fullName || document.s3Document?.name || lastPartOfS3Key || "Document"}
      isLoading={isFetching}
      onClick={handleDownload}
    />
  );
};
