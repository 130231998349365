import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffectOnce } from "src/hooks";

function useDebounce(ms: number) {
  const lastTime = useRef<number>(Date.now());

  return useCallback(() => {
    const ret = Date.now() - lastTime.current > ms;
    lastTime.current = Date.now();
    return ret;
  }, [ms]);
}

export default function useCaptureBrowserButtons({
  goBack,
  goNext,
  pages = [],
  currentPage,
}: {
  goNext: () => void;
  goBack: () => void;
  pages?: string[];
  currentPage?: string;
}) {
  const [isSetup, setIsSetup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const previousCurrentPageRef = useRef<string>();

  const setupHistory = useCallback(() => {
    if (!pages.includes(currentPage || "")) {
      return;
    }
    navigate({ hash: currentPage, search: window.location.search });
    setIsSetup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, navigate, pages]);

  useEffectOnce(
    () => {
      setupHistory();
    },
    [setupHistory],
    !currentPage || !pages.includes(currentPage || "")
  );

  const hash = decodeURIComponent(location.hash.slice(1));
  const shouldFire = useDebounce(50);

  useEffect(() => {
    if (
      !isSetup ||
      !currentPage ||
      hash === currentPage ||
      !pages.includes(currentPage) ||
      (currentPage === previousCurrentPageRef.current && !pages.includes(hash)) ||
      // this debounce is a bit of a hack. The reason we have it is because if the hash no longer
      // matches what is in the page list (which happens because of our modals) then this will
      // refire quickly again with a non-matching hash, which then messes up the state.
      // When we remove the nested modal flows, we can remove this debounce.
      // The reason for this mismatch is because the hash is a singleton (it's part of the url)
      // whereas there can be multiple flows. We could track the opening and closing of flows and reset
      // the hashes, but that would cause there to be lots more "hooking into" the app flow logic,
      // as opposed to keeping it all nice and encapsulated in this function.
      // The other noteworthy thing about choosing to do it this way is that the back button functionality
      // can get a bit "buggy" in between opening and closing flows. Since we plan to deprecate the modals,
      // I think this is okay in the name of keeping the code complexity down, since the on-screen
      // buttons still work as expected in those cases.
      !shouldFire()
    ) {
      return;
    }

    let pageCurrentlyOn = pages.indexOf(hash || "");
    let pageToGoTo = pages.indexOf(currentPage || "");

    let browserFired = false;

    if (currentPage === previousCurrentPageRef.current) {
      browserFired = true;
      // this has been fired by browser buttons.
      // this means the hash is the source of truth, not the "currentPage", so reverse them
      const tmp = pageCurrentlyOn;
      pageCurrentlyOn = pageToGoTo;
      pageToGoTo = tmp;
    }

    while (pageCurrentlyOn !== pageToGoTo) {
      if (pageCurrentlyOn > pageToGoTo) {
        pageCurrentlyOn--;
        if (browserFired) {
          goBack();
        } else {
          navigate(-1);
        }
      } else if (pageCurrentlyOn < pageToGoTo) {
        pageCurrentlyOn++;
        if (browserFired) {
          goNext();
        } else {
          navigate({ hash: currentPage });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetup, goBack, goNext, currentPage, pages, hash, previousCurrentPageRef.current]);

  useEffect(() => {
    if (!pages.includes(currentPage || "")) {
      return;
    }
    previousCurrentPageRef.current = currentPage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, hash]);

  return setupHistory;
}
