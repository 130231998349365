import { SerializedStyles } from "@emotion/react";
import { Transition, motion } from "framer-motion";
import { createStyles } from "src/styles";
import { cn } from "src/utilities/shadcnUtils";

const LOADING_CONTAINER = {
  display: "flex",
  justifyContent: "space-around",
};

const styles = createStyles((tokens) => ({
  circle: {
    display: "block",
  },
}));

const LOADING_CONTAINER_VARIANTS = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const LOADING_CIRCLE_VARIANTS = {
  start: {
    y: "00%",
  },
  end: {
    y: "100%",
  },
};

const LOADING_CIRCLE_TRANSITION: Transition = {
  duration: 0.5,
  repeat: Infinity,
  repeatType: "reverse",
  ease: "easeInOut",
};

export interface ThreeDotsWaveProps {
  dotCss?: SerializedStyles;
  size?: number;
  className?: string;
}

const ThreeDotsWave = ({ dotCss, className, size = 5 }: ThreeDotsWaveProps) => {
  const radius = { width: size, height: size, borderRadius: size };
  return (
    <motion.div
      css={[LOADING_CONTAINER, { width: size * 5, height: size * 2 }]}
      variants={LOADING_CONTAINER_VARIANTS}
      initial="start"
      animate="end"
    >
      <motion.span
        className={cn("bg-primary-foreground", className)}
        css={[styles.circle, radius, dotCss]}
        variants={LOADING_CIRCLE_VARIANTS}
        transition={LOADING_CIRCLE_TRANSITION}
      />
      <motion.span
        className={cn("bg-primary-foreground", className)}
        css={[styles.circle, radius, dotCss]}
        variants={LOADING_CIRCLE_VARIANTS}
        transition={LOADING_CIRCLE_TRANSITION}
      />
      <motion.span
        className={cn("bg-primary-foreground", className)}
        css={[styles.circle, radius, dotCss]}
        variants={LOADING_CIRCLE_VARIANTS}
        transition={LOADING_CIRCLE_TRANSITION}
      />
    </motion.div>
  );
};

export default ThreeDotsWave;
