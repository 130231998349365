import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import FinePrint from "src/components/FinePrint";

export interface HMDAProps {
  style?: SerializedStyles;
}

const HMDA = ({ style }: HMDAProps) => (
  <FinePrint style={style}>
    Federal law requires that we ask applicants for their demographic information in order to monitor our
    compliance with equal credit opportunity, fair housing, and home mortgage disclosure laws. You are not
    required to provide this information, but are encouraged to do so.
  </FinePrint>
);

export default memo(HMDA);
