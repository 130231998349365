import { DragEndEvent, DragOverEvent, DragStartEvent, UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useCallback, useState } from "react";
import { useKeyboardListener } from "src/hooks";
import { useEventualState } from "src/hooks/useEventualState";

type Items<Status extends string = string, Obj extends { id: string } = { id: string }> = Record<
  Status,
  Obj[]
>;

function findContainer<Status extends string>(
  id: UniqueIdentifier,
  items: Partial<Items<Status>>
): Status | undefined {
  return Object.keys(items).find((key) => key === id || items[key as Status]?.find((x) => x?.id === id)) as
    | Status
    | undefined;
}

export function useDraggable<Status extends string, Obj extends { id: string }>({
  items: startItems,
  onChange,
}: {
  items: Partial<Items<Status, Obj>>;
  onChange?: (change: { id: string; status: Status }) => Promise<boolean | void>;
}) {
  const [active, setActive] = useState<{ id: string; status?: Status } | null>(null);
  const [items, setItems] = useEventualState<Partial<Items<Status, Obj>>>(startItems);
  const [solidifiedState, setSolidifiedState] = useEventualState<Partial<Items<Status, Obj>>>(startItems);

  const handleEscape = useCallback(() => {
    setActive(null);
    setItems(solidifiedState);
  }, []);

  useKeyboardListener(["Escape"], handleEscape);

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event;
      const { id } = active;

      if (id) {
        setActive({ id: id as string, status: findContainer<Status>(id, items) as Status });
      }
    },
    [items]
  );

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;

      const { id } = active;
      const { id: overId } = over || {};

      if (!overId || !id) {
        return;
      }

      setItems((prev) => {
        // Find the containers
        const activeContainer =
          active?.data?.current?.sortable?.containerId || active?.id || findContainer<Status>(id, items);
        const overContainer =
          over?.data?.current?.sortable?.containerId || over?.id || findContainer<Status>(overId, items);

        if (!activeContainer || !overContainer) {
          return prev;
        }

        if (id) {
          setActive({ id: id as string, status: overContainer as Status });
        }

        const activeItems = prev[activeContainer as Status];
        const overItems = prev[overContainer as Status];

        if (overItems === undefined || activeItems === undefined) {
          return prev;
        }

        // Find the indexes for the items
        const activeIndex = activeItems?.findIndex((x) => x?.id === id);
        const overIndex = overItems?.findIndex((x) => x?.id === overId);

        let newIndex;
        if (overId in prev) {
          // We're at the root droppable of a container
          newIndex = overItems.length + 1;
        } else {
          const isBelowLastItem = over && overIndex === overItems.length - 1;

          const modifier = isBelowLastItem ? 1 : 0;

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        if (activeContainer === overContainer && activeIndex === overIndex) {
          return prev;
        }

        if (activeContainer !== overContainer) {
          return {
            ...prev,
            [activeContainer]: [
              ...(prev[activeContainer as Status] || []).filter((item) => item?.id !== active?.id),
            ],
            [overContainer]: [
              ...(prev[overContainer as Status]?.slice(0, newIndex) || []),
              items[activeContainer as Status]?.[activeIndex],
              ...(prev[overContainer as Status]?.slice(newIndex, prev[overContainer as Status]?.length) ||
                []),
            ],
          };
        } else {
          return {
            ...prev,
            [overContainer]: arrayMove(prev[overContainer as Status] || [], activeIndex, overIndex),
          };
        }
      });
    },
    [items]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active } = event;
      const { id } = active;

      if (!id) {
        return;
      }
      const activeContainer = findContainer<Status>(id, items);

      if (!activeContainer) {
        return;
      }

      // hasn't changed since last time
      if (solidifiedState[activeContainer]?.find((x) => x?.id === id)) {
        return;
      }

      const previousSolidifiedState = solidifiedState;
      setSolidifiedState(items);

      onChange?.({
        id: id as string,
        status: activeContainer as Status,
      }).then((good) => {
        if (good === false) {
          setSolidifiedState(previousSolidifiedState);
          setItems(previousSolidifiedState);
        }
      });

      setActive(null);
    },
    [items, solidifiedState, onChange]
  );

  return {
    items: items as Items<Status, Obj>,
    active,
    onDragStart: handleDragStart,
    onDragOver: handleDragOver,
    onDragEnd: handleDragEnd,
  };
}
