import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode } from "react";
import { Heading, Paragraph, PrimaryButton } from "src/components";
import { useRedirect } from "src/hooks";
import { MaintenanceIllustration } from "src/images";
import { createStyles } from "src/styles";

const styles = createStyles(({ spacing, lineHeight }) => ({
  heading: {
    marginTop: spacing[3],
    marginBottom: spacing[3],
  },
  center: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100vh",
    userSelect: "none",
    // The padding is to make sure
    // there's some space on mobile displays
    padding: spacing[4],
  },
  paragraph: {
    lineHeight: lineHeight.scalar[150],
    marginBottom: spacing[4],
  },
}));

export const Maintenance = ({ description }: { description?: string }) => {
  const handleRedirect = useRedirect("https://www.fraction.com");
  return (
    <div css={styles.center}>
      <MaintenanceIllustration width={346} height={217} />
      <Heading style={styles.heading}>Fraction is currently down for maintenance</Heading>
      <Paragraph style={styles.paragraph}>
        {description?.length ? (
          description
        ) : (
          <>
            {" "}
            Our website is currently undergoing some updates and improvements.
            <br />
            We'll be back online soon!
          </>
        )}
      </Paragraph>
      <PrimaryButton size="medium" onClick={handleRedirect}>
        Go to fraction.com
      </PrimaryButton>
    </div>
  );
};

const MaintenanceIfEnabled = ({
  flag,
  children,
}: {
  flag: "applicationFlow" | "dashboard";
  children: ReactNode;
}) => {
  const flags = useFlags();
  const maintenanceEnabled = flags[`${flag}MaintenanceEnabled`];
  const maintenanceDescription = flags[`${flag}MaintenanceDescription`];
  return <>{maintenanceEnabled ? <Maintenance description={maintenanceDescription} /> : children}</>;
};

export default MaintenanceIfEnabled;
