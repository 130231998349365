import { SerializedStyles } from "@emotion/react";
import { ReactNode, forwardRef } from "react";
import FormFlowContainer from "src/components/FormFlowContainer";
import { FormFlowContainerProps } from "src/components/FormFlowContainer";
import { HeaderText, RegularText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export const styles = createStyles({
  header: {
    color: colors.BLACK_TEXT,
    marginBottom: 24,
  },
  description: {
    fontSize: 16,
    marginBottom: 24,
    color: colors.BLACK_TEXT,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 620,
    "@media(max-width: 610px)": {
      maxWidth: "100%",
    },
  },
  iconContainer: {
    marginBottom: 32,
  },
  iconTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
});

export interface FormFlowPageProps extends Omit<FormFlowContainerProps, "title"> {
  title: ReactNode;
  description?: ReactNode;
  textContainerStyle?: SerializedStyles;
  icon?: ReactNode;
  headerStyle?: SerializedStyles;
}

const FormFlowPage = forwardRef<HTMLDivElement, FormFlowPageProps>(
  (
    { title, description, textContainerStyle, children, icon, headerStyle, ...rest }: FormFlowPageProps,
    ref
  ) => (
    <FormFlowContainer {...rest} ref={ref}>
      <div css={styles.iconTextContainer}>
        {icon && <div css={styles.iconContainer}>{icon}</div>}
        <div css={[styles.textContainer, textContainerStyle]}>
          <HeaderText style={[styles.header, headerStyle]}>{title}</HeaderText>
          {description && (
            <RegularText paragraph style={styles.description}>
              {description}
            </RegularText>
          )}
        </div>
      </div>
      {children}
    </FormFlowContainer>
  )
);

export default FormFlowPage;
