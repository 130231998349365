import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useRef, useState } from "react";

export interface UseAnimatedSelectArgs<T> {
  onNext?: (val?: T) => void;
  onChange?: (val?: T) => void;
  animate?: boolean;
}

const ANIMATION_VARIANTS = {
  clicked: { opacity: [1, 0.5, 1, 0.5, 1, 0.75, 1, 1], transition: { duration: 0.75 } },
  stop: { opacity: 1 },
};

/**
 * Given a button, change the opacity back and forth for a "selecting" style animation.
 * Then, after 750ms, hit the "onNext" on the page that contains this hook.
 *
 * You may need to use this hook in two separate places: where the button is defined, and then separately
 * with the page that contains the button(s).
 */
export default function useAnimatedSelect<T>({ onNext, onChange, animate }: UseAnimatedSelectArgs<T>) {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [animationVariant, setAnimationVariant] = useState<keyof typeof ANIMATION_VARIANTS>("stop");

  // we want to test whether this auto-progression improves the user experience or not, so we have a flag
  // to A/B test that here
  const { autoProgressionAnimatedButtons } = useFlags();

  // Pass this callback function to the page that contains the button that you want to proceed from
  const handleChangePageValue = useCallback(
    (v: any) => {
      onChange?.(v);

      if (autoProgressionAnimatedButtons) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          onNext?.(v);
        }, 750);
      }
    },
    [onChange, onNext, autoProgressionAnimatedButtons]
  );

  // Pass this callback function to the button you want to animate
  const handleClickButton = useCallback(() => {
    onChange?.();

    if (animate && autoProgressionAnimatedButtons) {
      setAnimationVariant("clicked");
      setTimeout(() => {
        setAnimationVariant("stop");
      }, 750);
    }
  }, [onChange, animate, autoProgressionAnimatedButtons]);

  return {
    variants: ANIMATION_VARIANTS,
    variant: animationVariant,
    onClickButton: handleClickButton,
    onChangePageValue: handleChangePageValue,
  };
}
