import { ResponsiveCalendar } from "@nivo/calendar";
import { endOfYear, format, parseISO, startOfYear } from "date-fns";
import { useMemo } from "react";
import { useLoanFundingsData } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";
import { useTabs } from "src/hooks";

import { formatters } from "@fraction/shared";

import { ChartCard } from "../ChartCard";

const Tooltip = (point: { date: Date; data: { value: number; volume: number } }) => {
  return (
    <div className="shadow p-2 bg-white rounded">
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Date: </b>
        {format(new Date(point.date), "MMMM dd, yyyy")}
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Fundings: </b>
        {point.data.value}
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Volume: </b>
        {formatters.number.getCurrencyFromNumber(point.data.volume)}
      </p>
    </div>
  );
};

export function FundingsCalendar() {
  const { data, isLoading } = useLoanFundingsData();

  const { Tabs, activeTab } = useTabs({
    options: [
      { value: "2021", label: "2021" },
      { value: "2022", label: "2022" },
      { value: "2023", label: "2023" },
      { value: "2024", label: "2024" },
    ],
    defaultValue: "2024",
  });

  const mapped = useMemo(() => {
    return (
      data
        ?.filter((item) => new Date(item.date).getFullYear().toString() === activeTab)
        ?.map((item) => ({
          value: item.count,
          volume: item.sum,
          day: formatters.date.iso8601(new Date(item.date), "date"),
        })) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, activeTab]);

  if (isLoading) {
    return (
      <div className="col-span-3">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  if (!mapped) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={mapped.length === 0}
      cardClassName="col-span-3"
      className="h-[250px]"
      header="Funding calendar"
      headerComponents={
        <div className="flex row gap-3 items-center flex-wrap">
          <Tabs />
        </div>
      }
    >
      <ResponsiveCalendar
        data={mapped}
        from={startOfYear(parseISO(activeTab))}
        to={endOfYear(parseISO(activeTab))}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        emptyColor="#eee"
        // @ts-ignore
        tooltip={Tooltip}
        margin={{ left: 10 }}
      />
    </ChartCard>
  );
}
