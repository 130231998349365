import { SerializedStyles } from "@emotion/react";
import {
  Dispatch,
  ReactElement,
  ReactNode,
  RefObject,
  SetStateAction,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import * as React from "react";
import { captureMessage } from "src/api/sentry";
import { FlowLayoutHeader, FlowLayoutNav } from "src/components";
import {
  ReportingMemoizer,
  useCaptureBrowserButtons,
  useEffectOnce,
  useMobileHeightAdjustment,
  useReportingMemoizer,
  useStateCallback,
  useToggle,
} from "src/hooks";
import { WrappedPage } from "src/hooks/useFlowLayoutState";
import { createStyles } from "src/styles";
import { simplifyChildren } from "src/utilities/children";
import { focusWhenPossibleWithParentScroller } from "src/utilities/react";
import { v4 as uuid } from "uuid";

import { hasDuplicates, utilities } from "@fraction/shared";

import { Logger } from "src/log";
import { NavSection } from "../FlowNavigationBar/useFlowNavScroll";

export interface FlowLayoutProps {
  pages?: Page[];
  onClose?: () => void;
  onComplete?: () => void;
  // If we want the flow state to be stored outside this component (if for example, it can be unmounted)
  // then we can do that by passing the state here.
  externalState?: Partial<FlowLayoutState>;
  style?: SerializedStyles;
  continueFlow?: boolean; // if true, we should show all pages that have an existing value (up to a page that doesn't have the value)
  navSections?: NavSection[];
  children?: ReactNode;
}

export interface FlowLayoutState {
  wrappedMemo: ReportingMemoizer<any>;
  sectionsVisibleState: [
    Record<string, boolean>,
    (
      state: SetStateAction<Record<string, boolean>>,
      cb?: ((state: Record<string, boolean>) => void) | undefined
    ) => void
  ];
  wrappedPagesState: [WrappedPage[], Dispatch<SetStateAction<WrappedPage[]>>];
}

const styles = createStyles({
  scroller: {
    backgroundColor: "white",
    display: "flex",
    maxWidth: "100%",
    flexDirection: "column",
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 32,
    paddingTop: 32,
    overflowY: "scroll",
    WebkitOverflowScrolling: "touch",
    width: "100%",
    scrollBehavior: "smooth",
    boxSizing: "border-box",
    "::-webkit-scrollbar": {
      display: "none",
      WebkitAppearance: "none",
      width: "0",
      height: "0",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
});

export interface HandlerContext {
  wrappedPages: WrappedPage[];
  nakedPages: Page[];
  currentIndex: number;
  setSectionsVisible: (
    state: SetStateAction<Record<string, boolean>>,
    cb?: ((state: Record<string, boolean>) => void) | undefined
  ) => void;
  sectionsVisible: Record<string, boolean>;
  onComplete?: () => void;
  scrollElement: RefObject<any>;
  scrollElementId: string;
  firstRender?: boolean;
  header?: ReactNode;
}

export interface Page {
  name: string;
  component: ReactElement;
  nextHandler?: (context: HandlerContext) => void;
  backHandler?: (context: HandlerContext) => void;
  headerText?: string;
  pageNeedsToBeFilled?: boolean;
}

const logger = new Logger("FlowLayout");

/**
 * We need to memoize these functions so that every page doesn't rerender on any change on any page.
 *
 * NOTE: This makes the assumption that the onChange never changes.
 *       We make this assumption for performance reasons.
 *
 * TODO: This memoization optimization causes onNext to only work properly when pages
 *       are added to the end of the array (what we typically imagine to be the use-case).
 *       If there ends up being pages injected in the middle, then we would have to purge
 *       the memo so that all callbacks have the correct index.
 */
const wrapPage = (
  page: Page,
  context: HandlerContext,
  memoizer: ReportingMemoizer<any>
): [WrappedPage, boolean] => {
  const {
    wrappedPages,
    nakedPages,
    currentIndex,
    setSectionsVisible,
    sectionsVisible,
    onComplete,
    scrollElement,
    scrollElementId,
    firstRender,
    header,
  } = context;
  const [focusRef, focusRefChanged] = memoizer([page.name, "focusRef"], [], createRef());
  const [scrollRef, scrollRefChanged] = memoizer([page.name, "scrollRef"], [], createRef());

  // If we are on the first render and the first page, focus
  if (firstRender && currentIndex === 0 && scrollRef?.curret && focusRef?.current && scrollElement.current) {
    focusWhenPossibleWithParentScroller(
      { focus: focusRef, scroll: scrollRef },
      scrollElement,
      page.component.props.blockFocus
    );
  }

  // If the size of the array has changed, we might need to update onNext
  const currentLength = Object.keys(sectionsVisible).length;
  // With length differences, we only need to change when there have been new pages added, and we are
  // at the last of the previous list
  const expandedPages = currentLength !== nakedPages.length && currentIndex + 1 === currentLength;

  const [value, valueChanged] = memoizer(
    [page.name, "value"],
    [page.component.props.value],
    page.component.props.value
  );

  const [onNext, onNextChanged] = memoizer(
    [page.name, "onNext"],
    [
      page.component.props.onNext,
      page.nextHandler,
      onComplete,
      expandedPages,
      wrappedPages[currentIndex + 1]?.name,
      wrappedPages[currentIndex + 1]?.component.props.blockFocus,
    ],
    (...args: any[]) => {
      page.component.props.onNext?.(...args);
      // We can call onComplete here indiscriminately
      // because only the last page is actually passed onComplete.
      onComplete?.();

      // nextHandler differs from onNext in that it is defined in the data structure
      // rather than in the component. It's existence is really just for ergonomics.
      if (page?.nextHandler) {
        page?.nextHandler?.(context);
      } else {
        const nextPage = wrappedPages[currentIndex + 1];
        const nextPageName = nextPage?.name;
        logger.log(`Setting ${nextPageName} to visible`);

        const timeout = setTimeout(() => {
          logger.log(
            `Using the fallback timeout, running focusWhenPossibleWithParentScroller to ${nextPageName}`
          );
          focusWhenPossibleWithParentScroller(
            {
              focus: nextPage?.focusRef,
              scroll: nextPage?.scrollRef,
            },
            scrollElement,
            nextPage?.component.props.blockFocus
          );
        }, 100);

        setSectionsVisible(
          (prev) => ({
            ...prev,
            ...(nextPageName ? { [nextPageName]: true } : {}),
          }),
          () => {
            logger.log(`Running focusWhenPossibleWithParentScroller to ${nextPageName}`);
            focusWhenPossibleWithParentScroller(
              {
                focus: nextPage?.focusRef,
                scroll: nextPage?.scrollRef,
              },
              scrollElement,
              nextPage?.component.props.blockFocus
            );
            clearTimeout(timeout);
          }
        );
      }
    }
  );

  const [onBack, onBackChanged] = memoizer(
    [page.name, "onBack"],
    [
      page.component.props.onBack,
      page.backHandler,
      wrappedPages[currentIndex - 1]?.name,
      wrappedPages[currentIndex - 1]?.component.props.blockFocus,
    ],
    () => {
      page.component.props.onBack?.();

      if (page?.backHandler) {
        page?.backHandler?.(context);
      } else {
        setSectionsVisible(
          (prev) => ({
            ...prev,
            // we want to set the previous page to visible (in case it was closed) as we will
            // be going to it. If it isn't visible, the scroller won't have the correct node to go to.
            [wrappedPages[currentIndex - 1].name]: true,
            // we also want to set the current page and all pages going forward a
            // not visible, because without doing this, if someone scrolled up and then
            // hit "back", there will be a gap in between the pages
            ...wrappedPages.slice(currentIndex).reduce((names, currentPage) => {
              names[currentPage.name] = false;
              return names;
            }, {} as Record<string, boolean>),
          }),
          () => {
            const wrappedPage = wrappedPages[currentIndex - 1];
            focusWhenPossibleWithParentScroller(
              {
                focus: wrappedPage?.focusRef,
                scroll: wrappedPage?.scrollRef,
              },
              scrollElement,
              wrappedPage?.component.props.blockFocus
            );
          }
        );
      }
    }
  );

  const [onChange, onChangeChanged] = memoizer(
    [page.name, "onChange"],
    [page.component.props.onChange],
    page.component.props.onChange
  );

  const [memoizedScrollElementId, onChangeScrollElement] = memoizer(
    [page.name, "scrollElementId"],
    [scrollElementId],
    scrollElementId
  );

  const [memoizedHeader, headerChanged] = memoizer([page.name, "header"], [header], header);
  const [memoizedChildren, childrenChanged] = memoizer(
    [page.name, "children"],
    [simplifyChildren(page.component.props.children)],
    page.component.props.children
  );

  // omit all the other props that we do special logic to check changes on.
  // without this check we can get stale pages when props other than "value" change
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    value: _1,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    focusRef: _2,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    onChange: _3,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    onNext: _4,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    onBack: _5,
    blockFocus,
    children,
    ...otherProps
  } = page.component.props;
  const [, otherPropsChanged] = memoizer([page.name, "otherProps"], [otherProps], otherProps);

  const changed =
    // We should only set the sections that are visible to have been changed.
    // If we are getting staleness issues, or subpages not behaving properly,
    // this sectionsVisible[page.name] is a likely culprit
    sectionsVisible[page.name] &&
    (onChangeChanged ||
      onBackChanged ||
      onNextChanged ||
      valueChanged ||
      onChangeScrollElement ||
      otherPropsChanged ||
      headerChanged ||
      focusRefChanged ||
      scrollRefChanged ||
      childrenChanged);

  return [
    {
      ...page,
      focusRef,
      scrollRef,
      component: React.cloneElement(page.component, {
        ...page.component.props,
        value,
        children: memoizedChildren,
        key: page.name,
        focusRef,
        scrollRef,
        onNext,
        onBack: currentIndex !== 0 ? onBack : undefined, // don't show the back button for the first item
        onChange,
        // we pass this down so that we can use it for sticky elements
        scrollElementId: memoizedScrollElementId,
        header: memoizedHeader,
      }),
    },
    changed,
  ];
};

/**
 * This component generalizes the long-form scrolling flow.
 * The pages are shown one at a time in order by default, although
 * that can be changed by passing a custom nextHandler with the context of the flow.
 *
 * The pages need the following props:
 * - focusRef, which is some attached as the ref to some element to focus attention to that element
 * - scrollRef, optional, which change the scroll target from the focusRef (used on ApplicantPhone for example)
 * - onNext, to proceed to next stage
 */
const FlowLayout = ({
  pages = [],
  onClose,
  onComplete,
  externalState,
  continueFlow,
  style,
  navSections,
  children,
}: FlowLayoutProps) => {
  const scrollElement = useRef<HTMLDivElement>(null);
  const innerSectionsState = useStateCallback<Record<string, boolean>>({});
  const innerWrappedPagesState = useState<WrappedPage[]>([]);
  const innerReportingMemoizer = useReportingMemoizer();
  const [sectionsVisible, setSectionsVisible] = externalState?.sectionsVisibleState || innerSectionsState;
  const [wrappedPages, setWrappedPages] = externalState?.wrappedPagesState || innerWrappedPagesState;
  const memo = externalState?.wrappedMemo || innerReportingMemoizer;
  const scrollElementId = useRef(uuid()).current;
  const firstRender = useRef(true);

  const navToggle = useToggle();

  useEffect(() => {
    if (hasDuplicates(pages.map(({ name }) => name))) {
      captureMessage("Page names have duplicates! This will cause endless looping.");
      return;
    }

    const newWrappedPages: WrappedPage[] = [...wrappedPages];
    let changed = wrappedPages.length !== pages.length;

    for (let i = pages.length - 1; i >= 0; i--) {
      // go in reverse, because then the .focus for the element after it will already be created
      const page = pages[i];

      const header = page.headerText ? <FlowLayoutHeader text={page.headerText} /> : null;

      const wp = wrapPage(
        page,
        {
          wrappedPages: newWrappedPages,
          nakedPages: pages,
          currentIndex: i,
          setSectionsVisible,
          sectionsVisible,
          // only pass onComplete to the last page
          onComplete: i === pages.length - 1 ? onComplete : undefined,
          scrollElement,
          scrollElementId,
          firstRender: firstRender.current,
          header,
        },
        memo
      );
      newWrappedPages[i] = wp[0];
      changed = changed || wp[1];
    }

    firstRender.current = false;

    if (changed) {
      // if there are any leftovers, clean up
      newWrappedPages.splice(pages.length);
      setWrappedPages(newWrappedPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, wrappedPages, onComplete, navSections]);

  useEffectOnce(
    () => {
      const newSections = { ...sectionsVisible };
      for (let i = 0; i < wrappedPages.length; i++) {
        const page = wrappedPages[i];
        // if a page needs to be filled, that means
        // (other than the first page that needs to be filled) it should be hidden when we are initially
        // setting up the "resumed" pages
        const showPage =
          Boolean(continueFlow) && !(page.pageNeedsToBeFilled ?? page.component.props.value === undefined);

        // this will make it so that we show the page that isn't filled out as the last page
        newSections[page?.name] = true;

        if (!showPage || i === wrappedPages.length - 1) {
          focusWhenPossibleWithParentScroller(
            { focus: page?.focusRef, scroll: page?.scrollRef },
            scrollElement,
            page?.component.props.blockFocus
          );
          break;
        }
      }
      setSectionsVisible(newSections);
    },
    [continueFlow, sectionsVisible, wrappedPages],
    !continueFlow
  );

  useEffect(() => {
    if (
      utilities.array.arraysEqual(
        Object.keys(sectionsVisible),
        pages.map(({ name }) => name)
      )
    ) {
      // only fire this if the pages have changed
      return;
    }
    const newSections: Record<string, boolean> = { ...sectionsVisible };

    // delete any sections that have been removed
    for (const key of Object.keys(sectionsVisible)) {
      if (pages.findIndex(({ name }) => name === key) === -1) {
        delete newSections[key];
      }
    }

    let changesMade = false;
    let allPreviousTrue = true;
    for (let i = 0; i < pages.length; i++) {
      // add any sections that have been added
      // if it already exists, dont modify.
      if (newSections[pages[i]?.name] === undefined) {
        changesMade = true;
        // If its the first element, then set to true. Otherwise, set to true if this value and ALL PREVIOUS VALUES
        // have been previously set (we are going through existing flow)
        // TODO - add in a prop so that a page can pass it's default "starter" value if it isn't undefined
        // and we check against that as well (because not all pages start undefined)
        newSections[pages[i]?.name] =
          i === 0 ? true : pages[i].component.props.value !== undefined && allPreviousTrue;
      }
      allPreviousTrue = Boolean(newSections[pages[i]?.name]);
    }

    if (changesMade) {
      setSectionsVisible(newSections);
    }
  }, [setSectionsVisible, sectionsVisible, pages]);

  const visiblePages = wrappedPages.filter((page) => sectionsVisible[page.name]);
  const lastPage = visiblePages[visiblePages.length - 1];

  useCaptureBrowserButtons({
    goBack: lastPage?.component?.props?.onBack,
    goNext: lastPage?.component?.props?.onNext,
    pages: wrappedPages.map(({ name }) => name),
    currentPage: lastPage?.name,
  });

  const height = useMobileHeightAdjustment();
  return (
    <div id={scrollElementId} ref={scrollElement} css={[styles.scroller, { height }, style]}>
      {scrollElement?.current && (
        <FlowLayoutNav
          scrollElement={scrollElement}
          wrappedPages={wrappedPages}
          navSections={navSections}
          navToggle={navToggle}
          onClose={onClose}
        />
      )}
      {visiblePages.map((page) => page.component)}
      {children}
    </div>
  );
};

export default React.memo(
  FlowLayout,
  (prevProps, nextProps) =>
    prevProps.style === nextProps.style &&
    prevProps.onClose === nextProps.onClose &&
    prevProps.continueFlow === nextProps.continueFlow &&
    prevProps.onComplete === nextProps.onComplete &&
    prevProps.pages === nextProps.pages &&
    prevProps.externalState?.sectionsVisibleState === nextProps.externalState?.sectionsVisibleState &&
    prevProps.externalState?.wrappedMemo === nextProps.externalState?.wrappedMemo &&
    prevProps.externalState?.wrappedPagesState === nextProps.externalState?.wrappedPagesState &&
    prevProps.children === nextProps.children
);
