import { zodResolver } from "@hookform/resolvers/zod";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { InfoAlert, ModalBox, TextInput } from "src/components";
import { InputModalContent } from "src/components/InnerModals";
import { PopupProps } from "src/components/Popup";
import { TextInputEvent } from "src/components/TextInput";
import { useFormReducer, useMediaFallback, useStepper } from "src/hooks";
import { createStyles } from "src/styles";
import * as z from "zod";

import { formatters, parsers } from "@fraction/shared";

import ConfirmationModalScreen from "../ConfirmationModalScreen";
import ReviewAndEdit from "../ReviewAndEdit";

export interface LoanDrawProps extends Omit<PopupProps, "children"> {
  onSubmit?: (submission: parsers.loanDraw.LoanDraw, onSuccess: () => void) => void;
  initialValues: Record<string, string>;
  onClose: () => void;
  isLoading?: boolean;
}

export interface InputScreenProps extends Omit<PopupProps, "children"> {
  onContinue: () => void;
  onBack?: () => void;
  formValues: Record<string, string>;
  handleTextChange?: (value: string, e: TextInputEvent) => void;
  handleGoToScreen?: (goTo: Screen) => void;
  isLoading?: boolean;
}

export enum Field {
  ABA_ROUTING = "abaRouting",
  ACCOUNT_NUMBER = "accountNumber",
  DRAW_AMOUNT = "drawAmount",
}

enum Screen {
  ACCOUNT_INFO = "accountInfo",
  DRAW_AMOUNT = "drawAmount",
  REVIEW = "review",
  CONFIRMATION = "confirmation",
}

const SCREENS = [Screen.ACCOUNT_INFO, Screen.DRAW_AMOUNT, Screen.REVIEW, Screen.CONFIRMATION];

const getModalWidth = (screen: string) => {
  switch (screen) {
    case Screen.REVIEW:
      return 540;
    case Screen.CONFIRMATION:
      return 496;
    default:
      return 448;
  }
};

const STYLES = createStyles({
  input: {
    width: "100%",
    "&:not(:last-child)": {
      marginBottom: 8,
    },
  },
  inputModalFooter: {
    marginTop: 16,
  },
  reviewModalFooter: {
    marginTop: 32,
  },
  confirmationModal: {
    padding: "56px 48px",
  },
  disclaimer: {
    marginTop: 16,
  },
});

const ACCOUNT_INFORMATION = z.object({
  abaRouting: z.string().length(9),
  accountNumber: z.string().min(1).max(30),
});

const AccountInformationScreen = ({ handleTextChange, onContinue, formValues }: InputScreenProps) => {
  const { register, errors, handleSubmit } = useForm({
    resolver: zodResolver(ACCOUNT_INFORMATION),
  });

  const getInputProps = (fieldName: Field) => ({
    ref: register,
    style: STYLES.input,
    name: fieldName,
    defaultValue: formValues[fieldName],
    onChange: handleTextChange,
  });

  return (
    <InputModalContent
      header="YOUR ACCOUNT INFORMATION"
      paragraph="Enter your account information below to receive your loan draw."
      footerStyle={STYLES.inputModalFooter}
      onSubmit={handleSubmit(onContinue)}
      nextDisabled={!formValues[Field.ABA_ROUTING] || !formValues[Field.ACCOUNT_NUMBER]}
      actions={[
        {
          text: "Next",
          submit: true,
        },
      ]}
    >
      <TextInput
        {...getInputProps(Field.ABA_ROUTING)}
        label="ABA Routing Number"
        error={errors?.[Field.ABA_ROUTING]?.message}
      />
      <TextInput
        {...getInputProps(Field.ACCOUNT_NUMBER)}
        label="Account Number"
        error={errors?.[Field.ACCOUNT_NUMBER]?.message}
      />
    </InputModalContent>
  );
};

const DRAW_AMOUNT = z.object({
  drawAmount: z.string(),
});

const DrawAmountScreen = ({ handleTextChange, formValues, onBack, onContinue }: InputScreenProps) => {
  const { register, errors, handleSubmit } = useForm({
    resolver: zodResolver(DRAW_AMOUNT),
  });

  return (
    <InputModalContent
      header="LOAN DRAW AMOUNT"
      paragraph="Enter the amount you'd like to request to draw."
      footerStyle={STYLES.inputModalFooter}
      nextDisabled={!formValues[Field.DRAW_AMOUNT]}
      onSubmit={handleSubmit(onContinue)}
      actions={[
        {
          text: "Back",
          action: onBack,
        },
        {
          text: "Next",
          submit: true,
        },
      ]}
    >
      <TextInput
        ref={register}
        style={STYLES.input}
        name={Field.DRAW_AMOUNT}
        label="Draw Amount"
        defaultValue={formValues[Field.DRAW_AMOUNT]}
        onChange={handleTextChange}
        error={errors?.[Field.DRAW_AMOUNT]?.message}
        maskOptions={{
          rightAlign: false,
          alias: "currency",
          prefix: "$ ",
          jitMasking: false,
          digits: 0,
        }}
      />
    </InputModalContent>
  );
};

const LoanDrawReviewScreen = ({
  formValues,
  onBack,
  handleGoToScreen,
  onContinue,
  isLoading,
}: InputScreenProps) => {
  const text = useMediaFallback(800, "Confirm loan draw", "Confirm") as string;

  const goToAccountInfo = useCallback(() => {
    handleGoToScreen?.(Screen.ACCOUNT_INFO);
  }, [handleGoToScreen]);

  const goToDrawAmount = useCallback(() => {
    handleGoToScreen?.(Screen.DRAW_AMOUNT);
  }, [handleGoToScreen]);

  return (
    <InputModalContent
      header="LOAN DRAW REVIEW"
      paragraph="Below is a review of your account information, and the amount you want to draw."
      footerStyle={STYLES.reviewModalFooter}
      isLoading={isLoading}
      actions={[
        {
          text: "Back",
          action: onBack,
        },
        {
          text,
          action: onContinue,
        },
      ]}
    >
      <ReviewAndEdit
        label="ABA Routing Number"
        value={formValues[Field.ABA_ROUTING]}
        onClickEdit={goToAccountInfo}
      />
      <ReviewAndEdit
        label="Account Number"
        value={formValues[Field.ACCOUNT_NUMBER]}
        onClickEdit={goToAccountInfo}
      />
      <ReviewAndEdit
        label="Draw Amount"
        value={formatters.number.getCurrencyFromNumber(Number(formValues[Field.DRAW_AMOUNT]) * 100)}
        onClickEdit={goToDrawAmount}
      />
    </InputModalContent>
  );
};

/**
 * @deprecated To be replaced with LoanDrawModal
 */
const OldLoanDrawModal = ({ initialValues, isLoading, ...props }: LoanDrawProps) => {
  const { step, ...stepper } = useStepper(SCREENS);

  const { formValues, handleTextChange, reset: resetForm } = useFormReducer({ initialValues });

  const onSubmit = useCallback(() => {
    props.onSubmit?.(formValues as parsers.loanDraw.LoanDraw, stepper.goForward);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.goForward, props.onSubmit, formValues]);

  const onClose = useCallback(() => {
    stepper.reset();
    resetForm();
    props.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.reset, resetForm, props.onClose]);

  return (
    <ModalBox width={getModalWidth(step)} {...props} onClose={onClose}>
      {step === Screen.ACCOUNT_INFO && (
        <AccountInformationScreen
          handleTextChange={handleTextChange}
          formValues={formValues}
          onContinue={stepper.goForward}
        />
      )}
      {step === Screen.DRAW_AMOUNT && (
        <DrawAmountScreen
          handleTextChange={handleTextChange}
          onBack={stepper.goBack}
          formValues={formValues}
          onContinue={stepper.goForward}
        />
      )}
      {step === Screen.REVIEW && (
        <LoanDrawReviewScreen
          onBack={stepper.goBack}
          handleGoToScreen={stepper.go}
          formValues={formValues}
          onContinue={onSubmit}
          isLoading={isLoading}
        />
      )}
      {step === Screen.CONFIRMATION && (
        <ConfirmationModalScreen
          style={STYLES.confirmationModal}
          text="An email confirming the loan draw is on its way."
          header="PENDING CONFIRMATION"
        >
          <InfoAlert style={STYLES.disclaimer}>
            It may take up to 3 business days for this to reflect in your account.
          </InfoAlert>
        </ConfirmationModalScreen>
      )}
    </ModalBox>
  );
};

export default memo(OldLoanDrawModal);
