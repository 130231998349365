import { TextInput } from "src/components";
import { InputModalForm, StackedInputModalFooter } from "src/components/InnerModals";
import { useZodForm } from "src/hooks";

import { entities, parsers } from "@fraction/shared";

const EditBankAccountScreen = ({
  onSubmit,
  onDelete,
  loading,
  bankAccount,
}: {
  onSubmit: (data: parsers.bankAccount.PatchBankAccountSubmission) => void;
  onDelete: () => void;
  loading: boolean;
  bankAccount?: entities.BankAccount;
}) => {
  const { register, handleSubmit } = useZodForm<parsers.bankAccount.PatchBankAccountSubmission>({
    schema: parsers.bankAccount.PATCH_BANK_ACCOUNT_SUBMISSION,
  });

  return (
    <InputModalForm
      header="EDIT BANK ACCOUNT"
      paragraph="Update or delete the bank account below."
      footer={
        <StackedInputModalFooter
          isLoading={loading}
          actions={[
            {
              text: "Save",
              // @ts-ignore
              action: handleSubmit(onSubmit),
            },
            {
              text: "Delete Account",
              action: onDelete,
              type: "ghostUrgent",
            },
          ]}
        />
      }
    >
      <TextInput label="Account Nickname" {...register("name")} defaultValue={bankAccount?.name} />
    </InputModalForm>
  );
};

export default EditBankAccountScreen;
