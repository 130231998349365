import { memo } from "react";
import { InputModalContent } from "src/components/InnerModals";

const DeleteRecurringPaymentScreen = ({
  cancelDeleteAttempt,
  confirmDelete,
  bankName,
  amount,
  isLoading,
}: {
  cancelDeleteAttempt: () => void;
  confirmDelete: () => void;
  bankName: string;
  amount: string;
  isLoading: boolean;
}) => (
  <InputModalContent
    header="DELETE RECURRING PAYMENT?"
    isLoading={isLoading}
    actions={[
      { text: "Cancel", action: cancelDeleteAttempt },
      { text: "Delete", action: confirmDelete, type: "urgent" },
    ]}
  >
    <p className="text-gray">
      Are you sure you want to delete your monthly recurring payment of <b>{amount}</b> from account{" "}
      <b>{bankName}</b>?
    </p>
  </InputModalContent>
);

export default memo(DeleteRecurringPaymentScreen);
