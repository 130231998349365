import { ReactNode } from "react";
import { InputModalForm, StackedInputModalFooter } from "src/components/InnerModals";
import { SemiBoldText } from "src/components/v1";
import { createStyles } from "src/styles";

const styles = createStyles(({ colors, spacing }) => ({
  labelContainer: {
    display: "flex",
    flexDirection: "column",
    "&:not(:last-child)": {
      marginBottom: spacing[2],
    },
  },
  label: {
    fontSize: 14,
    lineHeight: "160%",
    color: colors.grey500,
  },
  text: {
    fontSize: 14,
    color: colors.darkgrey800,
    lineHeight: "160%",
  },
  bodyContainer: {
    marginBottom: spacing[2],
  },
}));

const TextWithLabel = ({ label, text }: { label: string; text: ReactNode }) => (
  <div css={styles.labelContainer}>
    <SemiBoldText style={styles.label}>{label}</SemiBoldText>
    <SemiBoldText style={styles.text}>{text}</SemiBoldText>
  </div>
);

const ViewRecurringPaymentScreen = ({
  onDelete,
  bankName,
  amount,
}: {
  onDelete: () => void;
  bankName: string;
  amount: string;
}) => (
  <InputModalForm
    header="RECURRING PAYMENT"
    paragraph="View or delete a recurring payment plan for your Fraction loan."
    footer={
      <StackedInputModalFooter
        actions={[
          {
            text: "Delete",
            action: onDelete,
            type: "urgent",
          },
        ]}
      />
    }
  >
    <div css={styles.bodyContainer}>
      <TextWithLabel label="Bank account" text={bankName} />
      <TextWithLabel label="Monthly amount" text={amount} />
    </div>
  </InputModalForm>
);

export default ViewRecurringPaymentScreen;
