import { ReactNode } from "react";
import { createStyles } from "src/styles";

const styles = createStyles({
  cardContent: {
    padding: "0px 32px 40px 32px",
  },
});

const CardContent = ({ children }: { children: ReactNode }) => <div css={styles.cardContent}>{children}</div>;

export default CardContent;
