import { Breakpoint } from "src/styles/utilities/breakpoints";

const getDeviceType = (windowWidth: number): { phone: boolean; tablet: boolean; computer: boolean } => {
  if (windowWidth < Breakpoint.phone) {
    return {
      phone: true,
      tablet: false,
      computer: false,
    };
  }

  if (windowWidth < Breakpoint.tablet) {
    return {
      phone: false,
      tablet: true,
      computer: false,
    };
  }

  return {
    phone: false,
    tablet: false,
    computer: true,
  };
};
export default getDeviceType;
