import retry from "@fullstax/p-retry";
import { lazy } from "react";
import { Outlet, Route } from "react-router-dom";
import { PropertyReport } from "src/apps/BrokerDashboard/pages/Marketing/PropertyReport";
import BdmBrokerageSearch from "src/embeds/BdmBrokerageSearch";
import BdmCalendar from "src/embeds/BdmCalendar";
import BdmFinder from "src/embeds/BdmFinder";
import { IframeIOProvider } from "src/iframe";

const CostEstimateEmbed = lazy(() => retry(() => import("src/embeds/CostEstimate")));
const CookieTrackerEmbed = lazy(() => retry(() => import("src/embeds/CookieTracker")));
const ZHVIChartEmbed = lazy(() => retry(() => import("src/embeds/ZHVIChart")));
const HPIChartEmbed = lazy(() => retry(() => import("src/embeds/HPIChart")));
const InquiryFormEmbed = lazy(() => retry(() => import("src/embeds/InquiryForm")));
const ClassifierEmbed = lazy(() => retry(() => import("src/embeds/Classifier")));
const DocumentApprovalToolEmbed = lazy(() => retry(() => import("src/embeds/DocumentApprovalTool")));
const ChecklistEmbed = lazy(() => retry(() => import("src/embeds/Checklist")));
const DealDashboardEmbed = lazy(() => retry(() => import("src/embeds/DealDashboard")));
const PostalCodeCheckerEmbed = lazy(() => retry(() => import("src/embeds/PostalCodeChecker")));
const PropertyCheckerEmbed = lazy(() => retry(() => import("src/embeds/PropertyChecker")));
const CostCalculator = lazy(() => retry(() => import("src/embeds/CostCalculator")));
const RateCalculator = lazy(() => retry(() => import("src/embeds/RateCalculator")));

const IframeIOLayout = () => (
  <IframeIOProvider>
    <Outlet />
  </IframeIOProvider>
);

export function EmbedRoutes() {
  return [
    <Route key="iframelayout" element={<IframeIOLayout />}>
      <Route path="/embed/checklist" element={<ChecklistEmbed />} />
      <Route path="/embed/deal-dashboard/*" element={<DealDashboardEmbed />} />
      <Route path="/embed/classifier" element={<ClassifierEmbed />} />
      <Route path="/embed/document-approval" element={<DocumentApprovalToolEmbed />} />
      <Route path="/embed/postal-checker" element={<PostalCodeCheckerEmbed />} />
      <Route path="/embed/property-checker" element={<PropertyCheckerEmbed />} />
      <Route path="/embed/bdm-finder" element={<BdmFinder />} />
      <Route path="/embed/rate-calculator" element={<RateCalculator />} />
      <Route path="/bdm-calendar" element={<BdmCalendar />} />
      <Route path="embed/bdm-brokerage-search" element={<BdmBrokerageSearch />} />
    </Route>,
    <Route key="cost" path="/embed/cost-estimate" element={<CostEstimateEmbed />} />,
    <Route key="analytics" path="/embed/analytics" element={<CookieTrackerEmbed />} />,
    <Route key="zhvi" path="/embed/zhvi" element={<ZHVIChartEmbed />} />,
    <Route key="hpi" path="/embed/hpi" element={<HPIChartEmbed />} />,
    <Route key="inquiry" path="/embed/inquiry" element={<InquiryFormEmbed />} />,
    <Route key="calculator" path="/embed/calculator" element={<CostCalculator />} />,
    <Route key="scenario" path="/embed/scenario-planner" element={<PropertyReport backButton={false} />} />,
  ];
}
