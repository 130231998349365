import React, { ReactNode, createContext, useCallback, useMemo, useState } from "react";
import { IllustratedModalContent, IllustratedModalContentProps } from "src/components/InnerModals";
import Modal, { ModalProps as ModalPropsOriginal } from "src/components/Modal";
import ModalBox, { ModalBoxProps as ModalBoxPropsOriginal } from "src/components/ModalBox";
import { PopupProps } from "src/components/Popup";

import useContextSafe from "./useContextSafe";

type ModalProps = Omit<ModalPropsOriginal, "open" | "onClose"> & { children?: React.ReactNode };
type ModalBoxProps = Omit<ModalBoxPropsOriginal, "open" | "onClose"> & { children?: React.ReactNode };
type IllustratedModalProps = Pick<IllustratedModalContentProps, "header" | "text" | "children" | "image"> & {
  closeButton?: boolean;
  position?: PopupProps["position"];
};
export type ModalContent = ModalProps | ModalBoxProps | IllustratedModalProps;

interface ModalContextValue {
  /**
   * Set the content of the modal & show it
   *
   * Supports various types of modals (see the `ModalContent` type).
   */
  showModal: (modal: ModalContent | null) => void;

  /**
   * Dismiss the modal. Equivalent to calling `showModal(null)`
   */
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);
ModalContext.displayName = "ModalContext";

const ModalComponent = ({
  content,
  closeModal,
}: { content?: ModalContent | null; closeModal: () => void }) => {
  if (!content) {
    return null;
  } else if (isModalProps(content)) {
    return <Modal {...content} open onClose={closeModal} />;
  } else if (isIllustratedModalProps(content)) {
    const { closeButton = true, position, ...props } = content;
    return (
      <ModalBox position={position} open onClose={closeModal} closeButton={closeButton}>
        <IllustratedModalContent actions={[]} {...props} />
      </ModalBox>
    );
  } else {
    return <ModalBox {...content} open onClose={closeModal} />;
  }
};

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  // If we end up adding a dismiss animation, we'll need to track the open/closed and content states
  // separately, so we can animate the dismissal without removing the content
  const [content, setContent] = useState<ModalContent | null>(null);

  const closeModal = useCallback(() => setContent(null), []);
  const modalContextValue = useMemo(() => ({ showModal: setContent, closeModal }), [closeModal]);

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
      <ModalComponent closeModal={closeModal} content={content} />
    </ModalContext.Provider>
  );
};

const isModalProps = (content: ModalContent): content is ModalProps => "message" in content;
const isIllustratedModalProps = (content: ModalContent): content is IllustratedModalProps =>
  "image" in content;

/**
 * Allows you to show/dismiss modals.
 *
 * To use this hook, make sure `<ModalProvider />` is a parent/ancestor of your component.
 */
const useModal = () => useContextSafe(ModalContext);
export default useModal;
