import { enums } from "@fraction/shared";
import { MouseEvent, useCallback, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";

export function ConveyancerSelect({
  onValueChange,
  className,
  value,
}: {
  value?: enums.ConveyancerGroup;
  onValueChange?: (value: enums.ConveyancerGroup | null) => void;
  className?: string;
}) {
  const [key, setKey] = useState(+new Date());
  const handleClear = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onValueChange?.(null);
      setKey(+new Date());
    },
    [onValueChange]
  );

  return (
    // @ts-ignore
    <Select key={key} value={value} onValueChange={onValueChange}>
      <SelectTrigger className={className}>
        <SelectValue placeholder="Select conveyancer" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Conveyancer</SelectLabel>
          <SelectItem value={enums.ConveyancerGroup.DEEDED}>Deeded</SelectItem>
          <SelectItem value={enums.ConveyancerGroup.AXESS_LAW}>Axess</SelectItem>
          <SelectItem value={enums.ConveyancerGroup.SLG}>SLG</SelectItem>
          <SelectItem value={enums.ConveyancerGroup.DIAMOND_AND_DIAMOND}>Diamond & Diamond</SelectItem>
        </SelectGroup>
        <SelectSeparator />
        <button className="w-full px-2 text-sm font-semibold" onClick={handleClear}>
          Clear
        </button>
      </SelectContent>
    </Select>
  );
}
