import { SerializedStyles } from "@emotion/react";
import { motion } from "framer-motion";
import { KeyboardEvent, forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { styles } from "src/apps/AppFlow/pages/styles";
import Skeleton from "src/components/Skeleton";
import TextInput from "src/components/TextInput";
import { TextInputProps } from "src/components/TextInput";
import Touchable from "src/components/Touchable";
import { RegularText } from "src/components/v1";
import { useDimensions, useGeolocation, useGoogle, useMergedRef, usePrevious } from "src/hooks";
import { LocationPin } from "src/icons";
import { createStyles } from "src/styles";

import { colors, formatters, selectors, types } from "@fraction/shared";

const PAGE_STYLES = createStyles({
  container: {
    minWidth: 340,
    "@media(max-width: 400px)": {
      width: "100%",
      minWidth: "unset",
    },
    width: "100%",
    maxWidth: "100%",
    position: "relative",
  },
  skeleton: {
    height: 45.5,
    width: 340,
  },
  dropdown: {
    boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.15)",
    width: "100%",
    overflowY: "scroll",
    backgroundColor: "white",
    position: "absolute",
    zIndex: 5,
    // to counteract the spacer in the textinput
    marginTop: -18,
    "::-webkit-scrollbar": {
      display: "none",
      WebkitAppearance: "none",
      width: "0",
      height: "0",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  suggestionContainer: {
    width: "100%",
    minHeight: 50,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 36,
    paddingRight: 50,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: colors.DROPDOWN_HIGHLIGHTED,
    },
    justifyContent: "flex-start",
    borderBottomWidth: 0.5,
    borderBottomColor: colors.LINES,
    borderBottomStyle: "solid",
  },
  keyboardSelected: {
    backgroundColor: colors.DROPDOWN_KEYBOARD_HIGHLIGHTED,
  },
  suggestionText: {
    fontSize: 14,
    textAlign: "left",
  },
});

const SUGGESTION_HEIGHT = 58.85;

export type GoogleLocationTypes = "geocode" | "address" | "(regions)" | "(cities)";

export interface LocationInputProps extends Omit<TextInputProps, "value" | "onChange" | "onSelect"> {
  locationTypes?: GoogleLocationTypes[];
  onChange?: (location?: string) => void;
  onSelect?: (location?: types.ExtractedPropertyAddress) => void;
  value?: string | types.ExtractedPropertyAddress;
  style?: SerializedStyles;
  placeholder?: string;
  name?: string;
  loading?: boolean;
}

interface Suggestion {
  label: string;
  value: google.maps.places.AutocompletePrediction;
}

const LocationInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, LocationInputProps>(
  (
    {
      locationTypes,
      onSelect,
      onChange,
      value,
      style,
      placeholder,
      label,
      name,
      disabled,
      loading,
    }: LocationInputProps,
    forwardedRef
  ) => {
    const stringValue =
      typeof value === "object" ? formatters.property.formattedAddress(value, { premise: true }) : value;
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [inputValue, setInputValue] = useState(stringValue || "");
    const [keyboardIndex, setKeyboardIndex] = useState<number>(-1);
    const [getGeoLocation, setGetGeoLocation] = useState(false);
    const prevStringValue = usePrevious(stringValue);

    const { height, observe } = useDimensions();

    const mergedRef = useMergedRef(inputRef, forwardedRef);

    const geolocation = useGeolocation({ track: getGeoLocation });
    const { google, loaded } = useGoogle();

    const handleClickLocationPin = useCallback(() => {
      setGetGeoLocation(true);
    }, []);

    useEffect(() => {
      if ((stringValue || "") !== (prevStringValue || "")) {
        setInputValue(stringValue || "");
      }
    }, [setInputValue, prevStringValue, stringValue]);

    const handleChooseSuggestion = useCallback(
      async (suggestion: Suggestion) => {
        setSuggestions([]);
        setInputValue(suggestion.label);
        onChange?.(suggestion.label);

        const result = await google?.getPlaceDetails(suggestion?.value?.place_id);
        if (result) {
          onSelect?.(selectors.property.extractGoogleAddress(result));
        }
        // focus to reset the view so that we see the front of the text
        inputRef?.current?.focus();
      },
      [onSelect, setSuggestions, setInputValue, onChange, google]
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (!suggestions.length) {
          return;
        }

        switch (event.key) {
          case "ArrowUp":
            setKeyboardIndex((prev) => Math.min(Math.max(prev - 1, 0), suggestions.length));
            break;
          case "ArrowDown":
            setKeyboardIndex((prev) => Math.min(Math.max(prev + 1, 0), suggestions.length));
            break;
          case "Enter":
            if (keyboardIndex !== undefined && suggestions[keyboardIndex]) {
              handleChooseSuggestion(suggestions[keyboardIndex]);
            }
            break;
          default:
            break;
        }
      },
      [keyboardIndex, setKeyboardIndex, suggestions, handleChooseSuggestion]
    );

    const handleChangeInput = useCallback(
      async (text: string) => {
        setKeyboardIndex(-1);
        setInputValue(text);
        onChange?.(text);
        onSelect?.(undefined);

        if (!text.length) {
          setSuggestions([]);
          return;
        }

        const options: any = {
          input: text,
          location: { lat: geolocation?.latitude || 49.28273, lng: geolocation?.longitude || -123.120735 },
          radius: 500,
          types: locationTypes,
        };

        const predictions = await google?.getPlacePredictions(options);

        if (predictions) {
          setSuggestions(
            // The places autocomplete returns routes in addition to street addresses when we specify
            // the 'address' type.
            // This is counterintutitve, so we filter out ones that aren't actual addresses.
            (locationTypes?.[0] === "address"
              ? predictions.filter((prediction) =>
                  prediction.types.some((t) =>
                    ["street_address", "premise", "subpremise", "street_number"].includes(t)
                  )
                )
              : predictions
            ).map((prediction) => ({
              label: prediction.description,
              value: prediction,
            }))
          );
        }
      },
      [geolocation, locationTypes, onSelect, onChange, google]
    );

    if (!loaded || loading) {
      return <Skeleton style={PAGE_STYLES.skeleton} />;
    }

    return (
      <div css={[PAGE_STYLES.container, style]}>
        <TextInput
          onKeyDown={handleKeyDown}
          autoComplete="off"
          disabled={disabled}
          type="text"
          name={name}
          ref={mergedRef}
          label={label}
          placeholder={placeholder}
          borderRadius={suggestions.length ? 0 : 10}
          style={PAGE_STYLES.container}
          value={inputValue}
          onChange={handleChangeInput}
          adornment={
            <Touchable onClick={handleClickLocationPin}>
              <LocationPin height={20} width={20} color={colors.ICON} css={styles.icon} />
            </Touchable>
          }
        />
        <motion.div
          transition={{ duration: 0.25 }}
          css={PAGE_STYLES.dropdown}
          // do the suggestions.length calculation as a fallback in case the dimension tracking doesn't work
          animate={{ height: Math.max(height, suggestions.length * SUGGESTION_HEIGHT) }}
        >
          <div ref={observe}>
            {!disabled &&
              suggestions.map((suggestion: Suggestion, index) => (
                <Touchable
                  key={suggestion.value?.place_id}
                  css={[
                    PAGE_STYLES.suggestionContainer,
                    index === keyboardIndex && PAGE_STYLES.keyboardSelected,
                  ]}
                  onClick={() => handleChooseSuggestion(suggestion)}
                >
                  <RegularText style={PAGE_STYLES.suggestionText}>{suggestion.label}</RegularText>
                </Touchable>
              ))}
          </div>
        </motion.div>
      </div>
    );
  }
);

export default LocationInput;
