import { formatters, notUndefinedOrNull, types } from "@fraction/shared";
import _ from "lodash";
import { ChecklistApp } from "src/api/fraction";

export const getCheckName = (check: types.ChecklistResult) => {
  if (types.isDocumentChecklistItem(check)) {
    return check.documentName || check.document;
  }
  if (types.isTaskChecklistItem(check)) {
    return check.taskName || check.task;
  }
  if (types.isFieldChecklistItem(check)) {
    return check.field;
  }
  return "";
};

export const getCheckValue = (check: types.ChecklistResult, app?: ChecklistApp): string | null => {
  if (!app) {
    return null;
  }
  if (types.isSimpleFieldChecklistItem(check)) {
    const value = check.field ? _.get(app, check.field) : null;
    if (types.isDecimal(value)) {
      return formatters.number.getPercentageFromDecimal(value, { digits: 0 });
    }
    if (types.isDate(value)) {
      return formatters.date.getFormattedDate(value) as string;
    }
    if (notUndefinedOrNull(value) && check.field && currencyFields.includes(check.field)) {
      return formatters.number.getCurrencyFromNumber(value as string);
    }
    return value?.toString() || null;
  }
  return null;
};

const currencyFields = [
  "approvedAmount",
  "requestedAmount",
  "downpaymentAmount",
  "confirmedLoanAmount",
  "totalConfirmedIncome",
  "originationFeeInCents",
  "brokerOriginationFeeInCents",
  "estimated_home_value",
  "underwriter_estimated_home_value",
  "appraisal_value",
];
