import { enums } from "@fraction/shared";
import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { useCachedQuery } from "src/hooks/useCache";

export function useFractionContacts() {
  const { isAuthenticated } = useAuth();

  return useCachedQuery({
    enabled: isAuthenticated,
    queryKey: ["fractionContacts"],
    queryFn: async () => {
      try {
        const contacts = (await fraction.getFractionContacts()) || [];
        const bdm = contacts?.find((contact) => contact.type === enums.InternalEmployeeType.BDM);
        const mortgageSpecialist = contacts?.find(
          (contact) => contact.type === enums.InternalEmployeeType.MORTGAGE_SPECIALIST
        );

        return {
          bdm,
          mortgageSpecialist,
          contacts,
        };
      } catch (err) {
        return {
          bdm: undefined,
          mortgageSpecialist: undefined,
          contacts: [],
        };
      }
    },
  });
}
