import { AnimationProps, motion } from "framer-motion";
import { ReactElement, ReactNode, memo } from "react";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface TextProps extends Omit<AnimationProps, "children" | "style" | "paragraph"> {
  children: string | ReactElement | Array<string | ReactElement> | ReactNode;
  style?: any;
  paragraph?: boolean;
  value?: string;
  inline?: boolean;
  className?: string;
}

const styles = createStyles({
  base: {
    fontFamily: ["Hanken Grotesk", "sans-serif"].join(","),
    letterSpacing: "0.02em",
    color: colors.BLACK_TEXT,
    margin: 0,
  },
  paragraph: {
    lineHeight: "160%",
    display: "inline",
  },
  inline: {
    display: "inline-block",
  },
  header: {
    fontFamily: ["Canela", "serif"].join(","),
    letterSpacing: 0,
    color: colors.BLACK_TEXT,
    margin: 0,
    fontSize: 36,
  },
  thin: {
    fontWeight: 100,
  },
  extraLight: {
    fontWeight: 200,
  },
  light: {
    fontWeight: 300,
  },
  regular: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
  semiBold: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },
  extraBold: {
    fontWeight: 800,
  },
  black: {
    fontWeight: 900,
  },
});

export const Text = memo(({ children, style, paragraph, animate, inline, ...props }: TextProps) =>
  animate ? (
    <motion.p
      animate={animate}
      {...props}
      css={[styles.base, paragraph && styles.paragraph, inline && styles.inline, style]}
    >
      {children}
    </motion.p>
  ) : typeof children === "string" ? (
    <p
      className={props.className}
      css={[styles.base, paragraph && styles.paragraph, inline && styles.inline, style]}
    >
      {children}
    </p>
  ) : (
    <div css={[styles.base, paragraph && styles.paragraph, inline && styles.inline, style]}>{children}</div>
  )
);

export const BoldBase = memo(({ children, style, paragraph, animate, value, inline, ...props }: TextProps) =>
  animate ? (
    <motion.b
      // @ts-ignore
      value={value}
      animate={animate}
      {...props}
      css={[styles.base, paragraph && styles.paragraph, inline && styles.inline, style]}
    >
      {children}
    </motion.b>
  ) : (
    <b
      className={props.className}
      // @ts-ignore
      value={value}
      css={[styles.base, paragraph && styles.paragraph, inline && styles.inline, style]}
    >
      {children}
    </b>
  )
);

export const LightText = memo(({ children, style, ...props }: TextProps) => (
  <Text {...props} style={[styles.light, style]}>
    {children}
  </Text>
));
export const RegularText = memo(({ children, style, ...props }: TextProps) => (
  <Text {...props} style={[styles.regular, style]}>
    {children}
  </Text>
));
export const MediumText = memo(({ children, style, ...props }: TextProps) => (
  <Text {...props} style={[styles.medium, style]}>
    {children}
  </Text>
));
export const SemiBoldText = memo(({ children, style, ...props }: TextProps) => (
  <BoldBase {...props} style={[styles.semiBold, style]}>
    {children}
  </BoldBase>
));
export const BoldText = memo(({ children, style, ...props }: TextProps) => (
  <BoldBase {...props} style={[styles.bold, style]}>
    {children}
  </BoldBase>
));

export const HeaderText = memo(({ children, style }: TextProps) => (
  <p css={[styles.header, style]}>{children}</p>
));
