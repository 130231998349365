import { enums } from "@fraction/shared";
import { MouseEvent, useCallback, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { cn } from "src/utilities/shadcnUtils";

export function LoanStatusSelect({
  onValueChange,
  className,
  value,
}: {
  value?: enums.LoanStatus;
  onValueChange?: (value: enums.LoanStatus | undefined) => void;
  className?: string;
}) {
  const [key, setKey] = useState(+new Date());
  const handleClear = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onValueChange?.(undefined);
      setKey(+new Date());
    },
    [onValueChange]
  );

  return (
    // @ts-ignore
    <Select key={key} value={value} onValueChange={onValueChange}>
      <SelectTrigger className={cn("col-span-2", className)}>
        <SelectValue placeholder="Select a loan status" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Loan status</SelectLabel>
          <SelectItem value={enums.LoanStatus.ACTIVE}>Active</SelectItem>
          <SelectItem value={enums.LoanStatus.PAID_OUT}>Paid out</SelectItem>
        </SelectGroup>
        <SelectSeparator />
        <button className="w-full px-2 text-sm font-semibold" onClick={handleClear}>
          Clear
        </button>
      </SelectContent>
    </Select>
  );
}
