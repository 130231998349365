import { enums, formatters, notUndefinedOrNull } from "@fraction/shared";
import _ from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { ChecklistApp } from "src/api/fraction";
import { Checklist } from "src/apps/LOS/components/Checklist";
import { ModalInnards } from "src/components/Modal";
import ModalBox from "src/components/ModalBox";
import { useApplication } from "src/hooks/useApplication";

export const useChecklistModal = () => {
  const [showModal, setShowModal] = useState<{ id: string; status: enums.ApplicationStatus } | null>(null);
  const { data: app } = useApplication({ id: showModal?.id });

  return {
    showModal,
    setShowModal,
    ChecklistModal: (props: Partial<ChecklistModalProps>) => (
      <ChecklistModal showModal={showModal} setShowModal={setShowModal} app={app || undefined} {...props} />
    ),
  };
};

export interface ChecklistModalProps {
  app?: ChecklistApp;
  showModal: { id: string; status: enums.ApplicationStatus } | null;
  setShowModal: Dispatch<SetStateAction<{ id: string; status: enums.ApplicationStatus } | null>>;
  showRetoolButton?: boolean;
}

export const ChecklistModal = ({ showModal, setShowModal, app, showRetoolButton }: ChecklistModalProps) => {
  return (
    <ModalBox width={900} open={!!showModal?.id} onClose={() => setShowModal(null)}>
      <ModalInnards
        actions={[
          {
            text: "Close",
            action: () => setShowModal(null),
          },
          showRetoolButton
            ? {
                text: "View in Retool",
                action: () => {
                  return window.open(
                    `https://fractionhq.retool.com/apps/85253540-3694-11eb-b225-777f1da022f5/Applications%20%26%20Applicants/Application%20Management#eid=${showModal?.id}&tab=Doc%20View`,
                    "_blank"
                  );
                },
              }
            : undefined,
        ].filter(notUndefinedOrNull)}
        message={
          <p>
            Checklist at{" "}
            <b>
              {showModal?.status ? _.startCase(showModal?.status) : "next status"} for{" "}
              {app ? formatters.application.applicantNames(app) : "app"}
            </b>
          </p>
        }
      >
        {showModal?.status && app ? (
          <Checklist className="max-h-[450px] overflow-y-scroll" app={app} status={showModal?.status} />
        ) : null}
      </ModalInnards>
    </ModalBox>
  );
};
