import { SerializedStyles } from "@emotion/react";
import { ReactNode, useCallback, useMemo } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { createStyles } from "src/styles";

import { colors, types, verify } from "@fraction/shared";

const styles = createStyles({
  dropTarget: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.LIGHT_TEXT,
    fontSize: "0.9em",
    cursor: "pointer",
    "&:hover": {
      color: colors.palette.GREY_500,
    },
  },
  noPointer: {
    cursor: "default",
  },
});

export type FileWithEnumType = File & { type: types.MimeType };

const Dropzone = <Multi extends boolean>({
  accept,
  style,
  onDrop,
  // @ts-ignore
  multi = true,
  className,
  children,
  dragActiveChildren,
  disabled,
}: {
  accept?: types.MimeType[];
  style?: SerializedStyles;
  onDrop: (acceptedFiles: Multi extends true ? FileWithEnumType[] : FileWithEnumType) => void;
  multi: Multi;
  className?: string;
  children?: ReactNode;
  dragActiveChildren?: ReactNode;
  disabled?: boolean;
}) => {
  const handleOnDrop = useCallback(
    (acceptedFiles: File[]) => {
      const assertedFiles = acceptedFiles.filter((f) =>
        // This verification should always be true with accepted files that can only be part of the enum.
        verify.isEnum(types.MimeType, f.type)
      ) as FileWithEnumType[];

      if (multi) {
        // @ts-ignore
        onDrop(assertedFiles);
      } else {
        // @ts-ignore
        onDrop(assertedFiles[0]);
      }
    },
    [onDrop, multi]
  );

  const dropzoneAccept: Accept | undefined = useMemo(
    () => accept?.reduce((acc, value) => ({ ...acc, [value]: types.FILE_TYPES[value].extensions }), {}),
    [accept]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: multi ? undefined : 1,
    onDrop: handleOnDrop,
    accept: dropzoneAccept,
    disabled,
  });
  return (
    <div {...getRootProps()} className={className} css={style}>
      <input {...getInputProps()} />
      <div css={[styles.dropTarget, disabled && styles.noPointer]}>
        {isDragActive ? (
          dragActiveChildren ? (
            dragActiveChildren
          ) : (
            <i>Drop the files here ...</i>
          )
        ) : children ? (
          children
        ) : (
          <i>Drop files here, or click to select files</i>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
