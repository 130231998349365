import {
  DocumentType,
  SyntheticApplicationStatus,
  entities,
  enums,
  formatters,
  getNextStatus,
  verify,
} from "@fraction/shared";
import { addYears, subYears } from "date-fns";
import _ from "lodash";
import { EyeIcon, Loader, TrashIcon, UploadCloudIcon } from "lucide-react";
import { ChangeEvent, ReactNode, useCallback } from "react";
import fraction, { ChecklistApp } from "src/api/fraction";
import { Skeleton } from "src/components";
import { DocumentDropzone } from "src/components/DocumentDropzone";
import { PDFViewer } from "src/components/PDFViewer";
import Tooltip from "src/components/Tooltip";
import { Badge } from "src/components/ui/badge";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { useApplicationAuthed } from "src/hooks/useApplication";
import { useInsurancePolicy } from "src/hooks/useInsurancePolicy";
import useModal from "src/hooks/useModal";
import { Document } from "src/images";
import { useMutation } from "src/lib";
import { cn } from "src/utilities/shadcnUtils";

export const InsurancePoliciesBlock = ({
  applicationId,
  hideTitle,
}: { applicationId?: string; hideTitle?: boolean }) => {
  const {
    data: app,
    isLoading,
    refetch,
    isFetching,
  } = useApplicationAuthed({
    id: applicationId,
  });

  const nextStatus = app?.status ? getNextStatus(app?.loan?.status || app?.status) : undefined;
  const checklistStatus =
    app?.loan?.status === enums.LoanStatus.ACTIVE
      ? enums.LoanStatus.ACTIVE
      : nextStatus || enums.ApplicationStatus.FUNDED;

  const { preference } = useAccountViewPreference();

  if (!["employee", "conveyancer", "borrower"].includes(preference)) {
    return null;
  }

  return (
    <InsurancePolicies
      hideTitle={hideTitle}
      loading={isLoading || isFetching}
      app={app}
      status={checklistStatus}
      onNewUpload={refetch}
    />
  );
};

export const InsurancePolicies = ({
  app,
  status: status_,
  className,
  onNewUpload,
  loading,
  hideTitle,
}: {
  app?: ChecklistApp | null;
  status?: SyntheticApplicationStatus | enums.LoanStatus;
  className?: string;
  onNewUpload?: () => void;
  loading?: boolean;
  hideTitle?: boolean;
}) => {
  const nextStatus = app?.status ? getNextStatus(app?.loan?.status || app?.status) : undefined;
  const status =
    status_ ||
    (app?.loan?.status === enums.LoanStatus.ACTIVE
      ? enums.LoanStatus.ACTIVE
      : nextStatus || enums.ApplicationStatus.FUNDED);

  const files = app?.files?.filter(verify.isInsurancePolicyFile);
  // separate out files that are not associated with a policy
  const filesWithoutPolicies = files?.filter((file) => !file.insurancePolicyId);
  const filesWithPolicies = files?.filter((file) => file.insurancePolicyId);
  const groupedFilesByInsurancePolicy = _.groupBy(filesWithPolicies, "insurancePolicyId");
  const twoDimensionalArrayOfFilesGroupedByPolicy = [
    ...Object.values(groupedFilesByInsurancePolicy),
    ...(filesWithoutPolicies?.map((x) => [x]) || []),
  ].filter(Boolean);

  const createNewPolicyEntity = useMutation({
    mutationFn: async (file: entities.UploadedFile) => {
      if (!file.id) {
        throw new Error("No file id provided");
      }
      await fraction.createInsurancePolicy(app?.loan?.id!, file.id);
      onNewUpload?.();
    },
  });

  const createNewPolicyButton = (
    <DocumentDropzone
      pending={createNewPolicyEntity.isPending}
      onUploadSuccess={createNewPolicyEntity.mutateAsync}
      applicationId={app?.id}
      document={DocumentType.INSURANCE_POLICY_UPDATE}
      label="Upload new policy documents"
    />
  );

  if (!app?.loan?.id) {
    return null;
  }

  if (!files?.length) {
    return (
      <div className="flex flex-col items-center w-full max-w-3xl border border-gray-300 rounded p-4 py-6 gap-1">
        <Document className="w-16 h-full" />
        <p className="text-lg font-semibold text-center">No insurance policies found</p>
        <p className="text-sm text-center mb-2">
          Upload your insurance policy document(s) to keep your loan in good standing.
        </p>
        {createNewPolicyButton}
      </div>
    );
  }

  return (
    <div className={cn("flex flex-col border p-3 pt-3 border-gray-400 rounded gap-1", className)}>
      {hideTitle ? null : (
        <div className="flex flex-row justify-between items-center">
          <p className="text-2xl font-bold mb-1">Insurance policies</p>
          {createNewPolicyButton}
        </div>
      )}
      <div className="flex flex-col gap-4">
        {!twoDimensionalArrayOfFilesGroupedByPolicy?.length && loading
          ? [1].map((_, i) => <Skeleton key={`skele-${i}`} width="100%" height={180} />)
          : null}
        {_.sortBy(twoDimensionalArrayOfFilesGroupedByPolicy, (x) => _.minBy(x, "date")?.date)
          .reverse()
          ?.map((files, idx) => (
            <InsurancePolicy
              status={status}
              files={files}
              key={files?.[0]?.id}
              rightButton={idx === 0 && hideTitle ? createNewPolicyButton : null}
            />
          ))}
      </div>
    </div>
  );
};

export const InsurancePolicy = ({
  files,
  status,
  className,
  rightButton,
}: {
  files: entities.UploadedFile[];
  status: SyntheticApplicationStatus | enums.LoanStatus;
  className?: string;
  rightButton?: ReactNode;
}) => {
  const {
    addNewFileToPolicy,
    handleDeletePolicy,
    handleChangePolicyNumber,
    handleChangeExpiryDate,
    handleChangeStartDate,
    handleChangeInsuranceProvider,
    insurancePolicy,
    localUpdatePolicy,
    isDeletingFile,
  } = useInsurancePolicy({
    existingInsurancePolicy: files?.[0].insurancePolicy,
    dealId: files?.[0]?.applicationId!,
    dealStatus: status,
    firstFileId: files?.[0]?.id,
  });

  const onChangeStartDate = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      if (evt.target.value) {
        const newDate = new Date(evt.target.value);
        if (newDate > new Date("2024-01-01")) {
          return handleChangeStartDate(newDate);
        } else {
          localUpdatePolicy({ startDate: newDate });
        }
      } else {
        handleChangeStartDate(undefined);
      }
    },
    [insurancePolicy?.expiryDate, handleChangeExpiryDate]
  );

  const onBlurStartDate = useCallback(() => {
    if (insurancePolicy?.startDate && !insurancePolicy?.expiryDate) {
      handleChangeExpiryDate(addYears(insurancePolicy?.startDate, 1));
    }
  }, [insurancePolicy?.expiryDate, insurancePolicy?.startDate, handleChangeExpiryDate]);

  const onBlurExpiryDate = useCallback(() => {
    if (insurancePolicy?.expiryDate && !insurancePolicy?.startDate) {
      handleChangeStartDate(subYears(insurancePolicy?.expiryDate, 1));
    }
  }, [insurancePolicy?.expiryDate, insurancePolicy?.startDate, handleChangeStartDate]);

  const onChangeExpiryDate = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value) {
      const newDate = new Date(evt.target.value);
      if (newDate > new Date("2024-01-01")) {
        return handleChangeExpiryDate(newDate);
      } else {
        localUpdatePolicy({ expiryDate: newDate });
      }
    } else {
      handleChangeExpiryDate(undefined);
    }
  }, []);

  const onChangePolicyNumber = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    return handleChangePolicyNumber(evt.target.value);
  }, []);

  const onChangeInsuranceProvider = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    return handleChangeInsuranceProvider(evt.target.value);
  }, []);

  const earliestFile = _.minBy(files, "date") || _.minBy(files, "createdAt");

  if (!earliestFile) {
    return null;
  }

  const needsAttention =
    !insurancePolicy?.insuranceProvider ||
    !insurancePolicy?.policyNumber ||
    !insurancePolicy?.startDate ||
    !insurancePolicy?.expiryDate;

  return (
    <>
      <div className={cn(" border-gray-400 flex flex-row flex-wrap gap-2 w-full", className)}>
        <div className="w-full flex flex-row items-start justify-between">
          <Tooltip white text="Add new file to policy">
            <DocumentDropzone
              loading={addNewFileToPolicy.isPending}
              onUploadSuccess={addNewFileToPolicy.mutateAsync}
              variant="blue"
              applicationId={files?.[0]?.applicationId!}
              document={DocumentType.INSURANCE_POLICY_UPDATE}
              label={
                <>
                  Attach new file to policy <UploadCloudIcon height={12} />
                </>
              }
            />
          </Tooltip>
          {rightButton}
        </div>
        <div className="flex flex-col gap-1 w-full">
          {_.sortBy(files, "date")
            .reverse()
            .map((file) => (
              <InsurancePolicyFile
                isDeleting={isDeletingFile === file?.id}
                onDeletePolicy={handleDeletePolicy.mutateAsync}
                file={file}
                titleClassName={needsAttention ? "text-orange group-hover:text-orange-700" : undefined}
                needsMoreInformation={needsAttention}
              />
            ))}
        </div>

        <div className="flex flex-col w-[48%]">
          <label className="text-xs font-semibold">Start date</label>
          <input
            className="bg-gray-200 p-2 rounded hover:bg-gray-300"
            type="date"
            onChange={onChangeStartDate}
            defaultValue={insurancePolicy?.startDate?.toISOString()?.slice(0, 10)}
            onBlur={onBlurStartDate}
          />
        </div>
        <div className="flex flex-col w-[49%]">
          <label className="text-xs font-semibold">Expiry date</label>
          <input
            className="bg-gray-200 p-2 rounded hover:bg-gray-300"
            type="date"
            onChange={onChangeExpiryDate}
            defaultValue={insurancePolicy?.expiryDate?.toISOString()?.slice(0, 10)}
            onBlur={onBlurExpiryDate}
          />
        </div>

        <div className="flex flex-col w-[48%]">
          <label className="text-xs font-semibold">Policy no.</label>
          <input
            placeholder="Policy no."
            className="bg-gray-200 p-2 rounded hover:bg-gray-300"
            onChange={onChangePolicyNumber}
            defaultValue={insurancePolicy?.policyNumber}
          />
        </div>
        <div className="flex flex-col w-[49%]">
          <label className="text-xs font-semibold">Insurance provider.</label>
          <input
            placeholder="Insurance provider"
            className="bg-gray-200 p-2 rounded hover:bg-gray-300"
            onChange={onChangeInsuranceProvider}
            defaultValue={insurancePolicy?.insuranceProvider}
          />
        </div>
      </div>
      <hr className="last:hidden" />
    </>
  );
};

export const InsurancePolicyFile = ({
  file,
  onDeletePolicy,
  isDeleting,
  titleClassName,
  needsMoreInformation,
}: {
  file: entities.UploadedFile;
  onDeletePolicy?: (file: entities.UploadedFile) => Promise<void>;
  isDeleting?: boolean;
  titleClassName?: string;
  needsMoreInformation?: boolean;
}) => {
  const { showModal } = useModal();

  const handleClickPreview = useMutation({
    mutationFn: async (file: entities.UploadedFile) => {
      if (!file.id) {
        throw new Error("No file id provided");
      }
      const url = await fraction.getPresignedFileDownloadUrl(file.id);
      showModal({
        shovable: true,
        children: (
          <PDFViewer
            renderTextLayer
            filename={file.type?.fullName}
            toolbar="footer"
            download
            pdfClassName="h-[700px]"
            url={url}
          />
        ),
      });
    },
  });

  return (
    <div className="flex flex-row justify-between items-center p-2 bg-gray-200 w-full rounded border border-gray-400">
      <div>
        <Tooltip
          place="top-start"
          white
          text={needsMoreInformation ? "Policy details still need to be filled out" : undefined}
        >
          <div className="flex flex-row items-center gap-1.5 group cursor-pointer">
            <p
              onClick={() => handleClickPreview.mutateAsync(file)}
              className={cn("font-semibold text-sm cursor-pointer text-green w-fit", titleClassName)}
            >
              Policy{" "}
              {file.type?.name === DocumentType.INSURANCE_POLICY_UPDATE
                ? "update"
                : file.type?.name === DocumentType.CONDOSTRATA_INSURANCE_CERT
                ? "condo/strata certificate"
                : "binder"}{" "}
              document
            </p>
            {needsMoreInformation ? (
              <div className="bg-orange rounded-full w-1.5 h-1.5 group-hover:bg-orange-700 mt-[2px]" />
            ) : null}
          </div>
        </Tooltip>

        <p className="font-medium text-xs text-gray-900">
          uploaded on{" "}
          {file.date
            ? formatters.date.formatDate(file.date)
            : file.createdAt
            ? formatters.date.formatDate(file.createdAt)
            : "N/A"}
        </p>
      </div>
      <div className="flex flex-row items-center gap-2">
        <Tooltip white text="Delete policy">
          <Badge className="cursor-pointer" onClick={() => onDeletePolicy?.(file)} variant="destructive">
            {!isDeleting ? (
              <TrashIcon height={12} />
            ) : (
              <Loader height={14} className="animate-spin text-white" />
            )}
          </Badge>
        </Tooltip>
        <Tooltip white text="View policy">
          <Badge
            className="cursor-pointer"
            onClick={() => handleClickPreview.mutateAsync(file)}
            variant="success"
          >
            {handleClickPreview.isPending ? (
              <Loader height={14} className="animate-spin text-white" />
            ) : (
              <EyeIcon className="text-white" height={14} />
            )}
          </Badge>
        </Tooltip>
      </div>
    </div>
  );
};
