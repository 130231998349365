import { SerializedStyles } from "@emotion/react";
import { Chip } from "src/components";
import { Variant } from "src/components/Chip";
import { createStyles } from "src/styles";

import { enums } from "@fraction/shared";

const OUTCOMES: Record<enums.ApplicationTaskApprovalStatus, { message: string; chipVariant: Variant }> = {
  [enums.ApplicationTaskApprovalStatus.NEEDS_WORK]: {
    message: "Needs work",
    chipVariant: Variant.YELLOW,
  },
  [enums.ApplicationTaskApprovalStatus.REJECTED]: {
    message: "Rejected",
    chipVariant: Variant.RED,
  },
  [enums.ApplicationTaskApprovalStatus.REQUESTED]: {
    message: "Requested",
    chipVariant: Variant.BASE,
  },
  [enums.ApplicationTaskApprovalStatus.APPROVED]: {
    message: "Approved",
    chipVariant: Variant.GREEN,
  },
};

const styles = createStyles({
  chip: {
    width: "fit-content",
    height: "fit-content",
  },
});

export function StatusChip({
  status,
  style,
}: {
  status: enums.ApplicationTaskApprovalStatus;
  style?: SerializedStyles;
}) {
  return (
    <Chip style={[styles.chip, style]} variant={OUTCOMES[status].chipVariant}>
      {OUTCOMES[status].message}
    </Chip>
  );
}
