import { SerializedStyles } from "@emotion/react";
import { CSSProperties, ReactElement, ReactNode, memo } from "react";
import { RegularText } from "src/components/v1";
import { createStyles } from "src/styles";

const styles = createStyles({
  text: {
    fontSize: 12,
    marginBottom: 20,
  },
});

export interface FinePrintProps {
  style?: SerializedStyles | Array<SerializedStyles | undefined> | CSSProperties;
  children: string | ReactElement | Array<string | ReactElement> | ReactNode;
  className?: string;
}

const FinePrint = ({ style, children, className }: FinePrintProps) => (
  <RegularText className={className} style={[styles.text, style]} paragraph>
    {children}
  </RegularText>
);

export default memo(FinePrint);
