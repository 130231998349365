import retry from "@fullstax/p-retry";
import { Suspense, lazy } from "react";
import { Loading } from "src/components";

const PortfolioMap = lazy(() => retry(() => import("../../components/PortfolioMap")));

export function PortfolioMapScreen() {
  return (
    <div className="h-full w-full">
      <Suspense fallback={<Loading />}>
        <PortfolioMap />
      </Suspense>
    </div>
  );
}
