import { entities, formatters } from "@fraction/shared";
import { Badge } from "./ui/badge";

export function DebtList({ debts }: { debts?: entities.Debt[] }) {
  return (
    <div>
      {debts?.map((debt) => (
        <div
          className="flex flex-row hover:bg-gray-200 odd:bg-gray-100 p-2 odd:rounded gap-x-4"
          key={debt.id}
        >
          <div>
            <b className="text-lg">{debt.name || debt.description}</b>
            {debt.accountNumberLast4Digits ? (
              <p className="text-base text-gray-600">•••• •••• {debt.accountNumberLast4Digits}</p>
            ) : null}
          </div>
          <div>
            {debt.currentlyOwing ? (
              <Badge variant="gray" className="text-xs rounded-full font-medium">
                {formatters.number.getCurrencyFromNumber(debt.currentlyOwing)}
              </Badge>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}
