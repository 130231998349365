import { ReactNode } from "react";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import { AcceptInvitePage } from "src/apps/BrokerDashboard/pages/AcceptInvitePage";
import { AppDetailsRoutes } from "src/apps/BrokerDashboard/pages/AppDetails";
import { BrokerDocsAndResourcesPage } from "src/apps/BrokerDashboard/pages/BrokerDocsAndResources";
import { CostCalculatorPage } from "src/apps/BrokerDashboard/pages/Marketing/CostCalculatorPage";
import { PropertyReport } from "src/apps/BrokerDashboard/pages/Marketing/PropertyReport";
import { PropertyCheckPage } from "src/apps/BrokerDashboard/pages/PropertyCheckPage";
import NavDrawer from "src/apps/PostFundedDashboard/components/NavDrawer";
import { EditProfileScreen } from "src/apps/shared/EditProfile";
import { AccountDropdown, DashboardShell } from "src/components";
import { Logo } from "src/components/Logo";
import { NavItems } from "./components/NavItems";
import { BrokerMarketing } from "./pages/Marketing";
import { BrokerDashboardOverview } from "./pages/Overview";

const BrokerDashboardHeader = () => {
  return (
    <div className="flex items-center pl-6 pt-4 pb-4 pr-6 border-b-2 border-solid">
      <Link to="/">
        <Logo />
      </Link>
      <div className="pt-1 ml-6 overflow-x-scroll flex flex-row">
        <NavItems />
      </div>
      <div className="flex ml-auto">
        <AccountDropdown />
      </div>
    </div>
  );
};

const BrokerDashboardLayout = ({ footer }: { footer?: ReactNode }) => {
  return (
    <>
      <DashboardShell footer={footer} header={<BrokerDashboardHeader />}>
        <Outlet />
      </DashboardShell>
      <NavDrawer NavItems={NavItems} />
    </>
  );
};

export function BrokerDashboardRoutes() {
  return (
    <Routes>
      <Route path="/*" element={<BrokerDashboardLayout />}>
        <Route index path="" element={<BrokerDashboardOverview accountType="broker" status="active" />} />
        <Route path="closed" element={<BrokerDashboardOverview accountType="broker" status="closed" />} />
        <Route path="marketing" element={<BrokerMarketing />} />
        <Route path="marketing/property-report" element={<PropertyReport />} />
        <Route path="marketing/cost-calculator" element={<CostCalculatorPage />} />
        <Route path="marketing/property-check" element={<PropertyCheckPage />} />
        <Route path="broker-docs" element={<BrokerDocsAndResourcesPage />} />
        <Route path="app/:id/*" element={<AppDetailsRoutes />} />
        <Route path="profile" element={<EditProfileScreen />} />;
        <Route path="invite/:inviteCode" element={<AcceptInvitePage />} />;
      </Route>
    </Routes>
  );
}
