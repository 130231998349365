import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import FinePrint from "src/components/FinePrint";

export interface PatriotActProps {
  style?: SerializedStyles;
}

const PatriotAct = ({ style }: PatriotActProps) => (
  <FinePrint style={style}>
    To help the government fight the funding of terrorism and money laundering activities, federal law
    requires all financial institutions to obtain, verify, and record information that identifies each person
    who opens an account. What this means for you: When you open an account, we will ask for your name,
    address, date of birth, and other information that will allow us to identify you. We may also ask to see a
    copy of your driver's license or other identifying documents.
  </FinePrint>
);

export default memo(PatriotAct);
