import {
  UseMutationOptions as UseMutationOptionsLib,
  UseQueryOptions as UseQueryOptionsLib,
  useMutation as useMutationLib,
  useQuery as useQueryLib,
} from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { ResponseError as ResponseErrorSuperagent } from "superagent";

import {
  ERROR_MESSAGES,
  FractionError,
  UserAuthenticationError,
  ValueError,
  debounce,
} from "@fraction/shared";

const GENERIC_ERROR_MESSAGE = "Our server had a hiccup! Please wait and try again in a moment.";

interface AdditionalQueryOptions<TData, TError> {
  errorMessage?: string | ((error: TError) => string | undefined | void);
  onError?: (err: TError, toaster: typeof toast) => void;
  supersedeOtherErrorsInMs?: number;
  onSuccess?: (data: TData, fromCache?: boolean) => void | Promise<void>;
}

export type UseMutationOptions<TData = unknown, TError = any, TVariables = void> = UseMutationOptionsLib<
  TData,
  TError,
  TVariables
> &
  AdditionalQueryOptions<TData, TError>;

export type UseQueryOptions<TQueryFnData = unknown, TError = any, TData = TQueryFnData> = Omit<
  UseQueryOptionsLib<TQueryFnData, TError, TData>,
  "onError"
> &
  AdditionalQueryOptions<TData, TError>;

/**
 * A custom error class that wraps response errors from superagent
 */
export class ResponseError extends FractionError {
  public constructor(err: ResponseErrorSuperagent) {
    if (err.status === undefined || err.response === undefined) {
      throw new ValueError("ResponseError: missing error status or response in constructor");
    }
    const message = err.response.body.message || GENERIC_ERROR_MESSAGE;
    super(message, err.response.body);

    this.name = err.response.body.name || "HTTPError";
    this.data = err.response.body.data || {};
    this.statusCode = err.status;
  }
}

const debouncedToastError = debounce(toast.error, 1000);

/*
 * Error message order of precedence:
 * 1. If errorMessage is a function and it returns a non-empty string, use that
 * 2. If errorMessage is a non-empty string, use that
 * 3. If errorMessage is empty/undefined, use the generic error message
 *
 * If errorMessage is or returns an empty string don't show any error message
 */
export const defaultErrorMessageHandler = <TError extends Error>(
  error: TError,
  errorMessage?: string | ((error: TError) => string | undefined | void)
) => {
  const customErrorMessage =
    (errorMessage instanceof Function ? errorMessage?.(error) : errorMessage) || error?.message;

  if (customErrorMessage === "") {
    return;
  }

  if (customErrorMessage?.includes("Request has been terminated")) {
    return;
  }

  if (
    window.location.pathname === "/login" &&
    error instanceof UserAuthenticationError &&
    error.message !== ERROR_MESSAGES.INCORRECT_PASSWORD
  ) {
    // this is an auth error but we have been redirected to the login page, so just ignore.
    return;
  }
  console.error(error);
  debouncedToastError(customErrorMessage || GENERIC_ERROR_MESSAGE);
};

export const useQuery = <TQueryFnData = unknown, TError = any, TData = TQueryFnData>(
  options: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQueryLib({
    ...options,
    queryFn: async () => {
      // @ts-ignore
      const result = await options.queryFn?.();
      if (options.onSuccess) {
        await options.onSuccess?.(result, false);
      }
      return result;
    },
  });
};

export const useMutation = <TData = unknown, TError extends Error = any, TVariables = void>(
  options: UseMutationOptions<TData, TError, TVariables>
) => {
  const { errorMessage, onError, ...reactQueryOptions } = options;

  return useMutationLib<TData, TError, TVariables>({
    onError: (err, variables, context) => {
      onError?.(err, variables, context);
      if (!onError) {
        defaultErrorMessageHandler<TError>(err, errorMessage);
      }
    },
    ...reactQueryOptions,
  });
};

/**
 * Decorate a function so any ResponseErrors from superagent are wrapped and re-thrown as our custom
 * ResponseError object.
 *
 * This is necessary since, for some reason, react-query sometimes doesn't handle errors from superagent
 * nicely. I was experiencing errors being thrown but the onError react-query handler wasn't being called.
 * Wrapping and re-throwing the error fixed this issue.
 *
 * Note that other types of errors (i.e. not ResponseErrors) will likely be re-thrown as ValueErrors.
 */
export const wrapSuperagentErrors =
  <Args extends any[], Result>(
    fn: (...args: Args) => Promise<Result>
  ): ((...args: Args) => Promise<Result>) =>
  async (...args: Args): Promise<Result> => {
    try {
      return await fn(...args);
    } catch (err) {
      throw new ResponseError(err);
    }
  };
