import { SerializedStyles } from "@emotion/react";
import DrawerModal from "src/components/DrawerModal";
import { DrawerModalProps } from "src/components/DrawerModal";
import XButton from "src/components/XButton";
import { createStyles } from "src/styles";

export interface MenuCloseButtonProps {
  style?: SerializedStyles;
  onClick?: () => void;
}
export interface DrawerMenuProps extends DrawerModalProps {
  onClose: () => void;
  children: React.ReactNode;
}

const styles = createStyles({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 32,
    paddingBottom: 0,
  },
});

const DrawerMenu = ({ onClose, children, ...props }: DrawerMenuProps) => (
  <DrawerModal transitionDuration={0.5} {...props}>
    <div css={styles.container}>
      <div css={styles.header}>
        <XButton onClick={onClose} />
      </div>
      {children}
    </div>
  </DrawerModal>
);

export default DrawerMenu;
