import { memo } from "react";
import { InputModalContent } from "src/components/InnerModals";
import { MediumText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors, entities } from "@fraction/shared";

const styles = createStyles({
  paragraph: {
    textAlign: "center",
    color: colors.palette.GREY_600,
    lineHeight: "160%",
    marginTop: 8,
  },
  break: {
    display: "block",
    margin: "16px 0px",
    content: '" "',
  },
});

const DeleteBankAccountReview = ({
  cancelDeleteAttempt,
  confirmDelete,
  currentAccount,
  isLoading,
}: {
  cancelDeleteAttempt: () => void;
  confirmDelete: () => void;
  currentAccount?: entities.BankAccount;
  isLoading: boolean;
}) => (
  <InputModalContent
    header="Delete bank account?"
    isLoading={isLoading}
    actions={[
      { text: "Cancel", action: cancelDeleteAttempt },
      { text: "Delete", action: confirmDelete, type: "urgent" },
    ]}
  >
    <MediumText style={styles.paragraph}>
      Are you sure you want to delete:
      <br />
      <b>"{currentAccount?.name}"</b>?
      <br css={styles.break} />
      You can't undo this action.
    </MediumText>
  </InputModalContent>
);

export default memo(DeleteBankAccountReview);
