import { useParams } from "react-router-dom";

export function AppDocumentCreation() {
  const { id } = useParams();

  return (
    <div className="gap-y-2 flex flex-col">
      <p>{id}</p>
    </div>
  );
}
