import { RefObject, useEffect, useState } from "react";

export enum ScrollDirection {
  DOWN = "down",
  UP = "up",
  NONE = "none",
}

// Adapted from: https://stackoverflow.com/a/62497293/12707616
export default function useScrollDirection(scrollElement?: RefObject<any>, threshold = 0) {
  const [scrollDirection, setScrollDir] = useState<ScrollDirection>(ScrollDirection.NONE);

  // If no containerRef is provided, the hook will default to tracking the window scroll direction.
  // Providing a containerRef will track the scroll direction of an element with overflow behaviour.
  const scrollContainer = scrollElement?.current ? scrollElement.current : window;
  const scrollDistanceAttribute = scrollElement?.current ? "scrollTop" : "pageYOffset";

  useEffect(() => {
    let lastScrollY = scrollContainer[scrollDistanceAttribute];
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = scrollContainer[scrollDistanceAttribute];

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? ScrollDirection.DOWN : ScrollDirection.UP);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    scrollContainer.addEventListener("scroll", onScroll, { passive: true });
    return () => scrollContainer.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollDirection]);

  return {
    scrollDirection,
    isScrollingUp: scrollDirection === ScrollDirection.UP,
    isScrollingDown: scrollDirection === ScrollDirection.DOWN,
  };
}
