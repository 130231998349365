import {
  QueryCache,
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
  QueryKey,
} from "@tanstack/react-query";
import { ReactNode, createContext } from "react";

import { FractionError } from "@fraction/shared";
import { defaultErrorMessageHandler } from "src/lib";

// Can be overwritten at a query hook instance level
const MAX_RETRIES = 0;

const getStatusCode = (error: any) => {
  if (error instanceof FractionError) {
    return error.statusCode;
  }
  return error?.response?.statusCode;
};

const errorMessageCache = new Map<QueryKey, number>();

const createQueryClient = (config?: QueryClientConfig) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          console.error(error);
          console.log("status code", getStatusCode(error));
          return getStatusCode(error) >= 500 && count < MAX_RETRIES;
        },
        staleTime: 1000 * 20, // the default of 0 makes our app pretty bandwidth hungry, 20s seems like a good compromise to prevent refetching data in quick succession
      },
    },
    queryCache: new QueryCache({
      onError: (err, query) => {
        let timeSet: number;
        if (
          query.meta?.supersedeOtherErrorsInMs &&
          typeof query.meta?.supersedeOtherErrorsInMs === "number" &&
          query.options.queryKey
        ) {
          timeSet = Date.now() + query.meta?.supersedeOtherErrorsInMs;
          errorMessageCache.set(query.options.queryKey, timeSet);
        }

        // wait 25ms before throwing the error in case the cache has come in from elsewhere
        setTimeout(() => {
          const existingCache = query.options.queryKey
            ? errorMessageCache.get(query.options.queryKey)
            : undefined;

          if (!timeSet && existingCache && existingCache > Date.now()) {
            // the error has been superseded, dont run the error handler
            return;
          }

          defaultErrorMessageHandler(err, query.meta?.errorToast as string | undefined);
        }, 25);
      },
    }),
    ...config,
  });

const qc = createQueryClient();
const cachedQc = createQueryClient({
  queryCache: qc.getQueryCache(),
  mutationCache: qc.getMutationCache(),
});

export const CachedQueryClient = createContext<QueryClient>(cachedQc);

const ReactQueryRoot = ({ children }: { children: ReactNode }) => (
  <CachedQueryClient.Provider value={cachedQc}>
    <QueryClientProvider client={qc}>{children}</QueryClientProvider>
  </CachedQueryClient.Provider>
);

export default ReactQueryRoot;
