import { SerializedStyles } from "@emotion/react";
import { ReactNode, memo } from "react";
import FinePrint from "src/components/FinePrint";
import TextLink from "src/components/TextLink";

export interface AgreementParagraphProps {
  style?: SerializedStyles;
  children?: ReactNode;
}

const AgreementParagraph = ({ style, children }: AgreementParagraphProps) => (
  <FinePrint style={style}>
    By clicking below, I agree to Fraction’s{" "}
    <TextLink underlined href="https://www.fraction.com/usa/credit-profile-authorization">
      Credit Profile Authorization
    </TextLink>{" "}
    {children}
  </FinePrint>
);

export default memo(AgreementParagraph);
