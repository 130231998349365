import { SerializedStyles } from "@emotion/react";
import { ReactElement, ReactNode, useEffect } from "react";
import { AccountDropdown, DashboardHeader, Touchable } from "src/components";
import { Breadcrumb, BreadcrumbList } from "src/components/ui/breadcrumb";
import { useWindowSize } from "src/hooks";
import { Menu } from "src/icons";
import { createStyles } from "src/styles";
import { Breakpoint } from "src/styles/utilities/breakpoints";

import usePostFundedDashStore from "../../store";

const styles = createStyles(({ spacing, colors, fontSize }) => ({
  navItemsContainer: {
    marginRight: [spacing[3], spacing[6]],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  hamburger: {
    padding: spacing[0.5],
    color: colors.grey,
    height: 32,
    width: 32,
  },
}));

const MobileMenuButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Touchable onClick={onClick}>
      <Menu css={styles.hamburger} />
    </Touchable>
  );
};

const PostFundedHeader = ({
  children,
  logoBoxStyle,
  className,
  Breadcrumbs,
}: {
  Breadcrumbs?: ReactElement;
  children?: ReactNode;
  logoBoxStyle?: SerializedStyles;
  className?: string;
}) => {
  const { width } = useWindowSize();
  const closeNavDrawer = usePostFundedDashStore((state) => state.closeNavDrawer);
  const toggleNavDrawer = usePostFundedDashStore((state) => state.toggleNavDrawer);

  useEffect(() => {
    // auto close the nav drawer if the screen size changes to desktop
    if (width >= Breakpoint.tablet) {
      closeNavDrawer();
    }
  }, [width, closeNavDrawer]);

  return (
    <>
      <DashboardHeader>
        <div className={className} css={styles.navItemsContainer}>
          {children}
          {Breadcrumbs ? (
            <Breadcrumb>
              <BreadcrumbList>{Breadcrumbs}</BreadcrumbList>
            </Breadcrumb>
          ) : null}
          {width < Breakpoint.tablet ? <MobileMenuButton onClick={toggleNavDrawer} /> : <AccountDropdown />}
        </div>
      </DashboardHeader>
    </>
  );
};

export default PostFundedHeader;
