import { Navigate, useLocation } from "react-router-dom";
import { Logger } from "src/log";

const LOGGER = new Logger("MissingRouteRedirect");

const MissingRouteRedirect = () => {
  const location = useLocation();
  LOGGER.warn("Could not find a matching route for: ", location.pathname);
  return <Navigate to="/" replace />;
};

export default MissingRouteRedirect;
