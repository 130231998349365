import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";
import { ReactNode, useCallback, useEffect } from "react";

import { Button } from "src/components/ui/button";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from "src/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { cn } from "src/utilities/shadcnUtils";

export type Option<Opt extends string = string> = { value: Opt; label: string };

interface MultiSelectProps<Opt extends string> {
  options: Option<Opt>[];
  values?: Option<Opt>[];
  defaultValues?: Option<Opt>[];
  onChange?: (options: Option<Opt>[]) => void;
  className?: string;
  buttonClassName?: string;
  prefixIcon?: ReactNode;
}

export function MultiSelect<Opt extends string>({
  options,
  defaultValues = [],
  onChange,
  className,
  buttonClassName,
  values,
  prefixIcon,
}: MultiSelectProps<Opt>) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openCombobox, setOpenCombobox] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [selectedValues, setSelectedValues] = React.useState<Option<Opt>[]>(defaultValues);

  useEffect(() => {
    setSelectedValues(values || []);
  }, [values]);

  const toggleOption = useCallback(
    (option: Option<Opt>) => {
      setSelectedValues((currentOptions) => {
        const updated = !currentOptions.map(({ value }) => value).includes(option.value)
          ? [...currentOptions, option]
          : currentOptions.filter((l) => l.value !== option.value);
        onChange?.(updated);
        return updated;
      });
      inputRef?.current?.focus();
    },
    [inputRef?.current, onChange]
  );

  const onComboboxOpenChange = (value: boolean) => {
    inputRef.current?.blur(); // HACK: otherwise, would scroll automatically to the bottom of page
    setOpenCombobox(value);
  };

  const getLabel = useCallback(
    (selectedValue: Option<Opt>) => {
      if (selectedValue?.label) {
        return selectedValue?.label;
      }
      const fromOptions = options.find((option) => option.value === selectedValue.value);
      return fromOptions?.label || selectedValue.value;
    },
    [values]
  );

  return (
    <div className={cn("min-w-[200px]", className)}>
      <Popover open={openCombobox} onOpenChange={onComboboxOpenChange}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={openCombobox}
            className={cn(
              "w-full justify-between text-foreground flex-row items-center h-full",
              buttonClassName
            )}
          >
            {prefixIcon}
            <span className="truncate">
              {selectedValues.length === 0 && "Select options"}
              {selectedValues.length === 1 && getLabel(selectedValues[0])}
              {selectedValues.length === 2 && selectedValues.map(getLabel).join(", ")}
              {selectedValues.length === 3 && selectedValues.map(getLabel).join(", ")}
              {selectedValues.length > 3 && `${selectedValues.length} items selected`}
            </span>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command loop>
            <CommandInput
              ref={inputRef}
              placeholder="Search..."
              value={inputValue}
              onValueChange={setInputValue}
            />
            <CommandGroup className="max-h-[145px] overflow-auto">
              {options.map((option) => {
                const isActive = !!selectedValues.find((item) => item.value === option.value);
                return (
                  <CommandItem key={option.value} value={option.value} onSelect={() => toggleOption(option)}>
                    <Check className={cn("mr-2 h-4 w-4", isActive ? "opacity-100" : "opacity-0")} />
                    <div className="flex-1">{option.label}</div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            <CommandSeparator alwaysRender />
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
