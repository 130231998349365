import { useCallback } from "react";
import { Skeleton, TextInputWithLoading, Touchable } from "src/components";
import { createStyles } from "src/styles";

import { entities, formatters } from "@fraction/shared";
import { RegularText } from "src/components/v1/Text";

const styles = createStyles({
  input: {
    backgroundColor: "white",
  },
  text: {
    textAlign: "center",
    maxWidth: 450,
  },
  inputContainer: { width: 350 },
});

export const BdmBrokerageSearchLayout = ({
  onSubmit,
  loading,
  disabled,
  brokerages,
  onChooseBrokerage,
}: {
  errorMessage?: string;
  onSubmit: (postalCode: string) => void;
  loading?: boolean;
  disabled?: boolean;
  brokerages?: { id: string; name: string; tradeName?: string; officeLocation?: entities.OfficeLocation }[];
  onChooseBrokerage: (brokerageId: string) => void;
}) => {
  const handleSubmit = useCallback(
    (evt: any) => {
      evt?.preventDefault?.();
      onSubmit?.(evt.target.brokerageName.value);
    },
    [onSubmit]
  );

  const handleChange = useCallback(
    (name: string) => {
      if (handleSubmit) {
        handleSubmit?.({ target: { brokerageName: { value: name } } });
      }
    },
    [handleSubmit]
  );

  return (
    <div className="flex flex-col gap-5 items-center w-full">
      <RegularText style={styles.text}>
        Search for your brokerage below. You can search for BC, ON, and AB brokerages.
      </RegularText>
      <TextInputWithLoading
        style={styles.inputContainer}
        inputStyle={styles.input}
        errorSpacing={false}
        onChange={handleChange}
        variant="rounded"
        name="brokerageName"
        placeholder="Brokerage name"
        type="text"
        autoComplete="off"
        loading={loading || false}
        disabled={disabled}
      />
      {loading ? <Skeleton height={100} width={350} /> : null}
      {!loading && brokerages?.length ? (
        <div className="p-3 max-w-md block w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          {brokerages.map((brokerage) => (
            <Touchable style={{ width: "100%" }} onClick={() => onChooseBrokerage(brokerage.id)}>
              <div className="hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-1 pl-2 pr-2">
                <RegularText style={{ textAlign: "left" }}>
                  <b>{brokerage.name}</b>
                  {brokerage.tradeName ? ` (${brokerage.tradeName})` : ""}
                </RegularText>
                <RegularText style={{ textAlign: "left", fontSize: 13 }}>
                  {brokerage.officeLocation
                    ? `${formatters.property.formattedAddress(brokerage.officeLocation)}`
                    : ""}
                </RegularText>
              </div>
            </Touchable>
          ))}
        </div>
      ) : null}
    </div>
  );
};
