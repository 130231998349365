import { CSSObject, SerializedStyles, css } from "@emotion/react";
import type { StandardPropertiesFallback } from "csstype";
import { CSSProperties } from "react";

import SHAPED_TOKENS, { ShapedTokens } from "./figma/SHAPED_TOKENS";
import { applyMediaBreakpoints } from "./utilities/breakpoints";

export type SingleStyle = SerializedStyles | false | undefined;

// used for typing a components "style" prop. Can be a serialized style object or a falsey value since those get filtered out.
export type Style = SingleStyle | SingleStyle[];

type MinMax = "min" | "max";
type DigitNoZero = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

type WidthQueries =
  | `@media(${MinMax}-width: ${DigitNoZero | "0"}px)`
  | `@media(${MinMax}-width: ${DigitNoZero}${DigitNoZero | "0"}px)`
  | `@media(${MinMax}-width: ${DigitNoZero}${DigitNoZero | "0"}${DigitNoZero | "0"}px)`
  | `@media(${MinMax}-width: ${DigitNoZero}${DigitNoZero | "0"}${DigitNoZero | "0"}${DigitNoZero | "0"}px)`;

type Children = "first" | "last";
type EvenOdd = "even" | "odd";

/**
 * Pseudo classes as defined by https://developer.mozilla.org/en-US/docs/Web/CSS/Pseudo-classes
 */
type PseudoClasses =
  | "&:active"
  | "&:any-link"
  | "&:autofill"
  | "&:blank"
  | "&:checked"
  | "&:current"
  | "&:default"
  | "&:defined"
  | "&:disabled"
  | "&:empty"
  | "&:enabled"
  | "&:first"
  | "&:first-child"
  | "&:first-of-type"
  | "&:fullscreen"
  | "&:future"
  | "&:focus"
  | "&:focus-visible"
  | "&:focus-within"
  | "&:host"
  | "&:hover"
  | "&:indeterminate"
  | "&:in-range"
  | "&:invalid"
  | "&:last-child"
  | "&:last-of-type"
  | "&:left"
  | "&:link"
  | "&:local-link Experimental"
  | "&:not()"
  | `&:nth-child(${DigitNoZero})`
  | `&:nth-col(${DigitNoZero})`
  | `&:nth-last-child(${DigitNoZero})`
  | `&:nth-last-col(${DigitNoZero})`
  | `&:nth-last-of-type(${DigitNoZero})`
  | `&:nth-of-type(${DigitNoZero})`
  | "&:only-child"
  | "&:only-of-type"
  | "&:optional"
  | "&:out-of-range"
  | "&:past Experimental"
  | "&:picture-in-picture"
  | "&:placeholder-shown"
  | "&:paused"
  | "&:playing"
  | "&:read-only"
  | "&:read-write"
  | "&:required"
  | "&:right"
  | "&:root"
  | "&:scope"
  | "&:target"
  | "&:target-within"
  | "&:user-invalid"
  | "&:valid"
  | "&:visited"
  | "&:where()";

// https://developer.mozilla.org/en-US/docs/Web/CSS/Pseudo-elements
type PseudoElementsCss =
  | "::after"
  | "::before"
  | "::first-letter"
  | "::first-line"
  | "::selection"
  | "::placeholder";
type PseudoElements = `&${PseudoElementsCss}`;

type ChildQueries =
  | `&:not(:${Children}-child)`
  | `&:${Children}-child`
  | `&:nth-of-type(${EvenOdd})`
  | `&:${Children}-of-type`;

type WebkitQueries = "::-webkit-scrollbar" | "::-webkit-scrollbar-thumb";

type MediaQueries =
  | WidthQueries
  | ChildQueries
  | WebkitQueries
  | PseudoClasses
  | PseudoElements
  | `${PseudoClasses}${PseudoElementsCss}`;

/**
 * Note: We aren't technically using CSS fallbacks, but taking advantage of the fact that our breakpoints
 * implementation is equivalent to CSS fallbacks from a typing perspective
 */
type StandardCSSPropertiesWithArray = StandardPropertiesFallback<string | number>;

export type MediaCSSProperties =
  | CSSProperties
  | Partial<Record<MediaQueries, CSSProperties>>
  | Partial<Record<MediaQueries, Partial<Record<MediaQueries, CSSProperties>>>>
  | StandardCSSPropertiesWithArray;

type StyleReducer<T extends Record<string, MediaCSSProperties>> = (tokens: ShapedTokens) => Required<T>;

/**
 * Take a style object and serialize it for use by emotion.
 *
 * Also applies some custom transformations to the style object such as converting css properties passed
 * as arrays into media queries.
 *
 * If you have a custom css selector you want to use, you
 * can use a type assertion so that you don't get errors:
 *
 * const styles = createStyles({
 *   text: {
 *     [".tooltip-container:hover &" as any]: {
 *       visibility: "visible"
 *     }
 *   }
 * })
 *
 * Also accepts a function that is provided the Figma Tokens object.
 */
export function createStyles<T extends Record<string, MediaCSSProperties>>(
  stylesArg: Required<T> | StyleReducer<T>
): Readonly<Record<keyof T, SerializedStyles>> {
  const styles = typeof stylesArg === "function" ? stylesArg(SHAPED_TOKENS) : stylesArg;

  const style: Partial<Record<string, SerializedStyles>> = {};
  const classKeys = Object.keys(styles);

  for (const classKey of classKeys) {
    let styleValue = styles[classKey];

    // if an array is passed in the style object, reformat it with media query breakpoints
    if (hasArrayProperty(styleValue)) {
      styleValue = applyMediaBreakpoints(styleValue) as T[string];
    }

    style[classKey] = css(styleValue as unknown as CSSObject);
  }
  return style as Record<keyof T, SerializedStyles>;
}

// does a shallow check to see if any of the object's values are an array
const hasArrayProperty = (obj: any) => {
  return Object.keys(obj).some((key) => Array.isArray(obj[key]));
};
