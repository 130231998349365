import { ReactNode } from "react";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

const styles = createStyles({
  row: {
    padding: "16px 32px",
    "&:nth-of-type(odd)": {
      backgroundColor: colors.palette.GREY_100,
    },
  },
});

const BasicTableRow = ({ children, className }: { children: ReactNode; className?: string }) => (
  <tr css={styles.row} className={className}>
    {children}
  </tr>
);

export default BasicTableRow;
