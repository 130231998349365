export const downloadFile = (
  dataurl: string,
  fileName: string,
  contentType: string = "application/pdf"
): Promise<void> => {
  return new Promise((resolve) => {
    const req = new XMLHttpRequest();
    req.open("GET", dataurl, true);
    req.setRequestHeader("Accept", contentType);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], { type: "application/octetstream" });
      const url = window.URL || window.webkitURL;
      const link = url.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    req.onloadend = function () {
      resolve();
    };
    req.send();
  });
};
