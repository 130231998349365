import { entities, enums, types } from "@fraction/shared";
import { QueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useState } from "react";
import fraction, { ChecklistApp } from "src/api/fraction";
import { APPLICATION_KEY } from "src/hooks/useApplication";
import { useCachedQueryClient } from "src/hooks/useCache";
import { useInvites } from "src/hooks/useInvite";
import { useMutation } from "src/lib";

const modifyBrokersForApp = (
  queryClient: QueryClient,
  applicationId: string,
  callback: (brokers: entities.BrokerT[]) => entities.BrokerT[]
) => {
  queryClient.setQueriesData<types.ArrayResponse<ChecklistApp>>(
    {
      exact: false,
      queryKey: [...APPLICATION_KEY, "infinite"],
    },
    (prev) => {
      return {
        ...(prev || {}),
        data: prev?.data?.map((app) => {
          if (app?.id === applicationId) {
            return {
              ...app,
              brokers: callback(app?.brokers || []),
            };
          }
          return app;
        }),
      } as types.ArrayResponse<ChecklistApp>;
    }
  );

  queryClient.setQueryData(["application", applicationId], (prev: entities.Application) => {
    return {
      ...(prev || {}),
      brokers: callback(prev?.brokers || []),
    };
  });
};

export function useApplicationBrokers(applicationId?: string) {
  const [pendingBroker, setPendingBroker] = useState<string>();

  const queryClient = useCachedQueryClient();

  const setPrimaryBroker = useMutation({
    mutationFn: async (broker: entities.BrokerT) => {
      if (!applicationId) {
        throw new Error("Application ID is required to set a primary broker");
      }
      if (!broker.id) {
        throw new Error("Broker ID is required to set a primary broker");
      }
      try {
        setPendingBroker(broker.id);
        await fraction.setBrokerAsPrimaryForApp({ applicationId, brokerId: broker.id });

        modifyBrokersForApp(queryClient, applicationId, (brokers) =>
          brokers.map((prevBroker) => {
            return { ...prevBroker, primary: prevBroker.id === broker.id };
          })
        );
      } finally {
        setPendingBroker(undefined);
      }
    },
  });

  const addBrokerToApp = useMutation({
    mutationFn: async (broker: entities.BrokerT) => {
      if (!applicationId) {
        throw new Error("Application ID is required to add a broker to the app");
      }
      if (!broker.id) {
        throw new Error("Broker ID is required to add to app");
      }
      try {
        setPendingBroker(broker.id);
        await fraction.addBrokerToApp({ applicationId, brokerId: broker.id });
        modifyBrokersForApp(queryClient, applicationId, (brokers) => [...brokers, broker]);
      } finally {
        setPendingBroker(undefined);
      }
    },
  });

  const { sendBrokerToBrokerInvite } = useInvites();
  const inviteBrokersToApp = useMutation({
    mutationFn: async ({ emails, type }: { emails: string[]; type?: enums.InviteType }) => {
      if (!applicationId) {
        throw new Error("Application ID is required to invite brokers to the app");
      }
      const invites = await sendBrokerToBrokerInvite.mutateAsync({ emails, applicationId, type });
      modifyBrokersForApp(queryClient, applicationId, (brokers) =>
        _.uniqBy(
          [...brokers, ...invites.map((invite) => new entities.Broker({ user: invite.invitee }))],
          "user.id"
        )
      );
    },
  });

  return {
    setPrimaryBroker,
    addBrokerToApp,
    pendingBrokerId: setPrimaryBroker.isPending || addBrokerToApp.isPending ? pendingBroker : undefined,
    inviteBrokersToApp,
  };
}
