import { useEffect, useRef } from "react";

/**
 * An effect that should only be run once.
 *
 * When preCheck is true, we don't run the effect. (we wait for preCheck to be false)
 */
export default function useEffectOnce(cb: () => boolean | void, deps: any[] = [], preCheck: boolean = true) {
  const used = useRef(false);

  useEffect(() => {
    if (used.current || preCheck) {
      return;
    }
    used.current = true;
    const didItWork = cb();
    if (didItWork === false) {
      used.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
