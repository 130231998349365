import { useQuery } from "@tanstack/react-query";
import fraction, { ChecklistApp } from "src/api/fraction";
import { useAuth } from "src/auth";
import { useCachedQueryClient } from "src/hooks/useCache";

export function useApplicationsSearch(
  { enabled = true, query = "" }: { enabled?: boolean; query?: string } = {
    enabled: true,
    query: "",
  }
) {
  const queryClient = useCachedQueryClient();

  const { token } = useAuth();
  return useQuery({
    queryKey: ["applications-search", query],
    enabled: !!token && enabled && query?.length > 2,
    queryFn: async () => {
      const apps = await fraction.searchApplications(query);
      for (const app of apps) {
        queryClient.setQueryData(["application", app.id], (prev: ChecklistApp | undefined) => ({
          ...(prev || {}),
          ...app,
        }));

        queryClient.setQueryData(
          ["application", "infinite", "active", "employee"],
          (apps: { data: ChecklistApp[] }) => {
            return {
              ...apps,
              data: apps?.data?.map((prevapp) => {
                if (app.id !== prevapp.id) {
                  return prevapp;
                }
                return {
                  ...prevapp,
                  ...app,
                };
              }),
            };
          }
        );
        queryClient.setQueryData(
          ["application", "infinite", "realization", "employee"],
          (apps: { data: ChecklistApp[] }) => {
            return {
              ...apps,
              data: apps?.data?.map((prevapp) => {
                if (app.id !== prevapp.id) {
                  return prevapp;
                }
                return {
                  ...prevapp,
                  ...app,
                };
              }),
            };
          }
        );
      }
      return apps;
    },
  });
}
