export function redirect(url: string) {
  try {
    if (window.top?.location?.href) {
      window.top.location.href = url;
    } else {
      window.location.href = url;
    }
  } catch (err) {
    window.location.href = url;
  }
}
