import { TextInput } from "src/components";
import { InputModalFooter, InputModalForm, StackedInputModalFooter } from "src/components/InnerModals";
import { useZodForm } from "src/hooks";
import { createStyles } from "src/styles";
import { z } from "zod";

const styles = createStyles({
  input: {
    marginBottom: 8,
  },
});

const MICRO_DEPOSIT_SCHEMA = z
  .string()
  .transform((n) => Number(n))
  .refine((n) => n > 0, "Amount must be greater than $0.00")
  .refine((n) => n < 100, "Amount must be less than $1.00")
  .transform((n) => n.toString());

const MICRO_DEPOSITS = z.object({
  amount1: MICRO_DEPOSIT_SCHEMA,
  amount2: MICRO_DEPOSIT_SCHEMA,
});

export type MicroDeposits = z.infer<typeof MICRO_DEPOSITS>;

const MicrodepositAmountScreen = ({
  onSubmit,
  onDelete,
  onBack,
  loading,
}: {
  onSubmit: (microDeposits: MicroDeposits) => void;
  onDelete?: () => void;
  onBack?: () => void;
  loading: boolean;
}) => {
  const { register, handleSubmit } = useZodForm<MicroDeposits>({
    schema: MICRO_DEPOSITS,
  });

  const amountInputProps = {
    style: styles.input,
    autoComplete: "off",
    maskOptions: {
      mask: "$ 0.99",
      rightAlign: false,
      jitMasking: false,
    },
  };

  const footer = onBack ? (
    <InputModalFooter
      isLoading={loading}
      actions={[
        {
          text: "Back",
          action: onBack,
        },
        {
          text: "Submit",
          // @ts-ignore
          action: handleSubmit(onSubmit),
        },
      ]}
    />
  ) : (
    <StackedInputModalFooter
      isLoading={loading}
      actions={[
        {
          text: "Submit",
          // @ts-ignore
          action: handleSubmit(onSubmit),
        },
        {
          text: "Delete Account",
          action: onDelete,
          type: "ghostUrgent",
        },
      ]}
    />
  );

  return (
    <InputModalForm
      header="VERIFY MICRO-DEPOSITS"
      paragraph="To verify your account, enter two micro-deposits below. If you don't see them, they should arrive within 1-2 business days."
      footer={footer}
    >
      <TextInput label="Amount 1" {...register("amount1")} {...amountInputProps} />
      <TextInput label="Amount 2" {...register("amount2")} {...amountInputProps} />
    </InputModalForm>
  );
};

export default MicrodepositAmountScreen;
