import { Context, useContext } from "react";

/**
 * Same as useContext but will error if the context value is not defined. This usually means a context
 * provider is missing.
 */
const useContextSafe = <T>(SomeContext: Context<T | undefined>) => {
  const context = useContext(SomeContext);
  if (!context) {
    throw new Error(`${SomeContext.displayName || "Unknown context"} must be used within a provider`);
  }
  return context;
};

export default useContextSafe;
