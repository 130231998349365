import { ResponsiveLineCanvas } from "@nivo/line";
import { usePayoutsOverTimeData } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";
import HelpCircle from "src/components/HelpCircle";

import { formatters } from "@fraction/shared";

import { useMemo } from "react";
import { ChartCard } from "../ChartCard";

const MARGIN = { top: 20, right: 140, bottom: 50, left: 80 };
const SCALE = { type: "linear", min: 0.0, max: "auto" } as const;
const AXIS_BOTTOM = {
  orient: "bottom",
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "Month",
  legendPosition: "middle",
  legendOffset: 45,
} as const;

const AXIS_LEFT = {
  orient: "left",
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "% paid out",
  legendPosition: "middle",
  legendOffset: -70,
  format: formatters.number.getPercentageFromDecimal,
} as const;

const LEGENDS = [
  {
    anchor: "bottom-right",
    direction: "column",
    justify: false,
    translateX: 130,
    translateY: 0,
    itemWidth: 100,
    itemHeight: 12,
    itemsSpacing: 5,
    itemDirection: "left-to-right",
    symbolSize: 12,
    symbolShape: "circle",
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1,
        },
      },
    ],
  } as const,
];

const Info = () => (
  <HelpCircle>
    <p>See the tooltip in the previous "loan to value" chart to learn how LTV is calculated.</p>
  </HelpCircle>
);

export const Tooltip = ({
  point,
}: {
  point: {
    serieColor: string;
    serieId: string;
    data: {
      xFormatted: string;
      yFormatted: string;
      month: Date;
      paidOutCount: number;
      totalCount: number;
    };
  };
}) => {
  return (
    <div className="shadow p-2 bg-white rounded">
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b style={{ color: point.serieColor }}>{point.serieId}</b>
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Month: </b>
        {point.data.xFormatted} ({formatters.date.iso8601(new Date(point.data.month), "month")})
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Payouts: </b>
        {point.data.yFormatted} ({point.data.paidOutCount} payouts out of {point.data.totalCount} loans)
      </p>
    </div>
  );
};

export function PayoutsOverTime() {
  const { data, isLoading } = usePayoutsOverTimeData();

  const mapped = useMemo(() => {
    return data?.map((datum) => ({
      id: datum.cohort,
      data: datum.data.map((item) => ({
        x: item.monthCount,
        y: item.percentageCountPaidOut,
        month: item.month,
        paidOutCount: item.paidOutCount,
        totalCount: item.totalCount,
      })),
    }));
  }, [data]);

  const isEmpty = useMemo(
    () => mapped?.every((item) => item.data.every((datum) => datum.paidOutCount === 0)),
    [mapped]
  );

  if (isLoading) {
    return (
      <div className="col-span-2">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  if (!mapped) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={isEmpty}
      cardClassName="col-span-2"
      className="min-h-[300px]"
      header="Payouts by cohort"
      headerComponents={
        <div className="flex row gap-3 items-center flex-wrap">
          <Info />
        </div>
      }
    >
      {/* @ts-ignore */}
      <ResponsiveLineCanvas
        data={mapped}
        margin={MARGIN}
        // @ts-ignore
        yScale={SCALE}
        xScale={{ type: "point" }}
        // @ts-ignore
        yFormat={formatters.number.getPercentageFromDecimal}
        axisBottom={AXIS_BOTTOM}
        axisLeft={AXIS_LEFT}
        // @ts-ignore
        legends={LEGENDS}
        tooltip={Tooltip}
      />
    </ChartCard>
  );
}
