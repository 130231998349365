import { differenceInYears, format } from "date-fns";
import { ChevronLeft, ChevronRight, Globe2Icon, LineChartIcon, Loader, UserCircleIcon } from "lucide-react";
import { MouseEvent, useCallback } from "react";
import { Cell } from "react-table";
import fraction from "src/api/fraction";
import usePortfolioDashStore from "src/apps/PortfolioDashboard/store";
import { useDocumentsQuery, useLoanPackageQuery } from "src/apps/PostFundedDashboard/queries";
import { Badge, Chip, DrawerModal, Skeleton, Table } from "src/components";
import { DocumentRow } from "src/components/DocumentRow";
import HelpCircle from "src/components/HelpCircle";
import { Card, CardContent, CardHeader, CardTitle } from "src/components/ui/card";
import config from "src/config";
import { useCachedState } from "src/hooks/useCache";
import { Document } from "src/images";
import { useQuery } from "src/lib";
import { createStyles } from "src/styles";
import { downloadFile } from "src/utilities/file/download";
import { cn } from "src/utilities/shadcnUtils";

import { colors, enums, formatters, notUndefinedOrNull, selectors, utilities } from "@fraction/shared";
import { useAuth } from "src/auth";
import useKeyboardListener from "src/hooks/useKeyboardListener";

const styles = createStyles(() => ({
  container: {
    height: "100%",
    backgroundColor: "white",
    boxShadow: "0px 5px 10px rgba(0,0,0,0.25)",
  },
  rowStyle: { height: "unset" },
  cellStyle: { fontSize: 12, padding: 10, paddingLeft: 10 },
  appraisalRow: {
    backgroundColor: colors.palette.GREEN_150,
    "&:nth-of-type(even)": {
      backgroundColor: colors.palette.GREEN_150,
    },
  },
}));

export const useApp = ({ applicationId, enabled = true }: { applicationId?: string; enabled?: boolean }) => {
  const { user, isLoadingUser } = useAuth();
  const q = useQuery({
    queryKey: ["analyticsApp", applicationId],
    enabled:
      enabled && !!applicationId && user?.permissions?.includes(enums.Permission.SERVICING_DETAILED_DATA),
    queryFn: () => (applicationId ? fraction.getPortfolioLoan(applicationId) : undefined),
  });

  return {
    ...q,
    isLoading: q.isLoading || isLoadingUser,
  };
};

const SourceCell = ({ cell: { value, row } }: { cell: Cell }) => {
  let typeDisplay =
    formatters.enums.VALUATION_SOURCE_MAP[value as enums.PropertyValuationSource] ||
    // @ts-ignore
    formatters.enums.VALUATION_TYPE_MAP[row.original.type as enums.PropertyValuationType];

  if (
    [
      enums.PropertyValuationType.AVM_HIGH,
      enums.PropertyValuationType.AVM_MID,
      enums.PropertyValuationType.AVM_LOW,
      // @ts-ignore
    ].includes(row.original?.type)
  ) {
    // @ts-ignore
    typeDisplay = `${typeDisplay} ${row.original?.type.split("_")[1]} estimate`;
  }

  return (
    <Chip
      variant={
        value === enums.PropertyValuationSource.APPRAISER ||
        // @ts-ignore
        row.original?.type === enums.PropertyValuationType.APPRAISAL
          ? "green"
          : undefined
      }
    >
      {typeDisplay}
    </Chip>
  );
};

const getRowStyle = (row: { source?: enums.PropertyValuationSource; type?: enums.PropertyValuationType }) => {
  return row.source === "appraiser" || row.type === "appraisal" ? styles.appraisalRow : undefined;
};

const VALUATION_COLUMNS = [
  {
    Header: "Source",
    accessor: "source",
    Cell: SourceCell,
  },
  {
    Header: "Value",
    accessor: "value",
    Cell: ({ value }: Cell) => formatters.number.getCurrencyFromNumber(value),
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ value }: Cell) => formatters.date.getFormattedDate(value),
  },
];

export function PropertyInfoDrawerDetails() {
  const selectedApp = usePortfolioDashStore((state) => state.selectedApp);
  const { data: app, isLoading } = useApp({ applicationId: selectedApp, enabled: !!selectedApp });

  const negativeChange = !!app?.details?.percentChange && app?.details?.percentChange < 0;

  const users = app?.applicants?.map((a) => a?.user)?.filter(notUndefinedOrNull) || [];
  const primaryApplicant = app?.details?.primaryApplicant;
  const mostRecentCreditReport = primaryApplicant
    ? selectors.credit.selectMostRecentCreditReportFromApplicant(primaryApplicant)
    : {};
  const recentValuationChangeFromOriginalAppraisal =
    (app?.details?.mostRecentValuation?.value || 0) / (app?.details?.appraisedValuation?.value || 0) - 1;
  const ratesDifferent = app?.details?.minimumRate !== app?.details?.maximumRate;

  return (
    <>
      <Card>
        <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-1">
          <CardTitle className="text-sm font-medium">
            {formatters.property.shortFormattedAddress(app?.property || {})}
            <p>
              {app?.property?.administrativeArea}, {app?.property?.country}
            </p>
          </CardTitle>
          <Globe2Icon />
        </CardHeader>
        <CardContent>
          {app?.loan?.status === enums.LoanStatus.PAID_OUT ? (
            <Badge variant="green" size="sm">
              Loan paid out. Realization amount:{" "}
              {formatters.number.getCurrencyFromNumber(app?.loan?.realizationAmount, { digits: 2 })}
            </Badge>
          ) : (
            <div className="text-2xl font-bold">
              {formatters.number.getCurrencyFromNumber(app?.details?.balance, { digits: 0 })}
            </div>
          )}
          {app?.loan?.status === enums.LoanStatus.PAID_OUT ? null : (
            <>
              <p className="text-xs text-muted-foreground mt-0.5">
                <span className={negativeChange ? "text-red-500" : "text-green-600"}>
                  {negativeChange ? "" : "+"}
                  {formatters.number.getPercentageFromDecimal(app?.details?.percentChange)}
                </span>{" "}
                since funding{" "}
                {formatters.number.getCurrencyFromNumber(app?.details?.originalPrincipal || 0, { digits: 0 })}{" "}
                on {format(new Date(app?.loan?.startDate || new Date()), "MMMM d, yyyy")}.
              </p>
              <p className="text-xs text-muted-foreground mt-0.5">
                LTV: {formatters.number.getPercentageFromDecimal(app?.details?.ltv)}
              </p>
              <p className="text-xs text-muted-foreground mt-0.5">
                Rate: {formatters.number.getPercentageFromDecimal(app?.details?.minimumRate)}{" "}
                {!ratesDifferent
                  ? ""
                  : `to ${formatters.number.getPercentageFromDecimal(app?.details?.maximumRate)}`}
              </p>
            </>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-start gap-1 space-y-0 pb-1 flex-wrap">
          <Badge size="sm">{formatters.string.fromSnakeCase(app?.property?.type || "")}</Badge>
          <Badge size="sm">
            {
              formatters.enums.PROPERTY_USAGE_MAP[
                app?.property?.usage || enums.PropertyUsage.PRIMARY_RESIDENCE
              ]
            }
          </Badge>
          <Badge size="sm">
            Home size: {(app?.property?.homeSize || 0) + (app?.property?.basementSize || 0)} sqft
          </Badge>
          {app?.property?.lotSize ? (
            <Badge size="sm">
              Lot size: {app?.property?.lotSize}{" "}
              {formatters.string.fromSnakeCase(app?.property?.lotSizeUnit || "")?.toLowerCase()}
            </Badge>
          ) : null}
          {app?.property?.numberOfBedrooms ? (
            <Badge size="sm">Bedrooms: {app?.property?.numberOfBedrooms}</Badge>
          ) : null}
          {app?.property?.numberOfBathrooms ? (
            <Badge size="sm">Bathrooms: {app?.property?.numberOfBathrooms}</Badge>
          ) : null}
          <Badge size="sm">
            Original appraisal:{" "}
            {formatters.number.getCurrencyFromNumber(app?.details?.appraisedValuation?.value, { digits: 0 })}{" "}
            as of {format(new Date(app?.details?.appraisedValuation?.date || new Date()), "MMMM d, yyyy")}
          </Badge>
          <Badge size="sm">
            Current value:{" "}
            {formatters.number.getCurrencyFromNumber(app?.details?.mostRecentValuation?.value, { digits: 0 })}{" "}
            as of {format(new Date(app?.details?.mostRecentValuation?.date || new Date()), "MMMM d, yyyy")}
            <span
              className={cn(
                recentValuationChangeFromOriginalAppraisal < 0
                  ? "text-red-500  bg-red-100"
                  : "text-green-600 bg-green-100",
                "p-1 ml-1.5 rounded"
              )}
            >
              {recentValuationChangeFromOriginalAppraisal < 0 ? "" : "+"}
              {formatters.number.getPercentageFromDecimal(recentValuationChangeFromOriginalAppraisal)}
            </span>
          </Badge>
        </CardHeader>
        <CardContent>
          {app?.property && (
            <img
              // width={300}
              className="object-cover rounded-md"
              src={`https://maps.googleapis.com/maps/api/streetview?key=${
                config.googlePlacesAPIKey
              }&size=600x600&location=${formatters.property.formattedAddress(app?.property)}&fov=100`}
              alt="property"
            />
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-1">
          <CardTitle className="text-md font-medium">
            {formatters.user.formatNames(users)}
            {users.length > 1 ? <p>Primary: {app?.details?.primaryApplicant?.user?.firstName}</p> : null}
          </CardTitle>
          <UserCircleIcon />
        </CardHeader>
        <CardContent>
          <p className="text-sm text-muted-foreground mt-0.5">
            Home address:{" "}
            {formatters.property.formattedAddress(primaryApplicant?.homeAddress || {}, {
              shortAdmininistrativeArea: true,
            })}
          </p>
          <p className="text-sm text-muted-foreground mt-0.5">
            Use of proceeds:{" "}
            {app?.useOfFundsChoices?.map((u) => formatters.enums.USE_OF_FUNDS_MAP[u])?.join(", ")}
          </p>
          <p className="text-sm text-muted-foreground mt-0.5">
            FICO: {mostRecentCreditReport?.scores?.[0]?.value} as of{" "}
            {format(new Date(mostRecentCreditReport?.fetchedOn || new Date()), "MMMM d, yyyy")}
          </p>
          <p className="text-sm text-muted-foreground mt-0.5">
            Age: {differenceInYears(new Date(), new Date(primaryApplicant?.user?.dateOfBirth || new Date()))}
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-1">
          <CardTitle className="text-md font-medium">
            Valuations
            <HelpCircle className="inline mb-1 ml-1">
              Curious why the AVMs can have drastically different values from the appraisal? Generally, that
              is when there's been a change in the property (a new build, for example) that hasn't been
              captured by the AVMs databases yet.
            </HelpCircle>
          </CardTitle>
          <LineChartIcon />
        </CardHeader>
        <CardContent>
          {app?.property?.valuations && (
            <Table
              cellStyle={styles.cellStyle}
              rowStyle={styles.rowStyle}
              columns={VALUATION_COLUMNS}
              data={utilities.array.sortDatesBy(app?.property?.valuations, "date", "DESC")}
              loading={isLoading}
              getRowStyle={getRowStyle}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
}

export function PropertyInfoDrawer() {
  const navDrawerOpen = usePortfolioDashStore((state) => state.navDrawerOpen);
  const closeNavDrawer = usePortfolioDashStore((state) => state.closeNavDrawer);
  const selectedApp = usePortfolioDashStore((state) => state.selectedApp);
  const { isLoading } = useApp({ applicationId: selectedApp, enabled: navDrawerOpen });
  const [expanded, setExpanded] = useCachedState(false, "property-info-drawer-expanded");

  useKeyboardListener(["Escape"], () => {
    setExpanded(false);
    closeNavDrawer();
  });

  const onClickRight = useCallback((evt: MouseEvent) => {
    evt.stopPropagation();
    setExpanded((prev) => !prev);
  }, []);

  const onClickDiv = useCallback((evt: MouseEvent) => {
    evt.stopPropagation();
  }, []);

  return (
    <DrawerModal className="bg-white" variant="right" mounted={navDrawerOpen} transitionDuration={0.25}>
      <div
        onClick={onClickDiv}
        className={"space-y-2 overscroll-y-auto overflow-y-scroll pl-8 py-8 w-[500px] relative"}
        css={styles.container}
      >
        {!isLoading ? <PropertyInfoDrawerDetails /> : null}
        {isLoading ? (
          <>
            <Skeleton width="100%" height="35%" />
            <Skeleton width="100%" height="35%" />
            <Skeleton width="100%" height="35%" />
          </>
        ) : null}
        <div className="absolute top-3 w-full flex items-center justify-center">
          <button
            className="rounded-full text-xs text-gray-800 bg-gray-300 p-1 px-4 font-medium"
            onClick={closeNavDrawer}
          >
            Close drawer
          </button>
        </div>
      </div>

      <div
        onClick={onClickDiv}
        className={cn("bg-white origin-right duration-200 w-0", expanded && "w-[500px]")}
      >
        <DrawerDocuments visible={expanded} />
      </div>
      <button onClick={onClickRight} className="w-16 h-full pl-4 bg-white hover:bg-gray-100">
        {expanded ? <ChevronRight /> : <ChevronLeft />}
      </button>
    </DrawerModal>
  );
}

const DrawerDocuments = ({ visible }: { visible?: boolean }) => {
  const selectedApp = usePortfolioDashStore((state) => state.selectedApp);
  const auth = useAuth();
  const docs = useDocumentsQuery(selectedApp, {
    enabled: visible && auth?.user?.permissions?.includes(enums.Permission.SERVICING_DETAILED_DATA),
  });
  const { refetch, isFetching } = useLoanPackageQuery(selectedApp, {
    onSuccess: ({ fileURL, key }) => {
      return downloadFile(fileURL, key, "application/zip");
    },
  });

  const handleClickLoanPackage = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      refetch();
    },
    [refetch]
  );

  if (!visible) {
    return null;
  }

  return (
    <div className="p-8 pr-0 overflow-y-scroll h-[100svh]">
      <div className="flex flex-row items-center justify-between">
        <h2 className="font-serif-deck text-2xl mb-2 mt-1">Loan documents</h2>
        {!docs?.isLoading && !docs?.data?.length ? null : (
          <button disabled={isFetching || docs?.isLoading} onClick={handleClickLoanPackage}>
            <Badge className="min-w-[159px]" size="sm">
              {isFetching || docs?.isLoading ? (
                <Loader height={14} className="animate-spin" />
              ) : (
                "Download loan package"
              )}
            </Badge>
          </button>
        )}
      </div>
      {docs?.isLoading
        ? Array.from({ length: 20 }).map((_, i) => <Skeleton className="h-[60px]" key={i} />)
        : null}
      {docs?.data?.map((document) => (
        <DocumentRow key={document.id} document={document} />
      ))}
      {!docs?.isLoading && !docs?.data?.length ? (
        <div className="flex items-center justify-center flex-col h-full px-12">
          <Document className="h-[125px] w-[125px]" />
          <p className="text-gray-800 text-center text-sm">
            There are no documents stored in the system for this particular loan. Please contact your servicer
            if you have any concerns.
          </p>
        </div>
      ) : null}
    </div>
  );
};
