import { ReactNode, memo, useCallback } from "react";
import { useDeletePaymentSubscriptionMutation } from "src/apps/PostFundedDashboard/queries";
import { ModalBox } from "src/components";
import { PopupProps } from "src/components/Popup";
import { useStepper } from "src/hooks";
import recordDeleted from "src/images/record-deleted.png";
import { createStyles } from "src/styles";

import { formatters, parsers } from "@fraction/shared";

import ConfirmationModalScreen from "../../../../components/ConfirmationModalScreen";
import DeleteRecurringPaymentScreen from "./DeleteRecurringPaymentScreen";
import ViewRecurringPaymentScreen from "./ViewRecurringPaymentScreen";

export interface RecurringPaymentModalProps extends Omit<PopupProps, "children"> {
  onClose: () => void;
  isLoading?: boolean;
  subscription?: parsers.paymentSubscription.UsablePaymentSubscription;
}

enum Screen {
  VIEW_PAYMENT = "view_payment",
  DELETE_PAYMENT = "delete_payment",
  DELETE_CONFIRMATION = "delete_confirmation",
}

const DEFAULT_WIDTH = 448;

const styles = createStyles({
  confirmationModal: {
    padding: "56px 48px",
  },
  illustration: {
    height: 160,
    "@media(max-width: 600px)": {
      height: 128,
    },
  },
});

const RecurringPaymentModal = ({ isLoading, subscription, ...props }: RecurringPaymentModalProps) => {
  const { step, ...stepper } = useStepper([Screen.VIEW_PAYMENT]);
  const deleteMutation = useDeletePaymentSubscriptionMutation();

  const bankName = subscription?.bankAccount?.name ?? "N/A";
  const amount = subscription?.amount ? formatters.number.getCurrencyFromNumber(subscription?.amount) : "N/A";

  const onClose = useCallback(() => {
    stepper.reset();
    props.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.reset, props.onClose]);

  const handleAttemptDeletePayment = useCallback(() => {
    stepper.insert(Screen.DELETE_PAYMENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.insert]);

  const cancelDeleteAttempt = useCallback(() => {
    stepper.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.remove]);

  const handleConfirmDeletePayment = useCallback(() => {
    deleteMutation.mutate(subscription?.id || "", {
      onSuccess: () => stepper.push(Screen.DELETE_CONFIRMATION),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.push, deleteMutation.mutate, subscription?.id]);

  // Mapping of possible modal states, returns a component to render and optional width override in the format: [component, width]
  const modalStates: Record<Screen, [ReactNode] | [ReactNode, number]> = {
    [Screen.VIEW_PAYMENT]: [
      <ViewRecurringPaymentScreen
        onDelete={handleAttemptDeletePayment}
        bankName={bankName}
        amount={amount}
      />,
    ],
    [Screen.DELETE_PAYMENT]: [
      <DeleteRecurringPaymentScreen
        cancelDeleteAttempt={cancelDeleteAttempt}
        confirmDelete={handleConfirmDeletePayment}
        bankName={bankName}
        amount={amount}
        isLoading={deleteMutation.isPending}
      />,
    ],
    [Screen.DELETE_CONFIRMATION]: [
      <ConfirmationModalScreen
        style={styles.confirmationModal}
        header="Poof! This recurring payment has been deleted."
        text="If you’d like to add a new recurring payment, please return to the dashboard."
        onClose={onClose}
        image={<img alt="Hourglass" src={recordDeleted} css={styles.illustration} />}
      />,
    ],
  };

  const [content, width] = modalStates[step];

  return (
    <ModalBox width={width || DEFAULT_WIDTH} {...props} onClose={onClose}>
      {content}
    </ModalBox>
  );
};

export default memo(RecurringPaymentModal);
