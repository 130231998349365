import { SerializedStyles } from "@emotion/react";
import { ReactNode } from "react";
import Skeleton from "src/components/Skeleton";
import { RegularText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

interface BasicTableCellProps {
  children?: ReactNode;
  loading?: boolean;
  style?: SerializedStyles;
  textStyle?: SerializedStyles;
  variant?: "standard" | "dense";
}

const styles = createStyles({
  rowText: {
    fontSize: 18,
    color: colors.palette.GREY_800,
    "@media(max-width: 600px)": {
      fontSize: 16,
    },
  },
  standard: {
    padding: "16px 32px",
  },
  dense: {
    padding: "8px 16px",
  },
});

const BasicTableCell = ({
  children,
  loading,
  style,
  textStyle,
  variant = "standard",
}: BasicTableCellProps) => (
  <td css={[styles[variant], style]}>
    {loading ? (
      <Skeleton height={22} />
    ) : typeof children === "string" ? (
      <RegularText style={[styles.rowText, textStyle]}>{children}</RegularText>
    ) : (
      children
    )}
  </td>
);

export default BasicTableCell;
