import retry from "@fullstax/p-retry";
import { Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "src/components/Route/Loading";
// import Classifier from "src/embeds/Classifier/Classifier";
const Classifier = lazy(() => retry(() => import("src/embeds/Classifier/Classifier")));

export function AppDocumentUpload() {
  const { id } = useParams();
  return (
    <Suspense fallback={<Loading />}>
      <Classifier enabled applicationId={id} />
    </Suspense>
  );
}
