import { Link, NavLink } from "react-router-dom";
import { Logo } from "src/components/Logo";
import { navLinkStyle } from "src/components/ui/navigation-menu";

import { AccountDropdown } from "src/components/AccountDropdown";
import { createStyles } from "src/styles";

const styles = createStyles({
  container: {
    maxWidth: "100vw",
  },
});

export const ConveyancerHeader = () => {
  return (
    <div css={styles.container} className="flex items-center pl-6 pt-4 pb-4 pr-6 border-b-2 border-solid">
      <Link to="/">
        <Logo />
      </Link>
      <div className="pt-1 ml-6 overflow-x-scroll">
        <NavLink className={navLinkStyle} to="/">
          Pipeline
        </NavLink>
        <NavLink className={navLinkStyle} to="/calendar">
          Calendar
        </NavLink>
      </div>
      <div className="flex ml-auto">
        <AccountDropdown />
      </div>
    </div>
  );
};
