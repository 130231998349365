import { SerializedStyles } from "@emotion/react";
import { Children, ReactElement, Ref, cloneElement } from "react";
import { createStyles } from "src/styles";
import { cn } from "src/utilities/shadcnUtils";

import { RadioButtonProps } from "../RadioButton";

export interface RadioGroupProps<T extends string = string> {
  onChange: (val: T) => void;
  value?: T;
  children: Array<ReactElement<RadioButtonProps<T>>>;
  style?: SerializedStyles;
  focusRef?: Ref<HTMLDivElement>;
  className?: string;
}

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
});

const RadioGroup = <T extends string = string>({
  onChange,
  value,
  children,
  style,
  focusRef,
  className,
}: RadioGroupProps<T>) => (
  <div className={cn(className, "gap-3")} ref={focusRef} css={[styles.container, style]}>
    {Children.map(children, (element) =>
      cloneElement(element, {
        ...element.props,
        checked: value === element.props.value,
        onChange: () => onChange(element.props.value),
      })
    )}
  </div>
);

export default RadioGroup;
