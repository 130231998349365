import { ResizeObserver } from "@juggle/resize-observer";
import useDimensionsOriginal, { Options, Return } from "react-cool-dimensions";

export default function useDimensions<T extends HTMLElement | SVGElement | null>(
  // @ts-ignore - we actually CAN use SVGElement but it complains, but we can, so don't worry
  options?: Options<T>
  // @ts-ignore - we actually CAN use SVGElement but it complains, but we can, so don't worry
): Return<T> {
  // @ts-ignore - we actually CAN use SVGElement but it complains, but we can, so don't worry
  return useDimensionsOriginal({ ...options, polyfill: ResizeObserver });
}
