import { motion } from "framer-motion";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import HomeButton from "src/components/HomeButton";
import NavLink from "src/components/OldNavLink";
import Touchable from "src/components/Touchable";
import { useWindowSize } from "src/hooks";
import { Book, LogOut, Menu, User } from "src/icons";
import { createStyles } from "src/styles";
import getDeviceType from "src/utilities/interface/getDeviceType";

import { colors } from "@fraction/shared";

const styles = createStyles({
  drawer: {
    backgroundColor: colors.LIGHT_BACKGROUND,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 44,
    paddingBottom: 44,
    width: 264,
    maxWidth: "100%",
    flexShrink: 0,
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "1px 0px 1px rgba(164, 164, 164, 0.15)",
  },
  header: {
    marginLeft: 16,
    marginTop: 44,
  },
  logoContainer: {
    paddingLeft: 34,
  },
  buttonContainer: {
    paddingRight: 14,
    paddingTop: 5,
  },
  topLinks: {
    marginTop: 61,
  },
  menuButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

interface HeaderProps {
  phone?: boolean;
  tablet?: boolean;
  setMenuToggle: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ setMenuToggle, phone, tablet }: HeaderProps) => {
  const handleClickMenu = useCallback(() => {
    setMenuToggle((prev) => !prev);
  }, [setMenuToggle]);

  return (
    <div css={styles.menuButton}>
      {(phone || tablet) && (
        <Touchable css={styles.buttonContainer} onClick={handleClickMenu}>
          <Menu color={colors.ICON} height={20} width={20} />
        </Touchable>
      )}
      {!phone && !tablet && <div css={styles.buttonContainer} />}
      <HomeButton />
    </div>
  );
};

/**
 *
 * @deprecated
 */
const OldSidebar = () => {
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const [menuToggle, setMenuToggle] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const handleClick = useCallback(
    (href: string) => () => {
      navigate(href);
    },
    [navigate]
  );

  const inApplications = useMemo(
    () =>
      !!matchPath({ path: "/applications" }, path) ||
      !!matchPath({ path: "/application" }, path) ||
      !!matchPath({ path: "/", end: true }, path),
    [path]
  );

  const { phone, tablet } = getDeviceType(windowWidth);

  let sidebarX: number | string = 0;
  if (phone || tablet) {
    sidebarX = menuToggle ? 0 : "-120%";
  }

  return (
    <>
      {!menuToggle && (phone || tablet) && (
        <motion.div
          transition={{ duration: 0.25 }}
          animate={{ opacity: menuToggle ? 0 : 1 }}
          css={styles.header}
        >
          <Header setMenuToggle={setMenuToggle} phone={phone} tablet={tablet} />
        </motion.div>
      )}
      <motion.div animate={{ x: sidebarX }} css={[styles.drawer, { height: windowHeight }]}>
        <div>
          <Header setMenuToggle={setMenuToggle} phone={phone} tablet={tablet} />
          <div css={styles.topLinks}>
            <NavLink
              expanded={inApplications}
              active={inApplications}
              hasNested
              onClick={handleClick("/applications")}
              icon={Book}
            >
              Applications
            </NavLink>
            {inApplications && (
              <>
                <NavLink
                  active={
                    !!matchPath({ path: "/applications", end: true }, path) ||
                    !!matchPath({ path: "/", end: true }, path)
                  }
                  nested
                  onClick={handleClick("/applications")}
                >
                  Active
                </NavLink>
                <NavLink
                  active={!!matchPath({ path: "/applications/closed", end: true }, path)}
                  nested
                  onClick={handleClick("/applications/closed")}
                >
                  Closed
                </NavLink>
              </>
            )}
          </div>
        </div>
        <div>
          <NavLink
            active={!!matchPath({ path: "/user/self", end: true }, path)}
            onClick={handleClick("/user/self")}
            icon={User}
          >
            Account
          </NavLink>
          <NavLink
            active={!!matchPath({ path: "/logout", end: true }, path)}
            onClick={handleClick("/logout")}
            icon={LogOut}
          >
            Log out
          </NavLink>
        </div>
      </motion.div>
    </>
  );
};

export default OldSidebar;
