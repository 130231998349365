import { SerializedStyles } from "@emotion/react";
import { ReactNode } from "react";
import { Text } from "src/components/v1";
import { AlertI } from "src/icons";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface InfoAlertProps {
  style?: SerializedStyles;
  children?: ReactNode;
  variant?: "green" | "yellow" | "red" | "white";
  border?: boolean;
  className?: string;
}

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: "16px 24px",
    borderRadius: 6,
    width: "100%",
    boxSizing: "border-box",
  },
  text: {
    fontSize: 14,
    color: colors.ALERT_TEXT,
    marginLeft: 16,
    lineHeight: 1.5,
  },
  icon: {
    width: 28,
    flexShrink: 0,
    marginTop: 5,
  },
  green: { backgroundColor: "rgba(243, 250, 243, 0.95)" },
  yellow: { backgroundColor: "rgba(253, 242, 206, 0.95)" },
  red: { backgroundColor: "rgba(251, 222, 223, 0.95)" },
  white: { backgroundColor: "rgba(255, 255, 255, 0.92)" },
  greenBorder: {
    border: `1px solid ${colors.palette.GREEN_400}`,
  },
  yellowBorder: {
    border: `1px solid ${colors.palette.YELLOW_400}`,
  },
  redBorder: {
    border: `1px solid ${colors.palette.RED_400}`,
  },
  whiteBorder: {
    border: `1px solid ${colors.palette.GREEN_400}`,
  },
});

const InfoAlert = ({ style, children, className, variant = "green", border }: InfoAlertProps) => (
  <div
    className={className}
    css={[styles[variant], styles.container, style, border && styles[`${variant}Border`]]}
  >
    <AlertI css={styles.icon} color={colors.ALERT_TEXT} />
    <Text style={styles.text}>{children}</Text>
  </div>
);

export default InfoAlert;
