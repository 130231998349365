import { format } from "date-fns";
import { TransactionHistoryRecord } from "src/apps/PostFundedDashboard/queries";

import { calculators, enums, formatters, utilities } from "@fraction/shared";

export const selectTransactionForViewing = (
  {
    type,
    amount,
    date,
    status,
    receivingAccount,
    direction,
    periodStart,
    periodEnd,
  }: TransactionHistoryRecord,
  compoundFrequency?: enums.CompoundFrequency
) => {
  return {
    type: {
      status,
      type,
    },
    amount: formatters.number.getCurrencyFromNumber(
      utilities.transaction.getSignedAmountFromTransactionDirection({ amount, direction }),
      { explicitSign: true }
    ),
    receivingAccount,
    date: getDate({ date: new Date(date), type, compoundFrequency }),
    rawDate: new Date(date),
    periodStart,
    periodEnd,
  };
};

const getDate = ({
  type,
  date,
  compoundFrequency,
}: Pick<TransactionHistoryRecord, "date" | "type"> & { compoundFrequency?: enums.CompoundFrequency }) => {
  let formatStr = "";
  if (
    compoundFrequency === enums.CompoundFrequency.DAILY &&
    calculators.transaction.TYPES_INTEREST.includes(type)
  ) {
    formatStr = "MMMM y";
  } else {
    formatStr = "MMMM d, y";
  }

  return format(date, formatStr);
};
