import { ReactNode } from "react";
import { CardContent, CardHeader } from "src/components/ui/card";
import { HeaderText, RegularText } from "src/components/v1";
import Card from "src/components/v1/Card";
import { UnknownIllustration } from "src/images";
import { createStyles } from "src/styles";
import { cn } from "src/utilities/shadcnUtils";

const styles = createStyles(({ colors }) => ({
  header: { fontSize: 22 },
  emptyText: {
    fontSize: 20,
  },
}));

export function ChartCard(props: {
  header: string | ReactNode;
  headerComponents?: ReactNode;
  children: ReactNode;
  className?: string;
  cardClassName?: string;
  isEmpty?: boolean;
}) {
  return (
    <Card className={cn("w-full relative", props.cardClassName)}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 flex-wrap">
        <HeaderText style={styles.header}>{props.header}</HeaderText>
        {props.headerComponents}
      </CardHeader>
      <CardContent>
        <div
          className={cn("w-full lg:h-[400px] min-h-fit items-center justify-center flex", props.className)}
        >
          {props.isEmpty ? (
            <div className="flex flex-col justify-center items-center gap-4">
              <UnknownIllustration className="w-[50%] h-[50%]" />
              <RegularText style={styles.emptyText}>No data present for these filters</RegularText>
            </div>
          ) : null}
          {!props.isEmpty && props.children}
        </div>
      </CardContent>
    </Card>
  );
}
