import { SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import Skeleton from "src/components/Skeleton";
import { HeaderText, RegularText } from "src/components/v1/Text";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface CardProps {
  children: ReactNode;
  header?: ReactNode;
  subHeader?: ReactNode;
  style?: SerializedStyles | (SerializedStyles | boolean | undefined)[];
  loading?: boolean;
  className?: string;
}

const styles = createStyles({
  container: {
    borderRadius: 10,
    border: "solid 1px",
    borderColor: colors.BORDER,
    display: "inline-block",
    overflow: "visible",
  },
  headerArea: {
    padding: 32,
  },
  headerText: {
    fontWeight: 700,
    fontSize: 30,
    lineHeight: "45px",
    marginBottom: 16,
    "@media(max-width: 600px)": {
      fontSize: 28,
    },
  },
  subHeader: {
    fontSize: 18,
  },
});

const Card = ({ children, header, subHeader, style, loading, className, ...props }: CardProps) => (
  <div className={className} css={[styles.container, style]} {...props}>
    {header || subHeader ? (
      <div css={styles.headerArea}>
        {typeof header === "string" ? (
          <HeaderText style={styles.headerText}>{loading ? <Skeleton width="50%" /> : header}</HeaderText>
        ) : (
          header
        )}
        {typeof subHeader === "string" ? (
          <RegularText style={styles.subHeader}>{loading ? <Skeleton /> : subHeader}</RegularText>
        ) : (
          subHeader
        )}
      </div>
    ) : null}
    {children}
  </div>
);

export default React.memo(Card);
