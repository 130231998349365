import { colors } from "@fraction/shared";
import { Logo as LogoI } from "src/images";
import { createStyles } from "src/styles";

const styles = createStyles({
  logo: {
    width: 118,
    height: 20,
    "@media(max-width: 600px)": {
      width: 94.4,
      height: 16,
    },
  },
});

export const Logo = ({ ...props }: { className?: string }) => (
  <LogoI css={styles.logo} width={118} height={20} color={colors.palette.BLACK} {...props} />
);
