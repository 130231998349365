import { ReactNode, SyntheticEvent, useCallback, useState } from "react";
import { Checkbox, TextLink } from "src/components";
import { InputModalContent } from "src/components/InnerModals";
import { LightText, SemiBoldText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors, entities, formatters } from "@fraction/shared";

import ReviewAndEdit from "../../../../components/ReviewAndEdit";
import { PaymentScreen } from "../../hooks/usePaymentsModal";
import { PaymentFrequencyOption } from "../PrepaymentModal";

const styles = createStyles({
  reviewModalFooter: {
    marginTop: 32,
  },
  accountValueWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  accountText: {
    color: colors.TEXT,
    fontSize: 14,
    lineHeight: 1.5,
  },
  checkboxLabel: {
    fontSize: 14,
    textAlign: "left",
    color: colors.BLACK_TEXT,
    letterSpacing: "0.01em",
    lineHeight: "145%",
  },
});

const AutomaticPaymentAgreement = () => (
  <LightText style={styles.checkboxLabel}>
    By checking this box, I hereby certify that I am authorized to initiate transfers from the designated
    account and I authorize such transactions from that account each month, via automatic electronic
    withdrawal. I also certify that I have read and agree to Fraction’s{" "}
    <TextLink
      underlined
      href="https://www.fraction.com/usa/automatic-payment-agreement"
      onClick={(evt: SyntheticEvent) => {
        evt.stopPropagation();
      }}
    >
      Automatic Payment Agreement.
    </TextLink>{" "}
  </LightText>
);

export interface PaymentReviewScreenProps {
  bankAccount?: entities.BankAccount;
  header: string;
  paragraph: string;
  amountLabel: string;
  onContinue: () => void;
  onBack: () => void;
  handleGoToScreen: (goTo: PaymentScreen) => void;
  isLoading?: boolean;
  formValues: Record<string, string>;
  subHeader?: ReactNode;
  children?: ReactNode;
}

const PaymentReviewScreen = ({
  header,
  paragraph,
  amountLabel,
  isLoading,
  bankAccount,
  onContinue,
  onBack,
  handleGoToScreen,
  formValues,
  subHeader,
  children,
}: PaymentReviewScreenProps) => {
  const goToAccountSelect = useCallback(() => {
    handleGoToScreen?.(PaymentScreen.SELECT_ACCOUNT);
  }, [handleGoToScreen]);

  const [paymentAgreementChecked, setPaymentAgreementChecked] = useState(false);

  /*
   * Recurring payments are subject to additional terms and conditions. We need to make sure the user has agreed
   * to our Automatic Payment Agreement before allowing them to submit.
   */
  const isMonthlyPayment = formValues.frequency === PaymentFrequencyOption.MONTHLY;

  return (
    <InputModalContent
      nextDisabled={!paymentAgreementChecked && isMonthlyPayment}
      header={header}
      paragraph={paragraph}
      footerStyle={styles.reviewModalFooter}
      isLoading={isLoading}
      actions={[
        {
          text: "Back",
          action: onBack,
        },
        {
          text: "Submit",
          action: onContinue,
        },
      ]}
    >
      {subHeader}
      <ReviewAndEdit
        label="Bank Account"
        value={
          <div css={styles.accountValueWrapper}>
            <SemiBoldText style={styles.accountText}>{bankAccount?.name}</SemiBoldText>
            <SemiBoldText style={styles.accountText}>
              XXXX{bankAccount?.accountNumberLast4Digits}
            </SemiBoldText>
          </div>
        }
        onClickEdit={goToAccountSelect}
      />
      <ReviewAndEdit
        label={amountLabel}
        value={`$${formatters.number.numberWithCommas(formValues.amount, { digits: 2 })}`}
        onClickEdit={onBack}
      />
      {children}
      {isMonthlyPayment && (
        <Checkbox checked={paymentAgreementChecked} onChange={setPaymentAgreementChecked}>
          <AutomaticPaymentAgreement />
        </Checkbox>
      )}
    </InputModalContent>
  );
};

export default PaymentReviewScreen;
