import { formatters } from "@fraction/shared";
import { useCallback, useState } from "react";
import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { useTimers } from "src/hooks/useTimers";
import { useMutation } from "src/lib";

export function usePhoneVerify({
  onSuccess,
  onInvalidCode,
  channel: defaultChannel,
}: { onSuccess?: () => void; onInvalidCode?: () => void; channel?: string } = {}) {
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [phone, setPhone] = useState<string | undefined>(defaultChannel);
  const [code, setCode] = useState<string | undefined>(undefined);
  const [invalidMessage, setInvalidMessage] = useState<undefined | string>(undefined);
  const { channels, refetchUser } = useAuth();

  const { countdown, setCounterInterval, startCountdown: startCountdownTimer } = useTimers();

  const stopCountdown = useCallback(() => {
    setCounterInterval(undefined);
  }, []);

  const stopCountdownCleanup = useCallback(() => {
    return stopCountdown;
  }, [stopCountdown]);

  const resumeCountdown = useCallback(() => {
    startCountdownTimer(() => startVerification.mutateAsync({ to: phone }), countdown);
  }, [countdown]);

  const startCountdown = useCallback(() => {
    startCountdownTimer(() => startVerification.mutateAsync({ to: phone }));
  }, []);

  const startVerification = useMutation({
    mutationFn: async ({ to, verifyExisting }: { to?: string; verifyExisting?: boolean } = {}) => {
      setInvalidMessage(undefined);
      if (!verifyExisting) {
        if (
          channels?.phones?.find(
            (channel) =>
              formatters.phone.formatStrippedPhoneNumber(channel.value?.toLowerCase(), {
                stripInternationalCode: true,
              }) ===
              formatters.phone.formatStrippedPhoneNumber(phone?.toLowerCase(), {
                stripInternationalCode: true,
              })
          )
        ) {
          setInvalidMessage("You already have this phone added to your account.");
          return { ok: false };
        }
      }

      setInvalidMessage(undefined);
      if (to && to !== phone) {
        setPhone(to);
      }
      const verifyPhone = to || phone;
      if (!verifyPhone) {
        throw new Error("No phone");
      }
      setPhoneVerified(false);
      await fraction.startPhoneVerification(verifyPhone);
      startCountdown();
      return { ok: true };
    },
  });

  const checkVerification = useMutation({
    mutationFn: ({ channel: phone_, code: code_ }: { channel?: string; code?: string } = {}) => {
      const verifyPhone = phone_ || phone;
      const verifyCode = code_ || code;
      if (!verifyPhone) {
        throw new Error("No phone");
      }
      if (!verifyCode) {
        throw new Error("No code");
      }
      stopCountdown();
      return fraction.checkPhoneVerification(verifyPhone, verifyCode);
    },
    onSuccess: ({ status }) => {
      if (status === "approved") {
        setPhoneVerified(true);
        onSuccess?.();
        stopCountdown();
        refetchUser?.();
      } else {
        resumeCountdown();
        onInvalidCode?.();
        setInvalidMessage("Invalid code");
      }
    },
  });

  return {
    countdown,
    stopCountdown,
    stopCountdownCleanup,
    startVerification,
    checkVerification,
    channel: phone,
    setChannel: setPhone,
    verified: phoneVerified,
    setVerified: setPhoneVerified,
    invalidMessage,
    code,
    setCode,
    isPending: startVerification.isPending || checkVerification.isPending,
    acceptable:
      !!phone &&
      formatters.phone.formatStrippedPhoneNumber(formatters.string.removeEverythingButNumbers(phone), {
        stripInternationalCode: true,
      })?.length === 10,
  };
}
