import { subMonths } from "date-fns";
import { ChecklistApp } from "src/api/fraction";
import { ApplicationFlowState } from "src/apps/AppFlow/screens/root/Layout";
import { MortgageInformation, Property } from "src/types/Applicant";
import { EmploymentDetailsValue } from "src/types/Application";

import {
  entities,
  enums,
  formatters,
  notUndefinedOrNull,
  parsers,
  plainToInstance,
  schemas,
  selectors,
} from "@fraction/shared";

export function selectChecklistApplication(application: any): ChecklistApp {
  if (!application) {
    return application;
  }
  const app: any = plainToInstance(entities.Application, application);
  app.checklists = application.checklists;
  app.syntheticStatus = selectors.application.selectSyntheticApplicationStatus(application);
  return app;
}

export function selectApplication(application: schemas.Application): entities.Application {
  return plainToInstance(entities.Application, {
    ...application,
    applicants: application.applicants?.map((applicant) =>
      plainToInstance(entities.Applicant, {
        ...applicant,
        primaryResidence: applicant.properties?.find(
          (property) => property.usage === enums.PropertyUsage.PRIMARY_RESIDENCE
        ),
      })
    ),
    taskRoot: selectors.applicationTask.selectApplicationTaskTree(application.tasks),
    taskGroupings: application.tasks,
  });
}

export function selectApplicationCountry(country: string = "CA"): {
  country: enums.Country;
  isUSA: boolean;
  isCanada: boolean;
} {
  const trueCountry = country || enums.Country.CANADA;
  // If they aren't in US or CA, just show them CA stuff
  const countryToShow = ["CA", "US"].includes(trueCountry)
    ? (trueCountry as enums.Country)
    : enums.Country.CANADA;
  const isUSA = countryToShow === enums.Country.USA;
  const isCanada = countryToShow === enums.Country.CANADA;
  return {
    country: countryToShow,
    isUSA,
    isCanada,
  };
}

export function selectApplicationLocation(property?: Property) {
  const country = selectApplicationCountry(property?.country);
  const administrativeArea = property?.administrativeArea;
  const isWashington =
    administrativeArea ===
    selectors.property.ISO_TO_ADMIN_AREA[selectors.property.ADMINISTRATIVE_AREAS.US.Washington];
  const isColorado =
    administrativeArea ===
    selectors.property.ISO_TO_ADMIN_AREA[selectors.property.ADMINISTRATIVE_AREAS.US.Colorado];
  return { ...country, administrativeArea, isWashington, isColorado };
}

export interface ViewableApplication {
  applicants: string;
  lastModified: string;
  address: string;
  amount: string;
  status: string;
  alert: boolean;
}

export const selectLocation = (
  location: entities.Property | Property | undefined
): parsers.location.Location | undefined => {
  if (location === undefined) {
    return;
  }

  return {
    id: location.id,
    googlePlaceId: location.googlePlaceId,
    country: location.country,
    administrativeArea: location.administrativeArea,
    subAdministrativeArea: location.subAdministrativeArea,
    locality: location.locality,
    dependentLocality: location.dependentLocality,
    neighborhood: location.neighborhood,
    postalCode: location.postalCode,
    thoroughfare: location.thoroughfare,
    premise: location.premise,
    geo:
      location.geo?.lat && location.geo?.lng
        ? {
            lat: location.geo?.lat,
            lng: location.geo?.lng,
          }
        : undefined,
  };
};

const selectMortgageDefaults = (defaults?: boolean) => (mortgage: MortgageInformation) =>
  selectMortgage(mortgage, defaults);

export const selectMortgage = (
  mortgage: MortgageInformation,
  defaults: boolean | number = true
): parsers.application.Debt => {
  // we allow "defaults" to be a number so that we can pass selectMortgage in a mapping function
  if (typeof defaults === "number") {
    defaults = true;
  }
  return {
    id: mortgage.id,
    balance:
      formatters.number.getCents(mortgage.mortgageBalanceAndLender?.amount || ifDefault(defaults, 0)) ||
      undefined,
    paymentSize:
      formatters.number.getCents(mortgage.mortgagePayments?.paymentAmount || ifDefault(defaults, 0)) ||
      undefined,
    paymentFrequency: mortgage.mortgagePayments?.frequency,
    lenderName: mortgage.mortgageBalanceAndLender?.lenderName || ifDefault(defaults, "Unknown"),
    type: mortgage.mortgageRateAndType?.mortgageType,
    rate: Number(mortgage.mortgageRateAndType?.currentRate || ifDefault(defaults, 0)) || undefined,
    rateType: mortgage.mortgageRateAndType?.rateType,
    ownerEmails: mortgage.owners?.map((owner) => owner.email?.email).filter(notUndefinedOrNull),
  };
};

const ifDefault = (toUse: boolean, val: any) => (toUse ? val : undefined);

/**
 * Select a "FullApplication" object from the frontend state. This can actually be a "partial" application,
 * which uses the "FullApplication" as well.
 * @param application: The state from the frontend
 * @param defaults: Whether to use defaults or just undefined. Generally,
 *                  if we are submitting to /full, we will use defaults, but if it's a partial, we will not.
 */
export const selectFullApplication = (
  application: ApplicationFlowState,
  { defaults = true }: { defaults?: boolean } = { defaults: true }
): parsers.application.FullApplication => ({
  id: application.id,
  amountRequested: application.amountRequested || ifDefault(defaults, 0),
  termLength: application.term?.length,
  useOfFundsChoices: application.useOfFunds?.choices || ifDefault(defaults, []),
  useOfFundsDescription: application.useOfFunds?.description || ifDefault(defaults, ""),
  creditScoreGuess: application.creditScoreGuess,
  property: {
    location: selectLocation(application.propertyLocation),
    mortgages:
      application.primaryPropertyMortgages?.map(selectMortgageDefaults(defaults)) || ifDefault(defaults, []),
    ownerEmails:
      application.applicants?.map(({ email }) => email?.email).filter(notUndefinedOrNull) ||
      ifDefault(defaults, []),
    value: formatters.number.getCents(application.propertyValue || ifDefault(defaults, 0)) || undefined,
    type: application.propertyType?.propertyType,
    tenantsInCommon: application.propertyType?.tenantsInCommon || ifDefault(defaults, false),
    usage: application.propertyType?.propertyUsage,
    // don't collect this for property being mortgaged currently (todo - should we?)
    planToSellDuringProcess: false,
    rentalIncome: undefined,
  },
  applicants: application.applicants?.map((applicant) => ({
    id: applicant.id,
    userId: applicant.userId,
    name: applicant.name,
    email: applicant.email?.email,
    optedInToEmailsAt: applicant.email?.optedIn || ifDefault(defaults, null),
    phone: applicant.phoneAndContactMethodPreferences?.phone,
    contactMethodPreferences: applicant.phoneAndContactMethodPreferences?.contactMethodPreferences,
    numberOfDependents: applicant.dependents?.length || ifDefault(defaults, 0),
    // If there is only one applicant and they specified the property usage as primary residence,
    // we set that to their home address automatically.
    homeAddress:
      application.applicants?.length === 1 &&
      application.propertyType?.propertyUsage === enums.PropertyUsage.PRIMARY_RESIDENCE
        ? selectLocation(application.propertyLocation)
        : selectLocation(applicant.address),
    race: applicant.race,
    ethnicity: applicant.ethnicity,
    sex: applicant.sex,
    militaryStatus: applicant.militaryStatus,
    maritalStatus: applicant.maritalStatus?.maritalStatus,
    socialXNumber: applicant.socialXNumber,
    residencyStatus: applicant.residencyStatus,
    employmentStatus: applicant.employmentStatus,
    bankruptcyStatus: applicant.bankruptcyStatus,
    timeSinceBankruptcy: applicant.timeSinceBankruptcy,
    dateOfBirth: applicant.dateOfBirth,
    connectedToName: applicant.waCivilUnionAddendum?.connectedToName,
    waCivilUnionAddendum: applicant.waCivilUnionAddendum?.waCivilUnionAddendum,
    priority: applicant.priority,
  })),
  assets: application.assets?.items?.map((asset) => ({
    id: asset.id,
    type: asset.assetDetails?.type,
    value: formatters.number.getCents(asset.assetDetails?.value || ifDefault(defaults, 0)) || undefined,
    description: asset.assetDetails?.description,
    ownerEmails:
      asset.owners?.map(({ email }) => email?.email).filter(notUndefinedOrNull) || ifDefault(defaults, []),
  })),
  debts: application.debts?.items?.map((debt) => ({
    id: debt.id,
    payOffEveryMonth: debt.debtBalanceAndPayments?.payOffEveryMonth,
    type: debt.debtTypeAndLenderName?.type,
    balance:
      formatters.number.getCents(debt.debtBalanceAndPayments?.balance || ifDefault(defaults, 0)) || undefined,
    paymentSize:
      formatters.number.getCents(debt.debtBalanceAndPayments?.paymentSize || ifDefault(defaults, 0)) ||
      undefined,
    paymentFrequency: debt.debtBalanceAndPayments?.frequency,
    lenderName: debt.debtTypeAndLenderName?.lenderName,
    ownerEmails:
      debt.owners?.map(({ email }) => email?.email).filter(notUndefinedOrNull) || ifDefault(defaults, []),
  })),
  otherOwnedProperties: application.otherOwnedProperties?.items.map((property) => ({
    id: property.id,
    location: selectLocation(property.propertyLocation),
    mortgages: property.mortgages?.items?.map(selectMortgageDefaults(defaults)) || ifDefault(defaults, []),
    ownerEmails:
      property.owners?.map(({ email }) => email?.email).filter(notUndefinedOrNull) || ifDefault(defaults, []),
    value: formatters.number.getCents(property.propertyValue || ifDefault(defaults, 0)) || undefined,
    usage: property?.propertyType?.propertyUsage,
    type: property?.propertyType?.propertyType,
    planToSellDuringProcess: property.planToSellDuringProcess,
    rentalIncome: property.rentalIncome ? formatters.number.getCents(property.rentalIncome) : undefined,
  })),
  incomeSources: application.incomeSources?.items.map((income) => ({
    id: income.id,
    type: income.incomeSourceType,
    // treating the start date as "started on at least this day"
    startDate:
      (income.incomeDetails as EmploymentDetailsValue)?.startedLessThan6MonthsAgo === undefined
        ? undefined
        : (income.incomeDetails as EmploymentDetailsValue).startedLessThan6MonthsAgo
        ? new Date()
        : subMonths(new Date(), 7),
    ownerEmails:
      income.owners?.map(({ email }) => email?.email).filter(notUndefinedOrNull) || ifDefault(defaults, []),
    employerName: income.employerDetails?.name,
    employerLocation: selectLocation(income.employerDetails?.location),
    income: formatters.number.getCents(income.incomeDetails?.income || ifDefault(defaults, 0)) || undefined,
    jobTitle: (income.incomeDetails as EmploymentDetailsValue)?.jobTitle,
    employmentType: (income.incomeDetails as EmploymentDetailsValue)?.employmentType,
  })),
});
