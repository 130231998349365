import { constants, colors, entities, enums, formatters } from "@fraction/shared";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import {
  CircleCheck,
  CircleSlash,
  CircleX,
  Expand,
  Loader,
  LockIcon,
  RotateCcwIcon,
  RotateCwIcon,
  Shrink,
  TrashIcon,
  UnlockIcon,
} from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import fraction from "src/api/fraction";
import { DestructiveButton, PrimaryButton, WarningButton } from "src/components/Button";
import Chip from "src/components/Chip";
import ModalBox from "src/components/ModalBox";
import ScrollablePDFViewer from "src/components/ScrollablePDFViewer";
import { SearchableOptions } from "src/components/SearchableOptions";
import Skeleton from "src/components/Skeleton";
import { Text } from "src/components/Text";
import Tooltip from "src/components/Tooltip";
import ChangeDocumentStatusForm from "src/embeds/DocumentApprovalTool/components/ChangeDocumentStatusForm";
import { StatusChip } from "src/embeds/DocumentApprovalTool/components/StatusChip";
import useDocumentTypes from "src/hooks/useDocumentTypes";
import useModal from "src/hooks/useModal";
import { useMutation } from "src/lib";
import { createStyles } from "src/styles";
import { cn } from "src/utilities/shadcnUtils";

const styles = createStyles({
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  container: {
    position: "relative",
    width: "100%",
    // sometimes there's flickering at 100% height, idk why
    height: "102%",
    maxWidth: 600,
    "@media(max-width: 600px)": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  noMaxWidth: {
    maxWidth: "unset",
  },
  pdf: {
    width: "100%",
    height: "85%",
    "@media(max-width: 600px)": {
      height: "75%",
    },
  },
  bottomActionBar: {
    display: "flex",
    width: "100%",
    backgroundColor: colors.palette.GREY_200,
    padding: 10,
    height: 50,
    justifyContent: "space-between",
    alignItems: "center",
  },
  topActionBar: {
    display: "flex",
    width: "100%",
    minHeight: 30,
    flexDirection: "column",
    padding: 10,
  },
  row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowTogether: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  chip: {
    width: "fit-content",
    height: "fit-content",
  },
  mr8: {
    marginRight: 8,
  },
  mr6: {
    marginRight: 6,
  },
  mt4: {
    marginTop: 4,
  },
  noOverflow: { textOverflow: "ellipsis", overflowX: "hidden", whiteSpace: "nowrap" },
});

export function DocumentApproval({
  file,
  href,
  loading: loading_,
  onSubmit,
  onDelete,
  className,
}: {
  file?: entities.UploadedFile;
  href?: string;
  loading: boolean;
  onSubmit?: (opts: { id: string; status: enums.ApplicationTaskApprovalStatus; notes?: string }) => void;
  onDelete?: (id: string) => void;
  className?: string;
}) {
  const [formVisible, setFormVisible] = useState(false);
  const [lockRotation, setLockRotation] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [targetStatus, setTargetStatus] = useState<enums.ApplicationTaskApprovalStatus | undefined>();
  const { documentTypes } = useDocumentTypes();
  const queryClient = useQueryClient();

  const { mutate, isPending: mutateIsLoading } = useMutation({
    mutationFn: async ({
      status,
      notes,
    }: { status: enums.ApplicationTaskApprovalStatus; notes?: string }) => {
      if (!file?.id) {
        return;
      }
      onSubmit?.({ id: file?.id, status, notes });
      await fraction.setDocumentStatusAndNotes({ id: file?.id, status, notes });
    },
  });

  const handleModalBoxClose = useCallback(() => {
    setFormVisible(false);
  }, []);

  const changeDocumentType = useMutation({
    mutationFn: async (typeId: string) => {
      if (!file?.id) {
        return;
      }
      queryClient.setQueriesData(
        { exact: false, queryKey: ["documents", "needs-approval"] },
        (prev: entities.UploadedFile[] | undefined) =>
          prev?.map((item) => {
            if (item.id === file?.id) {
              return {
                ...item,
                type: documentTypes.find((type) => type.id === typeId),
                typeId,
              } as entities.UploadedFile;
            }
            return item;
          })
      );

      await fraction.setDocumentType({ id: file?.id, typeId });
    },
  });

  const handleSubmit = useCallback(
    ({ status, notes }: { status: enums.ApplicationTaskApprovalStatus; notes: string }) => {
      setFormVisible(false);
      if (!status || !file?.id) {
        return;
      }
      mutate({ status, notes });
    },
    [file?.id, mutate]
  );

  const submitDelete = useMutation({
    mutationFn: async () => {
      if (!file?.id) {
        return;
      }
      onDelete?.(file?.id);
      await fraction.deleteFile(file?.id);
    },
  });

  const handleApprove = useCallback(() => {
    setTargetStatus(enums.ApplicationTaskApprovalStatus.APPROVED);
    setFormVisible(true);
  }, []);

  const handleReject = useCallback(() => {
    setTargetStatus(enums.ApplicationTaskApprovalStatus.REJECTED);
    setFormVisible(true);
  }, []);

  const handleNeedsWork = useCallback(() => {
    setTargetStatus(enums.ApplicationTaskApprovalStatus.NEEDS_WORK);
    setFormVisible(true);
  }, []);

  const { showModal, closeModal } = useModal();
  const handleDelete = useCallback(() => {
    showModal({
      message: "Are you sure you want to delete the file?",
      actions: [
        { text: "Cancel", type: "inverse", action: closeModal },
        {
          text: "Delete",
          type: "urgent",
          action: () => {
            closeModal();
            submitDelete.mutateAsync();
          },
        },
      ],
    });
  }, [submitDelete.mutateAsync, closeModal, showModal]);

  const handleClickExpand = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const [rotate, setRotate] = useState(0);

  const handlePressRotateCw = useCallback(() => {
    setRotate((prev) => (prev + 90) % 360);
  }, []);

  const handlePressRotateCcw = useCallback(() => {
    setRotate((prev) => (prev - 90) % 360);
  }, []);

  const handleLockRotation = useCallback(() => {
    // if we are unlocking rotation, set the rotation back to the default
    if (lockRotation) {
      setRotate(0);
    }
    setLockRotation((prev) => !prev);
  }, [lockRotation]);

  useEffect(() => {
    if (!lockRotation) {
      setRotate(0);
    }
  }, [href]);

  const loading = loading_ || mutateIsLoading;

  return (
    <main className={cn("h-[100svh] w-full", className)} css={styles.main}>
      <ModalBox open={formVisible} onClose={handleModalBoxClose}>
        <ChangeDocumentStatusForm
          onSubmit={handleSubmit}
          previousStatus={file?.status}
          targetStatus={targetStatus}
          defaultNotes={file?.notes}
        />
      </ModalBox>
      <div
        css={[
          styles.container,
          !className && [90, 270].includes(Math.abs(rotate)) && { maxWidth: "50%", height: "fit-content" },
          expanded && styles.noMaxWidth,
        ]}
      >
        <div className="rounded-t bg-gray-50" css={styles.topActionBar}>
          <div css={styles.row}>
            <div css={styles.rowTogether}>
              <SearchableOptions
                onChange={changeDocumentType.mutateAsync}
                value={file?.typeId || ""}
                labelProps={{
                  variant: "base",
                }}
                options={documentTypes?.map((type) => ({
                  value: type.id,
                  search: type?.fullName,
                  label: (
                    <Chip
                      key={type?.id}
                      className="hover:brightness-95 cursor-pointer flex flex-row"
                      style={styles.chip}
                    >
                      {type?.fullName}{" "}
                      {changeDocumentType.isPending ? (
                        <Loader className="inline h-3 w-3 align-middle text-gray-600 animate-spin" />
                      ) : null}
                    </Chip>
                  ),
                }))}
              />
              {file?.applicants?.find((applicant) => applicant?.user?.firstName) ? (
                <>
                  <Text style={styles.mr6} weight="medium" size="2xs">
                    for
                  </Text>
                  {file?.applicants?.map((applicant) => (
                    <Chip key={applicant.id} style={styles.chip}>
                      {formatters.user.userName(applicant?.user)}
                    </Chip>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
            {file?.s3Document?.uploadedDate && (
              <Chip variant="green" style={styles.chip}>
                Uploaded on: {format(new Date(file?.s3Document?.uploadedDate), "MM/dd/yyyy")}
              </Chip>
            )}
          </div>
          <div css={[styles.row, styles.mt4]}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${constants.RETOOL_APP_VIEW}#eid=${file?.applicationId}`}
            >
              <Chip style={styles.chip}>Retool</Chip>
            </a>
            {file?.uploader && (
              <Chip variant="base" style={styles.chip}>
                Uploaded by: {formatters.user.userName(file?.uploader)}
              </Chip>
            )}
          </div>
        </div>
        {href ? (
          <ScrollablePDFViewer rotate={rotate} zoom newTab={false} style={styles.pdf} file={href}>
            <div className="absolute top-2 right-4 flex gap-2">
              {rotate !== 0 ? (
                <Tooltip text={lockRotation ? "Unlock rotation" : "Lock rotation"} white>
                  <button onClick={handleLockRotation} className="text-gray-700 bg-white/50 p-1 rounded-md">
                    {lockRotation ? <UnlockIcon className="h-6 w-6" /> : <LockIcon className="h-6 w-6" />}
                  </button>
                </Tooltip>
              ) : null}
              <Tooltip text="Rotate counterclockwise" white>
                <button onClick={handlePressRotateCcw} className="text-gray-700 bg-white/50 p-1 rounded-md">
                  <RotateCcwIcon className="h-6 w-6" />
                </button>
              </Tooltip>
              <Tooltip text="Rotate clockwise" white>
                <button onClick={handlePressRotateCw} className="text-gray-700 bg-white/50 p-1 rounded-md">
                  <RotateCwIcon className="h-6 w-6" />
                </button>
              </Tooltip>
              <Tooltip text="Toggle fullscreen" white>
                <button onClick={handleClickExpand} className="text-gray-700 bg-white/50 p-1 rounded-md">
                  {expanded ? <Shrink className="h-6 w-6" /> : <Expand className="h-6 w-6" />}
                </button>
              </Tooltip>
            </div>
          </ScrollablePDFViewer>
        ) : (
          <Skeleton className="w-full h-[680px]" />
        )}

        <div className="rounded-b" css={styles.bottomActionBar}>
          {!loading && file?.status && <StatusChip style={styles.noOverflow} status={file?.status} />}
          {loading && <Chip style={styles.chip}>Loading...</Chip>}
          <div css={styles.rowTogether}>
            <DestructiveButton
              loading={submitDelete.isPending}
              onClick={handleDelete}
              style={styles.mr8}
              size="small"
            >
              <TrashIcon className="w-3 h-3 inline aligm-middle mb-0.5" /> Delete
            </DestructiveButton>
            <DestructiveButton
              loading={mutateIsLoading}
              onClick={handleReject}
              style={styles.mr8}
              size="small"
            >
              <CircleX className="w-3 h-3 inline aligm-middle mb-0.5" /> Reject
            </DestructiveButton>
            <WarningButton
              loading={mutateIsLoading}
              onClick={handleNeedsWork}
              style={[styles.mr8, styles.noOverflow]}
              size="small"
            >
              <CircleSlash className="w-3 h-3 inline aligm-middle mb-0.5" /> Needs work
            </WarningButton>
            <PrimaryButton loading={mutateIsLoading} onClick={handleApprove} size="small">
              <CircleCheck className="w-3 h-3 inline aligm-middle mb-0.5" /> Approve
            </PrimaryButton>
          </div>
        </div>
      </div>
    </main>
  );
}
