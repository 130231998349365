import {
  ApplicationTaskType,
  ComplexApplicationFieldChecker,
  DocumentType,
  SimpleApplicationFieldChecker,
  SyntheticApplicationStatus,
  UI_APPLICATION_STATUS_ORDER,
  entities,
  enums,
  formatters,
  getNextApplicationStatus,
  isStatusAfterStatus,
  selectors,
  types,
} from "@fraction/shared";
import { useQueryClient } from "@tanstack/react-query";
import { addBusinessDays, addDays, formatDistanceToNowStrict } from "date-fns";
import _ from "lodash";
import { Check } from "lucide-react";
import { ReactNode, forwardRef } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import fraction from "src/api/fraction";
import { getCheckName } from "src/apps/LOS/utils";
import { SalesSelector } from "src/components/SalesSelector";
import Tooltip from "src/components/Tooltip";
import { Badge } from "src/components/ui/badge";
import { useApplicationLocal } from "src/hooks/useApplication";
import crackedGlass from "src/images/cracked-glass-white.webp";
import allDocsReceived from "src/images/status-all-docs-received.webp";
import appraisalApproved from "src/images/status-appraisal-approved.webp";
import appraisalNeedsToBeOrdered from "src/images/status-appraisal-to-order.webp";
import appraisalToReview from "src/images/status-appraisal-to-review.webp";
import needBrokerageOnboarding from "src/images/status-broker-onboarding.webp";
import commitmentLetterSigned from "src/images/status-commitment-letter-signed.webp";
import needEmailAction from "src/images/status-need-email-action.webp";
import appraisalNeedsToBePaidFor from "src/images/status-need-to-pay-for-appraisal.webp";
import needIncome from "src/images/status-needs-income.webp";
import kycRequired from "src/images/status-needs-kyc.webp";
import productReviewRequired from "src/images/status-needs-quiz.webp";
import productFollowUpRequired from "src/images/status-quiz-failed.webp";
import ratesLocked from "src/images/status-rates-locked.webp";
import stale from "src/images/status-stale.webp";
import waitingForAppraisal from "src/images/status-waiting-for-appraisal.webp";
import { useMutation } from "src/lib";
import { cn } from "src/utilities/shadcnUtils";
import { Bookmark } from "./Bookmark";

export interface PipelineDealCardProps {
  id: string;
  style?: any;
  statusOverride?: enums.ApplicationStatus;
  syntheticStatusOverride?: SyntheticApplicationStatus;
  setShowModal?: (opts: { id: string; status: enums.ApplicationStatus }) => void;
  children?: ReactNode;
  className?: string;
  hideStatus?: boolean;
  showSalesPerson?: boolean;
  salesOptions?: entities.InternalEmployee[];
}

const checkTaskStatus = (
  checklist: types.ChecklistResult[] | undefined,
  field: SimpleApplicationFieldChecker | ComplexApplicationFieldChecker | DocumentType | ApplicationTaskType,
  status: enums.ApplicationTaskStatus,
  {
    needDocumentUrl,
    approval,
  }: { needDocumentUrl?: boolean; approval?: enums.ApplicationTaskApprovalStatus } = {}
) => {
  return checklist?.find((check) => {
    if (check.type === "simpleField" || check.type === "complexField") {
      return check.field === field && check.status === status;
    } else if (check.type === "document") {
      return (
        check.document === field &&
        check.status === status &&
        (!needDocumentUrl || check.documentUrl) &&
        (!approval || check.approval === approval)
      );
    } else if (check.type === "task") {
      return check.task === field && check.status === status;
    }
    return false;
  });
};

const StyledIcon = ({
  sizeClassName,
  src,
  warning,
  error,
  className,
}: { sizeClassName?: string; src: string; warning?: boolean; error?: boolean; className?: string }) => {
  return (
    <div className={cn("relative rounded-sm", sizeClassName, className)}>
      <img
        className={cn(
          "rounded-sm border border-amber-500",
          sizeClassName,
          warning && "border-amber-700",
          error && "border-red"
        )}
        src={src}
      />
      {warning || error ? (
        <>
          <div
            className={cn(
              "absolute top-0 left-0 right-0 bottom-0 rounded-sm",
              sizeClassName,
              warning && "border-yellow-550 bg-yellow-550 mix-blend-multiply",
              error && "border-red-500 bg-red-500 mix-blend-multiply"
            )}
          />
          <img src={crackedGlass} className={cn("absolute top-0 left-0 right-0 bottom-0", sizeClassName)} />
        </>
      ) : null}
    </div>
  );
};

const StatusIcon = ({
  src,
  title,
  description,
  warning,
  error,
}: {
  src: string;
  title: ReactNode;
  description: ReactNode;
  warning?: boolean;
  error?: boolean;
}) => {
  return (
    <Tooltip
      white
      text={
        <div className="flex flex-1 h-full w-full bg-white flex-row gap-2 max-w-[350px]">
          <div>
            <StyledIcon
              src={src}
              sizeClassName="w-12 h-12 min-w-12 min-h-12"
              error={error}
              warning={warning}
            />
          </div>
          <div className="text-sm text-black">
            <b>{title}</b>
            <p className="leading-snug">{description}</p>
          </div>
        </div>
      }
    >
      <div>
        <StyledIcon src={src} sizeClassName="w-7 h-7 min-w-7 min-h-7" error={error} warning={warning} />
      </div>
    </Tooltip>
  );
};

export const PipelineDealCard = forwardRef<HTMLDivElement, PipelineDealCardProps>(
  (
    {
      id,
      statusOverride,
      syntheticStatusOverride,
      setShowModal,
      className,
      hideStatus,
      showSalesPerson,
      salesOptions,
      ...props
    },
    ref
  ) => {
    const { data: app, isLoading } = useApplicationLocal({ id });

    const queryClient = useQueryClient();
    const mutateAssignedSales = useMutation({
      mutationFn: async (employee?: entities.InternalEmployee) => {
        if (!employee?.id) {
          return;
        }
        const role = employee?.types?.find(
          (x) => x === enums.InternalEmployeeType.BDM || x === enums.InternalEmployeeType.INSIDE_SALES
        );
        if (!role) {
          throw new Error("Employee must be a BDM or Inside Sales");
        }
        await fraction.updateApplicationEmployee({ employeeId: employee.id, applicationId: id, role });
        // set the new employee in the cache
        queryClient.setQueryData(["application", id], (old: entities.Application) => {
          old.internalEmployees = [
            ...(old.internalEmployees?.filter((x) => {
              return !(
                x.type === enums.InternalEmployeeType.BDM ||
                x.type === enums.InternalEmployeeType.INSIDE_SALES
              );
            }) || []),
            employee,
          ];
          return old;
        });
      },
    });

    if (isLoading) {
      return <Skeleton width="100%" className="h-32" />;
    }

    if (!app) {
      return null;
    }

    const salesPerson = selectors.application.selectSalesPerson(app);
    const status = statusOverride || app?.status;
    const nextStatus = statusOverride
      ? statusOverride
      : status
      ? getNextApplicationStatus(status, UI_APPLICATION_STATUS_ORDER)
      : undefined;
    const nextStatusChecklist = nextStatus ? app?.checklists?.[nextStatus] : undefined;

    const closeDate =
      app.status === enums.ApplicationStatus.FUNDED
        ? app?.closeDate
        : app?.advanceDate || app?.estimatedCloseDate;

    return (
      <Link to={`/app/${id}`}>
        <div
          ref={ref}
          className={cn("bg-white p-3 px-4 rounded-md border-gray-300 border relative", className)}
          {...props}
        >
          <div className="flex flexwrap flex-row gap-1 mb-1">
            {checkTaskStatus(
              app?.checklists?.funded,
              SimpleApplicationFieldChecker.APPRAISAL_PAID_FOR_DATE,
              enums.ApplicationTaskStatus.TODO
            ) &&
            !checkTaskStatus(
              app?.checklists?.funded,
              DocumentType.APPRAISAL_REPORT,
              enums.ApplicationTaskStatus.COMPLETE
            ) ? (
              <StatusIcon
                src={appraisalNeedsToBePaidFor}
                description="The client still needs to use the Stripe link to pay for the appraisal."
                title="Appraisal not yet paid for"
              />
            ) : null}
            {!checkTaskStatus(
              app?.checklists?.funded,
              DocumentType.SIGNED_COMMITMENT_LETTER,
              enums.ApplicationTaskStatus.TODO
            ) ? (
              <StatusIcon
                src={commitmentLetterSigned}
                description="The commitment letter has been signed and uploaded."
                title="Commitment letter signed"
              />
            ) : null}
            {checkTaskStatus(
              app?.checklists?.funded,
              SimpleApplicationFieldChecker.APPRAISAL_ORDERED_DATE,
              enums.ApplicationTaskStatus.TODO
            ) &&
            checkTaskStatus(
              app?.checklists?.funded,
              SimpleApplicationFieldChecker.APPRAISAL_PAID_FOR_DATE,
              enums.ApplicationTaskStatus.COMPLETE
            ) ? (
              <StatusIcon
                src={appraisalNeedsToBeOrdered}
                description="We have received payment for the appraisal, but it has not yet been ordered."
                title="Appraisal paid but not ordered"
              />
            ) : null}
            {app?.appraisalOrderedDate &&
            checkTaskStatus(
              app?.checklists?.funded,
              SimpleApplicationFieldChecker.APPRAISAL_ORDERED_DATE,
              enums.ApplicationTaskStatus.COMPLETE
            ) &&
            checkTaskStatus(
              app?.checklists?.funded,
              SimpleApplicationFieldChecker.APPRAISAL_PAID_FOR_DATE,
              enums.ApplicationTaskStatus.COMPLETE
            ) &&
            checkTaskStatus(
              app?.checklists?.funded,
              DocumentType.APPRAISAL_REPORT,
              enums.ApplicationTaskStatus.TODO
            ) ? (
              <StatusIcon
                src={waitingForAppraisal}
                description={
                  <p>
                    We ordered the appraisal on <b>{formatters.date.formatDate(app?.appraisalOrderedDate)}</b>{" "}
                    and are waiting for the report to come back.
                  </p>
                }
                title="Waiting for appraisal report"
              />
            ) : null}
            {checkTaskStatus(
              app?.checklists?.funded,
              DocumentType.APPRAISAL_REPORT,
              enums.ApplicationTaskStatus.COMPLETE,
              { needDocumentUrl: true, approval: enums.ApplicationTaskApprovalStatus.REQUESTED }
            ) ? (
              <StatusIcon
                src={appraisalToReview}
                description="The appraisal report has been submitted and is pending review"
                title="Appraisal report pending review"
              />
            ) : null}
            {checkTaskStatus(
              app?.checklists?.funded,
              DocumentType.APPRAISAL_REPORT,
              enums.ApplicationTaskStatus.COMPLETE,
              { needDocumentUrl: true, approval: enums.ApplicationTaskApprovalStatus.APPROVED }
            ) ? (
              <StatusIcon
                src={appraisalApproved}
                warning={
                  selectors.application.selectAppraisal(app)?.date &&
                  addDays(selectors.application.selectAppraisal(app)?.date!, 40) < new Date()
                }
                error={
                  selectors.application.selectAppraisal(app)?.date &&
                  addDays(selectors.application.selectAppraisal(app)?.date!, 60) < new Date()
                }
                description={
                  <p>
                    The appraisal report has been submitted with a date of{" "}
                    <b>
                      {selectors.application.selectAppraisal(app)?.date
                        ? formatters.date.formatDate(selectors.application.selectAppraisal(app)?.date!)
                        : "N/A"}
                    </b>{" "}
                    and approved with a value of{" "}
                    <b>
                      {formatters.number.getCurrencyFromNumber(
                        selectors.application.selectAppraisal(app)?.value
                      )}
                    </b>
                    .
                    {selectors.application.selectAppraisal(app)?.date ? (
                      <>
                        {" "}
                        It{" "}
                        {addDays(selectors.application.selectAppraisal(app)?.date!, 60) < new Date() ? (
                          "expired on "
                        ) : (
                          <>
                            will expire in{" "}
                            {formatters.date.formatDistanceToNowCalendarDays(
                              addDays(selectors.application.selectAppraisal(app)?.date!, 60)
                            )}
                            , on{" "}
                          </>
                        )}
                        <b>
                          {formatters.date.formatDate(
                            addDays(selectors.application.selectAppraisal(app)?.date!, 60)
                          )}
                          .
                        </b>
                      </>
                    ) : null}
                  </p>
                }
                title={`Appraisal report approved${
                  selectors.application.selectAppraisal(app)?.date &&
                  addDays(selectors.application.selectAppraisal(app)?.date!, 40) < new Date()
                    ? " but is expired"
                    : addDays(selectors.application.selectAppraisal(app)?.date!, 40) < new Date()
                    ? " but expiring soon"
                    : ""
                }`}
              />
            ) : null}
            {checkTaskStatus(
              app?.checklists?.funded,
              ApplicationTaskType.PRODUCT_REVIEW_COMPLETED,
              enums.ApplicationTaskStatus.TODO
            ) ? (
              <StatusIcon
                src={productReviewRequired}
                description="The client has not yet completed the product review quiz"
                title="Product review quiz is required"
              />
            ) : null}
            {checkTaskStatus(app?.checklists?.funded, DocumentType.KYC, enums.ApplicationTaskStatus.TODO) ? (
              <StatusIcon
                src={kycRequired}
                description="The client has not yet completed the KYC process"
                title="KYC is required"
              />
            ) : null}
            {checkTaskStatus(
              app?.checklists?.funded,
              ApplicationTaskType.FOLLOWED_UP_WITH_FAILED_PRODUCT_REVIEW,
              enums.ApplicationTaskStatus.TODO
            ) ? (
              <StatusIcon
                src={productFollowUpRequired}
                description="The client failed the quiz and we need to follow up with them about it."
                title="Product quiz failed: follow up required"
              />
            ) : null}
            {checkTaskStatus(
              app?.checklists?.funded,
              ComplexApplicationFieldChecker.BROKERAGE_ONBOARDED,
              enums.ApplicationTaskStatus.TODO
            ) ? (
              <StatusIcon
                src={needBrokerageOnboarding}
                description="We still need to onboard the brokerage."
                title="Need brokerage onboarding"
              />
            ) : null}
            {checkTaskStatus(
              app?.checklists?.funded,
              DocumentType.INCOME,
              enums.ApplicationTaskStatus.TODO
            ) ? (
              <StatusIcon
                src={needIncome}
                description="We need income docs for at least one applicant still."
                title="Need income docs"
              />
            ) : null}
            {nextStatusChecklist?.every((item) => item.type !== "document" || item.ok) ? (
              <StatusIcon
                src={allDocsReceived}
                description="All documents that are needed for the next stage have been uploaded."
                title="All docs for stage received"
              />
            ) : null}
            {app.mostRecentActionTakenDate &&
            addBusinessDays(app.mostRecentActionTakenDate, 5) < new Date() ? (
              <StatusIcon
                src={stale}
                description="We haven't had any changes to the app in the last 5 business days."
                title="App is stale"
              />
            ) : null}
            {app.mostRecentEmailReceivedDate &&
            app.mostRecentActionTakenDate &&
            app.mostRecentActionTakenDate < app.mostRecentEmailReceivedDate ? (
              <StatusIcon
                src={needEmailAction}
                description="We have received an email about this app and haven't yet taken an action on the app."
                title="No action taken since email received"
              />
            ) : null}
            {app.ratesLockedInAt ? (
              <StatusIcon
                src={ratesLocked}
                warning={addDays(app.ratesLockedInAt, 40) < new Date()}
                error={addDays(app.ratesLockedInAt, 60) < new Date()}
                description={
                  <p>
                    Rates locked in on <b>{formatters.date.formatDate(app.ratesLockedInAt)}</b>,{" "}
                    {addDays(app.ratesLockedInAt, 60) < new Date() ? "expired" : "expiring"} on{" "}
                    <b className={addDays(app.ratesLockedInAt, 60) < new Date() ? "text-red" : undefined}>
                      {formatters.date.formatDate(addDays(app.ratesLockedInAt, 60))}
                    </b>
                    {addDays(app.ratesLockedInAt, 60) > new Date()
                      ? ` (${formatters.date.formatDistanceToNowCalendarDays(
                          addDays(app.ratesLockedInAt, 60)
                        )})`
                      : null}
                    .
                  </p>
                }
                title={
                  addDays(app.ratesLockedInAt, 60) < new Date()
                    ? "Rates expired"
                    : addDays(app.ratesLockedInAt, 40) < new Date()
                    ? "Rates expiring soon"
                    : "Rates locked in"
                }
              />
            ) : null}
          </div>
          <button
            className="float-right"
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              if (nextStatus) {
                setShowModal?.({ id, status: nextStatus });
              }
            }}
          >
            {hideStatus ? null : nextStatusChecklist?.length &&
              nextStatusChecklist?.filter((check) => !check.ok)?.length ? (
              <Tooltip
                text={nextStatusChecklist
                  ?.filter((check) => !check.ok)
                  .map((check) => _.startCase(getCheckName(check)))
                  .join(", ")}
              >
                <Badge variant={statusOverride ? "destructive" : "secondary"} className="rounded-xl flex">
                  {nextStatusChecklist?.filter((check) => !check.ok)?.length}
                </Badge>
              </Tooltip>
            ) : (
              <Badge variant="success" className="rounded-xl float-right">
                <Check className="w-4 h-4 text-white stroke-2" />
              </Badge>
            )}
          </button>

          <div className="flex flex-row gap-2 relative">
            {showSalesPerson ? (
              <SalesSelector
                className="h-[35px] min-h-[35px] w-[35px] min-w-[35px]"
                value={salesPerson}
                options={salesOptions || []}
                onChange={mutateAssignedSales.mutateAsync}
              />
            ) : null}
            <div>
              <p className="font-bold">{formatters.application.applicantNames(app)}</p>

              <p>{formatters.property.shortFormattedAddress(app.property)}</p>
              <div className="flex flex-row justify-between items-center">
                <p className="text-base">
                  {formatters.number.getCurrencyFromNumber(
                    selectors.application.selectApplicationAmount(app),
                    {
                      digits: 0,
                    }
                  )}
                </p>
              </div>
            </div>
          </div>

          {app.status &&
          closeDate &&
          isStatusAfterStatus(app.status, enums.ApplicationStatus.CONVEYANCER_ENGAGED, true) ? (
            <Badge
              className={cn(
                "h-5 rounded-full text-xs bg-gray-400 text-black",
                app.loan?.payoutReceivedDate ||
                  app.loan?.expectedPayoutReceivedDate ||
                  (closeDate < new Date() && "bg-red-350")
              )}
            >
              {app.loan?.payoutReceivedDate
                ? "Payout:"
                : app.loan?.expectedPayoutReceivedDate
                ? "Est. Payout:"
                : app.status === enums.ApplicationStatus.FUNDED
                ? "Closed:"
                : !app.advanceDate
                ? "Est. Closing"
                : " Closing:"}{" "}
              {formatters.date.formatDate(
                app.loan?.payoutReceivedDate || app.loan?.expectedPayoutReceivedDate || closeDate
              )}{" "}
              (
              {app.status === enums.ApplicationStatus.FUNDED
                ? formatDistanceToNowStrict(
                    app.loan?.payoutReceivedDate || app.loan?.expectedPayoutReceivedDate || closeDate,
                    { addSuffix: true }
                  )
                : formatters.date.formatDistanceToNowStrictCalendarDays(closeDate, { addSuffix: true })}
              )
            </Badge>
          ) : null}
          {app.loan?.portfolio ? (
            <Badge className="rounded-full">{`${_.startCase(app.loan.portfolio)} (${
              app.property?.country
            })`}</Badge>
          ) : null}
          <hr className="w-full border border-gray-300 my-2" />
          <div className="gap-1 flex-wrap flex flex-row justify-between">
            <div className="gap-1 flex-wrap flex flex-row w-[80%]">
              {app.brokers?.map((broker) => (
                <Badge key={broker.id} className="rounded-xl h-fit">
                  {formatters.user.userName(broker.user)}
                </Badge>
              ))}
            </div>
            {app.property?.administrativeArea ? (
              <Badge className="h-5 rounded-full text-xs bg-gray-400">
                {selectors.property.convertToISOAdminArea(app.property?.administrativeArea)}
              </Badge>
            ) : null}
          </div>

          {props.children ? (
            <>
              <hr className="w-full border border-gray-300 my-2" />
              {props.children}
            </>
          ) : null}
          {app?.dealPurpose === enums.DealPurpose.PURCHASE ? (
            <Bookmark title="Deal is a purchase" className="absolute top-[-4px] left-2 text-red w-5" />
          ) : null}
        </div>
      </Link>
    );
  }
);
