import { useCallback } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { cn } from "src/utilities/shadcnUtils";

export function TermLengthSelect({
  onValueChange,
  className,
  value,
  showOnlyOurProducts,
  hideLabel,
}: {
  value?: number;
  onValueChange?: (value: number) => void;
  className?: string;
  showOnlyOurProducts?: boolean;
  hideLabel?: boolean;
}) {
  const onChange = useCallback(
    (str: string) => {
      onValueChange?.(parseInt(str, 10));
    },
    [onValueChange]
  );

  return (
    <Select value={value?.toString()} onValueChange={onChange}>
      <SelectTrigger className={cn("col-span-2", className)}>
        <SelectValue placeholder="Select a term length" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {!hideLabel ? <SelectLabel>Term Length</SelectLabel> : null}
          {!showOnlyOurProducts ? <SelectItem value="1">1 year</SelectItem> : null}
          {!showOnlyOurProducts ? <SelectItem value="2">2 years</SelectItem> : null}
          <SelectItem value="3">3 years</SelectItem>
          <SelectItem value="4">4 years</SelectItem>
          <SelectItem value="5">5 years</SelectItem>
          {!showOnlyOurProducts ? <SelectItem value="10">10 years</SelectItem> : null}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
