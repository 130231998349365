import { ReactNode, Ref, memo, useCallback, useMemo } from "react";
import Select, {
  ControlProps,
  MultiValue,
  Props as SelectProps,
  SingleValue,
  StylesConfig,
  components,
} from "react-select";
import AnimatedLabel from "src/components/AnimatedLabel";
import { useLabel, useMergedRef } from "src/hooks";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";
import { cn } from "src/utilities/shadcnUtils";

export interface SelectorOption<T> {
  value: T;
  label: string;
  icon?: ReactNode;
}

export enum Variant {
  STANDARD = "standard",
  V2 = "v2",
}

export const V2_RADIUS = 6;

export interface DropdownSelectorProps<T> extends Omit<SelectProps, "onChange"> {
  error?: string;
  disabled?: boolean;
  options: SelectorOption<T>[];
  value?: T;
  label?: string;
  onChange: (value: T) => void;
  placeholder?: string;
  focusRef?: Ref<HTMLInputElement>;
  variant?: Variant | string;
}

const optionBackground = ({ isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }) => {
  if (isFocused) {
    return colors.DROPDOWN_HIGHLIGHTED;
  }
  if (isSelected) {
    return colors.DROPDOWN_SELECTED;
  }
  return "white";
};

const CONTROL_STYLES = {
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 14,
  boxShadow: "none",
  fontSize: 16,
  height: 51,
  fontFamily: "Montserrat",
  color: colors.TEXT,
  ":focus": {
    borderColor: colors.SELECTED,
    outlineColor: colors.LINES,
  },
  ":hover": {
    borderColor: "unset",
  },
  backgroundColor: "transparent",
};

const MENU_STYLES = {
  marginTop: 0,
  marginLeft: 1,
  width: "calc(100% - 2px)",
};

const Iconified = ({ className, ...props }: any) => (
  <div className={cn("flex flex-row items-center", className)}>
    {props.data.icon ? <props.data.icon className="w-8 h-8 mr-2" alt={props.data.label} /> : null}
    <p className="pt-1">{props.data.label}</p>
  </div>
);

const IconOption = (props: any) => (
  <components.Option {...props}>
    <Iconified {...props} />
  </components.Option>
);

const IconSingleValue = (props: any) => (
  <components.SingleValue {...props}>
    <Iconified {...props} className="bg-white" />
  </components.SingleValue>
);

const SELECT_STYLES: StylesConfig = {
  container: (styles) => ({
    ...styles,
    // to match TextInput's 18px spacer + 7px margin
    marginBottom: 25,
    width: "100%",
  }),
  control: (styles, { menuIsOpen }) => ({
    ...styles,
    ...CONTROL_STYLES,
    borderRadius: menuIsOpen ? 0 : "0px 0px 0px 10px",
    borderColor: menuIsOpen ? colors.SELECTED : colors.LINES,
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: "Montserrat",
    fontSize: 16,
    "& input": {
      font: "inherit",
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontFamily: "Montserrat",
    fontSize: 16,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 24,
    fontFamily: "Montserrat",
    fontSize: 16,
    backgroundColor: optionBackground({ isFocused, isSelected }),
    borderColor: colors.LINES,
    color: colors.TEXT,
    ":active": {
      ...styles[":active"],
      color: "white",
      backgroundColor: colors.DROPDOWN_SELECTED,
    },
    "&:last-child": {
      borderRadius: "0px 0px 0px 5px",
    },
  }),
  menu: (styles) => ({
    ...styles,
    ...MENU_STYLES,
    borderRadius: "0px 0px 0px 10px",
  }),
  menuList: (styles) => ({
    ...styles,
    marginTop: 0,
    borderRadius: "0px 0px 0px 10px",
    "::-webkit-scrollbar": {
      WebkitAppearance: "none",
      width: "7px",
    },

    "::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "rgba(0, 0, 0, .5)",
      boxShadow: "0 0 1px rgba(255, 255, 255, .5)",
    },
  }),
};

const styles = createStyles({
  container: {
    position: "relative",
    width: "100%",
  },
  disabled: {
    opacity: 0.4,
  },
});

const Control = ({ focusRef, ...props }: ControlProps & { focusRef?: Ref<HTMLInputElement> }) => {
  const refCallback = useCallback(
    (r: any) => {
      if (focusRef) {
        // @ts-ignore
        focusRef.current = r;
      }
    },
    [focusRef]
  );

  const refs = useMergedRef(props.innerRef, refCallback);
  return <components.Control {...props} innerRef={refs} />;
};

const DropdownSelector = ({
  onChange,
  options,
  value,
  placeholder,
  focusRef,
  isMulti,
  disabled,
  label,
  isSearchable = false,
  variant = Variant.V2,
  className,
  ...props
}: DropdownSelectorProps<any>) => {
  const [inputProps, labelProps] = useLabel({
    disabled,
    label,
    placeholder,
    value: value || props.defaultValue,
    error: props.error,
  });
  const handleChange = useCallback(
    (v: MultiValue<any> | SingleValue<any>) => {
      if (Array.isArray(v)) {
        onChange(v.map((vals) => vals.value));
      } else {
        onChange(v?.value);
      }
    },
    [onChange]
  );

  let values;
  if (Array.isArray(value)) {
    values = value.map((val) => options.find((option) => option.value === val)).filter(Boolean);
  } else {
    values = options.find((option) => option.value === value);
  }

  const modifiedSelectStyles = useMemo(
    () => ({
      ...SELECT_STYLES,
      control: (defaultStyles: any, { menuIsOpen }: any) => ({
        ...defaultStyles,
        ...CONTROL_STYLES,
        borderTopStyle: label ? "none" : "solid",
        borderRadius:
          variant === Variant.STANDARD
            ? menuIsOpen
              ? 0
              : "0px 0px 0px 10px"
            : menuIsOpen
            ? `${V2_RADIUS}px ${V2_RADIUS}px 0px 0px`
            : V2_RADIUS,
        borderColor: labelProps.borderColor,
        ":hover": {
          borderColor: labelProps.borderColor,
        },
      }),
      menu: (defaultStyles: any) => ({
        ...defaultStyles,
        ...MENU_STYLES,
        borderRadius:
          variant === Variant.STANDARD ? "0px 0px 0px 10px" : `0px 0px ${V2_RADIUS}px ${V2_RADIUS}px`,
      }),
    }),
    [label, labelProps.borderColor]
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const InnerControl = useCallback((p: ControlProps) => <Control focusRef={focusRef} {...p} />, [focusRef]);
  const otherBorderRadii = variant === Variant.V2 ? V2_RADIUS : 0;

  return (
    <div
      css={[styles.container, disabled && styles.disabled]}
      onMouseEnter={inputProps.onMouseEnter}
      onMouseLeave={inputProps.onMouseLeave}
      className={className}
    >
      {label && (
        <AnimatedLabel {...labelProps} borderRadius={otherBorderRadii}>
          {label}
        </AnimatedLabel>
      )}
      <Select
        isSearchable={isSearchable}
        isDisabled={disabled}
        isMulti={isMulti}
        styles={modifiedSelectStyles}
        value={values}
        options={options}
        onChange={handleChange}
        {...props}
        {...inputProps}
        components={{
          Control: InnerControl,
          Option: IconOption,
          SingleValue: IconSingleValue,
        }}
        placeholder={inputProps.placeholder ? inputProps.placeholder : " "}
      />
    </div>
  );
};

export default memo(DropdownSelector);
