import { RouterNavLink } from "src/components";
import { AutoDeposit2 } from "src/icons";

export const NavItems = ({ onClick }: { onClick?: () => any }) => {
  return (
    <>
      <RouterNavLink onClick={onClick} icon={AutoDeposit2} to="/">
        Loan overview
      </RouterNavLink>
    </>
  );
};
