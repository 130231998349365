import { SerializedStyles } from "@emotion/react";
import { CSSProperties, memo } from "react";
import FinePrint from "src/components/FinePrint";

export interface LoanAmountDisclosureProps {
  style?: SerializedStyles | CSSProperties;
}

const LoanAmountDisclosure = ({ style }: LoanAmountDisclosureProps) => (
  <FinePrint style={style}>
    Actual loan amount may vary based on verified home value. Fraction bases the maximum lending amount on the
    home value as determined by a third-party appraisal.
  </FinePrint>
);

export default memo(LoanAmountDisclosure);
