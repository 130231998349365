import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Loading } from "src/components";

import App from "./App";
import "./setupWorkers";
import "./utilities/initialSetup";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <Suspense fallback={<Loading />}>
      <RouterProvider router={App} />
    </Suspense>
  </StrictMode>
);
