import { SerializedStyles } from "@emotion/react";
import { motion } from "framer-motion";
import { memo, useMemo } from "react";
import Touchable from "src/components/Touchable";
import { MediumText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface RadioProps {
  checked?: boolean;
  size?: number;
  style?: SerializedStyles;
}

export interface RadioButtonProps<T extends string = string> {
  checked?: boolean;
  onChange?: () => void;
  style?: SerializedStyles;
  label: string;
  // Value is used by parent RadioGroup
  value: T;
}

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  filled: {
    backgroundColor: colors.RADIO_SET,
    borderRadius: "50%",
  },
  wrapper: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "solid",
    borderColor: colors.RADIO_UNSET,
    borderWidth: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  wrapperChecked: {
    borderColor: colors.RADIO_SET,
  },
  label: {
    marginLeft: 8,
    fontSize: 16,
  },
  touchable: {
    padding: 0,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
});

const getRadioSize = (size = 16) =>
  createStyles({
    wrapperSize: {
      width: size,
      height: size,
    },
    fillerSize: {
      width: size * 0.75,
      height: size * 0.75,
    },
  });

// make the radio icon independently usable
export const Radio = memo(({ checked = false, size, style }: RadioProps) => {
  const radioSize = useMemo(() => getRadioSize(size), [size]);
  return (
    <div css={[styles.wrapper, checked && styles.wrapperChecked, radioSize.wrapperSize, style]}>
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: checked ? 1 : 0,
        }}
        css={[styles.filled, radioSize.fillerSize]}
      />
    </div>
  );
});

const RadioButton = <T extends string = string>({ checked, onChange, style, label }: RadioButtonProps<T>) => (
  <Touchable css={[styles.touchable, style]} onClick={onChange}>
    <div css={[styles.container]}>
      <Radio checked={checked} />
      <MediumText style={styles.label}>{label}</MediumText>
    </div>
  </Touchable>
);

export default memo(RadioButton);
