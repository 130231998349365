import { PopupProps } from "src/components/Popup";
import { createStyles } from "src/styles";

import ConfirmationModalScreen from "../../../../components/ConfirmationModalScreen";

const styles = createStyles({
  confirmationModal: {
    padding: "56px 48px",
  },
});

const DepositConfirmationScreen = ({ onClose }: Omit<PopupProps, "children">) => (
  <ConfirmationModalScreen
    style={styles.confirmationModal}
    header="Hooray, your bank account is verified!"
    text="You’re ready to start making prepayments and draws on your balance."
    onClose={onClose}
  />
);

export default DepositConfirmationScreen;
