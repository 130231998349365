import { MouseEvent, useCallback, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";

export function ProvinceSelect({
  onValueChange,
  className,
  value,
}: {
  value?: string;
  onValueChange?: (value: string | null) => void;
  className?: string;
}) {
  const [key, setKey] = useState(+new Date());
  const handleClear = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onValueChange?.(null);
      setKey(+new Date());
    },
    [onValueChange]
  );

  return (
    <Select key={key} value={value} onValueChange={onValueChange}>
      <SelectTrigger className={className}>
        <SelectValue placeholder="Select a province" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Province</SelectLabel>
          <SelectItem value="British Columbia">British Columbia</SelectItem>
          <SelectItem value="Ontario">Ontario</SelectItem>
          <SelectItem value="Alberta">Alberta</SelectItem>
        </SelectGroup>
        <SelectSeparator />
        <button className="w-full px-2 text-sm font-semibold" onClick={handleClear}>
          Clear
        </button>
      </SelectContent>
    </Select>
  );
}
