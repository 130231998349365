import { constants, calculators, formatters, notUndefinedOrNull } from "@fraction/shared";
import _ from "lodash";

export function computeCostCalculatorRows({
  termLength = constants.DEFAULT_TERM_LENGTH,
  rate,
  loanAmount,
  appreciationRate,
  homeValue,
}: {
  termLength?: number;
  loanAmount: number | string;
  rate: number;
  appreciationRate?: number;
  homeValue?: number;
}) {
  return _.range(0, termLength + 1)
    .map((year) => {
      if (rate === undefined) {
        return;
      }
      const loanAmountNumber = formatters.number.getNumberFromString(loanAmount);
      const rateNumber = rate < 1 ? rate : rate / 100;

      const apr =
        year === 0
          ? "N/A"
          : calculators.rates
              .calculateAPR({
                interestRate: rateNumber,
                termLength: year,
                totalFees:
                  loanAmountNumber * constants.ORIGINATION_FEE +
                  constants.CONVEYANCING_FEE / 100 +
                  constants.TITLE_INSURANCE_FEE / 100,
                principal: loanAmountNumber,
              })
              .toDecimalPlaces(4)
              .toNumber();

      const loanValueAfterTerm = calculators.rates
        .calculateLoanValueAfterTerm({
          annualRate: rateNumber,
          termLength: year,
          totalLoanValue: loanAmountNumber,
        })
        .toDecimalPlaces(2)
        .toNumber();

      const homeValueAtYear = homeValue
        ? calculators.rates
            .calculateLoanValueAfterTerm({
              annualRate: appreciationRate ?? 0.06,
              termLength: year,
              totalLoanValue: homeValue,
            })
            .toDecimalPlaces(2)
            .toNumber()
        : 0;

      return {
        year,
        apr,
        totalAccruedInterest: loanValueAfterTerm - loanAmountNumber,
        totalLoanBalance: loanValueAfterTerm,
        homeValueAtYear,
      };
    })
    .filter(notUndefinedOrNull);
}
