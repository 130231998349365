import { SerializedStyles } from "@emotion/react";
import {
  AnimationControls,
  MotionValue,
  TargetAndTransition,
  Transition,
  VariantLabels,
  motion,
} from "framer-motion";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface CircleProgressProps {
  style?: SerializedStyles;
  color?: string;
  fill?: string;
  opacity?: number | MotionValue;
  pathLength?: number | MotionValue;
  rotate?: number | MotionValue;
  transition?: Transition;
  animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean;
}

const styles = createStyles({
  indicator: {
    width: 70,
    height: 70,
  },
});

const CircleProgress = ({
  style,
  color = colors.palette.GREEN,
  fill = "none",
  opacity,
  pathLength,
  rotate = 90,
  transition,
  animate,
}: CircleProgressProps) => (
  <svg height={70} width={70} css={[styles.indicator, style]} viewBox="-2.5 -2.5 44.5 44.5">
    <path d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0" fill={fill} strokeWidth="0" />
    <path
      d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
      fill="none"
      strokeWidth="4"
      stroke={color}
      opacity={0.28}
    />
    <motion.path
      fill="none"
      strokeWidth="4"
      stroke={color}
      d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
      // @ts-ignore
      initial={
        // we want to set initial only when it's a number
        // This is because if we set initial when it's a MotionValue,
        // it breaks the initial and looks completely filled on Safari (dont ask why)
        {
          pathLength: typeof pathLength === "number" ? pathLength : undefined,
          opacity: typeof opacity === "number" ? opacity : undefined,
        }
      }
      style={{
        rotate,
        scaleX: -1, // Reverse direction of line animation
        pathLength,
        opacity,
      }}
      animate={animate}
      transition={transition}
    />
  </svg>
);

export default CircleProgress;
