// @ts-ignore
import googleTagManager from "@analytics/google-tag-manager";
import Analytics from "analytics";
import twitterPlugin from "src/analytics/twitter";
import config from "src/config";
import { AnalyticsEvent } from "./events";

const TWITTER_CONVERSION_EVENT_MAP = {
  [AnalyticsEvent.FULL_APP_SUBMITTED]: "tw-obqm0-ojv7a",
  [AnalyticsEvent.PARTIAL_APP_SUBMITTED]: "tw-obqm0-ojv8v",
  [AnalyticsEvent.SUBMITTED]: "tw-obqm0-ojv8w",
};

const analytics = Analytics({
  app: "fraction",
  plugins: config.isProd
    ? [
        googleTagManager({
          containerId: "GTM-5W833XG",
        }),
        twitterPlugin({
          twitterKey: "obqm0",
          conversionEventMap: TWITTER_CONVERSION_EVENT_MAP,
        }),
      ]
    : [],
  debug: !config.isProd,
});

export async function trackEvent(event: AnalyticsEvent, data?: any, context?: any) {
  return analytics.track(event, { data, context, event: event });
}

export default analytics;
