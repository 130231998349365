import { format } from "date-fns";
import { memo, useCallback } from "react";
import NoDataScreen from "src/apps/PostFundedDashboard/components/NoDataScreen";
import { DashboardPage } from "src/components";
import FileTable, { FileRow } from "src/components/FileTable";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";
import { Mailbox } from "src/images";
import { createStyles } from "src/styles";
import { downloadFile } from "src/utilities/file/download";

import { entities } from "@fraction/shared";

import { useLoanQuery, useMonthlyStatementQuery } from "../../queries";

const styles = createStyles(() => ({
  noDataIllustration: {
    height: 100,
    width: 100,
  },
}));

const StatementRow = ({ statement }: { statement: entities.LoanStatement }) => {
  const text = (statement?.periodEndDate && format(statement.periodEndDate, "yyyy - MMMM")) || "N/A";
  const { refetch, isFetching } = useMonthlyStatementQuery(statement.id!, {
    onSuccess: ({ fileURL, key }) => {
      return downloadFile(fileURL, key);
    },
  });

  const handleDownload = useCallback(() => {
    refetch();
  }, [refetch]);

  return <FileRow text={text} isLoading={isFetching} onClick={handleDownload} />;
};

const MonthlyStatements = () => {
  useDocumentTitle("Monthly statements | Fraction");
  const { data: loan, isLoading } = useLoanQuery();

  return (
    <DashboardPage heading="Monthly statements" subHeading="View and download your monthly loan activities.">
      {!loan?.statements?.length && !isLoading ? (
        <NoDataScreen text="It doesn't look like you have any monthly statements yet.">
          <Mailbox css={styles.noDataIllustration} />
        </NoDataScreen>
      ) : (
        <FileTable isLoading={isLoading} tableHeadText="Statement date">
          {loan?.statements?.map((statement) => (
            <StatementRow key={statement.id} statement={statement} />
          ))}
        </FileTable>
      )}
    </DashboardPage>
  );
};

export default memo(MonthlyStatements);
