import { Children, FunctionComponentElement } from "react";

// Flattens all child elements into a single list
const flattenChildren = (children: FunctionComponentElement<any>) => {
  let q = Children.toArray(children) as FunctionComponentElement<any>[];
  const visited: FunctionComponentElement<any>[] = [];

  while (q.length > 0) {
    const node = q.pop();
    visited.push(node as FunctionComponentElement<any>);
    if (node?.props?.children) {
      q = [...q, ...(Children.toArray(node.props.children) as FunctionComponentElement<any>[])];
    }
  }

  return visited;
};

// Strips all circular references and internal fields
export const simplifyChildren = (childrenToSimplify: FunctionComponentElement<any>) => {
  const flat = flattenChildren(childrenToSimplify);

  return flat.map(({ key, ref, type, props }) => {
    const { children, ...rest } = props || {};
    return {
      key,
      ref,
      type,
      props: rest,
    };
  });
};
