import { calculators, entities, enums, formatters, notUndefinedOrNull, selectors } from "@fraction/shared";
import _ from "lodash";
import { useCallback, useState } from "react";
import { ChecklistApp } from "src/api/fraction";
import { KeyValue } from "src/components/KeyValue";
import { Badge } from "src/components/ui/badge";
import { useModal } from "src/hooks";
import { useInvites } from "src/hooks/useInvite";
import { cn } from "src/utilities/shadcnUtils";

interface ApplicantOverviewProps {
  className?: string;
  app?: ChecklistApp;
  loading?: boolean;
  detailsClassName?: string;
}

const AccountStatusMap = {
  [enums.UserAccountStatus.REGISTERED]: { variant: "success" as const, text: "Registered" },
  [enums.UserAccountStatus.VERIFIED]: { variant: "success" as const, text: "Registered" },
  [enums.UserAccountStatus.PENDING]: { variant: "lightgreen" as const, text: "Invited" },
  [enums.UserAccountStatus.UNVERIFIED]: { variant: "secondary" as const, text: "Invite to dashboard" },
};

export const ApplicantOverview = ({ app, loading, className, detailsClassName }: ApplicantOverviewProps) => {
  const { showModal, closeModal } = useModal();
  const { invites, sendInviteToApplicant, isPending } = useInvites();
  const [invitingUserId, setInvitingUserId] = useState<string | null>(null);

  const handleClickInvite = useCallback(
    (userId: string) => {
      const applicant = app?.applicants?.find((applicant) => applicant.user?.id === userId);
      if (!applicant?.user) {
        return;
      }
      const phone = selectors.user.selectPrimaryPhone(applicant?.user);
      const email = selectors.user.selectPrimaryEmail(applicant?.user);

      showModal({
        message: (
          <div className="text-sm">
            <p className="text-base mb-2">
              Invite <b>{formatters.user.userName(applicant?.user)}</b> to the Fraction dashboard?
            </p>
            <p>
              They will be able to see their application, upload relevant documents, and complete any pending
              tasks such as KYC and the Product Review quiz.
            </p>
          </div>
        ),
        actions: [
          { text: "Cancel", type: "inverse" as const, action: closeModal },
          email
            ? {
                type: "primary" as const,
                text: "Invite via email",
                action: () => {
                  closeModal();
                  setInvitingUserId(userId);
                  sendInviteToApplicant({
                    inviteeId: userId,
                    channels: [enums.ChannelType.EMAIL],
                  });
                },
              }
            : undefined,
          phone
            ? {
                type: "primary" as const,
                text: "Invite via phone",
                action: () => {
                  closeModal();
                  setInvitingUserId(userId);
                  sendInviteToApplicant({
                    inviteeId: userId,
                    channels: [enums.ChannelType.PHONE],
                  });
                },
              }
            : undefined,
        ].filter(notUndefinedOrNull),
      });
    },
    [showModal, app?.applicants]
  );

  return (
    <div className={cn("rounded max-w-full overflow-y-scroll flex flex-row gap-2 flex-wrap", className)}>
      {app?.applicants?.map((applicant) => (
        <ApplicantDetails
          application={app}
          onClickInvite={handleClickInvite}
          key={applicant.id}
          applicant={applicant || undefined}
          inviting={isPending && invitingUserId === applicant?.userId}
          invited={!!invites?.find((invite) => invite.inviteeId === applicant?.userId)?.id}
          className={detailsClassName}
        />
      ))}
    </div>
  );
};

const ApplicantDetails = ({
  application,
  applicant,
  onClickInvite,
  invited,
  inviting,
  className,
}: {
  application?: entities.ApplicationT;
  applicant?: entities.ApplicantT;
  onClickInvite?: (userId: string) => void;
  invited?: boolean;
  inviting?: boolean;
  className?: string;
}) => {
  if (!applicant?.user?.firstName || !applicant.user.accountStatus) {
    return null;
  }

  const handleClickInvite = useCallback(() => {
    if (!applicant?.user?.id) {
      return;
    }
    onClickInvite?.(applicant?.user?.id);
  }, [applicant?.user?.id, onClickInvite]);

  const primaryIncome = selectors.applicant.selectApplicantPrimaryIncome(applicant);

  return (
    <div className={cn("border p-4 px-6 border-gray-400 rounded", className)}>
      <b>{formatters.user.userName(applicant.user)}</b>
      <div className="mt-2 flex flex-col">
        <div className="flex flex-col [&>*:nth-child(odd)]:bg-gray-200 [&>*:nth-child(even)]:white hover:[&>*]:bg-gray-300">
          {applicant.user?.email ? <KeyValue label="Email" value={applicant.user.email} /> : null}
          {applicant.user?.phone ? (
            <KeyValue label="Phone" value={formatters.phone.formatPhoneNumber(applicant.user.phone)} />
          ) : null}
          {applicant.homeAddress ? (
            <KeyValue
              label="Home address"
              value={formatters.property.formattedAddress(applicant.homeAddress)}
            />
          ) : null}
          {applicant.user?.phone ? <KeyValue label="Phone" value={applicant.user.phone} /> : null}
          {primaryIncome ? (
            <KeyValue
              label="Primary Income"
              value={primaryIncome?.jobTitle || primaryIncome?.description || primaryIncome?.employerName}
            />
          ) : null}
          <KeyValue
            label="Account status"
            value={
              <button
                title={
                  applicant.user.accountStatus === enums.UserAccountStatus.REGISTERED
                    ? "Already registered with dashboard"
                    : invited
                    ? "Invited"
                    : "Invite to dashboard"
                }
                disabled={applicant.user.accountStatus === enums.UserAccountStatus.REGISTERED}
                onClick={handleClickInvite}
              >
                <Badge
                  pending={inviting}
                  childContainerClassName="px-4"
                  variant={AccountStatusMap[applicant.user.accountStatus].variant}
                  className={cn("w-fit rounded-full text-xs")}
                >
                  {applicant.user.accountStatus === enums.UserAccountStatus.REGISTERED
                    ? "Registered"
                    : invited
                    ? "Invited"
                    : "Invite to dashboard"}
                </Badge>
              </button>
            }
          />
          <KeyValue
            label="Total Annual Income"
            value={formatters.number.getCurrencyFromNumber(
              selectors.applicant.selectApplicantIncomeWithoutFractionProceeds(applicant)
            )}
          >
            {selectors.applicant.selectActiveApplicantIncomes(applicant)?.map((income) => (
              <KeyValue
                key={income.id}
                label={`${
                  _.startCase(income?.jobTitle) ||
                  (income?.type ? formatters.enums.INCOME_TYPE_MAP[income?.type] : undefined) ||
                  "Unknown"
                }${
                  income?.description && income?.description !== income?.jobTitle
                    ? ` (${_.startCase(income?.description?.replaceAll("|", "LINEGOESHERE"))
                        ?.replaceAll("LINEGOESHERE", "-")
                        ?.trim()})`
                    : ""
                }${
                  income?.employerName && income?.employerName !== (income?.jobTitle || income?.description)
                    ? ` (${income?.employerName?.trim()})`
                    : ""
                }`}
                value={formatters.number.getCurrencyFromNumber(
                  calculators.payment.convertPaymentToAnnualAmount(income?.payment || { amount: 0 })
                )}
              />
            ))}
          </KeyValue>
          <KeyValue
            label="Total Asset(s) Value"
            value={formatters.number.getCurrencyFromNumber(
              selectors.applicant.selectApplicantAssetsFormatted(applicant)
            )}
          >
            {applicant?.assets?.map((asset) => (
              <KeyValue
                key={asset?.id}
                label={`${
                  _.startCase(asset?.name) ||
                  (asset?.type ? formatters.enums.FINANCIAL_ASSET_TYPE_MAP[asset?.type] : null)
                } ${asset?.description ? ` (${asset?.description?.trim()})` : ""}`}
                value={formatters.number.getCurrencyFromNumber(asset?.value || 0)}
              />
            ))}
          </KeyValue>
          <KeyValue
            label="Total Debts Owing"
            value={formatters.number.getCurrencyFromNumber(
              selectors.applicant.selectApplicantDebts(applicant)
            )}
          >
            {applicant?.debts?.map((debt) => (
              <KeyValue
                key={debt?.id}
                label={
                  <>
                    {debt.withWho?.trim() || ""}{" "}
                    {debt.description && debt.description !== debt.withWho
                      ? `${debt.description?.trim()}`
                      : ""}
                    {debt.type ? ` ${formatters.enums.DEBT_TYPE_MAP[debt.type]?.toLowerCase()}` : ""}
                    {debt.propertyId &&
                    application &&
                    selectors.application
                      .selectAllPropertiesInApplication(application)
                      ?.find((p) => p.id === debt.propertyId)
                      ? ` at 🏡 ${formatters.property.shortFormattedAddress(
                          selectors.application
                            .selectAllPropertiesInApplication(application)
                            ?.find((p) => p.id === debt.propertyId)
                        )}`
                      : ""}
                  </>
                }
                sublabel={debt.payingOffWithProceeds ? "Paying off with proceeds" : undefined}
                value={formatters.number.getCurrencyFromNumber(debt?.currentlyOwing || 0)}
              />
            ))}
          </KeyValue>
        </div>
      </div>
    </div>
  );
};
