/**
 * These are the upper bounds in pixels
 */
export enum Breakpoint {
  phone = 620,
  tablet = 850,
  small_desktop = 1000,
  desktop = 1200,
}

export const PHONE_MEDIA_QUERY = `@media(min-width: ${Breakpoint.phone}px)`;
export const TABLET_MEDIA_QUERY = `@media(min-width: ${Breakpoint.tablet}px)`;
export const DESKTOP_MEDIA_QUERY = `@media(min-width: ${Breakpoint.desktop}px)`;

/**
 * Given this:
 * ```
 * {
 *    color: ["red", "green", "blue"]
 *    border: ["solid", "dashed"]
 * }
 * ```
 *
 * Return this:
 * ```
 * {
 *    color: "red",
 *    border: "solid"
 *    @media(min-width: 620px): {
 *      color: "green"
 *      border: "dashed"
 *    },
 *    @media(min-width: 850px): {
 *      color: "blue"
 *    },
 * }
 * ```
 *
 * Won't interfere with existing media queries.
 *
 * Note that this only works for top level properties.
 * You can't for example do this since it's nested (TS should catch it though):
 * ```
 * {
 *    "&:nth-of-type(odd)": {
 *       border: ["solid", "dashed"]
 *    },
 * }
 * ```
 *
 */
export const applyMediaBreakpoints = (styleValues: Record<string, any>) => {
  return Object.keys(styleValues).reduce<Record<string, any>>((acc, key) => {
    const value = styleValues[key];

    if (!Array.isArray(value)) {
      return acc;
    }

    // empty arrays are not valid, so remove it
    if (!value.length) {
      const { [key]: remove, ...rest } = acc;
      return rest;
    }

    const newValues = { ...acc };

    // phone
    if (value.length >= 1) {
      newValues[key] = value[0];
    }

    // tablet
    if (value.length >= 2) {
      newValues[PHONE_MEDIA_QUERY] = {
        ...newValues[PHONE_MEDIA_QUERY],
        [key]: value[1],
      };
    }

    // desktop
    if (value.length >= 3) {
      newValues[TABLET_MEDIA_QUERY] = {
        ...newValues[TABLET_MEDIA_QUERY],
        [key]: value[2],
      };
    }

    return newValues;
  }, styleValues);
};
