import { calculators } from "@fraction/shared";
import { CheckCircle2 } from "lucide-react";
import { useMemo } from "react";
import { useBankAccountsQuery } from "src/apps/PostFundedDashboard/queries";

import { usePlaid } from "src/apps/PreFundedDashboard/hooks/plaid";
import { AssetList } from "src/components/AssetList";
import { BankAccountList } from "src/components/BankAccountList";
import { DebtList } from "src/components/DebtList";
import { Button } from "src/components/ui/button";
import { useApplicationsQuery } from "src/hooks/useApplication";

const IncomeVerify = () => {
  const { data: apps } = useApplicationsQuery({ accountType: "applicant", status: "active" });

  const app = useMemo(() => apps?.[0], [apps]);
  const debts = useMemo(
    () => (app ? calculators.application.selectAllDebts(app)?.filter((debt) => debt?.plaidItemId) : []),
    [app]
  );
  const assets = useMemo(
    () => (app ? calculators.application.selectAllAssets(app)?.filter((asset) => asset?.plaidItemId) : []),
    [app]
  );

  const { start, ready, isLoading, registerMutation } = usePlaid({
    applicationId: app?.id,
    purpose: "underwriting",
  });
  const { data: bankAccounts } = useBankAccountsQuery();

  return (
    <div className="flex flex-col p-6 gap-y-4">
      <div className="flex flex-row justify-between items-center">
        <div>
          <p className="font-semibold text-3xl">Connect a bank account</p>
          <p>Connect your bank account to automatically pull in your income, debts, and assets.</p>
        </div>

        <div>
          <Button
            className="self-start"
            loading={isLoading}
            onClick={() => start()}
            disabled={!ready || !!registerMutation.data}
          >
            {registerMutation?.data ? <CheckCircle2 className="text-white mr-2" /> : null}
            {registerMutation?.data ? "Bank connected!" : "Connect a bank account"}
          </Button>
        </div>
      </div>

      <div>
        {bankAccounts?.length ? (
          <p className="font-semibold text-2xl mb-2">Your bank and depository accounts</p>
        ) : null}
        <BankAccountList bankAccounts={bankAccounts} />
      </div>
      <div>
        {debts?.length ? <p className="font-semibold text-2xl mb-2">Your debts</p> : null}
        <DebtList debts={debts} />
      </div>
      <div>
        {debts?.length ? <p className="font-semibold text-2xl mb-2">Your other assets</p> : null}
        <AssetList assets={assets} />
      </div>
    </div>
  );
};

export default IncomeVerify;
