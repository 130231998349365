import { useEffect, useRef } from "react";
import { useIsScrolling } from "src/hooks/useIsScrolling";

export const ScrollOnMount = ({ scrollOpts, ...props }: any & { scrollOpts?: ScrollIntoViewOptions }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView(scrollOpts);
    }
  }, []);

  return <div ref={ref} {...props} />;
};

export const AlwaysScrollToLastChild = (props: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const isScrolling = useIsScrolling(ref);

  useEffect(() => {
    const container = ref.current;
    if (container && !isScrolling) {
      container.scrollTop = container.scrollHeight;
    }
  }, [ref.current, props.children]);

  return <div ref={ref} {...props} />;
};
