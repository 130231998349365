import { useCallback, useState } from "react";
import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { useTimers } from "src/hooks/useTimers";
import { useMutation } from "src/lib/react-query";

export function useEmailVerify({
  onSuccess,
  onInvalidCode,
  channel: defaultChannel,
}: { onSuccess?: () => void; onInvalidCode?: () => void; channel?: string } = {}) {
  const [emailVerified, setEmailVerified] = useState(false);
  const [email, setEmail] = useState<string | undefined>(defaultChannel);
  const [code, setCode] = useState<string | undefined>(undefined);
  const [invalidMessage, setInvalidMessage] = useState<undefined | string>(undefined);

  const { channels, refetchUser } = useAuth();

  const { countdown, setCounterInterval, startCountdown: startCountdownTimer } = useTimers();

  const stopCountdown = useCallback(() => {
    setCounterInterval(undefined);
  }, []);

  const stopCountdownCleanup = useCallback(() => {
    return stopCountdown;
  }, [stopCountdown]);

  const resumeCountdown = useCallback(() => {
    startCountdownTimer(() => startVerification.mutate({ to: email }), countdown);
  }, [countdown]);

  const startCountdown = useCallback(() => {
    startCountdownTimer(() => startVerification.mutate({ to: email }));
  }, []);

  const startVerification = useMutation({
    mutationFn: async ({ to, verifyExisting }: { to?: string; verifyExisting?: boolean } = {}) => {
      setInvalidMessage(undefined);
      if (!verifyExisting) {
        if (channels?.emails?.find((channel) => channel.value?.toLowerCase() === email?.toLowerCase())) {
          setInvalidMessage("You already have this email added to your account.");
          return { ok: false };
        }
      }

      setInvalidMessage(undefined);
      const verifyEmail = to || email;
      if (to && to !== email) {
        setEmail(to);
      }
      if (!verifyEmail) {
        throw new Error("No email");
      }
      setEmailVerified(false);
      await fraction.startEmailVerification(verifyEmail);
      startCountdown();
      return { ok: true };
    },
  });

  const checkVerification = useMutation({
    mutationFn: ({ channel: email_, code: code_ }: { channel?: string; code?: string } = {}) => {
      const verifyEmail = email_ || email;
      const verifyCode = code_ || code;
      if (!verifyEmail) {
        throw new Error("No phone");
      }
      if (!verifyCode) {
        throw new Error("No code");
      }
      stopCountdown();
      return fraction.checkEmailVerification(verifyEmail, verifyCode);
    },
    onSuccess: ({ status }) => {
      if (status === "approved") {
        setEmailVerified(true);
        onSuccess?.();
        stopCountdown();
        refetchUser?.();
      } else {
        resumeCountdown();
        onInvalidCode?.();
        setInvalidMessage("Invalid code");
      }
    },
  });

  return {
    countdown,
    stopCountdown,
    stopCountdownCleanup,
    startVerification,
    checkVerification,
    channel: email,
    setChannel: setEmail,
    verified: emailVerified,
    setVerified: setEmailVerified,
    invalidMessage,
    code,
    setCode,
    isPending: startVerification.isPending || checkVerification.isPending,
    acceptable:
      !!email &&
      !!email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
  };
}
