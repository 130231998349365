import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { STORAGE, handleToken } from "src/auth/mutations";
import useQueryParams from "src/hooks/useQueryParams";

function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const useToken = () => {
  const queryClient = useQueryClient();
  const params = useQueryParams();

  const storedTokenData = useQuery({
    queryKey: ["cookies", "token"],
    queryFn: async () => {
      return STORAGE.getToken() || null;
    },
  });

  const storedToken = storedTokenData?.data;

  const { data: token, isLoading: tokenGrabLoading } = useQuery({
    enabled: !storedTokenData?.isLoading,
    queryKey: ["token"],
    queryFn: async () => {
      return params?.token || storedToken || null;
    },
  });

  const setToken = useCallback((t?: string) => {
    queryClient.setQueryData(["token"], t);
    queryClient.setQueryData(["cookies", "token"], t);
  }, []);

  const tokenSetup = useQuery({
    queryKey: ["token", token],
    enabled: !!token,
    queryFn: async () => {
      return handleToken(token);
    },
  });

  const json = useMemo(() => (token ? parseJwt(token) : undefined), [token]);

  return {
    isLoading: tokenGrabLoading || tokenSetup.isLoading || storedTokenData?.isLoading,
    token: tokenSetup.isFetched ? token : undefined,
    isSet: !!token && tokenSetup.isFetched,
    setToken,
    id: tokenSetup.isFetched ? json?.id : undefined,
    email: tokenSetup.isFetched ? json?.email : undefined,
  };
};

export default useToken;
