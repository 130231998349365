import { colors } from "@fraction/shared";
import { Loader, LogOutIcon, User2Icon, UserRoundPenIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { useAuth } from "src/auth";
import AccountDropdownButton from "src/components/AccountDropdownButton";
import { MonoButton } from "src/components/Button";
import { SemiBoldText } from "src/components/v1";
import { useAccountViewPreference } from "src/hooks/useAccountViewPreference";
import { Logger } from "src/log";
import { createStyles } from "src/styles";
import Dropdown from "../Dropdown";

const logger = new Logger("components.AccountDropdown");

const styles = createStyles(({ spacing, colors, fontSize }) => ({
  dropDownOverride: {
    transform: "translate(-26px, 10px)",
  },
  dropdownButton: {
    marginLeft: "auto",
  },
  dropdownContent: {
    minWidth: 320,
  },
  dropdownHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: `${spacing[2]}px ${spacing[3]}px`,
    borderBottom: `1px solid ${colors.darkgrey200}`,
  },
  dropdownHeaderText: {
    lineHeight: "27px",
  },
  dropdownSubheaderText: {
    fontSize: fontSize.sm,
  },
  dropdownFooter: {
    padding: spacing[3],
  },
}));

const selectInitials = (firstName?: string, lastName?: string, fullName?: string, email?: string) => {
  if ((!firstName || !lastName) && !fullName && !email) {
    logger.warn("Missing user's first or last name");
    return <User2Icon color={colors.palette.GREEN_800} />;
  }
  if (firstName && lastName) {
    return firstName[0].toUpperCase() + lastName[0].toUpperCase();
  }
  if (fullName) {
    const split = fullName?.split(" ");
    if (split?.length === 1) {
      return split[0][0].toUpperCase();
    }
    return (split?.[0][0].toUpperCase() || "") + (split?.[1][0].toUpperCase() || "");
  }
  if (email) {
    return email.slice(0, 2).toUpperCase();
  }
};

export const AccountDropdown = () => {
  const { logout, isLoggingOut, user } = useAuth();
  const { choices, preference, setPreference, isLoading } = useAccountViewPreference();

  const firstName = user?.firstName;
  const lastName = user?.lastName;

  return (
    <Dropdown
      trigger={
        <AccountDropdownButton initials={selectInitials(firstName, lastName, user?.fullName, user?.email)} />
      }
      align="right"
      style={styles.dropDownOverride}
      triggerStyle={styles.dropdownButton}
    >
      <div className="max-w-xl" css={styles.dropdownContent}>
        {firstName ? (
          <div css={styles.dropdownHeader}>
            <div>
              <SemiBoldText style={styles.dropdownHeaderText}>Hello, {firstName}</SemiBoldText>
              {user?.email ? <p className="text-xs text-gray">{user.email}</p> : null}
            </div>
            {isLoading ? <Loader height={20} className="ml-2 text-gray-600 animate-spin" /> : null}
          </div>
        ) : null}
        {choices?.length > 1 ? (
          <div className="flex flex-row flex-wrap gap-x-2 gap-y-1 mx-6 mb-2">
            {choices
              .filter((choice) => choice !== preference)
              .map((choice) => (
                <MonoButton
                  fullWidth
                  key={choice}
                  onClick={() => setPreference(choice)}
                  loading={isLoggingOut}
                  variant="outlined"
                  size="small"
                >
                  View as {choice}
                </MonoButton>
              ))}
          </div>
        ) : null}
        <div className="mx-6 mb-1">
          <Link to="/profile">
            <MonoButton size="small" fullWidth variant="standard">
              <UserRoundPenIcon className="w-3 h-3 mb-0.5 mr-1 inline" /> Edit profile
            </MonoButton>
          </Link>
        </div>
        <div className="mx-6 mb-3">
          <MonoButton size="small" fullWidth onClick={logout} loading={isLoggingOut} variant="standard">
            <LogOutIcon className="w-3 h-3 mb-0.5 mr-1 inline" /> Log out
          </MonoButton>
        </div>
      </div>
    </Dropdown>
  );
};
