import { enums, formatters } from "@fraction/shared";
import { useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { useInvite } from "src/hooks/useInvite";

export function AcceptInvitePage() {
  const { inviteCode } = useParams();
  const { invite, isLoading, acceptInvite, rejectInvite } = useInvite(inviteCode);
  const navigate = useNavigate();

  const handleAccept = useCallback(() => {
    (async () => {
      await acceptInvite.mutateAsync();
      navigate("/");
    })();
  }, [invite?.id]);

  const handleReject = useCallback(() => {
    (async () => {
      await rejectInvite.mutateAsync();
      navigate("/");
    })();
  }, [invite?.id]);

  return (
    <div className="p-6">
      <div className="text-center flex flex-col items-center">
        {isLoading ? <p className="text-lg font-serif-deck">Loading your invitation...</p> : null}
        {!isLoading && !invite && (
          <>
            <p className="font-serif-deck text-lg">We can't find your invite</p>
            <p className="mt-1 max-w-md">
              Sorry about that! We weren't able to find your invite. If you know who sent it to you, you can
              ask them to re-send you a new invite.
            </p>
            <p className="text-xs mt-4 mb-4 max-w-sm">
              Since you are here though, you can check out the rest of the dashboard if you'd like.
            </p>
            <Link to="/">
              <Button>Go to dashboard home</Button>
            </Link>
          </>
        )}
        {invite ? (
          <>
            <p className="text-lg font-serif-deck">
              {formatters.user.userName(invite?.inviter) || invite?.email} has invited you to join Fraction!
            </p>
            {invite.type === enums.InviteType.BROKER_TEAM_CONNECTION ? (
              <>
                <p className="mt-1 max-w-md">
                  Congratulations! You have been invited to join their deal team.
                  <br />
                  This is a great honour.
                </p>
                <p className="mt-4 text-sm max-w-md">
                  By accepting this invite, you will be able to view and manage any deals that they submit
                  through Fraction.
                </p>
                <p className="mt-4 text-xs max-w-md p-2 border-green bg-green-100 border rounded">
                  <b>Note:</b> This is <u>not</u> a two-way deal team. They will not be able to view or manage
                  the deals <i>you</i> submit—you'd have to go to your profile page and add them to{" "}
                  <i>your</i> deal team in order to do that.
                </p>
              </>
            ) : null}
            <div className="flex flex-row gap-2 mt-4">
              <Button pending={rejectInvite.isPending} onClick={handleReject} variant="destructive">
                Reject invite
              </Button>
              <Button pending={acceptInvite.isPending} onClick={handleAccept} variant="default">
                Accept invite
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
