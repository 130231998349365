import { Ref, memo, useCallback } from "react";
import DropdownSelector from "src/components/DropdownSelector";
import { Applicant } from "src/types/Applicant";

import { notUndefinedOrNull } from "@fraction/shared";

export interface SharedApplicantSelectorProps {
  onChange: (value: Applicant[]) => void;
  value?: Applicant[];
  otherApplicants: Applicant[];
  applicant: Applicant;
  isMulti?: boolean;

  focusRef?: Ref<HTMLInputElement>;
}

const SharedApplicantsSelector = ({
  focusRef,
  otherApplicants,
  applicant,
  value,
  onChange,
}: SharedApplicantSelectorProps) => {
  const handleChange = useCallback(
    (emails: string[]) => {
      // The noteworthy thing for this onChange is that we want to include the *applicant* as well, because
      // the applicant is SHARING with the other applicants, but we don't want to reflect that in our input,
      // as it is implicit for the user doing the sharing.
      onChange(
        [
          ...otherApplicants.filter(({ email }) => emails.includes(email?.email || "NOPE")).filter(Boolean),
          applicant,
        ].filter(notUndefinedOrNull)
      );
    },
    [applicant, onChange, otherApplicants]
  );

  return (
    <DropdownSelector
      isMulti
      label="Shared with"
      focusRef={focusRef}
      value={value?.map(({ email }) => email?.email)}
      onChange={handleChange}
      options={otherApplicants.map((otherApplicant) => ({
        label: otherApplicant.name || otherApplicant.email?.email || "",
        value: otherApplicant?.email?.email || "",
      }))}
    />
  );
};

export default memo(SharedApplicantsSelector);
