import { enums, formatters, types } from "@fraction/shared";
import _ from "lodash";
import { AlertCircleIcon, CircleCheckIcon, CircleSlashIcon, CircleXIcon, Loader } from "lucide-react";
import { ReactNode, useState } from "react";
import { Tooltip } from "src/components";
import LocationInput from "src/components/LocationInput";
import { usePropertyCheck } from "src/hooks/usePropertyCheck";
import { cn } from "src/utilities/shadcnUtils";

const tooltipExplainerText: Partial<Record<enums.UnderwritingRuleName, ReactNode>> = {
  [enums.UnderwritingRuleName.POSTAL_SCORE_CHECK]: (
    <div>
      <p>
        This rule checks the postal code of the property against a list of postal codes that we have scored.
      </p>
      <p>
        Score of <b>10</b>: unless it is a strange property, it will likely be approved.
      </p>
      <p>
        Score of <b>7</b>: almost all properties in the area will be approved.
      </p>
      <p>
        Score of <b>5</b>: most of the properties in the area will be approved.
      </p>
      <p>
        Score of <b>3</b>: some properties in the area will be approved.
      </p>
      <p>
        Score of <b>0</b>: very low chance to none that properties will be approved. We will need comparables,
        if there aren't reasonable comps within 10kms it has almost no chance to be approved.
      </p>
    </div>
  ),
  [enums.UnderwritingRuleName.ADMINISTRATIVE_REGION_CHECK]:
    "Administrative regions are provinces. Fraction currently lends in BC, Alberta, and Ontario",
  [enums.UnderwritingRuleName.TIM_HORTONS_DISTANCE_CHECK]:
    "This is not a hard-and-fast rule, but it is a reasonable heuristic to determine if a property may be too rural.",
  [enums.UnderwritingRuleName.PROPERTY_HIGH_END_CONDO_CHECK]:
    "Fraction does not lend on high-end condos, i.e. condos with a value over $2mm.",
  [enums.UnderwritingRuleName.PROPERTY_ZONING_CHECK]: "Fraction does not lend on commercial properties.",
  [enums.UnderwritingRuleName.PROPERTY_VALUE_CHECK]:
    "Fraction does not lend on properties worth less than $200k, because we have a minimum $100k loan size and we only lend up to 50% LTV.",
  [enums.UnderwritingRuleName.PROPERTY_TYPE_CHECK]:
    "Fraction does not lend on certain property types, e.g. properties with more than 4 units, mobile homes without foundations, and so on.",
  [enums.UnderwritingRuleName.PROPERTY_LOT_SIZE_CHECK]:
    "Fraction generally will not lend on properties exceeding 10 acres. Sometimes exceptions will be made if the property is in a major metro area.",
};

const PropertyChecker = () => {
  const [address, setAddress] = useState<types.ExtractedPropertyAddress | undefined>(undefined);
  const { data: results, isLoading } = usePropertyCheck(address);

  const filteredResult = results?.filter(
    (result) =>
      result.name !== enums.UnderwritingRuleName.PROPERTY_TENURE_CHECK &&
      // don't show unfulfillable high end condo rule
      (result.name !== enums.UnderwritingRuleName.PROPERTY_HIGH_END_CONDO_CHECK ||
        result.status !== enums.EngineRuleStatus.UNFULFILLABLE)
  );

  return (
    <div className="p-6">
      <h1 className="text-2xl font-serif-deck">Property Checker</h1>
      <p className="max-w-3xl text-sm">
        Want to see if your property is likely to be accepted? Enter it here and we will run a number of
        automated checks to give you an idea of whether it could be approved.
      </p>
      <div className="max-w-lg mt-4">
        <LocationInput
          name="Address"
          label="Property address"
          value={address ? formatters.property.formattedAddress(address) : undefined}
          onSelect={setAddress}
        />
      </div>
      <p className="p-2 px-4 bg-green-100 rounded w-fit max-w-xl text-xs text-green-900 mb-4">
        <AlertCircleIcon className="inline w-4 h-4 mr-2 align-middle mb-0.5 fill-green-200 text-green-900" />
        Note: This tool is a guide only and does not guarantee acceptance. Our underwriting team will make the
        final decision. If you have extra information, you can feel free to send it to your BDM who will pass
        it along to our underwriters for further evaluation.
      </p>
      <div>
        {isLoading ? (
          <p className="text-gray align-middle">
            <Loader height={16} className="inline animate-spin" /> Checking property against our server...
          </p>
        ) : null}
        <div className="flex flex-col gap-3">
          {filteredResult?.map((result) => (
            <div key={result.name}>
              <div className="flex flex-row gap-1 items-end">
                <Tooltip text={tooltipExplainerText[result.name]} white>
                  <div
                    className={cn(
                      "flex flex-row gap-1 items-end",
                      tooltipExplainerText[result.name] && "cursor-help"
                    )}
                  >
                    {result.status === enums.EngineRuleStatus.PASSED ? (
                      <CircleCheckIcon className="fill-green text-white" />
                    ) : result.status === enums.EngineRuleStatus.FAILED ? (
                      <CircleXIcon className="fill-red text-white" />
                    ) : (
                      <CircleSlashIcon className="fill-yellow text-white" />
                    )}
                    <b>{_.startCase(result.name)}</b>
                  </div>
                </Tooltip>
                <div
                  className={cn(
                    "font-semibold text-xs px-2 py-1 rounded-full",
                    result.status === enums.EngineRuleStatus.PASSED
                      ? "bg-green"
                      : result.status === enums.EngineRuleStatus.FAILED
                      ? "bg-red"
                      : "bg-yellow"
                  )}
                >
                  {result.status === enums.EngineRuleStatus.PASSED ? (
                    <p>Passed!</p>
                  ) : result.status === enums.EngineRuleStatus.FAILED ? (
                    <p>Failed</p>
                  ) : (
                    <Tooltip
                      white
                      text="This just means our system could not automatically find information about this property. It does not indicate a success or a fail."
                    >
                      <p className="cursor-help">Unable to auto-check</p>
                    </Tooltip>
                  )}
                </div>
              </div>

              <p className="text-sm font-gray">{result.message}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PropertyChecker;
