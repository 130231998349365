import { useCallback, useReducer } from "react";
import { TextInputEvent } from "src/components/TextInput";

export enum ActionType {
  CHANGE = "change",
  RESET = "reset",
}

type ChangeAction = {
  type: ActionType.CHANGE;
  payload: string;
  field: string;
};

type ResetAction = {
  type: ActionType.RESET;
  payload: Record<string, any>;
};

type Action = ChangeAction | ResetAction;

const formReducer = (state: Record<string, string>, action: Action) => {
  switch (action.type) {
    case ActionType.CHANGE:
      return {
        ...state,
        [action.field]: action.payload,
      };

    case ActionType.RESET:
      return action.payload;

    default:
      return state;
  }
};

export interface UseFormReducerResult {
  formValues: Record<string, string>;
  formDispatch: (action: Action) => void;
  handleTextChange: (payload: string, e: TextInputEvent) => void;
  updateField: (payload: any, field: string) => void;
  reset: () => void;
}

export default function useFormReducer({
  initialValues,
}: {
  initialValues?: Record<string, any>;
} = {}): UseFormReducerResult {
  const [formValues, formDispatch] = useReducer(formReducer, initialValues || {});

  const handleTextChange = useCallback((payload: string, e: TextInputEvent) => {
    formDispatch({
      type: ActionType.CHANGE,
      field: e.target.name,
      payload,
    });
  }, []);

  const updateField = useCallback((payload: any, field: string) => {
    formDispatch({
      type: ActionType.CHANGE,
      field,
      payload,
    });
  }, []);

  const reset = useCallback(() => {
    formDispatch({
      type: ActionType.RESET,
      payload: initialValues || {},
    });
  }, [initialValues]);

  return { formValues, formDispatch, handleTextChange, updateField, reset };
}
