import { Breakpoint } from "../utilities/breakpoints";
import tokens from "./Tokens";

const {
  spacing: { root: SPACING_ROOT },
  borderRadius: { root: BORDER_RADIUS_ROOT },
  borderWidth: { root: BORDER_WIDTH_ROOT },
} = tokens;

/**
 * TOKENS is data that is derived from Figma. If there is other style token info that we may want available
 * it can be added here. SHAPED_TOKENS is the version that will be available in our style functions.
 *
 * Consider adding tokens to Figma first if it makes sense, if not then it can be added here directly.
 */
const SHAPED_TOKENS = {
  ...tokens,
  spacing: {
    0.25: SPACING_ROOT * 0.25,
    0.5: SPACING_ROOT * 0.5,
    0.75: SPACING_ROOT * 0.75,
    1: SPACING_ROOT,
    1.5: SPACING_ROOT * 1.5,
    2: SPACING_ROOT * 2,
    2.5: SPACING_ROOT * 2.5,
    3: SPACING_ROOT * 3,
    3.5: SPACING_ROOT * 3.5,
    4: SPACING_ROOT * 4,
    5: SPACING_ROOT * 5,
    6: SPACING_ROOT * 6,
    8: SPACING_ROOT * 8,
    10: SPACING_ROOT * 10,
    12: SPACING_ROOT * 12,
  },
  borderRadius: {
    0.25: BORDER_RADIUS_ROOT * 0.25,
    0.5: BORDER_RADIUS_ROOT * 0.5,
    0.75: BORDER_RADIUS_ROOT * 0.75, // block
    1: BORDER_RADIUS_ROOT,
    2: BORDER_RADIUS_ROOT * 2,
    3: BORDER_RADIUS_ROOT * 3,
  },
  borderWidth: {
    1: BORDER_WIDTH_ROOT,
    2: BORDER_WIDTH_ROOT * 2,
    4: BORDER_WIDTH_ROOT * 4,
    8: BORDER_WIDTH_ROOT * 8,
  },
  breakpoints: {
    phone: Breakpoint.phone,
    tablet: Breakpoint.tablet,
    desktop: Breakpoint.desktop,
  },
  fontWeights: {
    regular: 350,
    medium: 550,
    bold: 700,
  },
  fontSize: {
    "3xs": "0.5rem",
    "2xs": "0.75rem",
    xs: "0.875rem",
    sm: "1rem", // 16px
    md: "1.125rem",
    lg: "1.25rem",
    xl: "1.5rem",
    "2xl": "2rem",
    "3xl": "2.25rem",
    "4xl": "2.5rem",
    "5xl": "3rem",
    "6xl": "3.5rem",
    "7xl": "4rem",
  },
  zIndex: {
    dropdown: 1000,
    banner: 1100,
    drawer: 1200,
    modal: 1300,
    toast: 1400,
    tooltip: 1500,
  },
} as const;

export type ShapedTokens = typeof SHAPED_TOKENS;
export default SHAPED_TOKENS;
