import { SerializedStyles } from "@emotion/react";
import { HelpCircleIcon } from "lucide-react";
import { ReactNode } from "react";

import { colors } from "@fraction/shared";

import Tooltip from "../Tooltip";

function HelpCircle({
  children,
  style,
  className,
}: {
  children: ReactNode;
  style?: SerializedStyles;
  className?: string;
}) {
  return (
    <Tooltip className={className} text={children}>
      <HelpCircleIcon css={style} size={15} color={colors.palette.GREY_GREEN_700} />
    </Tooltip>
  );
}

export default HelpCircle;
