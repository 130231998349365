import { ResponsivePie } from "@nivo/pie";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import {
  LabeledPropertyMsa,
  usePropertyMSAs,
} from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";
import { useToggler } from "src/hooks";

import { ChartCard } from "../ChartCard";

const MARGIN = { top: 20, right: 40, bottom: 20, left: 40 };
const MARGIN_TOP_10 = { top: 20, right: 40, bottom: 20, left: 40 };

export function PropertyMsaChart() {
  const { data, isLoading } = usePropertyMSAs();
  const { Toggle, on: showTop10 } = useToggler({
    id: "showTop10MSAs",
    label: "Show top 10 locations",
    defaultValue: false,
  });
  const { Toggle: SplitByAdminAreaToggle, on: splitByAdminArea } = useToggler({
    id: "splitByAdminArea",
    label: "Split by province/state",
    defaultValue: true,
  });

  if (isLoading) {
    return <Skeleton width="100%" height={389} />;
  }

  if (!data) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={data.length === 0}
      className="min-h-[300px]"
      header="Property locations"
      headerComponents={
        <div className="flex flex-col gap-y-1">
          <Toggle />
          <SplitByAdminAreaToggle />
        </div>
      }
    >
      {!splitByAdminArea && <PieChart data={data} showTop10={showTop10} />}
      {splitByAdminArea && <RadialChart data={data} showTop10={showTop10} />}
    </ChartCard>
  );
}

const RADIAL_MARGIN = { top: 30, right: 20, bottom: 20, left: 20 };
const RADIAL_AXIS = { tickSize: 5, tickPadding: 5, tickRotation: 0 };
const CIRCULAR_AXIS = { tickSize: 5, tickPadding: 12, tickRotation: 0 };

const RadialChart = ({ showTop10, data }: { showTop10?: boolean; data: LabeledPropertyMsa[] }) => {
  const convertedData: { id: string; data: { x: string; y: number }[] }[] = useMemo(() => {
    const grouped = _.groupBy(data, "administrativeArea");
    return _.reverse(_.sortBy(Object.entries(grouped), "length")).map(([key, value]) => ({
      id: key,
      data: (showTop10 ? value.slice(0, 10) : value).map((item) => ({
        x: item.id,
        y: item.value,
        label: item.label,
      })),
    }));
  }, [data, showTop10]);

  const renderTooltip = useCallback((props: any) => {
    return (
      <div className="shadow p-2 bg-white rounded">
        <p className="text-sm text-gray-800 mt-0.5 font-sans">{props?.bar?.data?.label}</p>
      </div>
    );
  }, []);

  return (
    <ResponsiveRadialBar
      data={convertedData}
      valueFormat=">-.2f"
      padding={0.4}
      cornerRadius={2}
      tooltip={renderTooltip}
      margin={RADIAL_MARGIN}
      radialAxisStart={RADIAL_AXIS}
      circularAxisOuter={CIRCULAR_AXIS}
    />
  );
};

const ARCLABEL_TEXT_COLOR = {
  from: "color",
  modifiers: [["darker", 2]],
};
const ARCLINK_LABELS_COLOR = { from: "color" };
const BORDER_COLOR = {
  from: "color",
  modifiers: [["darker", 0.2]],
};
const PieChart = ({ showTop10, data }: { showTop10?: boolean; data: LabeledPropertyMsa[] }) => {
  const renderTooltip = useCallback((props: any) => {
    return (
      <div className="shadow p-2 bg-white rounded">
        <p className="text-sm text-gray-800 mt-0.5 font-sans">{props.datum?.label}</p>
      </div>
    );
  }, []);

  const renderArcLabel = useCallback((item: any) => item.data?.percent, []);

  return (
    <ResponsivePie
      // @ts-ignore
      data={showTop10 ? data.slice(0, 10) : data}
      margin={showTop10 ? MARGIN_TOP_10 : MARGIN}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      // @ts-ignore
      borderColor={BORDER_COLOR}
      tooltip={renderTooltip}
      // @ts-ignore
      arcLabel={renderArcLabel}
      arcLinkLabelsSkipAngle={showTop10 ? 10 : 12}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={ARCLINK_LABELS_COLOR}
      arcLabelsSkipAngle={15}
      // @ts-ignore
      arcLabelsTextColor={ARCLABEL_TEXT_COLOR}
    />
  );
};
