import { useCallback } from "react";
import { TextInputWithLoading } from "src/components";
import { createStyles } from "src/styles";

import { colors, formatters } from "@fraction/shared";
import { AnimatePresence, motion } from "framer-motion";
import { HeaderText, RegularText } from "src/components/v1/Text";

const styles = createStyles({
  input: {
    backgroundColor: "white",
    textTransform: "uppercase",
    "&::placeholder": {
      textTransform: "none",
    },
  },
  header: {
    fontSize: 26,
    "@media(max-width: 600px)": {
      textAlign: "center",
    },
  },
  link: {
    textDecoration: "none",
    color: colors.LINK,
    fontWeight: 500,
  },
  text: {
    maxWidth: 400,
    "@media(max-width: 600px)": {
      textAlign: "center",
    },
  },
  cText: {
    textAlign: "center",
    maxWidth: 450,
  },
});

const BdmLink = ({ email = "larry@fraction.com" }: { email?: string }) => (
  <a target="_blank" rel="noopener noreferrer" css={styles.link} href={`mailto:${email}`}>
    {email}
  </a>
);

export const BdmFinderLayout = ({
  onSubmit,
  loading,
  disabled,
  bdm,
}: {
  errorMessage?: string;
  bdm?: {
    email: string;
    firstName: string;
    lastName: string;
    title?: string;
    photoLink?: string;
    phone: string;
    calendarLink?: string;
  };
  onSubmit: (postalCode: string) => void;
  loading?: boolean;
  disabled?: boolean;
}) => {
  const handleSubmit = useCallback(
    (evt: any) => {
      evt?.preventDefault?.();
      onSubmit?.(evt.target.postalCode.value);
    },
    [onSubmit]
  );
  const handleChange = useCallback(
    (postal: string) => {
      if (handleSubmit && postal?.length === 3) {
        handleSubmit?.({ target: { postalCode: { value: postal } } });
      }
    },
    [handleSubmit]
  );

  return (
    <div className="flex items-center justify-center w-full">
      {bdm?.email ? (
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.25 }}
          className="flex flex-col md:flex-row items-center md:gap-3 gap-1"
        >
          {bdm?.photoLink && (
            <img
              className="md:w-[150px] md:h-[150px] h-[100px] w-[100px] rounded-full"
              src={bdm?.photoLink}
            />
          )}
          <div>
            <HeaderText style={styles.header}>
              Meet {bdm.firstName} {bdm.lastName}.
            </HeaderText>
            <RegularText style={styles.text}>
              Your BDM is {bdm.firstName} {bdm.lastName}, the {bdm?.title}. You can reach {bdm.firstName} at{" "}
              <BdmLink email={bdm.email} /> or by phone at{" "}
              <a target="_blank" rel="noopener noreferrer" css={styles.link} href={`tel:${bdm.phone}`}>
                {formatters.phone.formatPhoneNumber(bdm?.phone)}
              </a>
              . If you'd like to schedule a time to chat,{" "}
              <a target="_blank" rel="noopener noreferrer" css={styles.link} href={bdm.calendarLink}>
                book a time on {bdm.firstName}'s calendar
              </a>
              .
            </RegularText>
          </div>
        </motion.div>
      ) : null}
      <AnimatePresence>
        {!bdm ? (
          <motion.div
            className="flex flex-col gap-5"
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            initial={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            <RegularText style={styles.cText}>
              Want to know which BDM you are working with? Fraction BDMs are assigned by geography, so{" "}
              <b>just enter the first three letters of your brokerage office's postal code</b> to find which
              BDM you will be working with.
            </RegularText>
            <TextInputWithLoading
              maxLength={3}
              inputStyle={styles.input}
              errorSpacing={false}
              onChange={handleChange}
              variant="rounded"
              name="postalCode"
              placeholder="Brokerage postal code (e.g. V6Z)"
              type="text"
              autoComplete="off"
              loading={loading || false}
              disabled={disabled}
            />
          </motion.div>
        ) : (
          <div />
        )}
      </AnimatePresence>
    </div>
  );
};
