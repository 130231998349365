import { enums } from "@fraction/shared";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { cn } from "src/utilities/shadcnUtils";

export function ProductTypeSelect({
  onValueChange,
  className,
  value,
}: {
  value?: enums.ProductType;
  onValueChange?: (value: enums.ProductType) => void;
  className?: string;
}) {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className={cn("col-span-2", className)}>
        <SelectValue placeholder="Select a product type" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Product Type</SelectLabel>
          <SelectItem value={enums.ProductType.APPRECIATION_MORTGAGE}>Appreciation mortgage</SelectItem>
          <SelectItem value={enums.ProductType.FIXED_RATE}>No-payments fixed rate mortgage</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
