import { forwardRef, memo } from "react";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

import TextInput, { TextInputProps } from ".";
import ThreeDotsWave from "../ThreeDotsWave";

export interface TextInputWithLoadingProps {
  loading: boolean;
}

export const styles = createStyles({
  adornment: {
    marginRight: "12px",
  },
  dotWave: {
    backgroundColor: colors.button.PRIMARY.BACKGROUND,
  },
});

const ADORNMENT = (
  <div css={styles.adornment}>
    <ThreeDotsWave dotCss={styles.dotWave} />
  </div>
);

const TextInputWithLoading = forwardRef(
  ({ loading, ...props }: TextInputWithLoadingProps & TextInputProps, ref: any) => (
    <TextInput ref={ref} adornment={loading ? ADORNMENT : undefined} {...props} />
  )
);

export default memo(TextInputWithLoading);
