import fuzzysort from "fuzzysort";
import { useCallback, useMemo } from "react";

export default function useFuzzySort<T>(items: T[], searchInput: string, key: string): T[] {
  const filter = useCallback(
    (itemsToSearch: T[]) => {
      if (searchInput === "") {
        return itemsToSearch;
      }
      return fuzzysort.go(searchInput, itemsToSearch, { key }).map((result) => result.obj);
    },
    [key, searchInput]
  );

  return useMemo(() => filter(items || []), [filter, items]);
}
