import { SerializedStyles } from "@emotion/react";
import { memo, useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Column, useTable } from "react-table";
import ThreeDotsWave from "src/components/ThreeDotsWave";
import { ChevronDown } from "src/icons";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";
import { cn } from "src/utilities/shadcnUtils";

import Touchable from "../Touchable";

export interface TableProps<T = any> {
  columns: Array<Column>;
  data: T[];
  onPressSeeMore?: () => void;
  onClickRow?: (row: T) => void;
  loading?: boolean | number;
  showSeeMore?: boolean;
  cellStyle?: SerializedStyles;
  rowStyle?: SerializedStyles;
  getRowStyle?: (row: T) => SerializedStyles | undefined;
  className?: string;
  headerClassName?: string;
  rowClassName?: string;
  tableClassName?: string;
  tableContainerClassName?: string;
}

const styles = createStyles({
  tableContainer: {
    position: "relative",
    borderRadius: 6,
    overflow: "auto",
    display: "flex",
    flex: 1,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  touchable: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: colors.HIGHLIGHTED,
    },
  },
  header: {
    padding: 11,
    paddingLeft: 32,
    textAlign: "left",
    fontFamily: "Hanken Grotesk",
    fontWeight: 400,
    fontSize: 12,
    color: colors.table.HEADER,
  },
  row: {
    paddingLeft: 50,
    borderBottomWidth: 0.5,
    borderBottomColor: colors.table.LINES,
    borderBottomStyle: "solid",
  },
  cell: {
    padding: 32,
    paddingTop: 16,
    paddingBottom: 16,
    textAlign: "left",
    fontFamily: "Hanken Grotesk",
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  seeMoreContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    minHeight: 48,
    // backgroundColor: colors.palette.GREY_200,
    "&:hover": {
      backgroundColor: colors.palette.GREY_100,
    },
  },
  seeMoreText: {
    fontFamily: "Hanken Grotesk",
    fontWeight: 600,
    fontSize: 14,
    color: colors.palette.GREY_600,
  },
  icon: {
    color: colors.ICON,
    height: 12,
    width: 12,
    marginLeft: 5,
  },
});

const Table = ({
  columns = [],
  data = [],
  onPressSeeMore,
  onClickRow,
  loading,
  showSeeMore = true,
  cellStyle,
  rowStyle,
  getRowStyle,
  className,
  headerClassName,
  rowClassName,
  tableClassName,
  tableContainerClassName,
}: TableProps) => {
  const [seeMoreLoading, setSeeMoreLoading] = useState(false);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  const handlePressSeeMore = useCallback(async () => {
    setSeeMoreLoading(true);
    await onPressSeeMore?.();
    setSeeMoreLoading(false);
  }, [onPressSeeMore]);

  return (
    <div className={className}>
      <div className={tableContainerClassName} css={styles.tableContainer}>
        <table className={tableClassName} {...getTableProps()} css={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} css={[styles.row, rowStyle]}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={headerClassName}
                    {...column.getHeaderProps()}
                    css={[styles.header, cellStyle]}
                  >
                    {/* @ts-ignore */}
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading &&
              (typeof loading === "boolean" ? [1, 2] : Array.from(new Array(loading))).map((count) => (
                <tr key={count}>
                  {headerGroups[0].headers.map((column, idx) => (
                    // @ts-ignore
                    <td css={[styles.cell, cellStyle, column?.style]} key={idx}>
                      <Skeleton height={15} style={{ minWidth: 150, width: "60%" }} />
                    </td>
                  ))}
                </tr>
              ))}
            {!loading &&
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    className="hover:bg-gray-100"
                    onClick={() => onClickRow?.(row)}
                    css={[styles.row, onClickRow && styles.touchable, rowStyle, getRowStyle?.(row.original)]}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => (
                      <td
                        className={cn("text-md text-gray-900", rowClassName)}
                        {...cell.getCellProps()}
                        // @ts-ignore
                        css={[
                          styles.cell,
                          cellStyle,
                          // @ts-ignore
                          cell?.column?.style,
                          // @ts-ignore
                          cell?.column?.getStyle?.(row.original),
                        ]}
                      >
                        {/* @ts-ignore */}
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {showSeeMore && !loading && onPressSeeMore && (
        <Touchable onClick={handlePressSeeMore} css={styles.seeMoreContainer}>
          {seeMoreLoading && <ThreeDotsWave />}
          {!seeMoreLoading && (
            <>
              <p className="text-center font-semibold text-gray text-sm">See more</p>
              <ChevronDown css={styles.icon} />
            </>
          )}
        </Touchable>
      )}
    </div>
  );
};

export default memo(Table);
