import { ReactNode, memo, useCallback, useState } from "react";
import { usePlaid } from "src/apps/PreFundedDashboard/hooks/plaid";
import { ModalBox } from "src/components";
import { PopupProps } from "src/components/Popup";
import { useFormReducer, useStepper } from "src/hooks";
import recordDeleted from "src/images/record-deleted.png";
import { selectMicroDeposits } from "src/selectors";
import { createStyles } from "src/styles";

import { entities, parsers, schemas } from "@fraction/shared";

import ConfirmationModalScreen from "../../../../components/ConfirmationModalScreen";
import useBankAccountMutation from "../../hooks/useBankAccountMutation";
import AccountCreatedConfirmationScreen from "../sharedModalScreens/AccountCreatedConfirmationScreen";
import AddBankAccountScreen from "../sharedModalScreens/AddBankAccountScreen";
import DepositConfirmationScreen from "../sharedModalScreens/DepositConfirmationScreen";
import ExpiredVerificationScreen from "../sharedModalScreens/ExpiredVerificationScreen";
import MicrodepositAmountScreen, { MicroDeposits } from "../sharedModalScreens/MicrodepositAmountScreen";
import ReviewAddBankAccountScreen from "../sharedModalScreens/ReviewAddBankAccountScreen";
import SelectBankAccountScreen from "../sharedModalScreens/SelectBankAccountScreen";
import DeleteBankAccountReview from "./DeleteBankAccountConfirmation";
import EditBankAccountScreen from "./EditBankAccountScreen";

export interface BankAccountsModalProps extends Omit<PopupProps, "children"> {
  onClose: () => void;
  isLoading?: boolean;
  bankAccounts?: entities.BankAccount[];
  loan?: entities.LoanT;
}

// User flow diagram: https://drive.google.com/file/d/1DAqPLxjDb88zs-NhZiM76SPwwZ1OJ2hz/view?usp=sharing
enum Screen {
  SELECT_ACCOUNT = "select_account", // account selection, can skip if first account
  ADD_BANK_INFO = "add_bank_info", // full bank info form
  REVIEW_BANK_INFO = "review_bank_info",
  EDIT_BANK_INFO = "edit_bank_info",
  ENTER_MICRO_DEPOSIT = "enter_micro_deposit", // micro deposit amounts form
  CREATED_CONFIRMATION = "created_confirmation", // bank account created and deposit sent confirmation (after ADD_BANK_INFO)
  EDIT_CONFIRMATION = "edit_confirmation",
  DEPOSIT_CONFIRMED = "deposit_confirmed", // deposit amounts confirmed, account now verified (after ENTER_MICRO_DEPOSIT)
  DEPOSIT_RESENT = "deposit_resent", // deposit amounts failed (either expired or over 5 attempts), resend deposit (after ENTER_MICRO_DEPOSIT)
  DELETE_ACCOUNT = "delete_account", // delete account review
  DELETE_CONFIRMATION = "delete_confirmation", // delete account confirmation
}

const CREATE_ACCOUNT_SCREENS = [Screen.ADD_BANK_INFO, Screen.REVIEW_BANK_INFO, Screen.CREATED_CONFIRMATION];
const EDIT_ACCOUNT_SCREENS = [Screen.EDIT_BANK_INFO, Screen.EDIT_CONFIRMATION];
const VERIFY_ACCOUNT_SCREENS = [Screen.ENTER_MICRO_DEPOSIT, Screen.DEPOSIT_CONFIRMED];

const DEFAULT_WIDTH = 448;

const styles = createStyles({
  confirmationModal: {
    padding: "56px 48px",
  },
  illustration: {
    height: 160,
    "@media(max-width: 600px)": {
      height: 128,
    },
  },
});

const BankAccountsModal = ({ isLoading, bankAccounts, ...props }: BankAccountsModalProps) => {
  const { step, ...stepper } = useStepper([Screen.SELECT_ACCOUNT]);
  const [currentBankAccount, setCurrentBankAccount] = useState<entities.BankAccount | undefined>();

  const mutation = useBankAccountMutation({
    bankAccount: currentBankAccount,
    verifyOptions: {
      onExpiredDepositsError: () => stepper.push(Screen.DEPOSIT_RESENT),
    },
  });

  // Create bank account form state needs to be tracked across multiple screens
  const createBankAccountForm = useFormReducer({
    initialValues: { routingNumberType: schemas.modernTreasury.RoutingNumberType.ABA },
  });

  const handleSelectBankAccount = useCallback(
    (bankAccount: entities.BankAccount) => {
      setCurrentBankAccount(bankAccount);
      const isVerified =
        bankAccount.verificationStatus === schemas.modernTreasury.VerificationStatus.VERIFIED;
      stepper.push(isVerified ? EDIT_ACCOUNT_SCREENS : VERIFY_ACCOUNT_SCREENS);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepper.push]
  );

  const onClose = useCallback(() => {
    stepper.reset();
    createBankAccountForm.reset();
    setCurrentBankAccount(undefined);
    props.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.reset, props.onClose, createBankAccountForm.reset]);

  const handleDeleteAccount = useCallback(() => {
    stepper.insert(Screen.DELETE_ACCOUNT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.insert]);

  const cancelDeleteAttempt = useCallback(() => {
    stepper.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.remove]);

  const submitBankAccount = useCallback(() => {
    mutation.createAccount(createBankAccountForm.formValues as parsers.bankAccount.BankAccountSubmission, {
      onSuccess: stepper.goForward,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.createAccount, createBankAccountForm.formValues, stepper.goForward]);

  const editBankAccount = useCallback(
    (data: parsers.bankAccount.PatchBankAccountSubmission) => {
      mutation.editAccount(data, {
        onSuccess: stepper.goForward,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.editAccount, stepper.goForward]
  );

  const deleteBankAccount = useCallback(() => {
    mutation.deleteAccount(undefined, {
      onSuccess: () => stepper.push(Screen.DELETE_CONFIRMATION),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.deleteAccount, stepper.push]);

  const verifyBankAccount = useCallback(
    (data: MicroDeposits) => {
      mutation.verifyAccount(selectMicroDeposits(data), {
        onSuccess: stepper.goForward,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.verifyAccount, stepper.goForward]
  );

  const {
    start,
    ready,
    isLoading: isLoadingPlaid,
  } = usePlaid({ applicationId: props.loan?.applicationId, purpose: "payments" });

  const onAddBank = useCallback(() => {
    stepper.push(CREATE_ACCOUNT_SCREENS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.push]);

  // Mapping of possible modal states, returns a component to render and optional width override in the format: [component, width]
  const modalStates: Record<Screen, [ReactNode] | [ReactNode, number]> = {
    [Screen.SELECT_ACCOUNT]: [
      <SelectBankAccountScreen
        isLoading={isLoading}
        isPending={isLoadingPlaid}
        bankAccounts={bankAccounts}
        onAddBank={ready ? start : onAddBank}
        handleSelectBankAccount={handleSelectBankAccount}
        paragraph="Edit an existing bank account below or add a new one."
      />,
      518,
    ],

    // Create account screens:
    [Screen.ADD_BANK_INFO]: [
      <AddBankAccountScreen
        {...createBankAccountForm}
        onBack={stepper.goBack}
        onContinue={stepper.goForward}
      />,
    ],
    [Screen.REVIEW_BANK_INFO]: [
      <ReviewAddBankAccountScreen
        {...createBankAccountForm}
        onContinue={submitBankAccount}
        onBack={stepper.goBack}
        isLoading={mutation.isLoading}
      />,
      540,
    ],
    [Screen.CREATED_CONFIRMATION]: [<AccountCreatedConfirmationScreen onClose={onClose} />, 600],

    // Edit verified account screens:
    [Screen.EDIT_BANK_INFO]: [
      <EditBankAccountScreen
        onSubmit={editBankAccount}
        onDelete={handleDeleteAccount}
        loading={mutation.isLoading}
        bankAccount={currentBankAccount}
      />,
    ],
    [Screen.EDIT_CONFIRMATION]: [
      <ConfirmationModalScreen
        style={styles.confirmationModal}
        header="Hooray, your bank account has been updated!"
        text="If you’d like to start making prepayments and draws, please return to the dashboard."
        onClose={onClose}
      />,
    ],

    // Delete account screens:
    [Screen.DELETE_ACCOUNT]: [
      <DeleteBankAccountReview
        cancelDeleteAttempt={cancelDeleteAttempt}
        currentAccount={currentBankAccount}
        confirmDelete={deleteBankAccount}
        isLoading={mutation.isLoading}
      />,
    ],
    [Screen.DELETE_CONFIRMATION]: [
      <ConfirmationModalScreen
        style={styles.confirmationModal}
        header="Poof! This bank account record has been deleted."
        text="If you’d like to add a new bank account or check your balance, please return to the dashboard."
        onClose={onClose}
        image={<img alt="Hourglass" src={recordDeleted} css={styles.illustration} />}
      />,
    ],

    // Micro-deposit verification screens:
    [Screen.ENTER_MICRO_DEPOSIT]: [
      <MicrodepositAmountScreen
        onSubmit={verifyBankAccount}
        onDelete={handleDeleteAccount}
        loading={mutation.isLoading}
      />,
    ],
    [Screen.DEPOSIT_CONFIRMED]: [<DepositConfirmationScreen onClose={onClose} />],
    [Screen.DEPOSIT_RESENT]: [<ExpiredVerificationScreen onClose={onClose} />],
  };

  const [content, width] = modalStates[step];

  return (
    <ModalBox width={width || DEFAULT_WIDTH} {...props} onClose={onClose}>
      {content}
    </ModalBox>
  );
};

export default memo(BankAccountsModal);
