import { ReactNode, memo, useCallback, useMemo, useState } from "react";
import { ModalBox } from "src/components";
import { InputModalContent } from "src/components/InnerModals";
import { ModalAction } from "src/components/Modal";
import { PopupProps } from "src/components/Popup";
import { Button, SemiBoldText } from "src/components/v1";
import { useStepper, useWindowSize } from "src/hooks";
import { createStyles } from "src/styles";

import { constants, colors, types } from "@fraction/shared";

import ConfirmationModalScreen from "../ConfirmationModalScreen";

export interface PaymentScreen {
  actions: ModalAction[];
  virtualAccountNumber?: string;
  isLoading?: boolean;
}

export interface ConfirmationScreenProps extends Omit<PopupProps, "children"> {
  onBack: () => void;
}

export interface PrepaymentModalProps extends Omit<PopupProps, "children"> {
  virtualAccountNumber: string;
  onClose: () => void;
  onSubmit?: (submission: { type: types.PrepaymentType }, onSuccess: () => void) => void;
  isLoading?: boolean;
}

enum Screen {
  CHOOSE = "choose",
  PAYMENT = "payment",
  CONFIRMATION = "confirmation",
}

const FRACTION_BANK_INFO = {
  swift: "GSCRUS33",
  aba: "026015079",
  bankName: "Goldman Sachs Bank USA",
  bankAddress: "200 West St, New York, NY, 10282",
  beneficiaryName: "FRACTION LENDING US INC",
  officeAddress: "1725 Hughes Landing Blvd, Suite 11-135, The Woodlands, TX, 77380",
};

const SCREENS = [Screen.CHOOSE, Screen.PAYMENT, Screen.CONFIRMATION];

const STYLES = createStyles({
  paymentsButton: {
    padding: 16,
    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
  labelContainer: {
    display: "flex",
    flexDirection: "column",
    "&:not(:last-child)": {
      marginBottom: 16,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: "160%",
    color: colors.palette.GREY_500,
  },
  text: {
    fontSize: 14,
    color: colors.palette.GREY_800,
    lineHeight: "160%",
  },
});

const ChoosePaymentMethodScreen = ({
  handlePaymentTypeSelected,
}: {
  handlePaymentTypeSelected: (value: types.PrepaymentType) => void;
}) => {
  const onClickWire = useCallback(
    () => handlePaymentTypeSelected(types.PrepaymentType.WIRE),
    [handlePaymentTypeSelected]
  );
  const onClickACH = useCallback(
    () => handlePaymentTypeSelected(types.PrepaymentType.ACH),
    [handlePaymentTypeSelected]
  );
  const onClickCheck = useCallback(
    () => handlePaymentTypeSelected(types.PrepaymentType.CHECK),
    [handlePaymentTypeSelected]
  );
  return (
    <InputModalContent
      header="HOW TO MAKE A PREPAYMENT"
      paragraph="We offer 3 ways to make a prepayment on your loan. Select the method that works best for you and follow the instructions."
    >
      <Button onClick={onClickWire} style={STYLES.paymentsButton}>
        Wire Payment
      </Button>
      <Button onClick={onClickACH} style={STYLES.paymentsButton}>
        ACH Payment
      </Button>
      <Button onClick={onClickCheck} style={STYLES.paymentsButton} type="inversePrimary">
        Check Payment
      </Button>
    </InputModalContent>
  );
};

const TextWithLabel = ({ label, text }: { label: string; text: ReactNode }) => (
  <div css={STYLES.labelContainer}>
    <SemiBoldText style={STYLES.label}>{label}</SemiBoldText>
    <SemiBoldText style={STYLES.text}>{text}</SemiBoldText>
  </div>
);

const FRACTION_BANK_INFO_COMPONENT = (
  <>
    <TextWithLabel label="ABA Routing" text={FRACTION_BANK_INFO.aba} />
    <TextWithLabel label="Beneficiary Bank" text={FRACTION_BANK_INFO.bankName} />
    <TextWithLabel label="Bank Address" text={FRACTION_BANK_INFO.bankAddress} />
    <TextWithLabel label="Beneficiary Name" text={FRACTION_BANK_INFO.beneficiaryName} />
  </>
);

const WirePaymentScreen = ({ actions, virtualAccountNumber, isLoading }: PaymentScreen) => (
  <InputModalContent
    header="WIRE PAYMENT"
    paragraph="Log in to your online banking and select the wire payment option. Input the account information below."
    actions={actions}
    isLoading={isLoading}
  >
    {FRACTION_BANK_INFO_COMPONENT}
    <TextWithLabel label="Beneficiary Account Number" text={virtualAccountNumber} />
  </InputModalContent>
);

const ACHPaymentScreen = ({ actions, virtualAccountNumber, isLoading }: PaymentScreen) => (
  <InputModalContent
    header="ACH PAYMENT"
    paragraph="Log in to your online banking and select the ACH payment option. Input the account information below."
    actions={actions}
    isLoading={isLoading}
  >
    {FRACTION_BANK_INFO_COMPONENT}
    <TextWithLabel label="Beneficiary Account Number" text={virtualAccountNumber} />
  </InputModalContent>
);

const CheckPaymentScreen = ({ actions, isLoading }: PaymentScreen) => (
  <InputModalContent
    header="CHECK PAYMENT"
    paragraph="Send your check to our office listed below."
    actions={actions}
    isLoading={isLoading}
  >
    <TextWithLabel
      label="Fraction Lending US Inc. | Attn: Servicing Dept"
      text={
        <>
          {constants.AMERICAN_ADDRESS_LINE_1}
          <br />
          {constants.AMERICAN_ADDRESS_LINE_2}
        </>
      }
    />
  </InputModalContent>
);

/**
 * @deprecated To be replaced with PrepaymentModal
 */
const OldPrepaymentModal = ({ virtualAccountNumber, isLoading, ...props }: PrepaymentModalProps) => {
  const [paymentType, setPaymentType] = useState<types.PrepaymentType>();
  const { width } = useWindowSize();

  const { step, ...stepper } = useStepper<Screen>(SCREENS);

  const handlePaymentTypeSelected = useCallback(
    (selectedPaymentType: types.PrepaymentType) => {
      setPaymentType(selectedPaymentType);
      stepper.goForward();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepper.goForward]
  );

  const onSubmit = useCallback(() => {
    if (!paymentType) {
      return;
    }
    props.onSubmit?.({ type: paymentType }, stepper.goForward);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.goForward, props.onSubmit, paymentType]);

  const onClose = useCallback(() => {
    stepper.reset();
    props.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.reset, props.onClose]);

  const paymentActions = useMemo(
    () => [
      {
        text: "Back",
        action: stepper.goBack,
      },
      {
        text: width < 600 ? "Next" : "Completed my payment",
        action: onSubmit,
      },
    ],
    [stepper.goBack, onSubmit, width]
  );

  const modalProps = {
    actions: paymentActions,
    isLoading,
  };

  return (
    <ModalBox width={448} {...props} onClose={onClose}>
      {step === Screen.CHOOSE && (
        <ChoosePaymentMethodScreen handlePaymentTypeSelected={handlePaymentTypeSelected} />
      )}
      {step === Screen.PAYMENT && (
        <>
          {paymentType === types.PrepaymentType.WIRE && (
            <WirePaymentScreen {...modalProps} virtualAccountNumber={virtualAccountNumber} />
          )}
          {paymentType === types.PrepaymentType.ACH && (
            <ACHPaymentScreen {...modalProps} virtualAccountNumber={virtualAccountNumber} />
          )}
          {paymentType === types.PrepaymentType.CHECK && <CheckPaymentScreen {...modalProps} />}
        </>
      )}

      {step === Screen.CONFIRMATION && (
        <ConfirmationModalScreen
          header="PENDING CONFIRMATION"
          text="Once you’ve made a payment and it has been confirmed by all parties, we will send you a confirmation email."
        />
      )}
    </ModalBox>
  );
};

export default memo(OldPrepaymentModal);
