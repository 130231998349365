import { entities, formatters } from "@fraction/shared";
import { Badge } from "./ui/badge";

export function BankAccountList({ bankAccounts }: { bankAccounts?: entities.BankAccount[] }) {
  return (
    <div>
      {bankAccounts?.map((bankAccount) => (
        <div
          className="flex flex-row hover:bg-gray-200 odd:bg-gray-100 p-2 odd:rounded gap-x-4"
          key={bankAccount.id}
        >
          <div>
            <b className="text-lg">{bankAccount.name}</b>
            <p className="text-base text-gray-600">•••• •••• {bankAccount.accountNumberLast4Digits}</p>
          </div>
          <div>
            {bankAccount.currentBalance ? (
              <Badge variant="gray" className="text-xs rounded-full font-medium">
                {formatters.number.getCurrencyFromNumber(bankAccount.currentBalance)}
              </Badge>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}
