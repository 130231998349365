import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import FinePrint from "src/components/FinePrint";
import TextLink from "src/components/TextLink";

export interface FooterDisclosureProps {
  style?: SerializedStyles;
  className?: string;
}

const FooterDisclosure = ({ style, className }: FooterDisclosureProps) => (
  <FinePrint className={className} style={style}>
    Fraction is a family of companies. Fraction Lending Inc. provides mortgages in Canada; Fraction Lending US
    Inc. provides HELOC products in the United States. All rights reserved. NMLS #2084341. British Columbia
    Mortgage License #X300860. Not available in all provinces or states.{" "}
    <TextLink href="https://www.fraction.com/equal-credit-opportunity-act">Equal Housing Lender</TextLink>.
  </FinePrint>
);

export default memo(FooterDisclosure);
