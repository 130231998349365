import { SerializedStyles } from "@emotion/react";
import { ReactElement, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Touchable from "src/components/Touchable";
import { SemiBoldText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface LinkProps {
  children: ReactElement | string;
  href?: string;
  popup?: boolean;
  onClick?: () => void;
  style?: SerializedStyles;
  textStyle?: SerializedStyles | (SerializedStyles | undefined | false)[];
}

const styles = createStyles({
  container: {
    padding: 0,
  },
  link: {
    color: colors.LINK,
    fontSize: 14,
    margin: 0,
  },
});

const Link = ({ children, href, popup, onClick, style, textStyle }: LinkProps) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (popup && href) {
      window.open(href, "_blank", "noopener,noreferrer");
    } else if (href) {
      navigate(href);
    }
  }, [popup, href, navigate]);

  return (
    <Touchable css={[styles.container, style]} onClick={onClick || handleClick}>
      {(typeof children === "string" || typeof children === "undefined") && (
        <SemiBoldText style={[styles.link, textStyle]}>{children}</SemiBoldText>
      )}
      {typeof children !== "string" && children}
    </Touchable>
  );
};

export default Link;
