import { ReactNode } from "react";
import { BigButton } from "src/components";
import { InputModalFooter, InputModalForm } from "src/components/InnerModals";
import { MediumText } from "src/components/v1";
import { AutoDeposit, OneTimePay } from "src/icons";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

const styles = createStyles({
  alert: {
    marginBottom: 32,
  },
  oneTimeButton: {
    marginBottom: 16,
  },
  buttonText: {
    color: colors.palette.GREY_700,
  },
  buttonLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    color: colors.palette.GREY_700,
    minWidth: 24,
    minHeight: 24,
    marginRight: 16,
  },
});

const PaymentFrequencyScreen = ({
  header,
  paragraph,
  onBack,
  alert,
  handleSelectOneTimePayment,
  handleSelectRecurringPayment,
}: {
  maxAmount?: number;
  header: string;
  paragraph: string;
  onBack: () => void;
  alert?: ReactNode;
  handleSelectOneTimePayment: () => void;
  handleSelectRecurringPayment: () => void;
}) => (
  <InputModalForm
    header={header}
    paragraph={paragraph}
    footer={
      <InputModalFooter
        actions={[
          {
            text: "Back",
            action: onBack,
            secondary: true,
          },
        ]}
      />
    }
  >
    {alert}
    <BigButton onClick={handleSelectOneTimePayment} style={styles.oneTimeButton}>
      <div css={styles.buttonLayout}>
        <OneTimePay css={styles.icon} />
        <MediumText style={styles.buttonText}>One time payment</MediumText>
      </div>
    </BigButton>
    <BigButton onClick={handleSelectRecurringPayment}>
      <div css={styles.buttonLayout}>
        <AutoDeposit css={styles.icon} />
        <MediumText style={styles.buttonText}>Monthly auto-payment</MediumText>
      </div>
    </BigButton>
  </InputModalForm>
);
export default PaymentFrequencyScreen;
