import { entities, enums, formatters, types } from "@fraction/shared";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import _ from "lodash";
import { CheckCircle, CircleHelpIcon, Clock, EyeIcon, Loader, Trash } from "lucide-react";
import { useCallback } from "react";
import { useDeleteDocuments } from "src/apps/PostFundedDashboard/queries";
import Dropzone, { FileWithEnumType } from "src/components/Dropzone";
import { PDFViewer } from "src/components/PDFViewer";
import Tooltip from "src/components/Tooltip";
import { Badge } from "src/components/ui/badge";
import { useUploadDocuments } from "src/hooks/useSimpleUploadDocuments";
import { useMutation } from "src/lib";
import { cn } from "src/utilities/shadcnUtils";
import useModal from "../hooks/useModal";

export function DocumentChecklistRow({
  item,
  allDebts,
  applicationId,
  onUploadSuccess,
  canUpload,
  disabled,
}: {
  item: types.DocumentChecklistItem;
  allDebts: entities.Debt[];
  applicationId?: string;
  onUploadSuccess?: () => Promise<any>;
  canUpload?: boolean;
  disabled?: boolean;
}) {
  const debt = item?.debtId ? allDebts?.find((debt) => debt.id === item?.debtId) : null;
  const refetchFromDeleteMutation = useMutation({
    mutationFn: onUploadSuccess,
  });
  const refetchFromUploadMutation = useMutation({
    mutationFn: onUploadSuccess,
  });
  const { mutateAsync: uploadDocuments, isPending: uploadPending_ } = useUploadDocuments({
    onUploadSuccess: () => refetchFromUploadMutation.mutateAsync(),
  });
  const { mutateAsync: deleteDocument, isPending: deletePending_ } = useDeleteDocuments({
    onSuccess: refetchFromDeleteMutation.mutateAsync,
  });

  const deletePending = deletePending_ || refetchFromDeleteMutation.isPending;
  const uploadPending = uploadPending_ || refetchFromUploadMutation.isPending;

  const onClickDelete = useCallback(async () => {
    if (!item.id) {
      throw new Error("No document id");
    }
    deleteDocument(item.id);
  }, [item?.id]);

  const { showModal } = useModal();
  const handleClickPreview = useCallback(() => {
    if (!item.documentUrl) {
      return;
    }
    showModal({
      shovable: true,
      children: (
        <PDFViewer
          renderTextLayer
          filename={item.documentName}
          toolbar="footer"
          download
          pdfClassName="h-[700px]"
          url={item.documentUrl}
        />
      ),
    });
  }, []);

  const handleDropDocument = useCallback(
    (docs: FileWithEnumType[]) => {
      if (!item?.document || !applicationId) {
        throw new Error("No document type found");
      }
      uploadDocuments({
        assets: docs,
        applicationId,
        documentTypeName: item?.document,
        applicantId: item?.applicantId,
        debtId: item?.debtId,
        incomeId: item?.incomeId,
        date: item.year ? new Date(item.year) : undefined,
      });
    },
    [item?.type, applicationId, uploadDocuments, item?.applicantId, item?.debtId, item?.incomeId]
  );

  return (
    <Tooltip
      white
      key={item.id}
      text={
        <div>
          <p>
            <b>{item?.documentName}</b>
          </p>
          <p className="text-xs">
            {item?.approval
              ? item.approval === enums.ApplicationTaskApprovalStatus.REQUESTED
                ? "Pending Fraction review"
                : `Document ${formatters.enums.APPROVAL_STATUS_MAP[item?.approval]?.toLowerCase()}`
              : "Waiting for upload"}
            <i>{item?.notes ? `: ${item?.notes}` : ""}</i>
          </p>
          <p className="text-xs mt-1">{item?.documentDescription}</p>
        </div>
      }
    >
      <div
        key={item.id}
        className={cn(
          "flex flex-col md:flex-row flex-wrap md:items-center justify-between p-3 rounded gap-1 odd:bg-gray-100 bg-white hover:bg-gray-200 relative",
          disabled && "opacity-40"
        )}
      >
        <div className="md:max-w-[50%]">
          <p onClick={handleClickPreview} className="cursor-pointer">
            {item.documentName || _.startCase(item.document)} {item.year ? `for ${item.year}` : ""}
            {item?.approval ? (
              <div
                className={cn(
                  "rounded-full inline-flex items-center justify-center h-3 w-3 align-middle mb-1",
                  item?.approval === enums.ApplicationTaskApprovalStatus.APPROVED && "bg-green",
                  item?.approval === enums.ApplicationTaskApprovalStatus.REQUESTED && "bg-yellow",
                  item?.approval === enums.ApplicationTaskApprovalStatus.NEEDS_WORK && "bg-orange",
                  item?.approval === enums.ApplicationTaskApprovalStatus.REJECTED && "bg-red"
                )}
              >
                {item?.approval === enums.ApplicationTaskApprovalStatus.APPROVED ? (
                  <CheckCircle className="inline text-white" />
                ) : item?.approval === enums.ApplicationTaskApprovalStatus.REQUESTED ? (
                  <Clock className="inline text-white" />
                ) : item?.approval === enums.ApplicationTaskApprovalStatus.REJECTED ? (
                  <CrossCircledIcon className="inline text-white" />
                ) : item?.approval === enums.ApplicationTaskApprovalStatus.NEEDS_WORK ? (
                  <CircleHelpIcon className="inline text-white" />
                ) : null}
              </div>
            ) : null}
          </p>
          {item.applicantName && !item.incomeType ? (
            <p className="text-sm font-medium text-gray-600">For {item.applicantName}</p>
          ) : null}
          {item.applicantName && item.incomeType ? (
            <p className="text-sm font-medium text-gray-600">{formatIncomeItem(item)}</p>
          ) : null}
          {item.debtWithWho ? (
            <p className="text-sm font-medium text-gray-600">
              For {item.debtWithWho || item.debtDescription || ""}{" "}
              {item.debtType
                ? formatters.enums.DEBT_TYPE_MAP[item.debtType as enums.DebtType]?.toLowerCase()
                : ""}
              {debt?.currentlyOwing
                ? ` (${formatters.number.getCurrencyFromNumber(debt.currentlyOwing)})`
                : ""}
            </p>
          ) : null}
          {item?.notes ? (
            <p className="mt-1 bg-gray-400 p-1.5 px-2 text-sm rounded">
              <b>Notes:</b> {item.notes}
            </p>
          ) : null}
        </div>
        <div className="flex flex-row gap-x-1">
          {!deletePending ? (
            <>
              <Dropzone
                accept={[types.MimeType.PDF, types.MimeType.PNG, types.MimeType.JPEG, types.MimeType.HEIC]}
                disabled={!canUpload || uploadPending}
                multi
                onDrop={handleDropDocument}
                dragActiveChildren={
                  <Badge className="w-full" variant="default" loading={uploadPending}>
                    Drag file here
                  </Badge>
                }
              >
                <Badge
                  className="w-full"
                  variant={
                    item?.approval === enums.ApplicationTaskApprovalStatus.APPROVED
                      ? "success"
                      : item?.approval === enums.ApplicationTaskApprovalStatus.REQUESTED
                      ? "secondary"
                      : item?.approval === enums.ApplicationTaskApprovalStatus.REJECTED
                      ? "destructive"
                      : item?.approval === enums.ApplicationTaskApprovalStatus.NEEDS_WORK
                      ? "alert"
                      : item?.status === enums.ApplicationTaskStatus.TODO && !uploadPending
                      ? "default"
                      : "default"
                  }
                >
                  {item?.approval === enums.ApplicationTaskApprovalStatus.APPROVED ? (
                    <CheckCircle height={14} width={14} className="text-black mr-1" />
                  ) : item?.approval === enums.ApplicationTaskApprovalStatus.REQUESTED ? (
                    <Clock height={14} width={14} className="text-black mr-1" />
                  ) : item?.approval === enums.ApplicationTaskApprovalStatus.REJECTED ? (
                    <CrossCircledIcon height={14} width={14} className="text-white mr-1" />
                  ) : item?.approval === enums.ApplicationTaskApprovalStatus.NEEDS_WORK ? (
                    <CircleHelpIcon height={14} width={14} className="text-white mr-1" />
                  ) : null}
                  {uploadPending ? (
                    <>
                      <Loader height={14} className="text-white animate-spin" /> Uploading...
                    </>
                  ) : item?.approval === enums.ApplicationTaskApprovalStatus.REJECTED ? (
                    "Rejected"
                  ) : item?.approval === enums.ApplicationTaskApprovalStatus.NEEDS_WORK ? (
                    "Needs work"
                  ) : item.status === enums.ApplicationTaskStatus.COMPLETE ? (
                    item?.approval === enums.ApplicationTaskApprovalStatus.APPROVED ? (
                      "Approved"
                    ) : (
                      "Pending review"
                    )
                  ) : (
                    "Upload doc"
                  )}
                </Badge>
              </Dropzone>
              {item?.documentUrl ? (
                <Badge onClick={handleClickPreview} className="w-full cursor-pointer" variant="default">
                  <EyeIcon height={12} />
                </Badge>
              ) : null}
            </>
          ) : null}
          {item?.documentUrl ? (
            <button disabled={deletePending} onClick={onClickDelete}>
              <Badge variant="destructive">{!deletePending ? <Trash height={12} /> : "Deleting..."}</Badge>
            </button>
          ) : null}
        </div>
        {disabled ? <div className="absolute top-0 left-0 right-0 bottom-0 cursor-not-allowed" /> : null}
      </div>
    </Tooltip>
  );
}

const formatIncomeItem = (item: types.DocumentChecklistItem) => {
  let str = `For ${formatters.user.possessive(item.applicantName)}`;

  if (item?.incomeAmount) {
    str = `${str} ${formatters.number.getCurrencyWords(item.incomeAmount)}`;
  }

  if (item?.incomeType) {
    str = `${str} ${_.startCase(item.incomeType).toLowerCase()}`;
  }

  const jobTitle = item.incomeJobTitle || item.incomeDescription;
  if (
    item.incomeType &&
    jobTitle &&
    [enums.IncomeType.EMPLOYMENT_INCOME, enums.IncomeType.SELF_EMPLOYMENT_INCOME].includes(
      item.incomeType as enums.IncomeType
    )
  ) {
    return `${str} as a ${jobTitle?.replace("|", "in").toLowerCase()}`;
  }
};
