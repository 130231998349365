import { useEffect, useState } from "react";

export interface GeoLocationOptions {
  enableHighAccuracy?: boolean;
  maximumAge?: number;
  timeout?: number;
  track?: boolean;
}

export interface GeoLocation {
  accuracy: number;
  altitude: number;
  altitudeAccuracy: number;
  heading: number;
  latitude: number;
  longitude: number;
  speed: number;
  timestamp: number;
  error: any;
}
const useGeolocation = (
  { enableHighAccuracy, maximumAge, timeout, track = true }: GeoLocationOptions = {},
  callback?: (info: GeoLocation) => void
) => {
  const [coordinates, setCoordinates] = useState<any>({
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: null,
    error: null,
  });

  useEffect(() => {
    if (!track) {
      return;
    }
    let didCancel = false;
    const updateCoordinates = ({ coords = {}, timestamp }: { coords: any; timestamp: number }) => {
      const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = coords;
      if (!didCancel) {
        setCoordinates({
          accuracy,
          altitude,
          altitudeAccuracy,
          heading,
          latitude,
          longitude,
          speed,
          timestamp,
          error: null,
        });
        if (callback instanceof Function) {
          callback({
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            latitude,
            longitude,
            speed,
            timestamp,
            error: null,
          });
        }
      }
    };

    const setError = (error: any) => {
      if (!didCancel) {
        setCoordinates({
          accuracy: null,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: null,
          longitude: null,
          speed: null,
          timestamp: null,
          error,
        });
      }
    };

    let watchId: number;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updateCoordinates, setError);
      watchId = navigator.geolocation.watchPosition(updateCoordinates, setError, {
        enableHighAccuracy,
        maximumAge,
        timeout,
      });
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
      didCancel = true;
    };
  }, [callback, enableHighAccuracy, maximumAge, timeout, track]);

  return coordinates;
};

export default useGeolocation;
