import { SerializedStyles } from "@emotion/react";
import * as React from "react";
import { useCallback } from "react";
import Tooltip from "src/components/Tooltip";
import Touchable from "src/components/Touchable";
import { BoldText, RegularText } from "src/components/v1";
import { Edit2, Trash2 } from "src/icons";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface FlowLayoutItemProps {
  header: string;
  description: string;
  subheader: string;
  index?: number;
  onClickEdit?: (index: number) => void;
  onClickDelete?: (index: number) => void;
  style?: SerializedStyles;
  secondary?: boolean;
}

const styles = createStyles({
  itemContainer: {
    borderRadius: 16,
    boxSizing: "border-box",
    height: 126,
    width: 285,
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 22,
    paddingBottom: 22,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "80%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
  },
  filledItemContainer: {
    backgroundColor: colors.itemCard.FILLED.BACKGROUND,
    borderColor: colors.itemCard.FILLED.OUTLINE,
  },
  unfilledItemContainer: {
    backgroundColor: colors.itemCard.UNFILLED.BACKGROUND,
    borderColor: colors.itemCard.UNFILLED.OUTLINE,
  },
  filledItemContainerSecondary: {
    backgroundColor: colors.itemCard.FILLED_SECONDARY.BACKGROUND,
    borderColor: colors.itemCard.FILLED_SECONDARY.OUTLINE,
  },
  unfilledItemContainerSecondary: {
    backgroundColor: colors.itemCard.UNFILLED_SECONDARY.BACKGROUND,
    borderColor: colors.itemCard.UNFILLED_SECONDARY.OUTLINE,
  },
  header: {
    fontSize: 20,
    fontStyle: "italic",
    color: colors.itemCard.FILLED.TEXT,
    marginBottom: 4,
    marginTop: 4,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  subheader: {
    fontSize: 14,
    color: colors.itemCard.FILLED.TEXT,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  description: {
    fontSize: 16,
    fontStyle: "italic",
    color: colors.itemCard.FILLED.TEXT,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  firstIcon: {
    marginBottom: 20,
  },
});

export const FlowLayoutItemContainer = React.memo(
  ({
    children,
    outline,
    secondary,
    style,
  }: {
    children: React.ReactNode;
    outline?: boolean;
    secondary?: boolean;
    style?: SerializedStyles | (SerializedStyles | undefined | false)[];
  }) => {
    let baseStyle;
    if (outline) {
      baseStyle = secondary ? styles.unfilledItemContainerSecondary : styles.unfilledItemContainer;
    } else {
      baseStyle = secondary ? styles.filledItemContainerSecondary : styles.filledItemContainer;
    }
    return <div css={[styles.itemContainer, baseStyle, style]}>{children}</div>;
  }
);

const FlowLayoutItem = ({
  onClickDelete,
  onClickEdit,
  index,
  header,
  subheader,
  description,
  style,
  secondary,
}: FlowLayoutItemProps) => {
  const handleOnClickDelete = useCallback(() => {
    if (index !== undefined) {
      onClickDelete?.(index);
    }
  }, [onClickDelete, index]);

  const handleOnClickEdit = useCallback(() => {
    if (index !== undefined) {
      onClickEdit?.(index);
    }
  }, [onClickEdit, index]);

  return (
    <FlowLayoutItemContainer secondary={secondary} style={style}>
      <div css={styles.textContainer}>
        <RegularText style={styles.subheader}>{subheader}</RegularText>
        <BoldText style={styles.header}>{header}</BoldText>
        <RegularText style={styles.description}>{description}</RegularText>
      </div>
      <div css={styles.buttonContainer}>
        {onClickDelete && (
          <Tooltip text="Delete">
            <Touchable css={styles.firstIcon} onClick={handleOnClickDelete}>
              <Trash2 height={24} width={24} color="white" />
            </Touchable>
          </Tooltip>
        )}
        {onClickEdit && (
          <Tooltip text="Edit">
            <Touchable onClick={handleOnClickEdit}>
              <Edit2 height={24} width={24} color="white" />
            </Touchable>
          </Tooltip>
        )}
      </div>
    </FlowLayoutItemContainer>
  );
};

export default React.memo(FlowLayoutItem);
