import CostCalculator from "src/embeds/CostCalculator";

export function CostCalculatorPage() {
  return (
    <div className="p-6 flex flex-col gap-2">
      <p className="font-serif-deck text-3xl font-bold">Fraction Mortgage Cost Calculator</p>
      <p>
        Calculate the total loan balance on a per-year basis for a Fraction mortgage.
        <br />
        If you change the term, it will set the current rate for that given term.
      </p>
      <CostCalculator forBrokers className="w-fit p-0 mt-4" />
    </div>
  );
}
