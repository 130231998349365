import { Global, css } from "@emotion/react";
import { format as formatDate, parse } from "date-fns";
import { FocusEvent, Ref, useCallback, useMemo, useRef, useState } from "react";
import Calendar, {
  CalendarProps,
  Detail,
  OnChangeDateCallback,
  ViewCallbackProperties,
} from "react-calendar";
import "react-calendar/dist/Calendar.css";
import TextInput from "src/components/TextInput";
import { TextInputProps } from "src/components/TextInput";
import useDimensions from "src/hooks/useDimensions";
import { createStyles } from "src/styles";

import { colors, formatters } from "@fraction/shared";

type Inheritable = Omit<TextInputProps, "value" | "onChange" | "defaultValue"> &
  Omit<CalendarProps, "value" | "onChange">;

export interface DatePickerCalendarProps extends Inheritable {
  onChange?: (val: Date | undefined) => void;
  value: Date | undefined;
  focusRef?: Ref<HTMLInputElement> & any;
  defaultValue?: Date;
  minDate?: Date;
  maxDate?: Date;
  format?: string;
  inputFormats?: string[];
  inputFormatMask?: string;
}

const MIN_DATE_DEFAULT = new Date(1900, 1, 1);
const MAX_DATE_DEFAULT = new Date(2099, 12, 31);
const NO_MASK = { regex: ".*" } as const;

const styles = createStyles({
  container: {
    position: "relative",
  },
  calendar: {
    // ignore the spacer below the textinput
    marginTop: -18,
    position: "absolute",
    zIndex: 1,
  },
  hiddenClickCatcher: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
  },
});

const CALENDAR_CSS_OVERRIDE = css`
  .react-calendar {
    font-family: Montserrat, sans-serif;
  }
  .react-calendar__month-view__days__day--weekend {
    color: ${colors.BLACK_TEXT};
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${colors.BLACK_TEXT};
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: ${colors.palette.GREEN_100};
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: ${colors.palette.GREEN_200};
  }
  .react-calendar__tile--hasActive {
    background: ${colors.palette.GREEN_300};
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: ${colors.palette.GREEN};
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${colors.palette.GREEN_300};
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
`;

/**
 * Date input supporting both text input & a visual widget
 *
 * @param format - The display format for when the input is not focused
 * @param inputFormats - Date formats to use when parsing text input. To support
 *    partial input (e.g. just the day) provide multiple formats in descending
 *    specificity. The first format should always be a complete match.
 * @param inputFormatMask - The format of the text input mask. Note that this
 *    format is for Inputmask and is not equivalent to formats for date-fns.
 */
const DatePickerCalendar = ({
  value,
  onChange,
  focusRef,
  defaultValue,
  defaultActiveStartDate,
  defaultView = "month",
  minDate = MIN_DATE_DEFAULT,
  maxDate = MAX_DATE_DEFAULT,
  format = "MMMM dd, y",
  inputFormats = ["MM/dd/yyyy", "MM/dd/", "MM/"],
  inputFormatMask = "mm/dd/yyyy",
  ...props
}: DatePickerCalendarProps) => {
  const { onFocus, onBlur } = props;

  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState<string>();
  const [view, setView] = useState<Detail>(defaultView);
  const [activeStartDate, setActiveStartDate] = useState<Date | undefined>(defaultActiveStartDate);

  const inputRefInternal = useRef<HTMLInputElement>(null);
  const inputRef = focusRef || inputRefInternal;

  const onChangeFinal = useCallback(
    (val: Date | undefined) => {
      onChange?.(val);
      // If we do not track & change activeStartDate ourselves (e.g. just using
      // the defaultActiveStartDate prop), the widget does not follow `value`.
      setActiveStartDate(val);
    },
    [onChange]
  );

  const handleSelectDate: OnChangeDateCallback = useCallback(
    (selected) => {
      setVisible(false);
      setInputValue(formatDate(selected, format));
      setError(undefined);
      onChangeFinal(Array.isArray(selected) ? selected[0] : selected);
    },
    [format, onChangeFinal]
  );

  const handleFocus = useCallback(
    (evt: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setVisible(true);
      setInputValue("");
      onFocus?.(evt);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | React.MouseEvent<HTMLDivElement>) => {
      setVisible(false);
      setInputValue(value ? formatDate(value, format) : "");
      setError(undefined);
      // @ts-ignore
      onBlur?.(evt);
    },
    [value, format, onBlur]
  );

  const handleKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (["Enter", "Escape"].includes(evt.key)) {
        // @ts-ignore
        handleBlur(evt);
        inputRef?.current?.blur();
      }
    },
    [inputRef, handleBlur]
  );

  const onViewChange = useCallback(({ view: newView }: ViewCallbackProperties) => {
    setView(newView);
  }, []);

  const onActiveStartDateChange = useCallback(
    ({ activeStartDate: newActiveStartDate }: ViewCallbackProperties) => {
      setActiveStartDate(newActiveStartDate);
    },
    []
  );

  const onUnMask: Inputmask.Options["onUnMask"] = useCallback(
    (maskedValue: string, unmaskedValue: string) => {
      setError(undefined);

      const referenceDate = value || defaultValue || defaultActiveStartDate || new Date();

      let inputFormatIndex = 0;
      for (const inputFormat of inputFormats) {
        const dateFromInput = parse(maskedValue, inputFormat, referenceDate);

        if (formatters.date.isValidDate(dateFromInput)) {
          if (dateFromInput >= minDate && dateFromInput <= maxDate) {
            // When there's a valid date, show the month view so the calendar
            // widget can follow along.
            setView("month");
            onChangeFinal(dateFromInput);
          } else if (inputFormatIndex === 0) {
            // Only provide an error if a full date is provided. The first input
            // format is expected to be for a complete date.
            setError(`Enter a date between ${minDate.getFullYear()} and ${maxDate.getFullYear()}`);
          }
          break;
        }

        inputFormatIndex++;
      }

      return unmaskedValue;
    },
    [value, defaultValue, defaultActiveStartDate, inputFormats, minDate, maxDate, onChangeFinal]
  );

  const { height, observe } = useDimensions<HTMLDivElement>();

  const placeholder = inputFormatMask.toLowerCase();
  const maskOptions: Inputmask.Options = useMemo(
    () =>
      visible
        ? {
            alias: "datetime",
            inputFormat: inputFormatMask,
            onUnMask,
          }
        : NO_MASK,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputFormatMask, visible]
  );

  return (
    <div>
      <Global styles={CALENDAR_CSS_OVERRIDE} />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      {visible && <div css={[styles.hiddenClickCatcher]} onClick={handleBlur} />}
      <div css={styles.container}>
        <TextInput
          divRef={observe}
          ref={inputRef}
          {...props}
          error={visible ? error : undefined}
          value={inputValue}
          onChange={setInputValue}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          maskOptions={maskOptions}
          placeholder={placeholder}
        />
        {visible && (
          <Calendar
            view={view}
            onViewChange={onViewChange}
            css={[styles.calendar, { top: height }]}
            onChange={handleSelectDate}
            value={value}
            defaultValue={defaultValue}
            minDate={minDate}
            maxDate={maxDate}
            activeStartDate={activeStartDate}
            onActiveStartDateChange={onActiveStartDateChange}
            {...props}
          />
        )}
      </div>
    </div>
  );
};

export default DatePickerCalendar;
