import { useComms } from "src/apps/LOS/hooks/queries";
import { MailBlock } from "src/apps/LOS/pages/Mail";
import { Skeleton } from "src/components/index";

export function AppComms({ applicationId }: { applicationId?: string }) {
  const { data: comms, isLoading: commsLoading } = useComms({ appId: applicationId });

  return (
    <div className="flex gap-2 flex-col col-span-3 w-full">
      {commsLoading ? <Skeleton width="100%" height={400} /> : null}
      {comms?.length ? (
        <div className="border p-6 border-gray-400 rounded w-full max-h-[600px] overflow-y-scroll flex flex-col gap-4">
          <p className="text-2xl mb-1 font-bold">Comms</p>
          {comms?.map((mail) => (
            <MailBlock mail={mail} key={mail.id} />
          ))}
        </div>
      ) : null}
    </div>
  );
}
