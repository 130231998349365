import { ReactNode } from "react";
import { Badge, BadgeProps } from "src/components/ui/badge";
import { useClipboard } from "src/hooks/useClipboard";
import { cn } from "src/utilities/shadcnUtils";

export const ClipboardBadge = (
  props: Omit<BadgeProps, "children"> & { children: ReactNode; toCopy?: string }
) => {
  const onClick = useClipboard(props.toCopy || (typeof props.children === "string" ? props.children : ""));

  return (
    <button onClick={onClick}>
      <Badge className={cn("rounded-full", props.className)} {...props} />
    </button>
  );
};
