import { useCallback } from "react";
import { useAuth } from "src/auth";
import { DashboardHeader, DrawerModal, MonoButton, Touchable } from "src/components";
import { X } from "src/icons";
import { createStyles } from "src/styles";

import usePostFundedDashStore from "../../store";

const styles = createStyles(({ spacing, colors }) => ({
  drawerContainer: {
    background: colors.white,
    width: "100%",
    height: "100%",
  },
  drawerActions: {
    marginRight: [spacing[2], spacing[6]],
    display: "flex",
    alignItems: "center",
  },
  icon: {
    padding: spacing[0.5],
    color: colors.grey,
    height: 36,
    width: 36,
  },
  linksContainer: {
    padding: `${spacing[3]}px ${spacing[2]}px`,
  },
  logout: {
    marginTop: spacing[5],
  },
}));

// slide in nav drawer for mobile
const NavDrawer = ({ NavItems }: { NavItems: React.ComponentType<any> }) => {
  const navDrawerOpen = usePostFundedDashStore((state) => state.navDrawerOpen);
  const closeNavDrawer = usePostFundedDashStore((state) => state.closeNavDrawer);
  const { logout, isLoggingOut } = useAuth();

  const onLogout = useCallback(() => {
    closeNavDrawer();
    logout();
  }, [closeNavDrawer, logout]);

  return (
    <DrawerModal mounted={navDrawerOpen} transitionDuration={0.5}>
      <div css={styles.drawerContainer}>
        <DashboardHeader>
          <div css={styles.drawerActions}>
            <Touchable onClick={closeNavDrawer}>
              <X css={styles.icon} />
            </Touchable>
          </div>
        </DashboardHeader>
        <div css={styles.linksContainer}>
          {NavItems ? <NavItems onClick={closeNavDrawer} /> : null}
          <MonoButton
            style={styles.logout}
            onClick={onLogout}
            loading={isLoggingOut}
            fullWidth
            variant="outlined"
          >
            Log out
          </MonoButton>
        </div>
      </div>
    </DrawerModal>
  );
};

export default NavDrawer;
