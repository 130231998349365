import { CalendarClock } from "lucide-react";
import { ReactNode, memo, useCallback, useMemo, useState } from "react";
import { Cell, Column } from "react-table";
import { TransactionHistoryRecord } from "src/apps/PostFundedDashboard/queries";
import { Chip, Table, Tooltip } from "src/components";
import { Variant } from "src/components/Chip";
import { MaskedBankAccount } from "src/components/MaskedBankAccount";
import { SectionHeader } from "src/components/SectionHeader";
import { PiggyBank } from "src/images";
import { selectTransactionForViewing } from "src/selectors";
import { createStyles } from "src/styles";

import { entities, enums, formatters } from "@fraction/shared";
import { cn } from "src/utilities/shadcnUtils";

import { isSameMonth } from "date-fns";
import EmptyDataCard from "../EmptyDataCard";

const CARD_SPACING = 32;

interface TransactionHistoryProps {
  columns?: Array<Column>;
  data: TransactionHistoryRecord[];
  loading?: boolean;
  className?: string;
  compoundFrequency?: enums.CompoundFrequency;
  productType?: enums.ProductType;
}

const styles = createStyles({
  card: {
    marginBottom: CARD_SPACING,
    width: "100%",
  },
  piggyBank: {
    marginTop: 64,
    marginBottom: 64,
    marginLeft: 32,
    height: 193,
    width: 317,
    "@media(max-width: 1048px)": {
      height: 122,
      width: 200,
      marginTop: 24,
      marginBottom: 24,
    },
  },
  cellContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  statusChip: {
    marginTop: 8,
  },
  accountNameText: {
    fontSize: 14,
  },
  accountNumberText: {
    marginTop: 4,
    fontSize: 14,
  },
  amountCell: {
    fontWeight: 700,
  },
  pendingAmount: { transform: "translateX(-25%)" },
});

export const TRANSACTION_STATUS_CHIP_MAPPING: Record<
  enums.TransactionStatus | "SCHEDULED",
  { variant: Variant; text: string } | null
> = {
  SCHEDULED: {
    variant: Variant.BASE,
    text: "Scheduled",
  },
  [enums.TransactionStatus.CREATED]: {
    variant: Variant.YELLOW,
    text: "Pending",
  },
  [enums.TransactionStatus.PENDING]: {
    variant: Variant.YELLOW,
    text: "Pending",
  },
  [enums.TransactionStatus.QUEUED]: {
    variant: Variant.YELLOW,
    text: "Pending",
  },
  [enums.TransactionStatus.NEEDS_APPROVAL]: {
    variant: Variant.YELLOW,
    text: "Awaiting Approval",
  },
  [enums.TransactionStatus.APPROVED]: {
    variant: Variant.YELLOW,
    text: "Pending",
  },
  [enums.TransactionStatus.PROCESSING]: {
    variant: Variant.YELLOW,
    text: "Pending",
  },
  [enums.TransactionStatus.SENT]: {
    variant: Variant.YELLOW,
    text: "Pending",
  },
  [enums.TransactionStatus.RETURNED]: {
    variant: Variant.YELLOW,
    text: "Returned",
  },
  [enums.TransactionStatus.FAILED]: {
    variant: Variant.RED,
    text: "Failed",
  },
  [enums.TransactionStatus.DENIED]: {
    variant: Variant.RED,
    text: "Denied",
  },
  [enums.TransactionStatus.CANCELLED]: {
    variant: Variant.RED,
    text: "Failed",
  },
  [enums.TransactionStatus.REVERSED]: {
    variant: Variant.RED,
    text: "Reversed",
  },
  [enums.TransactionStatus.COMPLETED]: {
    variant: Variant.GREEN,
    text: "Posted",
  },
};

export const TransactionType = ({
  title,
  subtitle,
  className,
}: { title: string; subtitle: ReactNode | string; className?: string }) => (
  <div className={cn("font-normal text-gray text-sm", className)}>
    <b className="text-base text-black font-semibold">{title}</b>
    {typeof subtitle === "string" ? <p className="font-normal text-gray text-sm">{subtitle}</p> : subtitle}
  </div>
);

export const TransactionStatus = ({
  date,
  status,
  className,
  compoundFrequency,
  periodEnd,
}: {
  date?: Date;
  status?: enums.TransactionStatus | "SCHEDULED";
  className?: string;
  compoundFrequency?: enums.CompoundFrequency;
  periodEnd?: Date;
}) => {
  return (
    <div className={className}>
      {status ? (
        <Chip className="rounded-md mb-1" variant={TRANSACTION_STATUS_CHIP_MAPPING[status]?.variant}>
          {TRANSACTION_STATUS_CHIP_MAPPING[status]?.text}
        </Chip>
      ) : null}
      {date ? (
        <p className="text-sm font-normal text-gray">
          {/* if we compound monthly, we want to show the day since it doesn't compound at the end of the month but rather
           the number of full months that have elapsed since the start date
           (i.e. if the loan starts on January 15th, the interest accrues on Feb 15th, not Jan 31st) */}
          On:{" "}
          <b>
            {compoundFrequency === enums.CompoundFrequency.DAILY
              ? formatters.date.formatDate(date)
              : periodEnd
              ? formatters.date.formatDate(periodEnd)
              : formatters.date.formatDate(date)}
          </b>
        </p>
      ) : null}
    </div>
  );
};

const TypeCell = ({ cell }: { cell: Cell<entities.TransactionRecord> }) => {
  return (
    <TransactionType
      title={
        // @ts-ignore
        cell.row.original?.type?.type === enums.TransactionType.INTEREST_ACCUMULATION &&
        // @ts-ignore
        cell.row.original?.productType === enums.ProductType.APPRECIATION_MORTGAGE
          ? "Minimum interest"
          : // @ts-ignore
            formatters.enums.TRANSACTION_TYPE_MAP[cell.row.original?.type?.type as enums.TransactionType] ||
            ""
      }
      subtitle={
        cell.value ? (
          <MaskedBankAccount account={cell.value} />
        ) : (
          formatters.enums.TRANSACTION_TYPE_SOURCE_MAP[
            // @ts-ignore
            cell.row.original?.type?.type as enums.TransactionType
          ] || ""
        )
      }
    />
  );
};

const StatusCell = ({ cell }: { cell: Cell }) => {
  // @ts-ignore
  const date = cell.row.original?.date;
  if (
    // @ts-ignore
    cell.row.original?.type?.type === enums.TransactionType.INTEREST_ACCUMULATION &&
    // @ts-ignore
    cell.row.original?.compoundFrequency === enums.CompoundFrequency.DAILY &&
    // @ts-ignore
    isSameMonth(cell.row.original?.rawDate, new Date())
  ) {
    return (
      <Tooltip
        white
        text="Your loan accrues interest daily. This month is not yet complete, so we are showing you the interest that has so far accrued."
      >
        <div className="h-fit w-fit">
          <Chip className="rounded-md mb-1" variant="yellow">
            Accumulating
          </Chip>
          <p className="text-sm font-normal text-gray">
            Up to: <b>{formatters.date.formatDate(new Date())}</b>
          </p>
        </div>
      </Tooltip>
    );
  }

  return (
    <TransactionStatus
      // @ts-ignore
      periodEnd={cell.row?.original?.periodEnd}
      status={cell.value?.status}
      // @ts-ignore
      date={cell.row?.original?.rawDate}
    />
  );
};

const AmountCell = ({ cell: { value } }: { cell: Cell }) => {
  if (value === "$0.00") {
    return (
      <Chip variant="yellow" style={styles.pendingAmount}>
        Waiting for confirmation
      </Chip>
    );
  }
  return <p className="font-light text-gray">{value}</p>;
};

const DEFAULT_COLUMNS: Column[] = [
  {
    Header: "Type",
    accessor: "receivingAccount",
    Cell: TypeCell,
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: AmountCell,
  },
  {
    Header: "Status",
    accessor: "type",
    Cell: StatusCell,
  },
];

const TransactionHistory = ({
  columns = DEFAULT_COLUMNS,
  data,
  loading,
  className,
  compoundFrequency,
  productType,
}: TransactionHistoryProps) => {
  const [rowsToShow, setRowsToShow] = useState(6);

  const shapedData = useMemo(
    () =>
      data.map((datum) => ({
        ...selectTransactionForViewing(datum, compoundFrequency),
        compoundFrequency,
        productType,
      })),
    [data, compoundFrequency, productType]
  );

  const onPressSeeMore = useCallback(() => {
    setRowsToShow((prev) => prev + 6);
  }, []);

  return (
    <div className={className}>
      <SectionHeader header={`Transactions (${data?.length || 0})`} Icon={CalendarClock} loading={loading} />
      {!data.length && !loading ? (
        <EmptyDataCard
          headerText="Empty transaction history"
          subheaderText="You have no completed transactions for your loan."
          illustration={<PiggyBank css={styles.piggyBank} />}
        />
      ) : (
        <Table
          className="w-full"
          columns={columns}
          data={shapedData.slice(0, rowsToShow)}
          onPressSeeMore={onPressSeeMore}
          showSeeMore={rowsToShow < shapedData.length}
          loading={loading}
        />
      )}
    </div>
  );
};

export default memo(TransactionHistory);
