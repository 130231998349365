import { SerializedStyles } from "@emotion/react";
import { ReactNode, cloneElement, useRef } from "react";
import { useClickedOutsideElement, useToggle } from "src/hooks";
import { createStyles } from "src/styles";

export interface DropdownProps {
  trigger: JSX.Element;
  children: ReactNode;
  align?: "left" | "right"; // alignment of the dropdown relative to the trigger component
  closeOnClickOutside?: boolean; // whether to close the dropdown when clicking outside of it, defaults to true
  style?: SerializedStyles;
  triggerStyle?: SerializedStyles;
}

export interface DropdownTriggerProps {
  open?: boolean;
  onClick?: () => void;
}

const styles = createStyles(({ colors, zIndex, borderRadius }) => ({
  outerContainer: {
    position: "relative",
  },
  dropdownContent: {
    backgroundColor: colors.grey100,
    border: `1px solid ${colors.darkgrey200}`,
    borderRadius: borderRadius[1],
    boxShadow: "0px 4px 40px 0px rgba(0, 0, 0, 0.1)",
    zIndex: zIndex.dropdown,
    position: "absolute",
    transform: "translate(0, 8px)", // offset from the trigger element
  },
  alignRight: {
    right: "0%",
  },
}));

/**
 * A more primitive version of dropdown that is more flexible but requires the manual management of the open state.
 * If you don't need to manually manage the open state, use the Dropdown component instead.
 */
export const ControlledDropdown = ({
  trigger,
  children,
  open,
  align,
  onClickOutside,
  style,
  triggerStyle,
}: DropdownProps & { open: boolean; onClickOutside?: () => void }) => {
  const ref = useRef(null);
  useClickedOutsideElement(ref, onClickOutside);

  return (
    <div css={[styles.outerContainer, triggerStyle]} ref={ref}>
      {trigger}
      {open && (
        <div css={[styles.dropdownContent, align === "right" && styles.alignRight, style]}>{children}</div>
      )}
    </div>
  );
};

/**
 * A generic dropdown container that can be used to render a dropdown menu or any other types of popup content.
 *
 * @param trigger A component that will be used to trigger the dropdown opening. It's also what the dropdown will be
 * anchored to. The trigger component should accept an "onClick" prop. The component will also receive an "open" prop, which
 * can optionally be used to do conditional styling or trigger other behaviour
 */

const Dropdown = ({
  trigger,
  children,
  closeOnClickOutside = true,
  triggerStyle,
  ...rest
}: DropdownProps) => {
  const toggle = useToggle();
  return (
    <ControlledDropdown
      open={toggle.on}
      triggerStyle={triggerStyle}
      onClickOutside={closeOnClickOutside ? toggle.setOff : undefined}
      trigger={cloneElement(trigger, {
        open: toggle.on,
        onClick: toggle.toggle,
      })}
      {...rest}
    >
      {children}
    </ControlledDropdown>
  );
};

export default Dropdown;
