import { useMutation } from "@tanstack/react-query";
import fraction from "src/api/fraction";

import { parsers } from "@fraction/shared";
import { useCachedQuery, useCachedQueryClient } from "src/hooks/useCache";

const useDocumentTypes = ({ enabled }: { enabled?: boolean } = {}) => {
  const queryClient = useCachedQueryClient();
  const documentTypesQuery = useCachedQuery({
    queryKey: ["documentTypes"],
    queryFn: () => fraction.getDocumentTypes(),
    enabled,
  });

  const createDocumentType = useMutation({
    mutationFn: (newDocumentType: parsers.documentType.DocumentTypeNoId) =>
      fraction.createDocumentType(newDocumentType),
    onSuccess: async (newDocumentType) =>
      queryClient.setQueryData(["documentTypes"], [...(documentTypesQuery.data || []), newDocumentType]),
  });

  const updateDocumentType = useMutation({
    mutationFn: (documentType: parsers.documentType.DocumentType) =>
      fraction.updateDocumentType(documentType),
    onSuccess: async (newDocumentType) =>
      queryClient.setQueryData(
        ["documentTypes"],
        [...(documentTypesQuery.data || []).filter((type) => type.id !== newDocumentType.id), newDocumentType]
      ),
  });

  const deprecateDocumentType = useMutation({
    mutationFn: ({ id, deprecated }: { id: string; deprecated: boolean }) =>
      fraction.deprecateDocumentType(id, deprecated),
    onSuccess: () => documentTypesQuery.refetch(),
  });

  return {
    documentTypes: documentTypesQuery.data,
    documentTypesLoading: documentTypesQuery.isLoading || createDocumentType.isPending,
    createDocumentType,
    updateDocumentType,
    deprecateDocumentType,
  };
};

export default useDocumentTypes;
