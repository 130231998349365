import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import FinePrint from "src/components/FinePrint";
import TextLink from "src/components/TextLink";

export interface SMSChargesProps {
  style?: SerializedStyles;
}

const SMSCharges = ({ style }: SMSChargesProps) => (
  <FinePrint style={style}>
    Receipt of cellular phone calls (including text messages) may be subject to service provider charges.
    Message and Data Rates May Apply. See our{" "}
    <TextLink underlined href="https://www.fraction.com/usa/sms-policy">
      SMS Policy
    </TextLink>{" "}
    for more details.
  </FinePrint>
);

export default memo(SMSCharges);
