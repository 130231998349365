import { CSSProperties, ReactNode } from "react";

import { colors } from "@fraction/shared";

interface Props {
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
}

const EmptyPage = ({ children, ...rest }: Props) => (
  <div {...rest} style={{ backgroundColor: colors.LIGHT_BACKGROUND, ...rest.style }}>
    {children}
  </div>
);

export default EmptyPage;
