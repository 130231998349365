import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import FinePrint from "src/components/FinePrint";

export interface ECOAProps {
  style?: SerializedStyles;
}

const ECOA = ({ style }: ECOAProps) => (
  <FinePrint style={style}>
    The Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the
    basis of race, color, religion, national origin, sex, marital status, and age; because all or part of the
    applicant’s income derives from any public assistance program; or because the applicant has in good faith
    exercised any right under the Consumer Credit Protection Act. The federal agency that administers our
    compliance with these federal laws is the Federal Trade Commission, Equal Credit Opportunity, Washington,
    DC, 20580.
  </FinePrint>
);

export default memo(ECOA);
