import { G, Path, Styles, Svg } from "@react-pdf/renderer";

import { colors } from "@fraction/shared";

const LOGO_COLOR = colors.palette.GREEN_500;
type Style = Styles[keyof Styles];

const PdfLogo = ({ style }: { style?: Style | Style[] }) => (
  // width, height, and viewBox carefully adjusted to create a 109x21 element
  <Svg style={style} width="109" height="21" viewBox="119 302 500 115">
    <G>
      <Path
        fill={LOGO_COLOR}
        d="M172.9 361.8c5.6-15 11.9-21.4 20.9-21.4 7.1 0 12.7 5.3 12.7 12.2 0 6.7-4.8 11.8-10.8 11.8-2.4 0-4.9-.8-6.6-2.4-1.1-1-1.1-1-4.1-4.9-1.5-2-2.8-2.8-4.2-2.8-1.8 0-3.2 1-4.3 2.9-2.1 3.8-2.5 7.1-2.5 19.6v17.5c0 14.3 1.3 16.1 11.1 16.5v1.4H138v-1.4c9.9-.4 11.2-2.2 11.2-16.5v-23c0-8.4-.6-11.8-2.8-14.7-2-2.7-4.5-3.8-9.1-4.1l-.3-1.4c15-3.5 19.8-4.9 35.9-10.8v21.5z"
      />
      <Path
        fill={LOGO_COLOR}
        d="M291.6 402.8c-9.7 8-16.2 11.2-23.3 11.2-7.8 0-12.3-4.2-14.1-13.3-6.7 9.4-14.2 13.3-25.2 13.3-11.8 0-19.9-6.3-19.9-15.4 0-8 4.9-12.7 19.3-19.1 7-2.9 15.7-6 25.8-9v-7.3c0-9.2-.6-13.6-1.8-15.8-1.5-2.4-3.6-3.5-6.4-3.5-4.1 0-7.8 2.1-9.5 5.6-1.3 2.5-1.4 3.4-2.5 12.2-1.1 8.8-6.9 14.4-14.8 14.4-5.7 0-9.8-4.1-9.8-9.8 0-5.7 3.8-10.6 12-15.4 10.6-6 27.5-10.6 39.5-10.6 5.6 0 9.5 1.1 12.7 3.9 4.1 3.2 5.5 7.4 5.5 15.4v32.2c0 5.3.1 7.1 1 8.8.7 1.8 2.8 2.9 5.2 2.9 1.7 0 2.9-.4 5.6-1.8l.7 1.1zm-37.4-30.5c-12.7 4.1-16.5 8.5-16.5 19.2 0 8.4 2.8 13 8.1 13 5.2 0 8.4-4.1 8.4-10.5v-21.7z"
      />
      <Path
        fill={LOGO_COLOR}
        d="m420.2 342.1-3.5 6.6h-14.1V389c0 10.6 3.4 14.8 11.6 14.8 2.7 0 4.6-.4 8-1.5l.8 1.1c-10.4 7.6-17.4 10.5-25.1 10.5-5.5 0-10.4-1.7-13.9-4.5-4.6-3.9-6.3-8.8-6.3-18.9v-41.9h-17.2c16.4-7.1 32.5-20.7 42-35V342h17.7z"
      />
      <Path
        fill={LOGO_COLOR}
        d="M423.5 351.1c12.9-2.9 29-7.7 37-10.8v53.9c0 14.1 1.1 16.1 10.2 16.5v1.4h-45.4v-1.4c9.2-.4 10.4-2.2 10.4-16.5v-23c0-8.4-.6-11.6-2.8-14.7-2-2.7-4.5-3.8-9.2-4.1l-.2-1.3zm37.8-30c0 8-6.7 14.7-14.8 14.7-8.3 0-15-6.7-15-15 0-8.1 6.7-14.8 14.8-14.8 8.5 0 15 6.6 15 15.1z"
      />
      <Path
        fill={LOGO_COLOR}
        d="M592.4 353.6c9.5-9.8 16.2-13.3 25.5-13.3 6.3 0 11.6 2.1 14.3 5.9 2.4 3.1 3.2 7.1 3.2 14.6v36.3c-.1 11.5.8 13 8.4 13.7v1.4h-39.6v-1.4c3.2-.3 4.8-1.3 5.6-3.4.7-1.8.8-3.2.8-10.4v-26.5c0-11.2-.3-13.7-1.5-16.1-1-2.1-3.6-3.5-6.2-3.5-3.4 0-6.7 1.5-10.5 4.9V397c-.1 11.3.7 13 6.4 13.7v1.4h-42.4v-1.4c9.9-.4 11.2-2.2 11.2-16.5v-23c0-8.4-.6-11.8-2.8-14.7-2-2.7-4.5-3.8-9.1-4.1l-.3-1.4c12.7-2.8 23.4-6 37-10.8v13.4z"
      />
      <Path
        fill={LOGO_COLOR}
        d="m123.8 348.7 3.8-6.6h-15.4V310.7c-4.6 2.9-9.5 8.2-16.9 17.7l-16 20.3h8.1v45.5c0 14.3-1.3 16.1-11.2 16.5v1.4h48.7v-1.4c-11.5-.1-12.7-1.8-12.7-16.5v-45.5h11.6z"
      />
      <Path
        fill={LOGO_COLOR}
        d="M361.6 386.5c-6.4 6.6-12.9 9.5-21 9.5-14.6 0-24-10.9-24-27.7 0-12.3 4.7-22.4 11.2-25.3v-2.5c-20.1 1.8-35.4 17.7-35.4 37.7 0 20.7 15 35.7 35.6 35.7 15.7 0 27.3-8.7 35.3-26.6l-1.7-.8z"
      />
      <Path
        fill={LOGO_COLOR}
        d="M334.2 340.4v2c3.6.9 6.1 4 6.1 8.1 0 1.7-.3 3.1-1.1 6-1.1 3.5-1.4 5.2-1.4 7.1 0 7 4.9 12.2 11.8 12.2 6.6 0 11.5-5.3 11.5-12.5 0-12.1-11.8-21.9-26.9-22.9z"
      />
      <Path
        fill={LOGO_COLOR}
        d="M539.5 349.7c-6.7-5.6-14.2-8.6-22.8-9.2v2.3c1.9.7 3.4 2 4.6 3.9 2.5 4.1 3.9 14.7 3.9 30.8 0 13.9-1.3 24.9-3.2 29-1.8 3.8-4.8 5.7-8.4 5.7-3.4 0-6-1.5-7.8-4.5-2.5-4.1-3.9-14.8-3.9-30.5 0-14.1 1.3-25.2 3.2-29.3 1.3-2.6 3.1-4.3 5.3-5.1v-2.2c-20.2 1.5-35.6 16.9-35.6 36.7 0 20.7 16.9 36.8 38.7 36.8 21.7 0 38.8-16.1 38.8-36.3-.1-11.8-4.1-20.8-12.8-28.1z"
      />
      <Path
        fill={LOGO_COLOR}
        d="M123.6 307.7c-1.9 0-3.6.1-5.2.5v1.6c.5-.1 1-.1 1.5-.1 1.8 0 3.2.9 4.1 2.5.8 1.4 1.1 3.1 1.3 7.6.3 5.9.6 8.1 1.7 10.2 2 3.6 6 5.9 10.5 5.9 6.3 0 10.9-4.3 10.9-10.5 0-9.9-10.9-17.7-24.8-17.7z"
      />
    </G>
  </Svg>
);

export default PdfLogo;
