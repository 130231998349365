import {
  ApplicationTaskType,
  SyntheticApplicationStatus,
  UI_APPLICATION_STATUS_ORDER,
  entities,
  enums,
  getNextApplicationStatus,
  types,
} from "@fraction/shared";
import { QueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useState } from "react";
import fraction, { ChecklistApp } from "src/api/fraction";
import { useAuth } from "src/auth";
import { APPLICATION_KEY, useApplicationAuthed } from "src/hooks/useApplication";
import { useCachedQuery } from "src/hooks/useCache";
import { useMutation } from "src/lib";
import { selectChecklistApplication } from "src/selectors";

export const setDeletedFile = (
  queryClient: QueryClient,
  dealId: string,
  dealStatus: enums.ApplicationStatus,
  fileId: string
) => {
  queryClient.setQueryData(
    ["application", "checklist", dealId, dealStatus],
    (prev: types.ChecklistResult[]) =>
      prev?.filter((item) => {
        if (types.isDocumentChecklistItem(item)) {
          return item.id !== fileId;
        }
        return true;
      })
  );
  queryClient.setQueryData(
    ["application", dealId],
    (prev: ChecklistApp) =>
      new entities.Application({
        ...prev,
        files: (prev.files || []).filter((file) => file.id !== fileId),
      })
  );
};

export const addFile = (queryClient: QueryClient, dealId: string, file: entities.UploadedFile) => {
  queryClient.setQueryData(
    ["application", dealId],
    (prev: ChecklistApp) =>
      new entities.Application({
        ...prev,
        files: _.uniqBy(
          [
            ...(prev.files || []).map((oldFile) => {
              if (oldFile.id !== file.id) {
                return oldFile;
              }
              return new entities.UploadedFile({
                ...oldFile,
                ...file,
              });
            }),
            file,
          ],
          "id"
        ),
      })
  );
  if (file?.insurancePolicy?.id || file?.insurancePolicyId) {
    queryClient.setQueryData(
      ["insurancePolicy", dealId, file.insurancePolicy?.id || file.insurancePolicyId],
      (prev: entities.InsurancePolicy | null) => {
        if (!prev) {
          return file.insurancePolicy;
        }
        return new entities.InsurancePolicy({
          ...prev,
          files: _.uniqBy([...(prev.files || []), file], "id"),
        });
      }
    );
  }
};

export const updateFile = (
  queryClient: QueryClient,
  dealId: string,
  fileId: string,
  updater: (file: entities.UploadedFile) => entities.UploadedFile
) => {
  const existingFile = (queryClient.getQueryData(["application", dealId]) as ChecklistApp)?.files?.find(
    (file) => file.id === fileId
  );

  if (existingFile?.insurancePolicyId) {
    const policy = updater({})?.insurancePolicy;
    queryClient.setQueryData(
      ["insurancePolicy", dealId, existingFile?.insurancePolicyId],
      (prev: entities.InsurancePolicy | null | undefined) => ({ ...(prev || {}), ...policy })
    );
  }

  queryClient.setQueryData(["application", dealId], (prev: ChecklistApp) => {
    const existingFile = (prev.files || []).find((file) => file.id === fileId);

    return new entities.Application({
      ...prev,
      files: existingFile
        ? (prev.files || []).map((file) => {
            if (file.id !== fileId) {
              return file;
            }
            return updater(file);
          })
        : [...(prev.files || []), updater(new entities.UploadedFile({ id: fileId }))],
    });
  });
};

export const updatePolicyFile = (
  queryClient: QueryClient,
  dealId: string,
  policyId: string,
  updater: (file: entities.UploadedFile) => entities.UploadedFile
) => {
  const policy = updater({})?.insurancePolicy;
  queryClient.setQueryData(
    ["insurancePolicy", dealId, policyId],
    (prev: entities.InsurancePolicy | null | undefined) => ({ ...(prev || {}), ...policy })
  );

  queryClient.setQueryData(["application", dealId], (prev: ChecklistApp) => {
    const hasFile = !!(prev.files || []).find((file) => file.insurancePolicyId === policyId);
    return new entities.Application({
      ...prev,
      files: hasFile
        ? (prev.files || []).map((file) => {
            if (file.insurancePolicyId !== policyId) {
              return file;
            }
            return updater(file);
          })
        : [...(prev.files || []), updater(new entities.UploadedFile({ insurancePolicyId: policyId }))],
    });
  });
};

export function useChecklistForNextAppStage({
  id,
  fetchEnabled,
}: {
  id?: string;
  fetchEnabled?: boolean;
}) {
  const { data: app, isLoading } = useApplicationAuthed({
    id,
  });
  const status = app?.status ? getNextApplicationStatus(app.status, UI_APPLICATION_STATUS_ORDER) : undefined;
  const q = useChecklist({
    id,
    status: status || undefined,
    fetchEnabled,
  });
  return {
    ...q,
    isLoading: q.isLoading || isLoading,
  };
}

export function useChecklist({
  id: appId,
  status: dealStatus,
  fetchEnabled,
  initialData,
}: {
  id?: string;
  status?: SyntheticApplicationStatus | enums.LoanStatus;
  fetchEnabled?: boolean;
  initialData?: types.ChecklistResult[];
}) {
  const { token, isLoadingUser } = useAuth();
  const [pendingTask, setPendingTask] = useState<
    { taskType: ApplicationTaskType; channel: string } | undefined
  >();

  const checklistQ = useCachedQuery<types.ChecklistResult[] | null, ChecklistApp>({
    behaviour: "both",
    deserialize: selectChecklistApplication,
    initialRefetch: true,
    initialData,
    cacheKey: [...APPLICATION_KEY, appId, dealStatus],
    queryKey: [...APPLICATION_KEY, "checklist", appId, dealStatus],
    enabled: !!token && !!appId && !!dealStatus && fetchEnabled !== false,
    queryFn: async () => {
      if (!dealStatus) {
        return null;
      }
      return (await fraction.getAppChecklist(appId!, dealStatus)) || null;
    },
    select: (data: types.ChecklistResult[] | ChecklistApp | null | undefined) => {
      return (
        (!data || !dealStatus ? null : "checklists" in data ? data.checklists?.[dealStatus] : data) || null
      );
    },
  });

  const initiateTask = useMutation({
    mutationFn: async (args: Parameters<typeof fraction.initiateTask>[0]) => {
      setPendingTask({ taskType: args.taskType, channel: args.channels[0] });
      await fraction.initiateTask(args);
      await checklistQ.refetch();
    },
    onSettled: () => {
      setPendingTask(undefined);
    },
  });

  return {
    ...checklistQ,
    initiateTask: initiateTask.mutateAsync,
    pendingTask,
    isPending: checklistQ.isLoading || initiateTask.isPending,
    isLoading: checklistQ.isLoading || isLoadingUser,
  };
}
