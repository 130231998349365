import { entities, formatters } from "@fraction/shared";
import { formatDistanceToNow } from "date-fns";
import _ from "lodash";
import { Bot } from "lucide-react";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useComms } from "src/apps/LOS/hooks/queries";
import { Mail as MailPageBlock } from "src/apps/LOS/pages/MailPage";
import { Badge } from "src/components/ui/badge";
import { useToggler } from "src/hooks";

export function Mail() {
  const { Toggle, on: groupByApp } = useToggler({
    id: "toggleGroupByApp",
    label: "Group by app",
    defaultValue: false,
  });

  return (
    <div>
      <div className="flex flex-row gap-1 mb-4">
        <Toggle />
      </div>
      {groupByApp ? <GroupedByAppMail /> : <UngroupedMail />}
    </div>
  );
}

export function UngroupedMail({ appId }: { appId?: string }) {
  const { data } = useComms({ appId });

  return (
    <div>
      {data?.map((mail) => (
        <MailRow mail={mail} key={mail.id} />
      ))}
    </div>
  );
}

const RawButton = (props: any) => <button {...props} />;

const nextMailRegexes = [
  /On \w{3}, \w{3} \d{1,2}, \d{4} at \d{1,2}:\d{2} (?:AM|PM) (.*) <[\S+\n\r\s]+[\w.-]+@[\w.-]+\.\w+>/,
  /From: (.*) <[\S+\n\r\s]+[\w.-]+@[\w.-]+\.\w+>/,
  /-+ ?original message ?-+/i,
  /-----------------------------/,
];

export function MailBlock({
  mail,
}: {
  mail: entities.Communication;
}) {
  let content = mail?.content || "";
  for (const regex of nextMailRegexes) {
    const index = regex.exec(content)?.index;
    if (index !== -1) {
      content = content.slice(0, index);
    }
  }

  return <MailPageBlock className="shadow p-4 rounded" comm={{ ...mail, content: content.trim() }} />;
}

export function MailRow({
  mail,
  showRecipient,
  onClick,
}: {
  mail: entities.Communication;
  showRecipient?: boolean;
  onClick?: (comm: entities.Communication) => void;
}) {
  const handleClick = useCallback(() => {
    onClick?.(mail);
  }, [mail, onClick]);

  const Container = onClick ? RawButton : Link;
  const props = useMemo(
    () => (onClick ? { onClick: handleClick } : { to: `/mail/${mail.id}` }),
    [handleClick, mail.id]
  );

  return (
    // @ts-ignore
    <Container
      {...props}
      className="flex flex-col p-2 hover:bg-gray-100 gap-y-1 border-t-2 first:border-none"
    >
      <div className="flex flex-row gap-x-6">
        <div className="w-[300px]">
          <p className="text-sm text-wrap text-left">
            {showRecipient ? <b>from: </b> : ""}
            {mail.rawSender}
          </p>
          {showRecipient ? (
            <p className="text-sm text-wrap w-[300px] text-left">
              <b>to:</b> {mail.rawRecipients?.join(", ")}
            </p>
          ) : null}
        </div>

        <p className="text-sm w-[600px] truncate">
          <span>{mail.subject}</span> <span className="font-light text-gray-700">- {mail.content}</span>
        </p>
        {mail.createdAt ? (
          <p className="text-xs ml-auto text-gray-700">{formatDistanceToNow(mail.createdAt)} ago</p>
        ) : null}
      </div>
      <div className="flex flex-row gap-x-6">
        <div className="flex flex-row gap-1 flex-wrap w-[300px]">
          {mail.applications
            ?.map((app) =>
              app.applicants?.map((applicant) => (
                <Badge className="rounded-full h-6" key={applicant.id}>
                  {formatters.user.userName(applicant.user)}
                </Badge>
              ))
            )
            .flat()}
        </div>
        <div className="flex flex-row flex-wrap gap-1 max-w-[600px]">
          {mail.attachments?.map((attachment) => (
            <Badge variant="outline" className="rounded-full p-1 hover:bg-gray-300" key={attachment.id}>
              <div className="h-4 w-4 text-center bg-red rounded-sm text-white font-medium text-[0.5rem] mr-1">
                {attachment.s3Document?.type?.split("/")[1]}
              </div>
              <p className="truncate max-w-[100px]">{attachment.type?.fullName}</p>
              {attachment?.robotGuessed ? <Bot height={16} className="text-gray-800 ml-0.5" /> : null}
            </Badge>
          ))}
        </div>
        <div className="flex flex-row flex-wrap gap-1 ml-auto">
          {mail.applications?.map((app) => (
            <Badge className="h-6 rounded-full">{_.startCase(app.status)}</Badge>
          ))}
        </div>
      </div>
    </Container>
  );
}

export function GroupedByAppMail({ appId }: { appId?: string }) {
  const { data } = useComms({ appId });
  const groupedData = useMemo(() => {
    const grouped = _.groupBy(data, (mail) => mail.applications?.map((app) => app.id).join(","));
    return Object.values(grouped);
  }, [data]);

  return (
    <div className="flex flex-col gap-y-2">
      {groupedData?.map((group) => {
        const app = group?.[0]?.applications?.[0];
        if (!app) {
          return null;
        }
        return (
          <div className="border-2 rounded">
            {group.map((mail) => (
              <MailRow mail={mail} key={mail.id} />
            ))}
          </div>
        );
      })}
    </div>
  );
}
