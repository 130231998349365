import { useCallback } from "react";
import { TextInputWithLoading } from "src/components";
import { createStyles } from "src/styles";

import { RegularText } from "src/components/v1/Text";
import { Logo } from "src/images";

const styles = createStyles({
  input: {
    backgroundColor: "white",
    textTransform: "uppercase",
    "&::placeholder": {
      textTransform: "none",
    },
  },
  text: {
    textAlign: "center",
    maxWidth: 450,
  },
  inputContainer: { width: 350 },
});

export const BdmCalendarLayout = ({
  onSubmit,
  loading,
  disabled,
}: {
  errorMessage?: string;
  onSubmit: (postalCode: string) => void;
  loading?: boolean;
  disabled?: boolean;
}) => {
  const handleSubmit = useCallback(
    (evt: any) => {
      evt?.preventDefault?.();
      onSubmit?.(evt.target.postalCode.value);
    },
    [onSubmit]
  );

  const handleChange = useCallback(
    (postal: any) => {
      if (handleSubmit && postal?.length === 3) {
        handleSubmit?.({ target: { postalCode: { value: postal } } });
      }
    },
    [handleSubmit]
  );

  return (
    <div className="flex flex-col gap-5 items-center justify-center">
      <Logo width={120} height={25} />
      <RegularText style={styles.text}>
        <b>Find your local Fraction BDM</b> by entering the first three digits of your brokerage's postal code
        below.
      </RegularText>
      <TextInputWithLoading
        maxLength={3}
        style={styles.inputContainer}
        inputStyle={styles.input}
        errorSpacing={false}
        onChange={handleChange}
        variant="rounded"
        name="postalCode"
        placeholder="Brokerage postal code (e.g. V6Z)"
        type="text"
        autoComplete="off"
        loading={loading || false}
        disabled={disabled}
      />
      {loading ? <RegularText>Loading...</RegularText> : <div className="h-6" />}
    </div>
  );
};
