import { useCallback, useRef } from "react";

export type Memoizer<T> = (name: any, deps: any[], item: T) => T;
export type ReportingMemoizer<T> = (name: any, deps: any[], item: T) => [T, boolean];

/**
 * Returns an function that has a name,
 * a dep array, and the object to memoize, and it returns that object
 * if the name and dep array haven't changed.
 */
export default function useMemoizer<T>(): Memoizer<T> {
  const memoizer = useReportingMemoizer<T>();
  return (name: any, deps: any[], item: T) => memoizer(name, deps, item)[0];
}

export function useResettableReportingMemoizer<T>(baseName?: string): [ReportingMemoizer<T>, () => void] {
  const memos = useRef<Record<string, T>>({});
  const reset = useCallback(() => {
    memos.current = {};
  }, []);

  return [
    (name: any, deps: any[], item: T) => {
      const n = `memoized--${baseName || ""}--${JSON.stringify(name)}`;

      const isUndefined = memos.current[n] === undefined && item !== undefined;

      const changed =
        isUndefined ||
        deps.some(
          (value, index) =>
            memos.current[`${n}--${index}`] !== value &&
            // if the objects are the same shape exactly, that's cool too
            (typeof value !== "object" ||
              (typeof value === "object" && memos.current[`${n}--${index}`] !== JSON.stringify(value)))
        );

      if (changed) {
        memos.current[n] = item;
        deps.forEach((value, index) => {
          memos.current[`${n}--${index}`] = typeof value === "object" ? JSON.stringify(value) : value;
        });
      }

      return [memos.current[n], changed];
    },
    reset,
  ];
}

export function useReportingMemoizer<T>(baseName?: string): ReportingMemoizer<T> {
  return useResettableReportingMemoizer<T>(baseName)[0];
}
