import { entities } from "@fraction/shared";
import { useQueryClient } from "@tanstack/react-query";
import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { useMutation, useQuery } from "src/lib";
import { v5 as uuidv5 } from "uuid";

const NAMESPACE = "f9b2c51f-3a9a-4b1d-9c0e-0d1a1b9a1a2a";

export function useChat({ applicationId }: { applicationId?: string }) {
  const { isAuthenticated, user } = useAuth();
  const queryClient = useQueryClient();

  const q = useQuery({
    enabled: !!applicationId && isAuthenticated,
    queryKey: ["appChat", applicationId, isAuthenticated],
    queryFn: () => (applicationId ? fraction.getChat({ applicationId }) : null),
    select: useGroupedUserChat,
  });

  const sendMessage = useMutation({
    mutationFn: ({ message, applicationId }: { message: string; applicationId: string }) => {
      queryClient.setQueryData(["appChat", applicationId, isAuthenticated], (oldChats: entities.Chat[]) => {
        const createdAt = new Date();
        return [
          ...(oldChats || []),
          new entities.Chat({
            user: user || undefined,
            userId: user?.id,
            id: uuidv5(`${message}-${createdAt.toISOString()}`, NAMESPACE),
            createdAt,
            message,
            applicationId,
          }),
        ];
      });
      return fraction.sendChat({ message, applicationId });
    },
    onError: (e) => {
      if (applicationId) {
        return queryClient.invalidateQueries({ queryKey: ["appChat", applicationId, isAuthenticated] });
      }
    },
  });

  return {
    ...q,
    chats: q.data,
    sendMessage: sendMessage.mutateAsync,
    isPending: sendMessage.isPending,
  };
}

export function useGroupedUserChat(chats_: entities.Chat[] | undefined | null) {
  const chats = chats_ || [];
  const userChats = [];
  let currentUser: string | undefined;
  let currentUserChats: entities.Chat[] = [];

  for (let i = 0; i < (chats || []).length; i++) {
    const chat = chats[i];
    if (!chat.userId) {
      continue;
    }

    if (currentUser === chat.userId) {
      currentUserChats.push(chat);
    } else {
      if (currentUserChats?.length) {
        userChats.push(currentUserChats);
      }
      currentUserChats = [chat];
      currentUser = chat.userId;
    }

    if (i === chats.length - 1) {
      userChats.push(currentUserChats);
    }
  }

  return userChats.filter((c) => c.length).map((x) => ({ user: x?.[0]?.user, chats: x }));
}
