import { AnalyticsPlugin } from "analytics";
import _ from "lodash";
import { initializeTwitterScript } from "src/analytics/twitterScript";

interface TwitterPluginConfig {
  twitterKey: string;
  twq?: any;
  conversionEventMap?: Record<string, string | undefined>;
}

interface Content {
  content_type: string;
  content_id: string;
  content_name: string;
  content_price: string;
  num_items: number;
  content_group_id: string;
}

interface TwitterPluginArgs {
  config: TwitterPluginConfig;
  payload?: {
    event: string;
    email_address?: string;
    phone_number?: string;
    conversion_id?: string;
    status?: string;
    currency?: string;
    value?: number;
    contents?: Content[]; // array of products
  };
}

// "obqm0" is the twitter key for fraction
const twitterPlugin = (userConfig: TwitterPluginConfig): AnalyticsPlugin => {
  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: "twitter-events",
    config: userConfig,
    initialize: ({ config }: TwitterPluginArgs) => {
      console.log("initializing twitter");
      // load provider script to page
      initializeTwitterScript(config.twitterKey).then((twq) => {
        config.twq = twq;
      });
    },
    page: ({ payload }: TwitterPluginArgs) => {
      // call provider specific page tracking
    },
    track: ({ payload, config }: TwitterPluginArgs) => {
      // call provider specific event tracking
      if (!payload?.event) {
        return;
      }
      const conversionId = config.conversionEventMap?.[payload.event];
      if (config.conversionEventMap && !conversionId) {
        return;
      }
      const eventName = conversionId || payload.event;
      config.twq?.("event", eventName, _.omit(payload, "event"));
    },
    identify: ({ payload }: TwitterPluginArgs) => {
      // call provider specific user identify method
    },
    loaded: ({ config }: TwitterPluginArgs) => {
      return !!config.twq;
    },
  };
};

export default twitterPlugin;
