import { SerializedStyles } from "@emotion/react";
import { ReactNode, memo } from "react";
import { IllustratedModalContent } from "src/components/InnerModals";
import { ModalAction } from "src/components/Modal";
import { PopupProps } from "src/components/Popup";
import { AchievementIllustration } from "src/images";

export interface ConfirmationModalScreenProps extends Omit<PopupProps, "children"> {
  additionalActions?: ModalAction[];
  text?: string;
  header: string;
  buttonText?: string;
  children?: ReactNode;
  style?: SerializedStyles;
  image?: ReactNode;
}

const ConfirmationModalScreen = ({
  onClose,
  text,
  additionalActions = [],
  children,
  style,
  header,
  buttonText = "Okay",
  image = <AchievementIllustration width={195} height={195} />,
}: ConfirmationModalScreenProps) => (
  <IllustratedModalContent
    actions={[
      {
        type: "primary",
        text: buttonText,
        action: onClose,
      },
      ...additionalActions,
    ]}
    header={header}
    text={text}
    image={image}
    style={style}
  >
    {children}
  </IllustratedModalContent>
);

export default memo(ConfirmationModalScreen);
