import { useController } from "react-hook-form";
import { DropdownSelector, TextInput } from "src/components";
import { InputModalFooter, InputModalForm } from "src/components/InnerModals";
import { useZodForm } from "src/hooks";
import { UseFormReducerResult } from "src/hooks/useFormReducer";
import { createStyles } from "src/styles";

import { formatters, parsers, schemas } from "@fraction/shared";

const styles = createStyles({
  input: {
    marginBottom: 8,
  },
});

const AddBankAccountScreen = ({
  formValues,
  handleTextChange,
  updateField,
  onContinue,
  onBack,
}: UseFormReducerResult & { onContinue: () => void; onBack?: () => void }) => {
  const { register, handleSubmit, control, errors } = useZodForm<parsers.bankAccount.BankAccountSubmission>({
    schema: parsers.bankAccount.BANK_ACCOUNT_SUBMISSION,
    formValues,
  });

  const { field } = useController({
    control,
    name: "accountType",
    defaultValue: formValues.accountType,
  });

  return (
    <InputModalForm
      header="ADD BANK ACCOUNT"
      paragraph="Enter your account information below to connect your bank account for draws and payments."
      footer={
        <InputModalFooter
          actions={[
            {
              text: "Back",
              action: onBack,
            },
            {
              text: "Next",
              // @ts-ignore
              action: handleSubmit(onContinue),
            },
          ]}
        />
      }
    >
      <input
        {...register("routingNumberType")}
        defaultValue={schemas.modernTreasury.RoutingNumberType.ABA}
        type="hidden" // defaulting to ABA and not letting the user select for now
      />
      <TextInput
        label="Account nickname"
        {...register("name")}
        onChange={handleTextChange}
        style={styles.input}
        autoComplete="off"
      />
      <DropdownSelector
        label="Account type"
        error={errors?.accountType?.message}
        value={field.value}
        onChange={(value) => {
          field.onChange(value);
          updateField(value, "accountType");
        }}
        options={Object.values(schemas.modernTreasury.AccountType).map((type) => ({
          value: type,
          label: formatters.string.capitalize(type),
        }))}
      />
      <TextInput
        label="Account number"
        {...register("accountNumber")}
        onChange={handleTextChange}
        style={styles.input}
        autoComplete="off"
      />
      <TextInput
        label="ABA Routing number"
        {...register("routingNumber")}
        onChange={handleTextChange}
        autoComplete="off"
      />
    </InputModalForm>
  );
};

export default AddBankAccountScreen;
