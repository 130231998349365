import { entities, enums, verify } from "@fraction/shared";

export const selectInsurancePolicyStanding = (loan: entities.LoanT | undefined) => {
  if (!loan) {
    return "to-update";
  }

  const files = loan?.application?.files?.filter(verify.isInsurancePolicyFile);
  const insurancePolicyFile = files?.find((file) => {
    const policy = file?.insurancePolicy;
    if (!policy) {
      return false;
    }

    if (
      !(
        policy?.insuranceProvider &&
        policy?.policyNumber &&
        policy?.startDate &&
        policy?.expiryDate &&
        policy.expiryDate > new Date() &&
        policy.startDate < new Date()
      )
    ) {
      return false;
    }

    return true;
  });

  return !insurancePolicyFile
    ? "to-update"
    : insurancePolicyFile?.status === enums.ApplicationTaskApprovalStatus.APPROVED
    ? "approved"
    : "needs-review";
};
