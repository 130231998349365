import * as React from "react";
import LoadingSkeleton, { SkeletonProps as LoadingSkeletonProps } from "react-loading-skeleton";

import LoadingSVG, { LoadingSVGProps } from "./LoadingSVG";

// @ts-ignore TODO there is a 'direction' property that doesn't matc
export interface SkeletonProps extends Omit<LoadingSkeletonProps, "style">, LoadingSVGProps {
  children?: React.ReactNode;
  dark?: boolean;
  className?: string;
  containerClassName?: string;
}

const Skeleton = ({ children, style, dark, className, ...props }: SkeletonProps) => {
  if (children) {
    return (
      <LoadingSVG className={className} {...props} style={style}>
        {children}
      </LoadingSVG>
    );
  }
  return (
    <LoadingSkeleton
      className={className}
      baseColor={dark ? "#636363" : undefined}
      highlightColor={dark ? "#bababa" : undefined}
      css={style}
      {...props}
    />
  );
};

export default Skeleton;
