import { memo } from "react";
import DrawerModal from "src/components/DrawerModal";
import FlowLayout, { FlowLayoutState, Page } from "src/components/FlowLayout";

import { NavSection } from "../FlowNavigationBar/useFlowNavScroll";

export interface FlowLayoutSubpagesProps {
  subpages?: Page[];
  onCloseSubpages?: () => void;
  onCompleteSubpages?: () => void;
  externalState?: Partial<FlowLayoutState>;
  navSections?: NavSection[];
}

const FlowLayoutSubpages = ({
  subpages,
  onCloseSubpages,
  onCompleteSubpages,
  externalState,
  navSections,
}: FlowLayoutSubpagesProps) => (
  <DrawerModal mounted={!!subpages}>
    <FlowLayout
      externalState={externalState}
      onComplete={onCompleteSubpages}
      pages={subpages}
      onClose={onCloseSubpages}
      navSections={navSections}
    />
  </DrawerModal>
);

export default memo(FlowLayoutSubpages);
