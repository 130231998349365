import { BigButton } from "src/components";
import { Variant } from "src/components/BigButton";
import { InputModalFooter, InputModalForm } from "src/components/InnerModals";
import { BoldText, MediumText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors, formatters } from "@fraction/shared";

const styles = createStyles({
  totalAmountButton: {
    marginBottom: 16,
  },
  buttonTextContainer: {
    display: "flex",
    justifyContent: "space-between",
  },

  buttonText: {
    color: colors.palette.GREY_700,
  },
});

const PaymentAmountScreen = ({
  header,
  paragraph,
  buttonText,
  maxAmount,
  onBack,
  handleSelectTotalAmount,
  handleSelectCustomAmount,
}: {
  maxAmount?: number;
  header: string;
  paragraph: string;
  buttonText: string;
  onBack: () => void;
  handleSelectTotalAmount: () => void;
  handleSelectCustomAmount: () => void;
}) => (
  <InputModalForm
    header={header}
    paragraph={paragraph}
    footer={
      <InputModalFooter
        actions={[
          {
            text: "Back",
            action: onBack,
            secondary: true,
          },
        ]}
      />
    }
  >
    <BigButton variant={Variant.GREEN} style={styles.totalAmountButton} onClick={handleSelectTotalAmount}>
      <div css={styles.buttonTextContainer}>
        <MediumText style={styles.buttonText}>{buttonText}</MediumText>
        <BoldText style={styles.buttonText}>{formatters.number.getCurrencyFromNumber(maxAmount)}</BoldText>
      </div>
    </BigButton>
    <BigButton onClick={handleSelectCustomAmount}>
      <div css={styles.buttonTextContainer}>
        <MediumText style={styles.buttonText}>Custom amount</MediumText>
      </div>
    </BigButton>
  </InputModalForm>
);
export default PaymentAmountScreen;
