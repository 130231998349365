import retry from "@fullstax/p-retry";
import { lazy } from "react";
import { Outlet, Route, createRoutesFromElements } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { BrokerOrBorrowerRoutes } from "src/apps/BrokerOrBorrowerRoutes";
import MaintenanceIfEnabled from "src/apps/shared/Maintenance";
import { SharedRoutes } from "src/apps/shared/routes";
import { RequireAuth } from "src/components";
import AppRoot from "src/components/root/AppRoot";
import MissingRouteRedirect from "src/components/root/routeHelpers/MissingRouteRedirect";
import TrackRoute from "src/components/root/routeHelpers/TrackRoute";
import { EmbedRoutes } from "src/embeds/routes";
const ApplicationFlow = lazy(() => retry(() => import("src/apps/AppFlow/screens/root")));
const PasswordProtectedFileDownload = lazy(() =>
  retry(() => import("src/apps/PortfolioDashboard/screens/PasswordProtectedFileDownload"))
);

const styles = {
  white: {
    backgroundColor: "white",
    color: "black",
  },
};

const AppRouteLayout = () => {
  return (
    // @ts-ignore
    <AppRoot>
      <Outlet />
      <Tooltip id="tooltip" className="max-w-xl z-[5000]" />
      <Tooltip
        clickable
        opacity={1}
        border="1px solid rgba(228, 228, 231, 1)"
        id="white-tooltip"
        style={styles.white}
        className="max-w-xl z-[5000]"
      />
    </AppRoot>
  );
};

const AuthRouteLayout = () => <Outlet />;

const Router = createRoutesFromElements([
  <Route element={<AppRouteLayout />}>
    <Route
      key="/application"
      path="/application"
      element={
        <MaintenanceIfEnabled flag="applicationFlow">
          <TrackRoute title="Start your application | Fraction">
            <ApplicationFlow />
          </TrackRoute>
        </MaintenanceIfEnabled>
      }
    />
    <Route
      key="/"
      element={
        <MaintenanceIfEnabled flag="dashboard">
          <AuthRouteLayout />
        </MaintenanceIfEnabled>
      }
    >
      <Route path="/*" element={<RequireAuth />}>
        <Route path="*" element={<BrokerOrBorrowerRoutes />} />
      </Route>

      {SharedRoutes()}
      <Route key="/download-file" path="/download-file" element={<PasswordProtectedFileDownload />} />
    </Route>
    {EmbedRoutes()}
  </Route>,
  <Route key="*" path="*" element={<MissingRouteRedirect />} />,
]);

export default Router;
