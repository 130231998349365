import { SerializedStyles } from "@emotion/react";
import { SVGAttributes, memo } from "react";
import { v4 as uuid } from "uuid";

// File taken from react-content-loader
export interface LoadingSVGProps extends Omit<SVGAttributes<SVGElement>, "style"> {
  animate?: boolean;
  backgroundColor?: string;
  backgroundOpacity?: number;
  baseUrl?: string;
  foregroundColor?: string;
  foregroundOpacity?: number;
  gradientRatio?: number;
  interval?: number;
  rtl?: boolean;
  speed?: number;
  title?: string;
  uniqueKey?: string;
  style?: SerializedStyles;
}

const LoadingSVG = ({
  animate = true,
  backgroundColor = "#f5f6f7",
  backgroundOpacity = 1,
  baseUrl = "",
  foregroundColor = "#eee",
  foregroundOpacity = 1,
  gradientRatio = 2,
  uniqueKey,
  interval = 0.25,
  rtl = false,
  speed = 1.2,
  style,
  title = "Loading...",
  children,
  ...props
}: LoadingSVGProps) => {
  const fixedId = uniqueKey || uuid();
  const idClip = `${fixedId}-diff`;
  const idGradient = `${fixedId}-animated-diff`;
  const idAria = `${fixedId}-aria`;

  const rtlStyle = rtl ? { transform: "scaleX(-1)" } : null;
  const keyTimes = `0; ${interval}; 1`;
  const dur = `${speed}s`;

  return (
    <svg aria-labelledby={idAria} role="img" css={[style, rtlStyle]} {...props}>
      {title ? <title id={idAria}>{title}</title> : null}
      <rect
        role="presentation"
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath={`url(${baseUrl}#${idClip})`}
        style={{ fill: `url(${baseUrl}#${idGradient})` }}
      />
      <defs>
        <clipPath id={idClip}>{children}</clipPath>

        <linearGradient id={idGradient}>
          <stop offset="0%" stopColor={backgroundColor} stopOpacity={backgroundOpacity}>
            {animate && (
              <animate
                attributeName="offset"
                values={`${-gradientRatio}; ${-gradientRatio}; 1`}
                keyTimes={keyTimes}
                dur={dur}
                repeatCount="indefinite"
              />
            )}
          </stop>

          <stop offset="50%" stopColor={foregroundColor} stopOpacity={foregroundOpacity}>
            {animate && (
              <animate
                attributeName="offset"
                values={`${-gradientRatio / 2}; ${-gradientRatio / 2}; ${1 + gradientRatio / 2}`}
                keyTimes={keyTimes}
                dur={dur}
                repeatCount="indefinite"
              />
            )}
          </stop>

          <stop offset="100%" stopColor={backgroundColor} stopOpacity={backgroundOpacity}>
            {animate && (
              <animate
                attributeName="offset"
                values={`0; 0; ${1 + gradientRatio}`}
                keyTimes={keyTimes}
                dur={dur}
                repeatCount="indefinite"
              />
            )}
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default memo(LoadingSVG);
