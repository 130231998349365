import { formatters } from "@fraction/shared";
import { ResponsivePie } from "@nivo/pie";
import { useCallback, useMemo } from "react";
import { Skeleton } from "src/components";
import { useTabs } from "src/hooks";
import useWindowSize from "../../../../../hooks/useWindowSize";

import { ChartCard } from "../ChartCard";
import { usePayoutGroupings } from "../hooks";

const MARGIN = { top: 20, right: 70, bottom: 20, left: 70 };
const ARCLABEL_TEXT_COLOR = {
  from: "color",
  modifiers: [["darker", 2]],
};
const ARCLINK_LABELS_COLOR = { from: "color" };
const BORDER_COLOR = {
  from: "color",
  modifiers: [["darker", 0.2]],
};

export function PayoutGroupings() {
  const { data, isLoading } = usePayoutGroupings();
  const { width } = useWindowSize();
  const isMobile = !!width && width < 480;

  const { Tabs, activeTab } = useTabs({
    className: "flex-wrap w-fit h-min",
    containerClassName: "flex flex-col items-end",
    tabsPerRow: isMobile ? 2 : undefined,
    grid: isMobile ? undefined : [3, 2],
    options:
      data?.map((d) => ({
        label:
          d.group === "administrativeArea"
            ? "Province"
            : d.group === "fundedAmount"
            ? "Amount"
            : formatters.string.fromCamelCase(d.group),
        value: d.group,
      })) || [],
    defaultValue: "administrativeArea",
  });

  const renderTooltip = useCallback((props: any) => {
    return (
      <div className="shadow p-2 bg-white rounded">
        <p className="text-sm text-gray-800 mt-0.5 font-sans">
          <b>{props.datum?.label}</b>
        </p>
        <p className="text-sm text-gray-800 mt-0.5 font-sans">
          <b>Count:</b> {props.datum.data.value}
        </p>
        <p className="text-sm text-gray-800 mt-0.5 font-sans">
          <b>Total Amount:</b> {formatters.number.getCurrencyWords(props.datum.data.fundedAmount)}
        </p>
      </div>
    );
  }, []);

  const activeData = useMemo(() => {
    const selectedData = data?.find((datum) => datum.group === activeTab);

    return selectedData?.data?.map((d) => ({
      id: selectedData.format === "currency" ? formatters.number.getCurrencyWords(d.group) : d.group,
      label: selectedData.format === "currency" ? formatters.number.getCurrencyWords(d.group) : d.group,
      value: d.count,
      fundedAmount: d.fundedAmount,
      format: selectedData.format,
    }));
  }, [activeTab, data]);

  if (isLoading) {
    return <Skeleton width="100%" height={389} />;
  }

  if (!activeData) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={activeData.length === 0}
      headerComponents={<Tabs />}
      className="min-h-[300px]"
      header="Payouts"
    >
      <ResponsivePie
        // @ts-ignore
        data={activeData}
        margin={MARGIN}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        // @ts-ignore
        borderColor={BORDER_COLOR}
        tooltip={renderTooltip}
        // @ts-ignore
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={ARCLINK_LABELS_COLOR}
        arcLinkLabelsSkipAngle={5}
        arcLabelsSkipAngle={5}
        // @ts-ignore
        arcLabelsTextColor={ARCLABEL_TEXT_COLOR}
      />
    </ChartCard>
  );
}
