import { CalendarDays, FolderOpenDot, Megaphone, NotepadTextDashed } from "lucide-react";
import { NavLink } from "react-router-dom";
import { SupportPopup } from "src/components/SupportPopup";
import { navLinkStyle } from "src/components/ui/navigation-menu";
import { useApplicationsQuery } from "src/hooks/useApplication";

export const NavItems = ({ onClick }: { onClick?: () => any }) => {
  const { isLoading, data: closedApps } = useApplicationsQuery({
    accountType: "broker",
    status: "closed",
  });

  return (
    <div>
      <NavLink className={navLinkStyle} to="/">
        <FolderOpenDot size={12} className="mr-1" />
        Active deals
      </NavLink>
      {isLoading || closedApps?.length ? (
        <NavLink className={navLinkStyle} to="/closed">
          <CalendarDays size={12} className="mr-1" />
          Closed deals
        </NavLink>
      ) : null}
      <NavLink className={navLinkStyle} to="/marketing">
        <Megaphone size={12} className="mr-1" />
        Sales tools
      </NavLink>
      <NavLink className={navLinkStyle} to="/broker-docs">
        <NotepadTextDashed size={12} className="mr-1" />
        Docs & resources
      </NavLink>
      <SupportPopup />
    </div>
  );
};
