import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import { Mail, Phone } from "src/icons";
import { Logo } from "src/images";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

import Divider from "../Divider";
import TextLink from "../TextLink";
import FooterDisclosure from "../disclosures/FooterDisclosure";
import { MediumText, RegularText, SemiBoldText } from "../v1/Text";

interface PageFooterProps {
  style?: SerializedStyles;
  columnsToShow?: FooterColumn[];
}

export enum FooterColumn {
  FINANCING = "financing",
  COMPANY = "company",
  RESOURCES = "resources",
  PARTNER = "partner",
  LEGAL = "legal",
}

const styles = createStyles({
  container: {
    display: "block",
    paddingTop: 80,
    paddingLeft: 200,
    paddingRight: 200,
    "@media(max-width: 1120px)": {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 135,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  topRow: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    marginRight: 50,
  },
  logoColumn: {
    display: "flex",
    flexDirection: "column",
  },
  linkText: {
    fontSize: 14,
  },
  icon: {
    marginRight: 12,
  },
  contactContainer: {
    marginBottom: 14,
    alignItems: "center",
  },
  header: {
    fontSize: 16,
    "@media(max-width: 748px)": {
      marginTop: 30,
    },
  },
  directoryText: {
    marginBottom: 18,
    fontSize: 14,
  },
  divider: {
    marginTop: 40,
    marginBottom: 40,
  },
  directoryContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  logo: {
    marginBottom: 40,
  },
  textLink: {
    color: colors.LINK,
    "&:hover": {
      color: colors.palette.GREEN_300,
    },
    "&:visited": {
      color: colors.palette.GREEN_700,
      "&:hover": {
        color: colors.palette.GREEN_300,
      },
    },
  },
});

const PageFooter = ({
  style,
  columnsToShow = [FooterColumn.RESOURCES, FooterColumn.LEGAL],
}: PageFooterProps) => (
  <div css={[styles.container, style]}>
    <div css={styles.topRow}>
      <div css={styles.logoColumn}>
        <Logo css={styles.logo} color={colors.palette.GREEN} width={120} height="100%" />
        <div css={styles.column}>
          <div css={[styles.row, styles.contactContainer]}>
            <Phone css={styles.icon} color={colors.palette.GREY_800} />
            <MediumText style={styles.linkText}>
              <TextLink style={styles.textLink} href="tel:+1(800)785-0263">
                +1 (800) 785-0263
              </TextLink>
            </MediumText>
          </div>
          <div css={[styles.row, styles.contactContainer]}>
            <Mail css={styles.icon} color={colors.palette.GREY_800} />
            <MediumText style={styles.linkText}>
              <TextLink style={styles.textLink} href="mailto:contact@fraction.com">
                contact@fraction.com
              </TextLink>
            </MediumText>
          </div>
        </div>
      </div>
      <div css={styles.directoryContainer}>
        {columnsToShow.includes(FooterColumn.FINANCING) && (
          <div css={styles.column}>
            <SemiBoldText style={[styles.directoryText, styles.header]}>Financing</SemiBoldText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/the-cost">How it works</TextLink>
            </RegularText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://app.fraction.com/application">Apply now</TextLink>
            </RegularText>
          </div>
        )}
        {columnsToShow.includes(FooterColumn.COMPANY) && (
          <div css={styles.column}>
            <SemiBoldText style={[styles.directoryText, styles.header]}>Company</SemiBoldText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/our-story">Our story</TextLink>
            </RegularText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/careers">Careers</TextLink>
            </RegularText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/contact-us">Contact us</TextLink>
            </RegularText>
          </div>
        )}
        {columnsToShow.includes(FooterColumn.RESOURCES) && (
          <div css={styles.column}>
            <SemiBoldText style={[styles.directoryText, styles.header]}>Resources</SemiBoldText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/why-fraction">Why Fraction?</TextLink>
            </RegularText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/faqs">FAQ</TextLink>
            </RegularText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/blog">Blog</TextLink>
            </RegularText>
          </div>
        )}
        {columnsToShow.includes(FooterColumn.PARTNER) && (
          <div css={styles.column}>
            <SemiBoldText style={[styles.directoryText, styles.header]}>Partner</SemiBoldText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/partner-with-us">Brokers</TextLink>
            </RegularText>
          </div>
        )}
        {columnsToShow.includes(FooterColumn.LEGAL) && (
          <div css={styles.column}>
            <SemiBoldText style={[styles.directoryText, styles.header]}>Legal</SemiBoldText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/usa/privacy-policy">Privacy Policy</TextLink>
            </RegularText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/terms-of-use">Terms of Use</TextLink>
            </RegularText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2084341">
                NMLS Consumer Access
              </TextLink>
            </RegularText>
            <RegularText style={styles.directoryText}>
              <TextLink href="https://www.fraction.com/licenses">Licensing</TextLink>
            </RegularText>
          </div>
        )}
      </div>
    </div>
    <Divider style={styles.divider} />
    <FooterDisclosure />
  </div>
);

export default memo(PageFooter);
