import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode, memo, useCallback, useEffect } from "react";
import { ChecklistLoan, useCreatePaymentSubscriptionMutation } from "src/apps/PostFundedDashboard/queries";
import { InfoAlert, ModalBox, TextLink } from "src/components";
import { PopupProps } from "src/components/Popup";
import { Logger } from "src/log";
import { createStyles } from "src/styles";
import { z } from "zod";

import { entities, enums, formatters, parsers } from "@fraction/shared";

import ConfirmationModalScreen from "../../../../components/ConfirmationModalScreen";
import ReviewAndEdit from "../../../../components/ReviewAndEdit";
import { PaymentScreen, usePaymentsModal } from "../../hooks/usePaymentsModal";
import CustomPaymentAmountScreen from "../sharedModalScreens/CustomPaymentAmount";
import PaymentAmountScreen from "../sharedModalScreens/PaymentAmountScreen";
import PaymentFrequencyScreen from "../sharedModalScreens/PaymentFrequencyScreen";
import PaymentReviewScreen from "../sharedModalScreens/PaymentReviewScreen";
import WireInstructionsScreen from "../sharedModalScreens/WireInstructionsScreen";

const LOGGER = new Logger("PrepaymentModal.index");

// If the borrower enters an amount larger than this limit, we will show a warning and
// inform them of the direct Wire transfer option.
const ACH_LIMIT_WARNING_AMOUNT = 2000; // in dollars

const PAYMENT_FREQUENCY_COLUMN = "frequency";

export enum PaymentFrequencyOption {
  ONE_TIME = "One Time",
  MONTHLY = "Monthly",
}

const styles = createStyles({
  confirmationModal: {
    padding: "56px 48px",
  },
  alert: {
    marginBottom: 32,
  },
  alertLink: {
    fontWeight: 600,
    cursor: "pointer",
  },
});

export interface PrepaymentModalProps extends Omit<PopupProps, "children"> {
  onClose: () => void;
  isLoading?: boolean;
  bankAccounts?: entities.BankAccount[];
  loan?: ChecklistLoan;
  availableToPay: number;
  virtualAccountNumber: string;
  subscriptions?: parsers.paymentSubscription.UsablePaymentSubscription[];
  initialPaymentScreens?: PaymentScreen[];
  mode?: "either" | "one-time" | "recurring";
}

const PrepaymentModal = ({
  isLoading,
  bankAccounts,
  loan,
  availableToPay,
  virtualAccountNumber,
  subscriptions,
  initialPaymentScreens,
  mode = "either",
  ...props
}: PrepaymentModalProps) => {
  const { dashboardPaymentSubscription } = useFlags();

  // If the user has already setup a payment subscription, we don't allow them to create a second one.
  // Therefore, we skip the payment frequency screen.
  const hasPaymentSubscription = !!subscriptions?.length;

  const {
    step,
    stepper,
    bankSetupScreens,
    currentBankAccount,
    paymentForm,
    paymentsMutation,
    handleSubmitPaymentOrder,
    onClose,
    handleSelectCustomAmount,
  } = usePaymentsModal({
    loan,
    onClose: props.onClose,
    isLoading,
    bankAccounts,
    initialPaymentScreens: initialPaymentScreens?.length
      ? initialPaymentScreens
      : dashboardPaymentSubscription && !hasPaymentSubscription
      ? [PaymentScreen.PAYMENT_FREQUENCY]
      : [
          PaymentScreen.CUSTOM_PAYMENT_AMOUNT,
          PaymentScreen.PAYMENT_REVIEW,
          PaymentScreen.PAYMENT_CONFIRMATION,
        ],
  });

  const createPaymentSubscriptionMutation = useCreatePaymentSubscriptionMutation();

  const schema = z.object({
    amount: parsers.types.positiveNumberString.refine(
      (n) => Number(n) <= availableToPay / 100,
      "Amount must be less than remaining balance"
    ),
  });

  const handleSelectTotalAmount = useCallback(() => {
    paymentForm.updateField(availableToPay / 100, "amount");
    stepper.push([PaymentScreen.PAYMENT_REVIEW, PaymentScreen.PAYMENT_CONFIRMATION]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.push, paymentForm.updateField, availableToPay]);

  const handleSubmitPaymentSubscription = useCallback(() => {
    const parsedPaymentSubscription = parsers.paymentSubscription.parsePaymentSubscriptionBody({
      amount: formatters.number.getCents(paymentForm.formValues.amount),
      bankAccountId: currentBankAccount?.id,
      loanId: loan?.id,
    });
    createPaymentSubscriptionMutation.mutate(parsedPaymentSubscription, {
      onSuccess: () => stepper.push([PaymentScreen.PAYMENT_SUBSCRIPTION_CONFIRMATION]),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentForm.formValues.amount,
    currentBankAccount?.id,
    loan?.id,
    createPaymentSubscriptionMutation,
    stepper.push,
  ]);

  const submitPrepayment = useCallback(() => {
    if (paymentForm.formValues.frequency === PaymentFrequencyOption.MONTHLY) {
      LOGGER.log("Submitting payment subscription");
      handleSubmitPaymentSubscription();
    } else {
      LOGGER.log("Submitting payment order");
      handleSubmitPaymentOrder(enums.TransactionType.REPAYMENT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmitPaymentOrder, handleSubmitPaymentSubscription, paymentForm.formValues.frequency]);

  const handleSelectWirePayment = useCallback(() => {
    stepper.insert(PaymentScreen.WIRE_INSTRUCTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.insert]);

  const goBackAndRemoveStep = useCallback(() => {
    stepper.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.remove]);

  const handleSelectOneTimePayment = useCallback(() => {
    paymentForm.updateField(PaymentFrequencyOption.ONE_TIME, PAYMENT_FREQUENCY_COLUMN);
    // stepper.push(PaymentScreen.CUSTOM_PAYMENT_AMOUNT);
    handleSelectCustomAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.push, paymentForm.updateField]);

  const handleSelectRecurringPayment = useCallback(() => {
    paymentForm.updateField(PaymentFrequencyOption.MONTHLY, PAYMENT_FREQUENCY_COLUMN);
    // it wouldn't make sense to pay the full balance in recurring payments, so go straight to the custom amount screen
    stepper.push([
      PaymentScreen.CUSTOM_PAYMENT_AMOUNT,
      PaymentScreen.PAYMENT_REVIEW,
      PaymentScreen.PAYMENT_CONFIRMATION,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.push, paymentForm.updateField]);

  useEffect(() => {
    if (mode === "either" || !props.open) {
      return;
    }
    if (mode === "one-time") {
      paymentForm.updateField(PaymentFrequencyOption.ONE_TIME, PAYMENT_FREQUENCY_COLUMN);
      stepper.replace([
        PaymentScreen.SELECT_ACCOUNT,
        PaymentScreen.CUSTOM_PAYMENT_AMOUNT,
        PaymentScreen.PAYMENT_REVIEW,
        PaymentScreen.PAYMENT_CONFIRMATION,
      ]);
    } else {
      paymentForm.updateField(PaymentFrequencyOption.MONTHLY, PAYMENT_FREQUENCY_COLUMN);
      stepper.replace([
        PaymentScreen.SELECT_ACCOUNT,
        PaymentScreen.CUSTOM_PAYMENT_AMOUNT,
        PaymentScreen.PAYMENT_REVIEW,
        PaymentScreen.PAYMENT_SUBSCRIPTION_CONFIRMATION,
      ]);
    }
  }, [mode, props.open]);

  const goToFrequencySelection = useCallback(() => {
    stepper.go(PaymentScreen.PAYMENT_FREQUENCY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.go]);

  // Mapping of possible modal states, returns a component to render and optional width override in the format: [component, width]
  const modalStates: Record<string, [ReactNode] | [ReactNode, number]> = {
    ...bankSetupScreens,
    [PaymentScreen.PAYMENT_FREQUENCY]: [
      <PaymentFrequencyScreen
        onBack={stepper.goBack}
        header="Payment Frequency"
        alert={
          <InfoAlert style={styles.alert}>
            Monthly payments will be automatically pulled from your account on the first weekday of every
            month. You can disable this at any time.
          </InfoAlert>
        }
        paragraph="You can submit a one time payment, or set up an automated monthly recurring payment."
        handleSelectOneTimePayment={handleSelectOneTimePayment}
        handleSelectRecurringPayment={handleSelectRecurringPayment}
      />,
      518,
    ],
    [PaymentScreen.PAYMENT_AMOUNT]: [
      <PaymentAmountScreen
        onBack={stepper.goBack}
        header="ENTER PREPAYMENT AMOUNT"
        paragraph="You can prepay the entire Fraction loan or any amount less than the loan balance."
        buttonText="Total loan balance"
        maxAmount={loan?.balance}
        handleSelectTotalAmount={handleSelectTotalAmount}
        handleSelectCustomAmount={handleSelectCustomAmount}
      />,
      428,
    ],
    [PaymentScreen.CUSTOM_PAYMENT_AMOUNT]: [
      <CustomPaymentAmountScreen
        onBack={stepper.goBack}
        onContinue={stepper.goForward}
        paragraph="Enter your prepayment amount."
        formValues={paymentForm.formValues}
        handleTextChange={paymentForm.handleTextChange}
        schema={schema}
        overdueBalance={loan?.overdueBalance}
        paymentAmount={loan?.paymentAmount || undefined}
        recurring={paymentForm.formValues.frequency === PaymentFrequencyOption.MONTHLY}
      />,
      428,
    ],
    [PaymentScreen.PAYMENT_REVIEW]: [
      <PaymentReviewScreen
        bankAccount={currentBankAccount}
        header="PREPAYMENT REVIEW"
        paragraph="Below is a review of your prepayment."
        amountLabel="Prepayment amount"
        onContinue={submitPrepayment}
        onBack={stepper.goBack}
        handleGoToScreen={stepper.go}
        formValues={paymentForm.formValues}
        isLoading={paymentsMutation.isPending || createPaymentSubscriptionMutation.isPending}
        subHeader={
          Number(paymentForm.formValues.amount) > ACH_LIMIT_WARNING_AMOUNT ? (
            <InfoAlert style={styles.alert} variant="yellow">
              Before submitting, please be aware that this payment will be subject to your selected bank
              account's {loan?.application?.property?.country === "US" ? "ACH" : "EFT"} payment limit. If you
              would like to create a payment that exceeds your account limit, please see our{" "}
              <TextLink onClick={handleSelectWirePayment} style={styles.alertLink} underlined>
                Wire payment instructions.{" "}
              </TextLink>
            </InfoAlert>
          ) : null
        }
      >
        {dashboardPaymentSubscription && !hasPaymentSubscription && (
          <ReviewAndEdit
            label="Frequency"
            value={paymentForm.formValues.frequency}
            onClickEdit={goToFrequencySelection}
          />
        )}
      </PaymentReviewScreen>,
      770,
    ],
    [PaymentScreen.PAYMENT_CONFIRMATION]: [
      <ConfirmationModalScreen
        style={styles.confirmationModal}
        header="PREPAYMENT PENDING"
        text="Your prepayment request has been recieved. Once your payment has been completed, we will send you a confirmation email."
        onClose={onClose}
      />,
    ],
    [PaymentScreen.PAYMENT_SUBSCRIPTION_CONFIRMATION]: [
      <ConfirmationModalScreen
        style={styles.confirmationModal}
        header="Success! You have set up a monthly payment plan."
        text="Monthly payments will be automatically pulled from your account on the first weekday of every
        month. You can disable this at any time."
        onClose={onClose}
      />,
    ],
    [PaymentScreen.WIRE_INSTRUCTIONS]: [
      <WireInstructionsScreen
        virtualAccountNumber={virtualAccountNumber}
        onBack={goBackAndRemoveStep}
        onContinue={onClose}
        country={loan?.application?.property?.country}
        portfolio={loan?.portfolio}
      />,
    ],
  };

  const [content, width] = modalStates[step];

  return (
    <ModalBox width={width || 448} {...props} onClose={onClose}>
      {content}
    </ModalBox>
  );
};

export default memo(PrepaymentModal);
