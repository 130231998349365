import { Global, SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, ReactNode, memo, useCallback, useMemo } from "react";
import RSlider, { InputSliderProps } from "react-input-slider";
import { RegularText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors, formatters, utilities } from "@fraction/shared";

const RSliderComponent = RSlider as unknown as FunctionComponent<InputSliderProps>;

export interface InaccessibleRegion {
  color: string;
  value: number;
  label?: ReactNode;
  labelStyle?: SerializedStyles | Array<SerializedStyles | undefined>;
}

export interface SliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (val: number) => void;
  step?: number;
  markers?: Array<number | string>; // string is percentage
  color?: string;
  inaccessibleRegions?: InaccessibleRegion[];
}

export const SLIDER_THUMB_SIZE = 25;

// need just standard object for Slider styles
const styles = {
  track: {
    width: "100%",
    backgroundColor: colors.SLIDER_INACTIVE,
    height: 9,
    borderRadius: 0,
  },
  active: {
    backgroundColor: colors.SLIDER_STANDARD,
    height: 9,
    borderRadius: 0,
  },
  thumb: {
    backgroundColor: "white",
    height: SLIDER_THUMB_SIZE,
    width: SLIDER_THUMB_SIZE,
    borderRadius: "50%",
    borderWidth: 1,
    borderColor: colors.SLIDER_STANDARD,
    borderStyle: "solid",
    boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.15)",
    zIndex: 1,
  },
};

const CSS_STYLES = createStyles({
  marker: {
    height: 20,
    width: 1,
    backgroundColor: colors.SLIDER_MARKER,
    position: "absolute",
    transform: "translateY(-25%)",
  },
  bottomMarker: {
    height: 20,
    width: 1,
    backgroundColor: colors.SLIDER_MARKER,
  },
  markerContainer: {
    position: "absolute",
    top: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateX(-50%)",
    // bit of a hack to make the container not cause x overflows on mobile
    // since we do transforms on the inside, causing pieces to move around
    width: 0,
  },
  markerText: {
    fontSize: 12,
    color: colors.LIGHT_TEXT,
    textAlign: "center",
  },
  container: {
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexDirection: "row",
  },
  inaccessibleRegion: {
    height: 9,
  },
});

/**
 * We want a step that can evenly work between min and max so that users can go all the way to the min
 * and all the way to the max.
 *
 * This is a crappy algorithm to try to find such a step. There's probably a more elegant formula.
 */
function findSafeStep(max: number, min: number, startingStep: number) {
  let remainder = (max - min) % startingStep;
  let step = startingStep - remainder;
  remainder = (max - min) % step;

  let tries = 0;

  while (remainder !== 0) {
    if (tries > 10) {
      // give up, just send back a tiny step
      return 1000;
    }
    remainder = (max - min) % step;
    step -= remainder;
    tries++;
  }
  return step;
}

const Slider = ({
  min,
  max,
  value,
  step = 1,
  onChange,
  markers = [],
  inaccessibleRegions = [],
  color = colors.SLIDER_STANDARD,
}: SliderProps) => {
  const handleChange = useCallback(
    ({ x }: { x: number }) => {
      onChange(formatters.number.getNumberFromString(x));
    },
    [onChange]
  );

  const markerLocations = markers.map((marker) => {
    if (typeof marker === "string") {
      return marker;
    }
    return `${utilities.number.boundedPercentage(marker, min, max)}%`;
  });

  const cumulativeInaccessibleWidths = inaccessibleRegions.reduce(
    (sum, region) => sum + utilities.number.boundedPercentage(region.value, min, max),
    0
  );

  const xmin = Math.max(...[min, ...inaccessibleRegions.map((region) => region.value)]);
  const xstep = useMemo(() => findSafeStep(max, xmin, step), [max, step, xmin]);

  return (
    <>
      <Global
        styles={css`
          body {
            overscroll-behavior: none;
          }
        `}
      />
      <div css={CSS_STYLES.container}>
        {inaccessibleRegions.map((region) => (
          <div
            key={region.value}
            css={[
              CSS_STYLES.inaccessibleRegion,
              {
                width: `${utilities.number.boundedPercentage(region.value, min, max)}%`,
                backgroundColor: region.color,
              },
            ]}
          />
        ))}
        <RSliderComponent
          x={value}
          onChange={handleChange}
          xstep={xstep}
          xmin={xmin}
          xmax={max}
          styles={{
            ...styles,
            track: { ...styles.track, width: `${100 - cumulativeInaccessibleWidths}%` },
            active: { ...styles.active, backgroundColor: color },
            thumb: { ...styles.thumb, borderColor: color },
          }}
        />
        {markerLocations.map((marker, index) => (
          <div css={[CSS_STYLES.marker, { left: marker }]} key={`${marker}-${index}`} />
        ))}
        {inaccessibleRegions
          .filter((region) => region.label)
          .map((region) => (
            <div
              key={region.value}
              css={[
                CSS_STYLES.markerContainer,
                { left: `${utilities.number.boundedPercentage(region.value, min, max)}%` },
              ]}
            >
              <div css={CSS_STYLES.bottomMarker} />
              <RegularText style={[CSS_STYLES.markerText, region.labelStyle]}>{region.label}</RegularText>
            </div>
          ))}
      </div>
    </>
  );
};

export default memo(Slider);
