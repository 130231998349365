import { useEffect } from "react";

export type EnhanceRouteProps = {
  title?: string;
  children: React.ReactElement | null;
};

const EnhanceRoute = ({ title, children }: EnhanceRouteProps) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
  return children;
};

export default EnhanceRoute;
