// This file is generated automatically by the update:tokens script. DO NOT EDIT IT MANUALLY.

const tokens = {
  colors: {
    /**
     * #FAFAFA
     */
    grey100: "rgba(250,250,250,1)",
    /**
     * #F4F4F5
     */
    grey200: "rgba(244,244,245,1)",
    /**
     * #E4E4E7
     */
    grey300: "rgba(228,228,231,1)",
    /**
     * #D4D4D8
     */
    grey400: "rgba(212,212,216,1)",
    /**
     * #A1A1AA
     */
    grey500: "rgba(161,161,170,1)",
    /**
     * #71717A
     */
    grey600: "rgba(113,113,122,1)",
    /**
     * #52525B (Base)
     */
    grey700: "rgba(82,82,91,1)",
    /**
     * #52525B (Base)
     */
    grey: "rgba(82,82,91,1)",
    /**
     * #FFF9ED
     */
    amber100: "rgba(255,249,237,1)",
    /**
     * #FFF3DC
     */
    amber200: "rgba(255,243,220,1)",
    /**
     * #FFEDCB
     */
    amber300: "rgba(255,237,203,1)",
    /**
     * #FFE7BA
     */
    amber400: "rgba(255,231,186,1)",
    /**
     * #FFDB97
     */
    amber500: "rgba(255,219,151,1)",
    /**
     * #FFD586
     */
    amber600: "rgba(255,213,134,1)",
    /**
     * #FFD586 (Base)
     */
    amber700: "rgba(255,213,134,1)",
    /**
     * #FFD586 (Base)
     */
    amber: "rgba(255,213,134,1)",
    /**
     * #E5B04A
     */
    amber800: "rgba(229,176,74,1)",
    /**
     * #B2893A
     */
    amber900: "rgba(178,137,58,1)",
    /**
     * #7F6229
     */
    amber1000: "rgba(127,98,41,1)",
    /**
     * #FBEEEF
     */
    red100: "rgba(251,238,239,1)",
    /**
     * #F8DEDF
     */
    red200: "rgba(248,222,223,1)",
    /**
     * #F5CECF
     */
    red300: "rgba(245,206,207,1)",
    /**
     * #F2BEBF
     */
    red400: "rgba(242,190,191,1)",
    /**
     * #EC9E9F
     */
    red500: "rgba(236,158,159,1)",
    /**
     * #E98E8F
     */
    red600: "rgba(233,142,143,1)",
    /**
     * #E05E60 (Base)
     */
    red700: "rgba(224,94,96,1)",
    /**
     * #E05E60 (Base)
     */
    red: "rgba(224,94,96,1)",
    /**
     * #C95456
     */
    red800: "rgba(201,84,86,1)",
    /**
     * #9C4143
     */
    red900: "rgba(156,65,67,1)",
    /**
     * #702F30
     */
    red1000: "rgba(112,47,48,1)",
    /**
     * #EBFAFB
     */
    teal100: "rgba(235,250,251,1)",
    /**
     * #C8F0F5
     */
    teal200: "rgba(200,240,245,1)",
    /**
     * #A5E6EE
     */
    teal300: "rgba(165,230,238,1)",
    /**
     * #82DCE8
     */
    teal400: "rgba(130,220,232,1)",
    /**
     * #5ED3E1
     */
    teal500: "rgba(94,211,225,1)",
    /**
     * #3BC9DB
     */
    teal600: "rgba(59,201,219,1)",
    /**
     * #30A5B4 (Base)
     */
    teal700: "rgba(48,165,180,1)",
    /**
     * #30A5B4 (Base)
     */
    teal: "rgba(48,165,180,1)",
    /**
     * #26818C
     */
    teal800: "rgba(38,129,140,1)",
    /**
     * #1B5C65
     */
    teal900: "rgba(27,92,101,1)",
    /**
     * #11383D
     */
    teal1000: "rgba(17,56,61,1)",
    yellow50: "rgba(254, 249, 231, 1)",
    yellow100: "rgba(253, 242, 206, 1)",
    yellow150: "rgba(252, 236, 182, 1)",
    yellow200: "rgba(251, 229, 157, 1)",
    yellow250: "rgba(250, 223, 133, 1)",
    yellow300: "rgba(248, 216, 108, 1)",
    yellow350: "rgba(247, 210, 84, 1)",
    yellow400: "rgba(246, 203, 59, 1)",
    yellow450: "rgba(245, 197, 35, 1)",
    yellow500: "rgba(244, 190, 10, 1)",
    yellow550: "rgba(220, 171, 9, 1)",
    yellow600: "rgba(195, 152, 8, 1)",
    yellow650: "rgba(171, 133, 7, 1)",
    yellow700: "rgba(146, 114, 6, 1)",
    yellow750: "rgba(122, 95, 5, 1)",
    yellow800: "rgba(98, 76, 4, 1)",
    yellow850: "rgba(73, 57, 3, 1)",
    yellow900: "rgba(24, 19, 1, 1)",
    yellow950: "rgba(19, 15, 0, 1)",
    /**
     * #F3FAF3
     */
    green100: "rgba(243,250,243,1)",
    /**
     * #DCF0DC
     */
    green200: "rgba(220,240,220,1)",
    /**
     * #C6E6C6
     */
    green300: "rgba(198,230,198,1)",
    /**
     * #AFDCAF
     */
    green400: "rgba(175,220,175,1)",
    /**
     * #99D299
     */
    green500: "rgba(153,210,153,1)",
    /**
     * #82C882 (Brand color)
     */
    green600: "rgba(130,200,130,1)",
    /**
     * #6BA46B (Base)
     */
    green700: "rgba(107,164,107,1)",
    /**
     * #6BA46B (Base)
     */
    green: "rgba(107,164,107,1)",
    /**
     * #538053
     */
    green800: "rgba(83,128,83,1)",
    /**
     * #3C5C3C
     */
    green900: "rgba(60,92,60,1)",
    /**
     * #243824
     */
    green1000: "rgba(36,56,36,1)",
    /**
     * #FAFAF9
     */
    darkgrey100: "rgba(250,250,249,1)",
    /**
     * #F5F5F4
     */
    darkgrey200: "rgba(245,245,244,1)",
    /**
     * #E7E5E4
     */
    darkgrey300: "rgba(231,229,228,1)",
    /**
     * #D6D3D1
     */
    darkgrey400: "rgba(214,211,209,1)",
    /**
     * #A8A29E
     */
    darkgrey500: "rgba(168,162,158,1)",
    /**
     * #78716C
     */
    darkgrey600: "rgba(120,113,108,1)",
    /**
     * #57534E (Base)
     */
    darkgrey700: "rgba(87,83,78,1)",
    /**
     * #57534E (Base)
     */
    darkgrey: "rgba(87,83,78,1)",
    /**
     * #44403C
     */
    darkgrey800: "rgba(68,64,60,1)",
    /**
     * #E8EBE9
     */
    darkgreen100: "rgba(232,235,233,1)",
    /**
     * #D1D6D4
     */
    darkgreen200: "rgba(209,214,212,1)",
    /**
     * #BBC2BE
     */
    darkgreen300: "rgba(187,194,190,1)",
    /**
     * #A4ADA9
     */
    darkgreen400: "rgba(164,173,169,1)",
    /**
     * #8D9993
     */
    darkgreen500: "rgba(141,153,147,1)",
    /**
     * #76847D
     */
    darkgreen600: "rgba(118,132,125,1)",
    /**
     * #5F7068
     */
    darkgreen700: "rgba(95,112,104,1)",
    /**
     * #5F7068
     */
    darkgreen: "rgba(95,112,104,1)",
    /**
     * #495B52
     */
    darkgreen800: "rgba(73,91,82,1)",
    /**
     * #32473D
     */
    darkgreen900: "rgba(50,71,61,1)",
    /**
     * #1B3227
     */
    darkgreen1000: "rgba(27,50,39,1)",
    /**
     * #EBF5FE
     */
    blue100: "rgba(235,245,254,1)",
    /**
     * #C6E3FB
     */
    blue200: "rgba(198,227,251,1)",
    /**
     * #A1D1F8
     */
    blue300: "rgba(161,209,248,1)",
    /**
     * #7CBEF5
     */
    blue400: "rgba(124,190,245,1)",
    /**
     * #58ACF3
     */
    blue500: "rgba(88,172,243,1)",
    /**
     * #339AF0
     */
    blue600: "rgba(51,154,240,1)",
    /**
     * #2A7EC5 (Base)
     */
    blue700: "rgba(42,126,197,1)",
    /**
     * #2A7EC5 (Base)
     */
    blue: "rgba(42,126,197,1)",
    /**
     * #21639A
     */
    blue800: "rgba(33,99,154,1)",
    /**
     * #17476E
     */
    blue900: "rgba(23,71,110,1)",
    /**
     * #0E2B43
     */
    blue1000: "rgba(14,43,67,1)",
    /**
     * #FFFFFF
     */
    white: "rgba(255,255,255,1)",
    /**
     * #1A231A
     */
    black: "rgba(26,35,26,1)",
    /**
     * #FAF5E2
     */
    beige: "rgba(250,245,226,1)",
    /**
     * #AFAFAE
     */
    silver: "rgba(175,175,174,1)",
    /**
     * #f1f1f3
     */
    platinum: "rgba(241,241,243,1)",
  },
  borderRadius: {
    /**
     * This is our root value
     */
    root: 8,
  },
  spacing: {
    /**
     * This is our spacing root value
     */
    root: 8,
  },
  borderWidth: {
    /**
     * This is our root value
     */
    root: 1,
  },
  fontSize: {
    "1": 12,
    "2": 14,
    "3": 16,
    "4": 18,
    "5": 20,
    "6": 24,
    "7": 32,
    "8": 36,
    "9": 40,
    "10": 48,
    "11": 56,
    "12": 64,
  },
  "Canela Deck": "Canela Deck",
  "Avenir Pro": "Avenir Pro",
  Light: "Light",
  Regular: "Regular",
  Roman: "Roman",
  Medium: "Medium",
  Bold: "Bold",
  Black: "Black",
  lineHeight: {
    default: { none: "100%" },
    scalar: { "120": "120%", "140": "140%", "150": "150%" },
    auto: "Auto",
  },
  theme: {
    background: {
      /**
       * Use for frame and page backgrounds
       */
      base: "rgba(244,244,245,1)",
    },
    surface: {
      default: {
        /**
         * Use as a background for components in the foreground
         */
        base: "rgba(255,255,255,1)",
        /**
         * Use as an elevated background for components in the foreground
         */
        subdued: "rgba(250,250,249,1)",
        /**
         * Use as a background on interactive elements when in a hovered state.
         */
        hovered: "rgba(245,245,244,1)",
        /**
         * Use as a background on interactive elements when in a pressed state.
         */
        pressed: "rgba(231,229,228,1)",
        /**
         * Use as a background on interactive elements when in a depressed state.
         */
        depressed: "rgba(214,211,209,1)",
      },
      neutral: {
        /**
         * For use as a background color in neutral badges.
         */
        base: "rgba(228,228,231,1)",
        subdued: "rgba(250,250,250,1)",
        "subdued-hovered": "rgba(244,244,245,1)",
        "subdued-pressed": "rgba(228,228,231,1)",
        depressed: "rgba(212,212,216,1)",
      },
      disabled: { base: "rgba(241,241,243,1)" },
      selected: {
        /**
         * Prev: #d9eed9
         */
        base: "rgba(198,230,198,1)",
        /**
         * Prev: #f2f9f2
         */
        subdued: "rgba(243,250,243,1)",
        /**
         * Prev: #e6f4e6
         */
        "subdued-hovered": "rgba(220,240,220,1)",
      },
      warning: {
        base: "rgba(255,213,134,1)",
        subdued: "rgba(255,249,237,1)",
        "subdued-hovered": "rgba(255,243,220,1)",
        "subdued-pressed": "rgba(255,237,203,1)",
        "subdued-depressed": "rgba(255,231,186,1)",
      },
      critical: {
        base: "rgba(233,142,143,1)",
        subdued: "rgba(251,238,239,1)",
        "subdued-hovered": "rgba(248,222,223,1)",
        "subdued-pressed": "rgba(245,206,207,1)",
        "subdued-depressed": "rgba(242,190,191,1)",
      },
      success: {
        /**
         * Prev: #a7d8a7
         */
        base: "rgba(130,200,130,1)",
        /**
         * Prev: #f2f9f2
         */
        subdued: "rgba(243,250,243,1)",
        /**
         * Prev: #e6f4e6
         */
        "subdued-hovered": "rgba(220,240,220,1)",
        /**
         * Prev: #d9eed9
         */
        "subdued-pressed": "rgba(198,230,198,1)",
        /**
         * Prev: #cde9cd
         */
        "subdued-depressed": "rgba(175,220,175,1)",
      },
      highlight: {
        /**
         * Prev: #a0e7e0
         */
        base: "rgba(94,211,225,1)",
        /**
         * Prev: #ebfafb
         */
        subdued: "rgba(235,250,251,1)",
        /**
         * Prev: #c8f0f5
         */
        "subdued-hovered": "rgba(200,240,245,1)",
        /**
         * Prev: #a5e6ee
         */
        "subdued-pressed": "rgba(165,230,238,1)",
        /**
         * Prev: #c9f1ed
         */
        "subdued-depressed": "rgba(130,220,232,1)",
      },
      on: { base: "rgba(255,255,255,1)", monochrome: "rgba(26,35,26,1)" },
    },
    text: {
      default: {
        /**
         * Use for default text
         */
        base: "rgba(26,35,26,1)",
      },
      accent: {
        /**
         * Use for emphasized text
         */
        base: "rgba(130,200,130,1)",
        /**
         * Prev: #b4deb4
         */
        subdued: "rgba(198,230,198,1)",
        /**
         * Prev: #75b475
         */
        hovered: "rgba(107,164,107,1)",
        /**
         * Prev: #5b8c5b
         */
        pressed: "rgba(83,128,83,1)",
      },
      neutral: {
        /**
         * Use for secondary text or default input placeholders
         */
        base: "rgba(161,161,170,1)",
        /**
         * Use for italicized secondary text or legal subtext
         */
        hovered: "rgba(113,113,122,1)",
        pressed: "rgba(82,82,91,1)",
      },
      disabled: { base: "rgba(175,175,174,1)" },
      warning: {
        base: "rgba(255,213,134,1)",
        /**
         * Prev: #e5b04a
         */
        hovered: "rgba(229,176,74,1)",
        pressed: "rgba(178,137,58,1)",
      },
      critical: { base: "rgba(224,94,96,1)", hovered: "rgba(201,84,86,1)", pressed: "rgba(156,65,67,1)" },
      success: { base: "rgba(107,164,107,1)", hovered: "rgba(83,128,83,1)", pressed: "rgba(60,92,60,1)" },
      highlight: { base: "rgba(48,165,180,1)", hovered: "rgba(38,129,140,1)", pressed: "rgba(27,92,101,1)" },
      on: { base: "rgba(255,255,255,1)", monochrome: "rgba(26,35,26,1)" },
    },
    decorative: {
      text: { base: "rgba(168,162,158,1)" },
      surface: {
        black: "rgba(26,35,26,1)",
        darkgreen: "rgba(27,50,39,1)",
        brown: "rgba(68,64,60,1)",
        beige: "rgba(250,245,226,1)",
        white: "rgba(255,255,255,1)",
        green: "rgba(130,200,130,1)",
        light: "rgba(250,250,249,1)",
      },
      border: { base: "rgba(245,245,244,1)" },
    },
    icon: {
      default: { base: "rgba(26,35,26,1)" },
      accent: { base: "rgba(130,200,130,1)", hovered: "rgba(107,164,107,1)", pressed: "rgba(83,128,83,1)" },
      neutral: { base: "rgba(161,161,170,1)", hovered: "rgba(113,113,122,1)", pressed: "rgba(82,82,91,1)" },
      disabled: { base: "rgba(175,175,174,1)" },
      warning: { base: "rgba(255,213,134,1)", hovered: "rgba(229,176,74,1)", pressed: "rgba(178,137,58,1)" },
      critical: { base: "rgba(224,94,96,1)", hovered: "rgba(201,84,86,1)", pressed: "rgba(156,65,67,1)" },
      success: { base: "rgba(107,164,107,1)", hovered: "rgba(83,128,83,1)", pressed: "rgba(60,92,60,1)" },
      highlight: { base: "rgba(48,165,180,1)", hovered: "rgba(38,129,140,1)", pressed: "rgba(27,92,101,1)" },
      on: { base: "rgba(255,255,255,1)", monochrome: "rgba(26,35,26,1)" },
    },
    border: {
      default: {
        /**
         * Prev: #e7e5e4
         */
        base: "rgba(231,229,228,1)",
        subdued: "rgba(245,245,244,1)",
        /**
         * #e7e5e4
         */
        hovered: "rgba(231,229,228,1)",
        pressed: "rgba(214,211,209,1)",
      },
      neutral: {
        base: "rgba(228,228,231,1)",
        subdued: "rgba(244,244,245,1)",
        hovered: "rgba(212,212,216,1)",
      },
      disabled: { base: "rgba(212,212,216,1)" },
      selected: { base: "rgba(130,200,130,1)", subdued: "rgba(175,220,175,1)" },
      warning: { base: "rgba(255,213,134,1)", subdued: "rgba(255,219,151,1)" },
      critical: { base: "rgba(224,94,96,1)", subdued: "rgba(236,158,159,1)" },
      success: { base: "rgba(107,164,107,1)", subdued: "rgba(153,210,153,1)" },
      highlight: { base: "rgba(48,165,180,1)", subdued: "rgba(94,211,225,1)" },
      on: { base: "rgba(255,255,255,1)", monochrome: "rgba(26,35,26,1)" },
    },
    action: {
      default: {
        base: "rgba(130,200,130,1)",
        hovered: "rgba(107,164,107,1)",
        pressed: "rgba(83,128,83,1)",
        depressed: "rgba(60,92,60,1)",
      },
      critical: {
        base: "rgba(224,94,96,1)",
        hovered: "rgba(201,84,86,1)",
        pressed: "rgba(156,65,67,1)",
        depressed: "rgba(112,47,48,1)",
      },
      secondary: {
        /**
         * Use as a background on secondary action elements.
         */
        base: "rgba(255,255,255,1)",
        /**
         * Use as a background on secondary action elements when in a hovered state.
         */
        hovered: "rgba(250,250,250,1)",
        /**
         * Use as a background on secondary action elements when in a pressed state.
         */
        pressed: "rgba(244,244,245,1)",
        /**
         * Use as a background on secondary action elements when in a depressed state.
         */
        depressed: "rgba(228,228,231,1)",
      },
      tertiary: {
        /**
         * Use as a background on tertiary action elements like back or edit buttons.
         */
        base: "rgba(250,250,250,1)",
        /**
         * Use as a background on tertiary action elements like back or edit buttons when in a hovered state.
         */
        hovered: "rgba(244,244,245,1)",
        /**
         * Use as a background on tertiary action elements when in a pressed state.
         */
        pressed: "rgba(228,228,231,1)",
        /**
         * Use as a background on tertiary action elements when in a depressed state.
         */
        depressed: "rgba(212,212,216,1)",
      },
      disabled: { base: "rgba(241,241,243,1)" },
      on: { base: "rgba(255,255,255,1)", monochrome: "rgba(26,35,26,1)" },
    },
    interactive: {
      default: {
        base: "rgba(130,200,130,1)",
        hovered: "rgba(107,164,107,1)",
        pressed: "rgba(83,128,83,1)",
        depressed: "rgba(60,92,60,1)",
        disabled: "rgba(175,220,175,1)",
      },
      critical: {
        base: "rgba(224,94,96,1)",
        hovered: "rgba(201,84,86,1)",
        pressed: "rgba(156,65,67,1)",
        depressed: "rgba(112,47,48,1)",
        disabled: "rgba(236,158,159,1)",
      },
      secondary: {
        /**
         * Use for background of secondary interactive elements like segmented toggles or action lists..
         */
        base: "rgba(255,255,255,1)",
        hovered: "rgba(250,250,250,1)",
        pressed: "rgba(244,244,245,1)",
        depressed: "rgba(228,228,231,1)",
        disabled: "rgba(241,241,243,1)",
      },
      on: { base: "rgba(255,255,255,1)", monochrome: "rgba(26,35,26,1)" },
    },
    focus: {
      default: {
        /**
         * Use for focused states of stateful components.
         */
        base: "#82c88233",
      },
      critical: {
        /**
         * Use for focused states of stateful components that contain errors.
         */
        base: "#e05e6033",
      },
      mono: { base: "rgba(26,42,50,0.1)" },
    },
    spacing: {
      /**
       * No spacing
       */
      none: 0,
      default: {
        /**
         * Our base spacing value
         */
        base: 8,
        /**
         * Our base spacing value with some extra padding
         */
        extra: 12,
      },
      reduce: {
        /**
         * Spacing equal to 1/4 our base spacing value
         */
        "025": 2,
        /**
         * Spacing equal to 1/2 our base spacing value
         */
        "05": 4,
      },
      scale: {
        /**
         * Spacing equal to 2x our base spacing value
         */
        "2": 16,
        /**
         * Spacing equal to 3x our base spacing value
         */
        "3": 24,
        /**
         * Spacing equal to 3x our base spacing value
         */
        "4": 32,
        /**
         * Spacing equal to 5x our base spacing value
         */
        "5": 40,
        /**
         * Spacing equal to 6x our base spacing value
         */
        "6": 48,
        /**
         * Spacing equal to 8x our base spacing value
         */
        "8": 64,
        /**
         * Spacing equal to 10x our base spacing value
         */
        "10": 80,
        /**
         * Spacing equal to 12x our base spacing value
         */
        "12": 96,
      },
    },
    borderRadius: {
      /**
       * No radius
       */
      none: 0,
      default: {
        /**
         * This is our base border radius
         */
        base: 8,
      },
      shape: {
        /**
         * Gives a bolder more blocky look
         */
        block: 6,
        /**
         * Creates a circle
         */
        circle: "50%",
      },
      reduce: {
        /**
         * Border radius equal to 1/4 our base value
         */
        "025": 2,
        /**
         * Border radius equal to 1/2 our base value
         */
        "05": 4,
      },
      scale: {
        /**
         * Border radius equal to 2x our base value
         */
        "2": 16,
        /**
         * Border radius equal to 3x our base value
         */
        "3": 24,
      },
    },
    borderWidth: {
      default: {
        /**
         * This is our base Border width
         */
        base: 1,
      },
      scale: {
        /**
         * Border width equal to 2x our base value
         */
        "2": 2,
        /**
         * Border width equal to 4x our base value
         */
        "4": 4,
        /**
         * Border width equal to 8x our base value
         */
        "8": 8,
      },
    },
  },
} as const;
export type FigmaTokens = typeof tokens;
export default tokens;
