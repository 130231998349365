import { useQueryClient } from "@tanstack/react-query";
import cookie from "cookie";
import _ from "lodash";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/auth";
import { useApplicationsQuery } from "src/hooks/useApplication";
import { useQuery } from "src/lib";
import { saveCookie } from "src/utilities/tracking";

export type AccountViewPreference =
  | "broker"
  | "borrower"
  | "applicant"
  | "employee"
  | "servicing"
  | "conveyancer";

export const useAccountViewPreference = () => {
  const { user, isLoadingUser } = useAuth();
  const { data: apps, isInitialLoading: isLoadingApplications } = useApplicationsQuery({
    refetch: false,
    accountType: "applicant",
    status: "all",
    enabled: !!user?.applicantAccounts?.length,
  });
  const hasMultiple =
    [
      user?.brokerAccount,
      user?.applicantAccounts?.length,
      user?.internalEmployee,
      user?.servicingCustomer,
      user?.conveyancerAccount,
    ].filter(Boolean).length > 1;
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const choices: AccountViewPreference[] = [];
  if (user?.internalEmployee) {
    choices.push("employee");
  }
  if (user?.brokerAccount) {
    choices.push("broker");
  }
  if (user?.conveyancerAccount) {
    choices.push("conveyancer");
  }

  if (apps && _.isArray(apps) && user?.applicantAccounts?.length) {
    if (apps?.find((app) => app.loan?.id)) {
      choices.push("borrower");
    }
    if (apps?.find((app) => !app.loan?.id)) {
      choices.push("applicant");
    }
  }

  if (user?.servicingCustomer) {
    choices.push("servicing");
  }

  const cookieData = useQuery({
    queryKey: ["cookieData", "preferences", "accountView"],
    queryFn: () => {
      const cookies = cookie.parse(document.cookie);
      return cookies.user_preference || null;
    },
  });

  const isLoading = isLoadingUser || isLoadingApplications || cookieData?.isLoading;

  const setPreference = useCallback((key: AccountViewPreference) => {
    saveCookie("user_preference", key);

    queryClient.setQueryData(["cookieData", "preferences", "accountView"], key);
    if (key === "servicing") {
      navigate("/portfolio/analytics");
      return;
    } else {
      navigate("/");
    }
  }, []);

  if (hasMultiple && cookieData?.data) {
    return {
      preference: cookieData?.data,
      setPreference,
      isLoading,
      choices,
    };
  }

  if (user?.internalEmployee) {
    return { preference: "employee", setPreference, isLoading, choices };
  }

  if (user?.brokerAccount) {
    return { preference: "broker", setPreference, isLoading, choices };
  }

  if (user?.conveyancerAccount) {
    return { preference: "conveyancer", setPreference, isLoading, choices };
  }

  if (apps && _.isArray(apps) && user?.applicantAccounts?.length) {
    if (apps?.find((app) => app.loan?.id)) {
      return { preference: "borrower", setPreference, isLoading, choices };
    }
    if (apps?.find((app) => !app.loan?.id)) {
      return { preference: "applicant", setPreference, isLoading, choices };
    }
  } else if (apps && !_.isArray(apps)) {
    console.warn("apps is not an array in useAccountViewPreference. Likely a cache issue.", apps);
  }

  if (user?.servicingCustomer) {
    return { preference: "servicing", setPreference, isLoading, choices };
  }

  return { preference: "none", setPreference, isLoading, choices };
};
