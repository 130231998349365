import { ResponsiveLineCanvas } from "@nivo/line";
import { OverTimeTooltip } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/components/OverTimeTooltip";
import {
  GroupingOverTimeTabs,
  useGroupingTabs,
  useLoanToValueOverTimeData,
  useMemoizedGroupingOverTime,
} from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";
import HelpCircle from "src/components/HelpCircle";

import { formatters, parsers, selectors } from "@fraction/shared";

import { ChartCard } from "../ChartCard";

const MARGIN = { top: 20, right: 140, bottom: 50, left: 80 };
const SCALE = { type: "linear", min: 0.05, max: "auto" } as const;
const AXIS_BOTTOM = {
  orient: "bottom",
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "Month",
  legendPosition: "middle",
  legendOffset: 40,
  format: (d: any) => formatters.date.iso8601(new Date(d), "month").slice(2).replace("-", "/"),
} as const;

const AXIS_LEFT = {
  orient: "left",
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "Average LTV",
  legendPosition: "middle",
  legendOffset: -70,
  format: formatters.number.getPercentageFromDecimal,
} as const;

const LEGENDS = [
  {
    anchor: "bottom-right",
    direction: "column",
    justify: false,
    translateX: 130,
    translateY: 0,
    itemWidth: 100,
    itemHeight: 12,
    itemsSpacing: 5,
    itemDirection: "left-to-right",
    symbolSize: 12,
    symbolShape: "circle",
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1,
        },
      },
    ],
  } as const,
];

const Info = ({ className }: { className?: string }) => (
  <HelpCircle className={className}>
    <p>See the tooltip in the previous "loan to value" chart to learn how LTV is calculated.</p>
  </HelpCircle>
);

const mapToDataExpected =
  (activeTab: GroupingOverTimeTabs) =>
  ([id, adminAreaData]: [string, parsers.portfolio.LoanToValueOverTimeData[]]) => ({
    id:
      activeTab === "fsa"
        ? `${id} (${selectors.property.convertToISOAdminArea(adminAreaData[0].administrativeAreas?.[0])})`
        : id,
    data: adminAreaData
      .filter((item) => item.ltv)
      .map((item: parsers.portfolio.LoanToValueOverTimeData) => ({
        x: new Date(item.date),
        y: item.ltv,
        activeTab,
        count: item.count,
        meta: {
          LTV: formatters.number.getPercentageFromDecimal(item.ltv),
        },
      })),
  });

export function LoanToValueOverTime() {
  const { Tabs, activeTab } = useGroupingTabs();
  const { data, isLoading } = useLoanToValueOverTimeData();

  const mapped = useMemoizedGroupingOverTime({
    activeTab,
    data,
    isLoading,
    mapping: mapToDataExpected,
  });

  if (isLoading) {
    return (
      <div className="col-span-3">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  if (!mapped) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={mapped.length === 0}
      cardClassName="col-span-3"
      className="min-h-[300px]"
      header={
        <div className="flex flex-row">
          <p>Average loan to value over time</p>
          <Info className="mr-auto ml-2" />
        </div>
      }
      headerComponents={
        <>
          <Tabs />
        </>
      }
    >
      {/* @ts-ignore */}
      <ResponsiveLineCanvas
        data={mapped}
        margin={MARGIN}
        tooltip={OverTimeTooltip}
        // @ts-ignore
        xFormat={(d) => formatters.date.iso8601(new Date(d), "month")}
        yScale={SCALE}
        xScale={{ type: "time" }}
        // @ts-ignore
        yFormat={formatters.number.getPercentageFromDecimal}
        axisBottom={AXIS_BOTTOM}
        axisLeft={AXIS_LEFT}
        // @ts-ignore
        legends={LEGENDS}
      />
    </ChartCard>
  );
}
