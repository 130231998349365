import { SyntheticApplicationStatus, enums } from "@fraction/shared";
import _ from "lodash";
import Chip, { Variant } from "src/components/Chip";
import Skeleton from "src/components/Skeleton";
import { cn } from "src/utilities/shadcnUtils";

export const STATUS_ORDER = {
  "Requires more information": [
    enums.ApplicationStatus.PARTIALLY_COMPLETED,
    enums.ApplicationStatus.MISSING_INFORMATION,
  ],
  Underwriting: [enums.ApplicationStatus.UNDERWRITING],
  Approved: [
    enums.ApplicationStatus.APPROVED_FOR_COMMITMENT_LETTER,
    enums.ApplicationStatus.QUALIFIED_APPLICATION,
    enums.ApplicationStatus.COMMITMENT_LETTER_SENT,
  ],
  "Collecting documents": [
    enums.ApplicationStatus.COMMITTED,
    enums.ApplicationStatus.INSPECTION_AND_APPRAISAL_COMPLETED,
    enums.ApplicationStatus.READY_FOR_INSTRUCTIONS,
    enums.ApplicationStatus.PARTIAL_DOCS_RECEIVED,
    enums.ApplicationStatus.WAITING_FOR_CLIENT,
  ],
  "Engaging conveyancer": [
    enums.ApplicationStatus.CAPITAL_CALLED,
    enums.ApplicationStatus.READY_FOR_DRAW,
    enums.ApplicationStatus.CONVEYANCER_ENGAGED,
    enums.ApplicationStatus.READY_FOR_CAPITAL_CALL,
  ],
  Funded: [enums.ApplicationStatus.FUNDED],
  Rescinded: [enums.ApplicationStatus.HOMEOWNER_RESCINDED],
  Rejected: [enums.ApplicationStatus.REJECTED],
  "Pending decline": [enums.ApplicationStatus.PENDING_DECLINE],
  "Expired terms": [enums.ApplicationStatus.EXPIRED_TERMS],
};

export const getCurrentStatusBlock = (status: SyntheticApplicationStatus) => {
  const idx = Object.entries(STATUS_ORDER)?.findIndex(([label, block]) =>
    block?.find((item) => item === status)
  );

  return {
    idx,
    label: Object.keys(STATUS_ORDER)[idx],
    block: Object.values(STATUS_ORDER)[idx],
    color: STATUS_COLOR[Object.keys(STATUS_ORDER)[idx]],
  };
};

export const STATUS_COLOR: Record<string, Variant> = {
  "Requires more information": Variant.BASE,
  Underwriting: Variant.YELLOW,
  Approved: Variant.YELLOW,
  "Collecting documents": Variant.YELLOW,
  "Engaging conveyancer": Variant.YELLOW,
  Funded: Variant.GREEN,
  Rescinded: Variant.RED,
  Rejected: Variant.RED,
  "Pending decline": Variant.RED,
};

export function AppStatusBadge({
  status,
  loading,
  className,
  appId,
}: { status?: enums.ApplicationStatus; loading?: boolean; className?: string; appId?: string }) {
  if (loading) {
    return <Skeleton className={cn("rounded-full inline-flex", className)} height={25} width={70} />;
  }

  return status ? (
    <Chip className={className} variant={getCurrentStatusBlock(status)?.color || "green"}>
      {getCurrentStatusBlock(status)?.label}
    </Chip>
  ) : null;
}

export function RawAppStatusBadge({
  status,
  loading,
  className,
}: { status?: SyntheticApplicationStatus; loading?: boolean; className?: string }) {
  if (loading) {
    return <Skeleton className={cn("rounded-full inline-flex", className)} height={25} width={70} />;
  }

  return status ? (
    <Chip className={className} variant={getCurrentStatusBlock(status)?.color || "green"}>
      {_.startCase(status)}
    </Chip>
  ) : null;
}
