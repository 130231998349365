import { ReactNode } from "react";
import { Text } from "src/components";
import { createStyles } from "src/styles";

const styles = createStyles(({ colors, spacing }) => ({
  noDataContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginBottom: spacing[2],
  },
  noDataText: {
    color: colors.grey600,
    marginTop: 40,
    textAlign: "center",
  },
}));

const NoDataScreen = ({
  children,
  text,
  className,
}: { children?: ReactNode; text: string; className?: string }) => {
  return (
    <div className={className} css={styles.noDataContainer}>
      {children}
      <Text style={styles.noDataText}>{text}</Text>
    </div>
  );
};

export default NoDataScreen;
