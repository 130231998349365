import { useEffect } from "react";
import useWindowSize from "src/hooks/useWindowSize";

import { debounce } from "@fraction/shared";

const setHeightValue = debounce(
  (isMobile: boolean) => {
    const vh = document.documentElement.style.getPropertyValue("--vh") || `${window.innerHeight * 0.01}px`;
    if (isMobile) {
      document.documentElement.style.setProperty("--vh", vh);
    } else {
      // we only want to set this if it's a mobile browser.
      // Desktop browsers behave properly with vh.
      document.documentElement.style.removeProperty("--vh");
    }
  },
  100,
  { leading: true }
);

export default function useMobileHeightAdjustment() {
  const { width } = useWindowSize();
  const isMobile = !!width && width < 480;

  useEffect(() => {
    if (width !== 0) {
      setHeightValue(isMobile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return ["100vh", "calc(var(--vh, 1vh) * 100)"];
}
