import { ChevronsLeft, ChevronsRight } from "lucide-react";
import React from "react";
import { useCachedState } from "src/hooks/useCache";
import { cn } from "src/utilities/shadcnUtils";

interface HorizontallyCollapsibleDrawerProps {
  children: React.ReactNode;
  className?: string;
  expandedClassName?: string;
  collapsedClassName?: string;
  direction: "left" | "right";
}

const HorizontallyCollapsibleDrawer: React.FC<HorizontallyCollapsibleDrawerProps> = ({
  children,
  className,
  expandedClassName,
  collapsedClassName,
  direction,
}) => {
  const [isCollapsed, setIsCollapsed] = useCachedState(false, `collapsible-drawer-${direction}`);

  const toggleDrawer = () => setIsCollapsed(!isCollapsed);

  return (
    <div
      className={cn(
        "transition-all duration-300 relative",
        isCollapsed ? collapsedClassName : expandedClassName
      )}
    >
      <div
        className={cn(className, isCollapsed ? "duration-300 transition opacity-0 hidden" : "opacity-100")}
      >
        {children}
      </div>
      <button
        onClick={toggleDrawer}
        className={cn(
          "bg-green text-white p-2 rounded-full items-center justify-center hover:bg-green-600 absolute top-5 z-10 hidden md:flex",
          direction === "left" ? "right-0 translate-x-1/2" : "left-0 -translate-x-1/2"
        )}
      >
        {direction === "left" ? (
          isCollapsed ? (
            <ChevronsRight size={16} />
          ) : (
            <ChevronsLeft size={16} />
          )
        ) : isCollapsed ? (
          <ChevronsLeft size={16} />
        ) : (
          <ChevronsRight size={16} />
        )}
      </button>
    </div>
  );
};

export default HorizontallyCollapsibleDrawer;
