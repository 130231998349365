import { entities, types } from "@fraction/shared";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";
import fraction from "src/api/fraction";
import { useAuth } from "src/auth";
import { useQuery } from "src/lib";

export function useComm(id?: string) {
  const { isAuthenticated } = useAuth();

  return useQuery({
    enabled: !!id && isAuthenticated,
    queryKey: ["mail", id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      return fraction.getComm(id);
    },
  });
}

export function useComms({ appId }: { appId?: string } = {}) {
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();

  const LIMIT = 100;

  const q = useInfiniteQuery<types.ArrayResponse<entities.Communication>>({
    getNextPageParam: (lastPage, pages, lastPageParam) => {
      if (lastPage.data?.length === LIMIT) {
        return ((lastPageParam as number) || 0) + LIMIT;
      }
      return undefined;
    },
    initialPageParam: 0,
    queryKey: ["mail", isAuthenticated, appId],
    enabled: isAuthenticated,
    queryFn: async ({ pageParam }) => {
      const response = await fraction.getComms({
        offset: pageParam as number,
        limit: LIMIT,
        app: appId,
      });

      for (const comm of response?.data || []) {
        queryClient.setQueryData(["mail", comm.id], comm);
      }

      return response;
    },
  });

  const flattened = useMemo(
    () => _.sortBy(q.data?.pages?.map((d) => d.data).flat(), "createdAt").reverse(),
    [q.data?.pages?.length]
  );

  return {
    ...q,
    data: flattened,
    count: q.data?.pages?.[0]?.count,
  };
}
