import { Loader, MessageCircleQuestion } from "lucide-react";
import { BdmContactCard } from "src/components/BdmContactCard";
import { MortgageSpecialistContactCard } from "src/components/MortgageSpecialistContactCard";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { useFractionContacts } from "src/hooks/useFractionContacts";

export const SupportPopup = () => {
  const { isLoading, data } = useFractionContacts();

  if (!isLoading && !data?.contacts?.length) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="group inline-flex h-9 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm transition-colors hover:bg-accent hover:text-black focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50">
          {isLoading ? <Loader size={12} className="animate-spin" /> : <MessageCircleQuestion size={12} />}
          <p className="ml-1">Support</p>
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <BdmContactCard className="w-full" />
        <div className="h-[1px] w-full bg-green-200" />
        <MortgageSpecialistContactCard className="w-full" />
      </PopoverContent>
    </Popover>
  );
};
