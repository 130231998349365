import { formatters } from "@fraction/shared";
import { InputHTMLAttributes, forwardRef, useMemo } from "react";
import {
  CENTS_CURRENCY_MASK,
  CURRENCY_MASK,
  INTEGER_MASK,
  PERCENTAGE_MASK,
  PHONE_MASK,
} from "src/components/TextInput/index";
import { useInputMask } from "src/hooks";
import { cn } from "src/utilities/shadcnUtils";
import useMergedRef from "../../hooks/useMergedRef";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  currency?: boolean;
  integer?: boolean;
  cents?: boolean;
  percentage?: boolean;
  phone?: boolean;
  mask?: string | Inputmask.Options["mask"];
  maskOptions?: Inputmask.Options;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, currency, percentage, integer, cents, phone, maskOptions, mask, ...props }, ref) => {
    const usesMask = mask || maskOptions || currency || cents || percentage || integer || phone;

    const options = useMemo(
      () => ({
        placeholder: " ",
        clearMaskOnLostFocus: true,
        jitMasking: true,
        autoUnmask: true,
        showMaskOnHover: false,
        max: props.max,
        min: props.min,
        allowMinus: props.min === undefined || formatters.number.getNumberFromString(props.min) < 0,
        ...(currency ? CURRENCY_MASK : {}),
        ...(cents ? CENTS_CURRENCY_MASK : {}),
        ...(percentage ? PERCENTAGE_MASK : {}),
        ...(integer ? INTEGER_MASK : {}),
        ...maskOptions,
      }),
      [props.max, props.min, maskOptions, currency, integer, percentage, cents, phone]
    );

    const maskRef = useInputMask({
      mask: phone ? PHONE_MASK : mask,
      options,
    });

    const merged = useMergedRef(maskRef, ref);

    return (
      <input
        {...props}
        className={cn("outline-green focus:outline outline-offset-0", className)}
        ref={usesMask ? merged : ref}
      />
    );
  }
);
