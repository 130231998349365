import { entities } from "@fraction/shared";
import { MaskedAccountNumber } from "src/components/MaskedAccountNumber";

export const MaskedBankAccount = ({ account }: { account?: entities.BankAccount }) => {
  return (
    <div className="flex flex-row items-center font-light">
      <p className="mr-2">{account?.name}</p>
      <span className="mb-[1px] mr-[1px]">(</span>
      <MaskedAccountNumber className="text-sm inline-flex">
        {account?.accountNumberLast4Digits || ""}
      </MaskedAccountNumber>
      <span className="mb-[1px] ml-[1px]">)</span>
    </div>
  );
};
