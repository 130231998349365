import { useEffect, useRef } from "react";

export default function useEffectMemo(callback: () => void, dependencies: any[]) {
  const previousDependencies = useRef<any[]>([]);

  useEffect(() => {
    if (JSON.stringify(dependencies) !== JSON.stringify(previousDependencies.current)) {
      callback();
    }
    previousDependencies.current = dependencies;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies]);
}
