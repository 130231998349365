import { memo, useMemo } from "react";
import Chip, { Variant } from "src/components/Chip";
import Tooltip from "src/components/Tooltip";
import { LightText, RegularText, SemiBoldText } from "src/components/v1";
import { AlertI } from "src/icons";
import { createStyles } from "src/styles";

import { ChecklistOutcome, colors, enums, formatters, parsers } from "@fraction/shared";

const styles = createStyles((tokens) => ({
  header: {
    display: "flex",
    gap: 16,
    fontSize: 14,
    alignItems: "center",
  },
  chip: {
    marginLeft: "auto",
    width: "min-content",
  },
  outcomeChip: {
    marginLeft: "auto",
    width: "fit-content",
  },
  description: {
    fontSize: 12,
    marginTop: 12,
    backgroundColor: tokens.colors.grey200,
    color: tokens.colors.grey700,
    padding: "8px 12px",
    borderRadius: 8,
  },
  list: {
    listStyle: "none",
    margin: "16px 0 0 0",
    padding: 0,
  },
  listItem: {
    marginBottom: 10,
  },
  tooltipRow: {
    marginBottom: 4,
  },
  tooltipContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    gap: 8,
  },
  tooltipText: {
    textAlign: "left",
    maxWidth: "max-content",
    width: "90%",
    backgroundColor: "white",
    color: tokens.colors.grey700,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: tokens.colors.grey600,
    boxShadow: "0px 5px 10px rgba(0,0,0,0.05)",
  },
  listItemText: {
    color: tokens.colors.grey700,
  },
  smallListItemText: {
    color: tokens.colors.grey700,
    fontSize: 10,
  },
  taskChip: {
    minWidth: 100,
    marginRight: 6,
  },
  toolTipIcon: {
    width: 10,
    height: 10,
    marginLeft: 6,
  },
}));

const OUTCOMES: Record<string, { message: string; chipVariant: Variant }> = {
  [ChecklistOutcome.ACTION_REQUIRED]: {
    message: "Action required",
    chipVariant: Variant.YELLOW,
  },
  [ChecklistOutcome.FAIL]: {
    message: "Fail",
    chipVariant: Variant.RED,
  },
  [ChecklistOutcome.PENDING]: {
    message: "Pending",
    chipVariant: Variant.BASE,
  },
  [ChecklistOutcome.SUCCESS]: {
    message: "Success",
    chipVariant: Variant.GREEN,
  },
  [ChecklistOutcome.NOT_APPLICABLE]: {
    message: "N/A",
    chipVariant: Variant.GREEN,
  },
};

const TASK_STATUSES: Record<enums.ApplicationTaskStatus, { message: string; chipVariant: Variant }> = {
  [enums.ApplicationTaskStatus.TODO]: {
    message: "To Do",
    chipVariant: Variant.BASE,
  },
  [enums.ApplicationTaskStatus.IN_PROGRESS]: {
    message: "In Progress",
    chipVariant: Variant.YELLOW,
  },
  [enums.ApplicationTaskStatus.COMPLETE]: {
    message: "Complete",
    chipVariant: Variant.GREEN,
  },
  [enums.ApplicationTaskStatus.NOT_APPLICABLE]: {
    message: "N/A",
    chipVariant: Variant.GREEN,
  },
  [enums.ApplicationTaskStatus.FAILED]: {
    message: "Failed",
    chipVariant: Variant.RED,
  },
  [enums.ApplicationTaskStatus.TO_FIX]: {
    message: "To Fix",
    chipVariant: Variant.YELLOW,
  },
  [enums.ApplicationTaskStatus.DEPRECATED]: {
    message: "Deprecated",
    chipVariant: Variant.BASE,
  },
};

const getTaskStatus = (
  status: enums.ApplicationTaskStatus,
  required?: boolean
): { message: string; chipVariant: Variant } => {
  if (required === false && status !== enums.ApplicationTaskStatus.COMPLETE) {
    return TASK_STATUSES[enums.ApplicationTaskStatus.NOT_APPLICABLE];
  }
  return TASK_STATUSES[status];
};

const getTooltipText = (task: parsers.checklist.PerformedChecklist["tasks"][0]) => {
  if (!task.notes && !task.automatedNotes) {
    return null;
  }
  return (
    <RegularText>
      {task.notes ? (
        <div css={styles.tooltipRow}>
          <b>Notes:</b> {task.notes}
        </div>
      ) : null}
      {task.automatedNotes ? (
        <div css={styles.tooltipRow}>
          <b>Automation Notes:</b> {task.automatedNotes}
        </div>
      ) : null}
    </RegularText>
  );
};

const EmptyContainer = (props: any) => <div {...props} />;

const statusOrdering = [
  enums.ApplicationTaskStatus.FAILED,
  enums.ApplicationTaskStatus.TO_FIX,
  enums.ApplicationTaskStatus.IN_PROGRESS,
  enums.ApplicationTaskStatus.TODO,
  enums.ApplicationTaskStatus.COMPLETE,
  enums.ApplicationTaskStatus.NOT_APPLICABLE,
  enums.ApplicationTaskStatus.DEPRECATED,
];

const ChecklistDisplay = ({ checklist }: { checklist: parsers.checklist.PerformedChecklist }) => {
  const tasks = useMemo(
    () =>
      checklist.tasks
        .map((task) => ({
          ...task,
          outcome: getTaskStatus(task.status, task.required || undefined),
          tooltipText: getTooltipText(task),
          owner: task.user?.firstName
            ? formatters.user.userName(task.user)
            : task.taskOwners?.map(formatters.string.capitalize)?.join(", ") || "",
        }))
        .sort((a, b) => statusOrdering.indexOf(a.status) - statusOrdering.indexOf(b.status)),
    [checklist.tasks]
  );

  return (
    <div>
      <div css={styles.header}>
        <SemiBoldText>{checklist.definition.name}</SemiBoldText>
        <Chip style={styles.outcomeChip} variant={OUTCOMES[checklist.outcome].chipVariant}>
          {OUTCOMES[checklist.outcome].message}
        </Chip>
      </div>
      <RegularText style={styles.description}>{checklist.definition.description}</RegularText>
      <table css={styles.list}>
        {tasks.map((task) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const ContainerComponent = task.tooltipText ? Tooltip : EmptyContainer;
          return (
            <tr key={task.label} css={styles.listItem}>
              <td>
                <Chip style={styles.taskChip} variant={task.outcome.chipVariant}>
                  {task.outcome.message}
                </Chip>
              </td>
              <td>
                <ContainerComponent css={styles.tooltipContainer} text={task.tooltipText}>
                  <div>
                    <RegularText style={styles.listItemText}>
                      <div css={{ flexDisplay: "row", display: "flex", margin: 0 }}>
                        {task.label}
                        {task.tooltipText ? (
                          <AlertI color={colors.palette.GREY_600} css={styles.toolTipIcon} />
                        ) : null}
                      </div>
                    </RegularText>
                    <LightText style={styles.smallListItemText}>{task.owner}</LightText>
                  </div>
                </ContainerComponent>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default memo(ChecklistDisplay);
