import { MotionProps, motion } from "framer-motion";
import { forwardRef, useMemo } from "react";
import * as React from "react";
import { createStyles } from "src/styles";

import { debounce } from "@fraction/shared";

type MotionButtonProps = React.PropsWithoutRef<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & MotionProps
>;

export interface TouchableProps<
  Id extends object | string | undefined | Parameters<NonNullable<MotionButtonProps["onClick"]>> = any,
> extends Omit<MotionButtonProps, "onClick" | "id"> {
  css?: any;
  isDefaultLayout?: boolean;
  id?: Id;
  onClick?: (id: any) => void;
}

export const TOUCHABLE_PADDING = {
  paddingLeft: 6,
  paddingRight: 6,
  paddingBottom: 3,
  paddingTop: 2,
};

const styles = createStyles({
  button: {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    border: "0px solid transparent",
    textShadow: "0px 0px 0px transparent",
    margin: 0,
    padding: 0,
    "&:hover": {
      boxShadow: "0px 0px 0px transparent",
      textShadow: "0px 0px 0px transparent",
      cursor: "pointer",
    },
    "&:active": {
      color: "unset",
    },
    "&:focus": {
      outline: 0,
    },
  },
  disabled: {
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  layout: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    ...TOUCHABLE_PADDING,
  },
  noAlign: {
    textAlign: "inherit",
  },
});

const Touchable = forwardRef<HTMLButtonElement, TouchableProps>(
  ({ css, isDefaultLayout = true, onClick, disabled, id, className, ...props }, ref) => {
    const handleClick = useMemo(
      () => debounce((evt) => onClick?.(id || evt), 500, { leading: true }),
      [onClick, id]
    );

    return (
      <motion.button
        ref={ref}
        className={className}
        type="button"
        whileTap={{ opacity: 0.6 }}
        transition={{ duration: 0.25 }}
        {...props}
        css={[
          styles.button,
          isDefaultLayout && styles.layout, // do not apply any layouts or align styles to the Touchable if isDefaultLayout = false
          !isDefaultLayout && styles.noAlign, // same above
          disabled && styles.disabled,
          css,
        ]}
        onClick={handleClick}
        disabled={disabled}
      />
    );
  }
);

export default React.memo(
  Touchable,
  (prev, next) =>
    prev.onClick === next.onClick &&
    prev.css === next.css &&
    prev.className === next.className &&
    prev.children === next.children &&
    prev.disabled === next.disabled &&
    prev.id === next.id
);
