import { SerializedStyles } from "@emotion/react";
import React, { ReactNode } from "react";
import { createStyles } from "src/styles";

export interface BasicTableProps {
  children: ReactNode;
  style?: SerializedStyles;
  overflow?: boolean;
  className?: string;
}

const styles = createStyles({
  table: {
    width: "100%",
    borderCollapse: "collapse",
    height: "1px", // a bit hacky but allows for height set on a row/cell to be respected
  },

  // need two divs to allow for overflowX but NOT overflowY (https://stackoverflow.com/a/17432665)
  outerContainerOverflow: {
    overflowY: "visible",
  },
  innerContainerOverflow: {
    overflowX: "auto",
  },
});

const BasicTable = ({ children, style, overflow, className }: BasicTableProps) => (
  <div className={className} css={[overflow && styles.outerContainerOverflow]}>
    <div css={[overflow && styles.innerContainerOverflow]}>
      <table css={[styles.table, style]}>{children}</table>
    </div>
  </div>
);

export default React.memo(BasicTable);
