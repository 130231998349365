import { ReactNode, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { usePaymentsMutation } from "src/apps/PostFundedDashboard/queries";
import { usePlaid } from "src/apps/PreFundedDashboard/hooks/plaid";
import { useFormReducer, useStepper } from "src/hooks";
import { selectMicroDeposits } from "src/selectors";
import { v4 as uuid } from "uuid";

import { entities, enums, formatters, parsers, schemas } from "@fraction/shared";

import AccountCreatedConfirmationScreen from "../components/sharedModalScreens/AccountCreatedConfirmationScreen";
import AddBankAccountScreen from "../components/sharedModalScreens/AddBankAccountScreen";
import ExpiredVerificationScreen from "../components/sharedModalScreens/ExpiredVerificationScreen";
import MicrodepositAmountScreen, {
  MicroDeposits,
} from "../components/sharedModalScreens/MicrodepositAmountScreen";
import ReviewAddBankAccountScreen from "../components/sharedModalScreens/ReviewAddBankAccountScreen";
import SelectBankAccountScreen from "../components/sharedModalScreens/SelectBankAccountScreen";
import useBankAccountMutation from "./useBankAccountMutation";

export enum PaymentScreen {
  // bank setup:
  SELECT_ACCOUNT = "select_account",
  ADD_BANK_INFO = "add_bank_info",
  REVIEW_BANK_INFO = "review_bank_info",
  ENTER_MICRO_DEPOSIT = "enter_micro_deposit",
  CREATED_CONFIRMATION = "created_confirmation",
  DEPOSIT_RESENT = "deposit_resent",

  // payment amount:
  PAYMENT_AMOUNT = "payment_amount",
  CUSTOM_PAYMENT_AMOUNT = "custom_payment_amount",
  PAYMENT_REVIEW = "payment_review",
  PAYMENT_CONFIRMATION = "payment_confirmation",

  WIRE_INSTRUCTIONS = "wire_instructions",
  PAYMENT_FREQUENCY = "payment_frequency",
  PAYMENT_SUBSCRIPTION_CONFIRMATION = "payment_subscription_confirmation",
}

/**
 * The LoanDrawModal and PrepaymentModal will share a lot of the same logic and screens. Anything considered common between
 * them should live in this hook.
 */
export const usePaymentsModal = ({
  onClose,
  isLoading,
  bankAccounts,
  loan,
  initialPaymentScreens = [PaymentScreen.PAYMENT_AMOUNT],
}: {
  onClose: () => void;
  isLoading?: boolean;
  bankAccounts?: entities.BankAccount[];
  loan?: entities.LoanT;
  initialPaymentScreens?: PaymentScreen[];
}) => {
  const { step, ...stepper } = useStepper([PaymentScreen.SELECT_ACCOUNT]);
  const [currentBankAccount, setCurrentBankAccount] = useState<entities.BankAccount | undefined>();
  const [idempotencyKey, setIdempotencyKey] = useState<string | null>();

  const bankMutation = useBankAccountMutation({
    bankAccount: currentBankAccount,
    verifyOptions: {
      onExpiredDepositsError: () => stepper.push(PaymentScreen.DEPOSIT_RESENT),
    },
  });

  const wipeIdempotencyKey = useCallback(() => setIdempotencyKey(null), []);
  const paymentsMutation = usePaymentsMutation(idempotencyKey || "", {
    onError: wipeIdempotencyKey,
  });

  const createBankAccountForm = useFormReducer({
    initialValues: { routingNumberType: schemas.modernTreasury.RoutingNumberType.ABA },
  });

  const paymentForm = useFormReducer();

  const handleClose = useCallback(() => {
    setIdempotencyKey(null);
    stepper.reset();
    createBankAccountForm.reset();
    paymentForm.reset();
    setCurrentBankAccount(undefined);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.reset, onClose, createBankAccountForm.reset]);

  // const onAddBank = useCallback(() => {
  //   stepper.push(CREATE_ACCOUNT_SCREENS);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stepper.push]);

  const handleSelectBankAccount = useCallback(
    (bankAccount: entities.BankAccount) => {
      setCurrentBankAccount(bankAccount);
      const isVerified =
        bankAccount.verificationStatus === schemas.modernTreasury.VerificationStatus.VERIFIED;

      if (!isVerified) {
        stepper.push([PaymentScreen.ENTER_MICRO_DEPOSIT, ...initialPaymentScreens]);
      }

      if (!stepper.next) {
        stepper.push(initialPaymentScreens);
      } else {
        stepper.goForward();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepper.push, initialPaymentScreens]
  );

  const submitBankAccount = useCallback(() => {
    bankMutation.createAccount(
      createBankAccountForm.formValues as parsers.bankAccount.BankAccountSubmission,
      {
        onSuccess: stepper.goForward,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankMutation.createAccount, createBankAccountForm.formValues, stepper.goForward]);

  const verifyBankAccount = useCallback(
    (data: MicroDeposits) => {
      bankMutation.verifyAccount(selectMicroDeposits(data), {
        onSuccess: stepper.goForward,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bankMutation.verifyAccount, stepper.goForward]
  );

  const handleSelectCustomAmount = useCallback(() => {
    stepper.push([
      PaymentScreen.CUSTOM_PAYMENT_AMOUNT,
      PaymentScreen.PAYMENT_REVIEW,
      PaymentScreen.PAYMENT_CONFIRMATION,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepper.push]);

  const handleSubmitPaymentOrder = useCallback(
    (type: enums.TransactionType) => {
      try {
        const parsedPaymentOrder = parsers.payment.parsePaymentOrder({
          type,
          amount: formatters.number.getCents(paymentForm.formValues.amount),
          bankAccountId: currentBankAccount?.id,
          loanId: loan?.id,
        });
        paymentsMutation.mutate(parsedPaymentOrder, {
          onSuccess: stepper.goForward,
        });
      } catch (err) {
        toast.error(err?.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepper.goForward, paymentForm.formValues.amount, paymentsMutation.mutate]
  );

  const {
    start,
    ready,
    isLoading: isLoadingPlaid,
  } = usePlaid({ applicationId: loan?.applicationId, purpose: "payments" });

  useEffect(() => {
    if (!idempotencyKey) {
      setIdempotencyKey(uuid());
    }
  }, [idempotencyKey]);

  const bankSetupScreens: Record<string, [ReactNode] | [ReactNode, number]> = {
    [PaymentScreen.SELECT_ACCOUNT]: [
      <SelectBankAccountScreen
        isLoading={isLoading}
        isPending={isLoadingPlaid}
        bankAccounts={bankAccounts}
        onAddBank={ready ? start : undefined}
        handleSelectBankAccount={handleSelectBankAccount}
        paragraph="This will be the bank account that funds are transfered to or from."
      />,
      518,
    ],
    [PaymentScreen.ADD_BANK_INFO]: [
      <AddBankAccountScreen
        {...createBankAccountForm}
        onBack={stepper.goBack}
        onContinue={stepper.goForward}
      />,
    ],
    [PaymentScreen.REVIEW_BANK_INFO]: [
      <ReviewAddBankAccountScreen
        {...createBankAccountForm}
        onContinue={submitBankAccount}
        onBack={stepper.goBack}
        isLoading={bankMutation.isLoading}
      />,
      540,
    ],
    [PaymentScreen.CREATED_CONFIRMATION]: [<AccountCreatedConfirmationScreen onClose={onClose} />, 600],

    // Micro-deposit verification screens:
    [PaymentScreen.ENTER_MICRO_DEPOSIT]: [
      <MicrodepositAmountScreen
        onSubmit={verifyBankAccount}
        onBack={stepper.goBack}
        loading={bankMutation.isLoading}
      />,
    ],
    [PaymentScreen.DEPOSIT_RESENT]: [<ExpiredVerificationScreen onClose={onClose} />],
  };

  return {
    step,
    stepper,
    currentBankAccount,
    createBankAccountForm,
    paymentForm,
    bankMutation,
    bankSetupScreens,
    paymentsMutation,
    handleSubmitPaymentOrder,
    onClose: handleClose,
    submitBankAccount,
    verifyBankAccount,
    handleSelectCustomAmount,
  };
};
