export function getValidatorAndErrorMessages(required: boolean) {
  const obj: {
    validators: string[];
    errorMessages: string[];
  } = {
    validators: [],
    errorMessages: [],
  };
  if (required) {
    obj.validators.push("required");
    obj.errorMessages.push("Required");
  }
  return obj;
}

export function noInput(value: any): value is undefined {
  return value === undefined || value === "";
}
