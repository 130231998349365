import { ChangeEvent, ReactNode, cloneElement, useCallback, useRef, useState } from "react";
import useFuzzySort from "src/hooks/useFuzzySort";
import { focusWhenPossible } from "src/utilities/react";
import useClickedOutsideElement from "../hooks/useClickedOutsideElement";
interface SearchableOptionsProps {
  value: string;
  options:
    | {
        value: string;
        label: ReactNode;
        search?: string;
      }[]
    | undefined;
  onChange: (value: string) => void;
  labelProps?: any;
}

export function SearchableOptions({ value, options, onChange, labelProps }: SearchableOptionsProps) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useClickedOutsideElement(ref, () => {
    setOpen(false);
    setSearch("");
  });

  const handleClickControl = useCallback(() => {
    setOpen(true);
    focusWhenPossible(inputRef);
  }, [inputRef.current]);

  const handleClickOption = useCallback(
    (chosenValue: string) => {
      setOpen(false);
      if (value !== chosenValue) {
        onChange(chosenValue);
      }
    },
    [onChange, value]
  );

  const onChangeValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const sorted = useFuzzySort(options || [], search, "search");

  const activeLabel = options?.find((opt) => opt.value === value)?.label;

  return (
    <div ref={ref} className="relative">
      <button className={open ? "hidden" : undefined} onClick={handleClickControl}>
        {activeLabel
          ? cloneElement(
              // @ts-ignore
              activeLabel,
              labelProps
            )
          : null}
      </button>
      {open && activeLabel
        ? cloneElement(
            // @ts-ignore
            activeLabel,
            {
              children: (
                <input
                  value={search}
                  onChange={onChangeValue}
                  className="bg-transparent border-0 outline-none"
                  ref={inputRef}
                  placeholder="Search"
                />
              ),
              ...(labelProps || {}),
            }
          )
        : null}
      {open && sorted?.length ? (
        <div className="flex flex-col gap-1 max-h-[300px] overflow-y-scroll absolute z-10 bg-white rounded py-2 px-1">
          {sorted?.map((option) => (
            <ClickableOption option={option} onClick={handleClickOption} />
          ))}
        </div>
      ) : null}
    </div>
  );
}

const ClickableOption = ({
  option,
  onClick,
}: {
  option: {
    value: string;
    label: ReactNode;
  };
  onClick: (value: string) => void;
}) => {
  const handleClick = useCallback(() => {
    onClick(option.value);
  }, [onClick, option.value]);

  return (
    <button className="text-left" onClick={handleClick}>
      {option.label}
    </button>
  );
};
