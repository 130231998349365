import { MicroDeposits } from "src/apps/PostFundedDashboard/v1/PostFundedDashboard/components/sharedModalScreens/MicrodepositAmountScreen";

import { parsers } from "@fraction/shared";

export const selectMicroDeposits = ({
  amount1,
  amount2,
}: MicroDeposits): parsers.modernTreasury.Verification => ({
  amounts: [Number(amount1), Number(amount2)],
});
