import * as Sentry from "@sentry/browser";
import config from "src/config";

import { entities, formatters, parsers, toJS } from "@fraction/shared";

// eslint-disable-next-line no-console
console.log(`Release version: ${import.meta.env.SENTRY_RELEASE?.id || "unknown"}`);

Sentry.init({
  enabled: config.isProd,
  release: import.meta.env.SENTRY_RELEASE?.id, // this is undefined in local dev server, it's set in the build step
  dsn: "https://74d6fa42e2644c52bd163b2f88ca7393@sentry.io/1258213",
  environment: config.stage,
  beforeSend: (e) => (config.stage === "dev" || config.stage === "local" ? null : e),
});

export function captureException(err: any) {
  if (err?.isExpectedError) {
    // ignore.
    return;
  }
  if (!config.isProd) {
    // eslint-disable-next-line no-console
    console.warn(err);
    return;
  }
  try {
    Sentry.captureException(err);
  } catch (e) {
    // eslint-disable-next-line no-console
    captureException(e);
  }
}

export function captureMessage(message: string) {
  if (!config.isProd) {
    // eslint-disable-next-line no-console
    console.warn(message);
    return;
  }
  try {
    Sentry.captureMessage(message);
  } catch (e) {
    // eslint-disable-next-line no-console
    captureException(e);
  }
}

export function setScopeForApp(
  application: parsers.application.FullApplication,
  additionalContext: { application?: object; user?: object } = {}
) {
  Sentry.configureScope((scope) => {
    scope.setUser({
      email: application.applicants?.[0]?.email,
      id: application.applicants?.[0]?.userId,
      ...additionalContext?.user,
    });
    scope.setContext("application", {
      id: application.id,
      property: formatters.property.formattedAddress(application.property?.location),
      ...additionalContext?.application,
    });
  });
}

export function setScopeForUser(user: entities.User) {
  Sentry.configureScope((scope) => {
    scope.setUser(toJS(user));
  });
}
