import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface HeaderProps {
  children: React.ReactNode;
}

const styles = createStyles({
  container: {
    padding: "48px 72px",
    borderBottom: "solid 1px",
    borderColor: colors.LIGHT_BORDER,
    "@media(max-width: 600px)": {
      padding: "32px 32px",
    },
  },
});

const Header = ({ children }: HeaderProps) => <div css={styles.container}> {children}</div>;

export default Header;
