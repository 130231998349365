import { Link } from "react-router-dom";
import { TitleWithQr } from "src/apps/BrokerDashboard/components/TitleWithQr";
import useDocumentTitle from "src/components/root/routeHelpers/useDocumentTitle";

const BasicFileRow = ({ filename, description }: { filename: string; description: string }) => {
  return (
    <div className="max-w-[28rem] w-full flex flex-row items-center hover:bg-gray-100 justify-between border-gray-200 border-2 p-4 rounded">
      <div className="text-left mr-6">
        <p className="text-lg font-semibold">{filename}</p>
        <p className="text-gray text-sm">{description}</p>
      </div>
    </div>
  );
};

export const BrokerMarketing = () => {
  useDocumentTitle("Sales Tools");

  return (
    <div className="p-6 flex flex-col gap-2">
      <TitleWithQr
        rates
        bdm
        title="Sales tools"
        description={
          <>
            We are just getting started with this page, but if you want to generate some documents to help
            your borrower out, give these a spin. If you have ideas for other things you'd like to add, send
            us an email at{" "}
            <a className="text-green" href="mailto:dev@fraction.com">
              dev@fraction.com
            </a>
            .
          </>
        }
      />
      <div className="flex flex-row flex-wrap gap-2">
        <Link to="/marketing/property-report">
          <BasicFileRow
            filename="Scenario Planner"
            description="A customized report you can generate that can show your client how a Fraction Mortgage might work for their property and particular situation."
          />
        </Link>
        <Link to="/marketing/cost-calculator">
          <BasicFileRow
            filename="Cost Calculator"
            description="A tool to help you estimate the costs of a Fraction Mortgage for your client over the term of the mortgage, given an estimated loan value and rate."
          />
        </Link>
        <Link to="/marketing/property-check">
          <BasicFileRow
            filename="Property checker"
            description="Want to see if a property is in a Fraction-eligible region? Use this tool to check whether the property has a good chance of being accepted on the basis of the location"
          />
        </Link>
      </div>
    </div>
  );
};
