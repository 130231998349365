import { Stat, Stats } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/Stats";
import { useWeightedAverageCoupon } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";

import { formatters } from "@fraction/shared";
import { useTabs, useToggler } from "src/hooks";
import { ChartCard } from "../ChartCard";

export function WeightedAverageCoupon() {
  const { data, isLoading } = useWeightedAverageCoupon();
  const { Tabs, activeTab } = useTabs({
    options: [
      {
        value: "overall",
        label: "Overall",
      },
      {
        value: "year",
        label: "By year",
      },
    ],
    defaultValue: "overall",
  });

  const { Toggle, on: minimumRateOnly } = useToggler({
    id: "minimumRateOnlyToggleWac",
    label: "Minimum rate only",
    defaultValue: false,
  });

  if (isLoading) {
    return (
      <div className="col-span-3">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  const activeData = data?.find(
    (datum) => datum.group === activeTab && datum.withAppreciation === !minimumRateOnly
  );

  if (!activeData) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={activeData.data.length === 0}
      cardClassName="col-span-3"
      className="min-h-[300px]"
      header="Rate and Weighted Average Coupon"
      headerComponents={
        <div className="flex row gap-3 items-center flex-wrap">
          <Tabs />
          <Toggle />
        </div>
      }
    >
      {activeData.data.map((datum) => (
        <div key={datum.year || datum.wac}>
          {datum.year && (
            <p className="text-md text-gray-900 font-bold font-serif text-center">{datum.year}</p>
          )}
          <Stats formatter={formatters.number.getPercentageFromDecimal} data={datum}>
            <Stat label="WAC" value={formatters.number.getPercentageFromDecimal(datum.wac)} />
          </Stats>
        </div>
      ))}
    </ChartCard>
  );
}
