import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import FinePrint from "src/components/FinePrint";
import TextLink from "src/components/TextLink";

export interface CreditAuthorizationProps {
  style?: SerializedStyles;
  bureau?: string;
}

const CreditAuthorization = ({ style, bureau = "Experian" }: CreditAuthorizationProps) => (
  <FinePrint style={style}>
    I also acknowledge I am providing 'written instructions' to Fraction under the{" "}
    <TextLink underlined href="https://www.ftc.gov/enforcement/statutes/fair-credit-reporting-act">
      Fair Credit Reporting Act
    </TextLink>
    , authorizing Fraction to obtain information from my personal credit profile from {bureau}. I authorize
    Fraction to obtain such information solely to conduct a pre-qualification for credit.
  </FinePrint>
);

export default memo(CreditAuthorization);
