import { entities, enums, formatters, selectors } from "@fraction/shared";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { CircleChevronLeft, CircleChevronRight, ListFilterIcon, SquareArrowOutUpRight } from "lucide-react";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import fraction from "src/api/fraction";
import { ApplicantOverview } from "src/apps/BrokerDashboard/components/ApplicantOverview";
import { ApplicationOverview } from "src/apps/PreFundedDashboard/components/ApplicationOverview";
import { AppStatusBadge } from "src/components/AppStatusBadge";
import { DocumentApproval } from "src/components/DocumentApproval";
import { ExistingDocumentListSlider } from "src/components/ExistingDocumentListSlider";
import { Badge } from "src/components/ui/badge";
import { MultiSelect } from "src/components/ui/multi-select";
import { useApplication } from "src/hooks/useApplication";
import { useCachedState } from "src/hooks/useCache";
import { useEventualState } from "src/hooks/useEventualState";
import { useMutation, useQuery } from "src/lib";

const OPTIONS = [
  { value: "broker" as const, label: "Brokers" },
  { value: "conveyancer" as const, label: "Conveyancers" },
  { value: "applicant" as const, label: "Applicants" },
];

export function DocApproval() {
  const [filter, setFilter] = useCachedState(OPTIONS, "doc-approval-filters");
  const queryClient = useQueryClient();

  const { data: documentsNeedingApproval, isLoading } = useQuery({
    queryKey: ["documents", "needs-approval", (filter ?? OPTIONS).sort()].flat(),
    queryFn: async () => {
      return await fraction.getDocumentsNeedingApproval(filter?.map((x) => x.value));
    },
  });

  const [currentDocumentId, setCurrentDocumentId] = useEventualState(documentsNeedingApproval?.[0]?.id);
  const file = documentsNeedingApproval?.find((doc) => doc.id === currentDocumentId);
  const currentIndex = documentsNeedingApproval?.findIndex((doc) => doc.id === currentDocumentId) || 0;
  const nextIndex =
    currentIndex + 1 <= (documentsNeedingApproval?.length || 0) ? currentIndex + 1 : undefined;
  const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : undefined;
  const nextDocumentId = nextIndex !== undefined ? documentsNeedingApproval?.[nextIndex]?.id : undefined;
  const prevDocumentId = prevIndex !== undefined ? documentsNeedingApproval?.[prevIndex]?.id : undefined;

  const goBackOne = useCallback(() => {
    setCurrentDocumentId(prevDocumentId);
  }, [prevDocumentId]);

  const goForwardOne = useCallback(() => {
    setCurrentDocumentId(nextDocumentId);
  }, [nextDocumentId]);

  const onDelete = useCallback((id: string) => {
    queryClient.setQueryData(
      ["documents", "needs-approval", filter.sort()].flat(),
      (prev: entities.UploadedFile[]) => {
        return prev.filter((doc) => {
          return doc.id !== id;
        });
      }
    );
    toast("Document deleted!");
  }, []);

  const onSubmit = useMutation({
    mutationFn: async (opts: { id: string; status: enums.ApplicationTaskApprovalStatus; notes?: string }) => {
      queryClient.setQueryData(
        ["documents", "needs-approval", filter.sort()].flat(),
        (prev: entities.UploadedFile[]) =>
          prev.map((doc) => {
            if (doc.id !== opts.id) {
              return doc;
            }
            toast(`Document set to ${opts.status?.replace("_", " ")}`);
            return { ...doc, status: opts.status, notes: opts.notes };
          })
      );
      goForwardOne();
    },
  });

  const { data: app, isLoading: appLoading } = useApplication({
    id: file?.applicationId,
    initialRefetch: true,
    cached: true,
  });

  const applicantUsers = app ? selectors.application.applicationUsers(app) : [];
  const uploader = file?.uploaderId
    ? applicantUsers.find((u) => u.user?.id === file?.uploaderId)?.user
    : undefined;

  return (
    <div className="w-full flex flex-row h-[85svh]">
      <ExistingDocumentListSlider applicationId={file?.applicationId} enabled />
      <div className="flex flex-row items-start justify-center gap-x-2 w-full">
        <div className="h-[85svh] overflow-y-scroll overscroll-contain w-[600px]">
          <div className="flex flex-row flex-wrap gap-2 mb-1">
            <AppStatusBadge status={app?.status} />
            {_.sortBy(app?.applicants || [], "priority")?.map((applicant) => (
              <Badge className="rounded-full">
                {applicant.priority === 0 ? "⭐️" : null} {formatters.user.userName(applicant?.user)}
              </Badge>
            ))}
            {app?.brokers?.map((broker) => (
              <Badge className="rounded-full">
                {app?.primaryBrokerId === broker?.id ? "⭐️" : null} Broker:{" "}
                {formatters.user.userName(broker?.user)}
              </Badge>
            ))}
            <Link target="_blank" to={`/app/${app?.id}`}>
              <Badge className="rounded-full">
                Go to app <SquareArrowOutUpRight className="h-3 w-3 ml-1 align-middle " />
              </Badge>
            </Link>
          </div>

          <ApplicationOverview
            showHeader={false}
            className="border-0 p-0 overflow-y-clip overflow-x-clip"
            loading={isLoading || appLoading}
            app={app || undefined}
          />
          <ApplicantOverview
            detailsClassName="border-0 p-0 w-full"
            className="border-0 p-0 overflow-y-clip"
            app={app || undefined}
            loading={isLoading || appLoading}
          />
        </div>
        <div className="flex flex-row items-center justify-center gap-x-2">
          {prevDocumentId ? (
            <CircleChevronLeft
              onClick={goBackOne}
              className="text-white fill-gray min-w-8 min-h-8 h-8 w-8 cursor-pointer"
            />
          ) : (
            <div className="w-8" />
          )}
          <div>
            <div className="flex flex-row gap-2 mb-1">
              <Badge variant="gray" className="rounded-full text-black text-xs px-1" loading={isLoading}>
                <p>
                  On <b>{currentIndex + 1}</b> of <b>{documentsNeedingApproval?.length || "..."}</b> documents
                  to review
                </p>
              </Badge>
              <MultiSelect
                buttonClassName="w-fit items-start rounded-full bg-gray-300 text-xs"
                onChange={setFilter}
                options={OPTIONS}
                values={filter}
                prefixIcon={<ListFilterIcon className="h-4 text-gray mr-1" />}
              />
            </div>

            <DocumentApproval
              className="h-[80svh] w-[600px]"
              file={{ ...file, uploader }}
              href={file?.s3Document?.href}
              loading={isLoading}
              onSubmit={onSubmit.mutateAsync}
              onDelete={onDelete}
            />
          </div>

          {nextDocumentId ? (
            <CircleChevronRight
              onClick={goForwardOne}
              className="text-white fill-gray min-w-8 min-h-8 h-8 w-8 cursor-pointer"
            />
          ) : (
            <div className="w-8" />
          )}
        </div>
      </div>
    </div>
  );
}
