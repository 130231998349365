import Inputmask from "inputmask";
import { useCallback, useEffect, useRef, useState } from "react";
import useEffectMemo from "src/hooks/useEffectMemo";

interface UseInputMaskOptions {
  mask: Inputmask.Options["mask"];
  register?(element: HTMLElement): void;
  options?: Inputmask.Options;
  onChange?: (event: InputEvent) => void;
}

const useInputMask = ({ mask, register, options, onChange }: UseInputMaskOptions) => {
  const ref = useRef<HTMLInputElement>(null);
  const inputmask = useRef<Inputmask.Instance>(null);

  const [, setMaskInput] = useState<Inputmask.Instance | null>(null);

  const handleKeyDown = useCallback(
    (
      event: KeyboardEvent,
      buffer: string[],
      caretPos: { begin: number; end: number },
      opts: Inputmask.Options
    ) => {
      options?.onKeyDown?.(event, buffer, caretPos, opts);
      // @ts-ignore - event.target?.value apparently doesn't exist (but it does)
      const currentValue: string | undefined = event.target?.value;
      if (event.key === "Backspace" && currentValue === "-0" && ref.current) {
        // This is to handle the case of wanting to do a backspace to clear a minus zero - which
        // inputmask doesn't support for some reason
        ref.current.value = currentValue.slice(1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options?.onKeyDown]
  );

  const onBeforePaste = useCallback(
    (pastedValue: string) => {
      // for some reason the paste is not otherwise handled. lets HANDLE THIS
      if (ref.current) {
        ref.current.value = pastedValue;
        // @ts-ignore
        onChange?.({ target: ref.current });
      }
    },
    [onChange]
  );

  useEffectMemo(() => {
    if (!ref.current) {
      return;
    }

    // @ts-ignore
    inputmask.current = Inputmask({
      mask,
      ...options,
      onKeyDown: handleKeyDown,
      onBeforePaste,
    });
    inputmask.current.mask(ref.current);

    // Set an otherwise unused state value to trigger a re-render when the input
    // mask options change. Otherwise the options might not come into effect
    setMaskInput(inputmask.current);
  }, [mask, options]);

  useEffect(() => {
    if (register && ref.current) {
      register(ref.current);
    }
  }, [register]);

  return ref;
};

export default useInputMask;
