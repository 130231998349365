import { parsers } from "@fraction/shared";
import { ReactNode } from "react";

export function Stat({ label, value }: { label: string; value: string | number }) {
  return (
    <div className="h-20 w-32 flex rounded border-2 border-gray-700 items-center justify-center column p-2">
      <p className="text-md text-gray-900 font-bold font-serif text-center">{value}</p>
      <p className="text-xs text-gray-700 font-normal text-center">{label}</p>
    </div>
  );
}

export function Stats({
  data,
  formatter,
  children,
}: {
  data: parsers.stats.Stats;
  formatter: (val: number) => string | number;
  children?: ReactNode;
}) {
  return (
    <div className="flex flex-wrap gap-2 w-full items-center justify-center h-fit p-5">
      {children}
      <Stat label="median" value={formatter(data.median)} />
      <Stat label="mean" value={formatter(data.mean)} />
      <Stat label="min" value={formatter(data.min)} />
      <Stat label="max" value={formatter(data.max)} />
      <Stat label="std dev" value={formatter(data.std)} />
      <Stat label="quartile 1" value={formatter(data.q1)} />
      <Stat label="quartile 2" value={formatter(data.q2)} />
      <Stat label="quartile 3" value={formatter(data.q3)} />
      <Stat label="quartile 4" value={formatter(data.q4)} />
    </div>
  );
}
