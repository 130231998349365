import { ERROR_MESSAGES, errors } from "@fraction/shared";

export function parseErrorBody(error: any) {
  let body = error;
  if (body.response) {
    body = body.response;
  }
  if (body.body) {
    body = body.body;
  }
  return body;
}

export function parseErrorMessage(error: any) {
  let body = parseErrorBody(error);
  if (body.message) {
    body = body.message;
  }
  body = rewriteErrorMessage(body);
  return typeof body === "string" ? body : null;
}

export function rewriteErrorMessage(message: string) {
  if (message && typeof message === "string" && message.indexOf(ERROR_MESSAGES.REQUEST_TERMINATED) !== -1) {
    return "Our server had a hiccup! Please wait and try again in a moment.";
  }
  return message;
}

export function recreateErrorObject(error: any) {
  if (error?.response?.body?.message && error?.response?.body?.name) {
    // @ts-ignore
    if (errors[error?.response?.body?.name]) {
      // @ts-ignore
      return new errors[error.response.body.name](error.response.body.message);
    }
  }
  return error;
}
