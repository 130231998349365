import { InfoAlert } from "src/components";
import { PopupProps } from "src/components/Popup";
import { createStyles } from "src/styles";

import ConfirmationModalScreen from "../../../../components/ConfirmationModalScreen";

const styles = createStyles({
  confirmationModal: {
    padding: "56px 48px",
  },
  disclaimer: {
    marginTop: 16,
  },
  break: {
    display: "block",
    margin: "16px 0px",
    content: '" "',
  },
});

const AccountCreatedConfirmationScreen = (props: Omit<PopupProps, "children">) => (
  <ConfirmationModalScreen
    style={styles.confirmationModal}
    header="Not so fast! We have to verify your account."
    buttonText="I understand"
    {...props}
  >
    <InfoAlert style={styles.disclaimer}>
      In the next 1-2 days, you should see two small deposits arrive in your bank account.
      <br css={styles.break} />
      Once you see the deposits, please return to this dashboard and verify the amounts.
      <br css={styles.break} />
      This step is required before prepayments or draws can be made with this account. See you soon!
    </InfoAlert>
  </ConfirmationModalScreen>
);

export default AccountCreatedConfirmationScreen;
