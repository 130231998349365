import { useCallback, useState } from "react";
import { useCachedState } from "./useCache";

export interface Toggle {
  on: boolean;
  toggle: () => void;
  setOn: () => void;
  setOff: () => void;
}

export default function useToggle(
  initialState = false,
  {
    cacheKey,
  }: {
    cacheKey?: string;
  } = {}
): Toggle {
  const [on, setToggle] = (cacheKey ? useCachedState : useState)<boolean>(initialState, cacheKey!);

  const toggle = useCallback((): void => setToggle((state) => !state), []);
  const setOn = useCallback((): void => setToggle(true), []);
  const setOff = useCallback((): void => setToggle(false), []);

  return { on, toggle, setOn, setOff };
}
