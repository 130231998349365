import { SerializedStyles } from "@emotion/react";
import CircleProgress from "src/components/CircleProgress";

export interface CircleSpinnerProps {
  style?: SerializedStyles;
  color?: string;
  fill?: string;
}

const CircleSpinner = (props: CircleSpinnerProps) => (
  <CircleProgress
    {...props}
    pathLength={0.25}
    rotate={0}
    animate={{ rotate: 720 }}
    transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
  />
);

export default CircleSpinner;
