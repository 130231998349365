import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";

import { calculators, colors, finance, formatters } from "@fraction/shared";
import calculatorImg from "../images/calculator.png";
import paymentsBeforeImg from "../images/funding-process.png";
import paymentsAfterImg from "../images/optional-payments.png";
import proceedsImg from "../images/payments-before.png";
import { InfoContainer } from "./InfoContainer";

const styles = StyleSheet.create({
  largeContainer: {
    height: 160,
    width: "100%",
    borderRadius: 5,
    backgroundColor: colors.palette.GREEN_50,
    paddingHorizontal: 28,
    paddingVertical: 16,
    flexDirection: "row",
    alignItems: "center",
  },
});

export const FinancialProfile = ({
  monthlyDebtPaymentsBefore,
  monthlyDebtPaymentsAfter,
  termLength,
  cashToBorrower,
  loanAmount,
  style,
}: {
  monthlyDebtPaymentsBefore: number;
  monthlyDebtPaymentsAfter: number;
  termLength: number;
  cashToBorrower: number;
  loanAmount: number;
  style?: any;
}) => {
  const friendlyDebtPost = monthlyDebtPaymentsAfter <= 50 ? 0 : monthlyDebtPaymentsAfter;
  const netPerMonth = (monthlyDebtPaymentsBefore || 0) - (monthlyDebtPaymentsAfter || 0);
  const monthlyRate = calculators.rates.convertAnnualToMonthlyCompounding(0.08);
  const amountAtEndOfTerm =
    finance.fv(monthlyRate, netPerMonth, termLength * 12) + cashToBorrower * (1 + 0.08) ** termLength;

  return (
    <View style={style}>
      <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 4 }}>
        How Fraction Could Work For You
      </Text>
      <Text style={{ marginBottom: 4, fontSize: 12 }}>
        Ready to get ahead? With <Text style={{ fontWeight: "bold" }}>no monthly payments</Text>, your
        Fraction mortgage frees your finances (and you!) to{" "}
        <Text style={{ fontWeight: "bold" }}>invest in your future</Text>. Using the{" "}
        {formatters.number.getCurrencyFromNumber(loanAmount, { digits: 0, inputInDollars: true })} from your
        home equity, you can pay off existing debts and grow that TFSA.{"\n"}
      </Text>
      <Text style={{ marginBottom: 8, fontSize: 12, fontWeight: "bold" }}>
        Here's what {termLength} years of saving and investing could look like for you:{"\n"}
      </Text>
      <View style={{ flexDirection: "row" }}>
        <InfoContainer
          top="Your monthly debt payments before Fraction"
          bottom={formatters.number.getCurrencyFromNumber(monthlyDebtPaymentsBefore, {
            digits: 0,
            inputInDollars: true,
          })}
          miniBottom="per month"
          img={paymentsBeforeImg}
        />
        <InfoContainer
          style={{ marginLeft: 12 }}
          top="Your monthly debt payments after Fraction"
          bottom={formatters.number.getCurrencyFromNumber(friendlyDebtPost, {
            digits: 0,
            inputInDollars: true,
          })}
          miniBottom={`You save ${formatters.number.getCurrencyFromNumber(netPerMonth, {
            digits: 0,
            inputInDollars: true,
          })} per month!`}
          img={paymentsAfterImg}
        />
        <InfoContainer
          style={{ marginLeft: 12 }}
          top="Cash you receive after paying off debts"
          bottom={formatters.number.getCurrencyFromNumber(cashToBorrower, {
            digits: 0,
            inputInDollars: true,
          })}
          miniBottom="An estimate, including ILR, title insurance, conveyancing fees"
          img={proceedsImg}
        />
      </View>
      {cashToBorrower || netPerMonth ? (
        <View style={[styles.largeContainer, { marginTop: 12 }]}>
          <Image style={{ height: "80%", marginVertical: 8 }} src={calculatorImg} />
          <View style={{ height: "100%", flex: 1, marginLeft: 16, justifyContent: "center" }}>
            <Text style={{ lineHeight: 1.5, fontSize: 12 }}>
              <Text style={{ lineHeight: 1.3, fontSize: 16, fontWeight: 700 }}>
                {formatters.number.getCurrencyFromNumber(amountAtEndOfTerm, {
                  digits: 0,
                  inputInDollars: true,
                })}
              </Text>{" "}
              is the approximate amount you'd have in investments at the end of the {termLength} year term if
              you invested the{" "}
              {cashToBorrower
                ? `${formatters.number.getCurrencyFromNumber(cashToBorrower, {
                    digits: 0,
                    inputInDollars: true,
                  })} you received ${netPerMonth ? "along with the extra " : "in the future."}`
                : ""}
              {!netPerMonth
                ? ""
                : `${formatters.number.getCurrencyFromNumber(netPerMonth, {
                    digits: 0,
                    inputInDollars: true,
                  })} you no longer need to pay each month.`}
            </Text>
            <Text style={[{ fontSize: 8, color: colors.palette.GREY_800, marginTop: 8 }]}>
              An estimate based on an 8% annual return on your investments.
            </Text>
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default FinancialProfile;
