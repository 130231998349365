import { useDroppable } from "@dnd-kit/core";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { entities, enums, formatters, notUndefinedOrNull, selectors } from "@fraction/shared";
import { useVirtualizer } from "@tanstack/react-virtual";
import _ from "lodash";
import { forwardRef, useRef } from "react";
import { ChecklistApp } from "src/api/fraction";
import { PipelineDealCard } from "src/apps/LOS/components/PipelineDealCard";
import useMergedRef from "src/hooks/useMergedRef";

interface SortableItemProps {
  id: string;
  statusOverride?: string;
  setShowModal?: (opts: { id: string; status: enums.ApplicationStatus }) => void;
  showChecklist?: boolean;
  showSalesPerson?: boolean;
  salesOptions?: entities.InternalEmployee[];
  style?: any;
}

export const SortableItem = forwardRef<HTMLDivElement, SortableItemProps>((props: SortableItemProps, ref) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...props.style,
  };

  const mergedRef = useMergedRef(ref, setNodeRef);

  const { showChecklist, ...rest } = props;

  return (
    <PipelineDealCard
      {...rest}
      statusOverride={
        props.statusOverride ||
        (props.id === active?.id ? active?.data?.current?.sortable?.containerId : undefined)
      }
      ref={mergedRef}
      style={style}
      hideStatus={!showChecklist}
      {...attributes}
      {...listeners}
    />
  );
});

export function PipelineStageContainer({
  id,
  items,
  setShowModal,
  showChecklist,
  showSalesPerson,
  salesOptions,
  style,
}: {
  id: string;
  items: ChecklistApp[];
  setShowModal?: (opts: { id: string; status: enums.ApplicationStatus }) => void;
  showChecklist?: boolean;
  showSalesPerson?: boolean;
  salesOptions?: entities.InternalEmployee[];
  style: any;
}) {
  const filteredItems = items.filter(notUndefinedOrNull);
  const { setNodeRef } = useDroppable({
    id,
  });
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 200,
    getItemKey: (index) => filteredItems[index].id,
    gap: 6,
    overscan: 4,
  });

  return (
    <div css={style}>
      <div className="px-4 mb-1 flex flex-row gap-x-2 justify-between items-center">
        <p title={_.startCase(id)} className="text-md font-bold text-ellipsis line-clamp-1">
          {_.startCase(id)}
        </p>
        <p className="bg-gray-400 text-black font-semibold rounded-full h-6 text-xs text-center w-8 flex items-center justify-center">
          {filteredItems.length}
        </p>
      </div>
      <SortableContext id={id} items={filteredItems} strategy={verticalListSortingStrategy}>
        <div
          className="bg-gray-200 rounded-lg p-4 h-[60svh] w-80 overflow-y-scroll overscroll-y-contain"
          ref={setNodeRef}
        >
          <div
            ref={parentRef}
            css={{
              height: virtualizer.getTotalSize(),
              width: "100%",
              position: "relative",
            }}
          >
            {virtualizer.getVirtualItems().map((virtualItem) => (
              <SortableItem
                ref={virtualizer.measureElement}
                data-index={virtualItem.index}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  // height: `${virtualItem.size}px`,
                  // height: "100%",
                  width: "100%",
                  transform: `translateY(${virtualItem.start}px)`,
                }}
                showChecklist={showChecklist}
                setShowModal={setShowModal}
                showSalesPerson={showSalesPerson}
                key={virtualItem?.key}
                // @ts-ignore
                id={virtualItem.key}
                salesOptions={salesOptions}
              />
            ))}
          </div>
        </div>
      </SortableContext>
      <div className="px-4 mt-1 flex flex-col align-middle items-center">
        <p>
          <b>Total:</b>{" "}
          {formatters.number.getCurrencyFromNumber(
            _.sumBy(items, selectors.application.selectApplicationAmount),
            { digits: 0 }
          )}
        </p>
      </div>
    </div>
  );
}
