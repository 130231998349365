import * as React from "react";

type CB<T extends HTMLElement = HTMLElement> = React.RefCallback<T>;

const useMergedRef =
  <T extends HTMLElement = HTMLElement>(...refs: Array<React.Ref<T> | undefined>): CB<T> =>
  (element: T) =>
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(element);
      } else if (ref && typeof ref === "object") {
        (ref as React.MutableRefObject<T>).current = element;
      }
    });

export default useMergedRef;
