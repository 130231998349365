import { useWindowSize } from "src/hooks";

export default function useMediaFallback(
  widthThreshold: number,
  defaultElement: React.ReactNode,
  fallbackElement: React.ReactNode
) {
  const { width } = useWindowSize();

  return width < widthThreshold ? fallbackElement : defaultElement;
}
