import { ReactNode, memo } from "react";
import { Style, createStyles } from "src/styles";
import SHAPED_TOKENS from "src/styles/figma/SHAPED_TOKENS";
import { cn } from "src/utilities/shadcnUtils";

interface TextProps {
  children: ReactNode;
  weight?: "regular" | "medium" | "bold";
  size?: keyof typeof SHAPED_TOKENS.fontSize;
  font?: "canela" | "avenir" | "hk";
  as?: React.ElementType;
  style?: Style;
  className?: string;
}

const styles = createStyles(({ theme, fontSize }) => ({
  base: {
    color: theme.text.default.base,
    fontSize: "1rem",
    letterSpacing: "0.02em",
  },
  heading: {
    lineHeight: 1.2,
  },
  paragraph: {
    lineHeight: 1.4,
  },
  monospace: {
    fontFamily: "monospace",
    lineHeight: 1,
  },
  canelaFontFamily: {
    fontFamily: ["Canela Deck", "serif"].join(","),
  },
  avenirFontFamily: {
    fontFamily: ["Avenir", "sans-serif"].join(","),
  },
  hkFontFamily: {
    fontFamily: ["Hanken Grotesk", "sans-serif"].join(","),
  },
  // font weights
  regularFontWeight: {
    fontWeight: 400,
  },
  mediumFontWeight: {
    fontWeight: 500,
  },
  boldFontWeight: {
    fontWeight: 700,
  },
  // font sizes
  "3xsFontSize": {
    fontSize: fontSize["3xs"],
  },
  "2xsFontSize": {
    fontSize: fontSize["2xs"],
  },
  xsFontSize: {
    fontSize: fontSize.xs,
  },
  smFontSize: {
    fontSize: fontSize.sm,
  },
  mdFontSize: {
    fontSize: fontSize.md,
  },
  lgFontSize: {
    fontSize: fontSize.lg,
  },
  xlFontSize: {
    fontSize: fontSize.xl,
  },
  "2xlFontSize": {
    fontSize: fontSize["2xl"],
  },
  "3xlFontSize": {
    fontSize: fontSize["3xl"],
  },
  "4xlFontSize": {
    fontSize: fontSize["4xl"],
  },
  "5xlFontSize": {
    fontSize: fontSize["5xl"],
  },
  "6xlFontSize": {
    fontSize: fontSize["6xl"],
  },
  "7xlFontSize": {
    fontSize: fontSize["7xl"],
  },
}));

// todo add support for color props

/**
 * @param weight - "regular" (350px - default) | "medium" (550px) | "bold" (700px)
 * @param size - "2xs" (12px) | "xs" (14px) | "sm" (16px) | "md" (18px - default) | "lg" (20px) | "xl" (24px) | "2xl" (32px) | "3xl" (36px) | "4xl" (40px) | "5xl" (48px) | "6xl" (56px) | "7xl" (64px)
 */
export const Text = memo(
  ({ children, weight = "regular", size = "md", as = "span", font = "hk", style, className }: TextProps) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Component = as;
    return (
      <Component
        className={cn("m-0", className)}
        css={[
          styles.base,
          styles[`${weight}FontWeight`],
          styles[`${size}FontSize`],
          styles[`${font}FontFamily`],
          style,
        ]}
      >
        {children}
      </Component>
    );
  }
);

export const Paragraph = ({ style, ...rest }: TextProps) => (
  <Text as="p" style={[styles.paragraph, ...(Array.isArray(style) ? style : [style])]} {...rest} />
);
export const Italic = (props: TextProps) => <Text as="i" {...props} />;
export const Bold = (props: TextProps) => <Text as="b" weight="bold" {...props} />;
export const Underline = (props: TextProps) => <Text as="u" {...props} />;

/**
 * Heading is the same as Text except it renders an h2 by default and it uses the Canela font by default
 *
 * Generally we use Text for sizes up to 24px (xl) and Heading for sizes 32px (2xl) and up. But that's not a hard rule.
 */
export const Heading = ({ style, font = "canela", ...rest }: TextProps) => {
  return (
    <Text
      as="h2"
      size="2xl"
      weight="bold"
      font={font}
      style={[styles.heading, ...(Array.isArray(style) ? style : [style])]}
      {...rest}
    />
  );
};

export const Monospace = ({ style, ...rest }: TextProps) => {
  return <Text as="span" style={[styles.monospace, ...(Array.isArray(style) ? style : [style])]} {...rest} />;
};
