import { useEffect, useState } from "react";
import google from "src/api/google";

export default function useGoogle() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      await google.setupPromise;
      setLoaded(true);
    })();
  }, []);

  return {
    loaded,
    google: loaded ? google : undefined,
  };
}
