import _ from "lodash";
import { useState } from "react";
import { Tabs, TabsList, TabsTrigger } from "src/components/ui/tabs";
import { cn } from "src/utilities/shadcnUtils";

export default function useTabs<T extends string>({
  options,
  defaultValue,
  className,
  tabsPerRow,
  grid,
  containerClassName,
}: {
  options: { label: string; value: T }[];
  defaultValue: T;
  className?: string;
  tabsPerRow?: number;
  grid?: number[];
  containerClassName?: string;
}) {
  const [tab, setTab] = useState<T>(defaultValue);
  const chunks = grid ? [] : _.chunk(options, tabsPerRow || 100);
  let track = 0;
  for (const g of grid || []) {
    chunks.push(options.slice(track, track + g));
    track += g;
  }

  return {
    activeTab: tab,
    Tabs: ({ className: innerClassName }: { className?: string }) => (
      <Tabs
        value={tab}
        className={cn("w-fit grid gap-y-1", innerClassName, containerClassName)}
        // @ts-ignore
        onValueChange={setTab}
        defaultValue={defaultValue}
      >
        {chunks.map((chunk, index) => (
          <TabsList key={chunk[0].value} className={cn("flex-wrap", className)}>
            {chunk.map((option) => (
              <TabsTrigger key={option.label} value={option.value}>
                {option.label}
              </TabsTrigger>
            ))}
          </TabsList>
        ))}
      </Tabs>
    ),
  };
}
