import { ReactNode } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { CalendarPage } from "src/apps/LOS/pages/Calendar";
import { EditProfileScreen } from "src/apps/shared/EditProfile";
import { DashboardShell } from "src/components";
import EnhanceRoute from "src/components/root/routeHelpers/EnhanceRoute";
import { ConveyancerHeader } from "./components/Header";
import { AppDetailsRoutes } from "./pages/AppDetails";
import { ConveyancerDashboardOverview } from "./pages/Overview";

const ConveyancerDashboardLayout = ({ footer }: { footer?: ReactNode }) => {
  return (
    <>
      <DashboardShell header={<ConveyancerHeader />}>
        <Outlet />
      </DashboardShell>
    </>
  );
};

export function ConveyancerDashboardRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ConveyancerDashboardLayout />}>
        <Route index path="/" element={<ConveyancerDashboardOverview status="active" />} />
        <Route path="/app/:id/*" element={<AppDetailsRoutes />} />
        <Route path="/profile" element={<EditProfileScreen />} />;
        <Route
          path="/calendar"
          element={
            <EnhanceRoute title="Calendar | Fraction">
              <div className="flex flex-row p-6">
                <CalendarPage accountType="conveyancer" />
              </div>
            </EnhanceRoute>
          }
        />
      </Route>
    </Routes>
  );
}
