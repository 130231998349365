import { ReactNode } from "react";
import { HeaderText, RegularText } from "src/components/v1";
import { createStyles } from "src/styles";
import { cn } from "src/utilities/shadcnUtils";

const CARD_SPACING = 32;
const CARD_WIDTH = 542;

const styles = createStyles({
  card: {
    marginBottom: CARD_SPACING,
    padding: CARD_SPACING,
    paddingTop: 0,
    width: "100%",
    maxWidth: CARD_WIDTH * 2 + CARD_SPACING,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media(min-width: 1048px)": {
      marginRight: CARD_SPACING,
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: 0,
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    "@media(max-width: 1048px)": {
      alignItems: "center",
      marginLeft: 0,
      marginRight: 0,
      width: "auto",
    },
  },
  headerText: {
    fontWeight: 700,
    fontSize: 30,
    marginBottom: 16,
    "@media(max-width: 1048px)": {
      textAlign: "center",
      fontSize: 28,
    },
  },
  subHeader: {
    fontSize: 18,
    "@media(max-width: 1048px)": {
      textAlign: "center",
    },
  },
});

const EmptyDataCard = ({
  illustration,
  headerText,
  subheaderText,
  className,
  button,
}: {
  illustration: ReactNode;
  headerText: string;
  subheaderText: string;
  className?: string;
  button?: ReactNode;
}) => (
  <div className={cn("flex items-center py-6", className)}>
    {illustration}
    <div className="ml-2" css={styles.textContainer}>
      <HeaderText style={styles.headerText}>{headerText}</HeaderText>
      <RegularText style={styles.subHeader}>{subheaderText}</RegularText>
    </div>
    {button}
  </div>
);

export default EmptyDataCard;
