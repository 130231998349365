import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { FieldValues, useForm } from "react-hook-form";
import * as z from "zod";

/*
 * Utility hook to reduce the boilerplate of using react-hook-form with zod validation
 */
export default function useZodForm<T extends FieldValues>({
  schema,
  formValues,
}: {
  schema: z.ZodSchema<T>;
  formValues?: Record<keyof T, any>;
}) {
  const hookFormMethods = useForm<T>({
    resolver: zodResolver(schema),
  });

  const register = useCallback(
    (fieldName: keyof T) => {
      const fieldError = hookFormMethods.errors?.[fieldName] as unknown as { message?: string };
      return {
        ref: hookFormMethods.register,
        name: fieldName,
        defaultValue: formValues ? formValues[fieldName] : hookFormMethods.watch(fieldName as string),
        error: fieldError?.message,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues, hookFormMethods.errors, hookFormMethods.register]
  );

  return {
    ...hookFormMethods,
    hookFormRegister: hookFormMethods.register,
    register,
  };
}
