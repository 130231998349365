import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import Slider, { InaccessibleRegion, SliderProps } from "src/components/Slider";
import { BoldText, RegularText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface LabeledSliderProps extends SliderProps {
  formatter: (val: number) => string;
  labelFormatter?: (val: number) => string;
  secondaryLabel?: string;
  labelStyle?: SerializedStyles;
  style?: SerializedStyles;
  color?: string;
  inaccessibleRegions?: InaccessibleRegion[];
  hideMin?: boolean;
  hideMax?: boolean;
}

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  row: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: 15,
  },
  littleText: {
    fontSize: 12,
    color: colors.LIGHT_TEXT,
  },
  header: {
    fontSize: 24,
    marginBottom: 10,
  },
  labelContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  reverse: {
    flexDirection: "row-reverse",
  },
});

const LabeledSlider = ({
  min,
  max,
  value,
  onChange,
  step,
  formatter,
  labelFormatter,
  secondaryLabel,
  markers,
  style,
  labelStyle,
  color,
  inaccessibleRegions,
  hideMin,
  hideMax,
}: LabeledSliderProps) => (
  <div css={[styles.container, style]}>
    <div css={[styles.labelContainer, secondaryLabel && styles.reverse]}>
      <BoldText style={[styles.header, labelStyle]}>
        {labelFormatter ? labelFormatter(value) : formatter(value)}
      </BoldText>
      {secondaryLabel && <BoldText style={[styles.header, labelStyle]}>{secondaryLabel}</BoldText>}
    </div>
    <Slider
      color={color}
      markers={markers}
      min={min}
      max={max}
      value={value}
      step={step}
      onChange={onChange}
      inaccessibleRegions={inaccessibleRegions}
    />
    <div css={styles.row}>
      {!hideMin ? <RegularText style={styles.littleText}>{formatter(min)}</RegularText> : <div />}
      {!hideMax ? <RegularText style={styles.littleText}>{formatter(max)}</RegularText> : <div />}
    </div>
  </div>
);

export default memo(LabeledSlider);
