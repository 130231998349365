import { SerializedStyles } from "@emotion/react";
import CircleScrollProgress, { CircleScrollProgressProps } from "src/components/CircleScrollProgress";
import Touchable from "src/components/Touchable";
import { X } from "src/icons";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface ProgressCloseButtonProps extends CircleScrollProgressProps {
  style?: SerializedStyles;
  fill?: string;
  onClick?: () => void;
}

const styles = createStyles({
  xContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  position: {
    position: "fixed",
    top: 60,
    right: 100,
    height: 70,
    width: 70,
    padding: 0,
    "@media(max-width: 420px)": {
      top: 30,
      right: 30,
    },
  },
  progress: {
    position: "absolute",
    filter: "drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.1))",
  },
});

const ProgressCloseButton = ({
  onClick,
  style,
  fill = colors.DARK_BACKGROUND,
  viewportModifier,
  elementToWatch,
}: ProgressCloseButtonProps) => (
  <Touchable onClick={onClick} css={[styles.position, style]}>
    <CircleScrollProgress
      elementToWatch={elementToWatch}
      viewportModifier={viewportModifier}
      fill={fill}
      style={styles.progress}
    />
    <div css={styles.xContainer}>
      <X strokeWidth="1.5" height={34} width={34} color={colors.ICON} />
    </div>
  </Touchable>
);

export default ProgressCloseButton;
