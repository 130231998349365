import { useCallback } from "react";
import {
  useLoanDrawMutation,
  useLoanQuery,
  usePrepaymentMutation,
} from "src/apps/PostFundedDashboard/queries";
import { Toggle } from "src/hooks/useToggle";

import { parsers, types } from "@fraction/shared";

import OldLoanDrawModal, { Field } from "../OldLoanDrawModal";
import OldPrepaymentModal from "../OldPrepaymentModal";

const LOAN_DRAW_INITIAL_VALUES: Record<Field, string> = {
  [Field.ABA_ROUTING]: "",
  [Field.ACCOUNT_NUMBER]: "",
  [Field.DRAW_AMOUNT]: "",
};

export function OldModalViews({
  loanDrawToggle,
  prepaymentToggle,
}: {
  loanDrawToggle: Toggle;
  prepaymentToggle: Toggle;
}) {
  const { data: loan } = useLoanQuery();

  const loanDrawMutation = useLoanDrawMutation(loan?.id || "");
  const prepaymentMutation = usePrepaymentMutation(loan?.id || "");

  const onSubmitLoanDraw = useCallback(
    (submission: parsers.loanDraw.LoanDraw, onSuccess: () => void) => {
      loanDrawMutation.mutate(submission, { onSuccess });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loanDrawMutation.mutate]
  );

  const onSubmitPrepayment = useCallback(
    (submission: { type: types.PrepaymentType }, onSuccess: () => void) => {
      prepaymentMutation.mutate(submission, { onSuccess });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepaymentMutation.mutate]
  );

  return (
    <>
      <OldPrepaymentModal
        open={prepaymentToggle.on}
        onClose={prepaymentToggle.setOff}
        virtualAccountNumber={loan?.virtualAccount?.accountNumber || "N/A"}
        onSubmit={onSubmitPrepayment}
        isLoading={prepaymentMutation.isPending}
      />
      <OldLoanDrawModal
        open={loanDrawToggle.on}
        initialValues={LOAN_DRAW_INITIAL_VALUES}
        onClose={loanDrawToggle.setOff}
        onSubmit={onSubmitLoanDraw}
        isLoading={loanDrawMutation.isPending}
      />
    </>
  );
}
