import { useQueryParams } from "src/hooks";
import { Logger } from "src/log";

const LOGGER = new Logger("hook.useRateQueryParams");

const parseRateParam = (value?: string) => {
  // make sure it is roughly a reasonable APR value
  if (value && parseFloat(value) >= 0 && parseFloat(value) <= 0.5) {
    return parseFloat(value);
  }
};

export default function useRateQueryParams(required: {
  minAPR?: boolean;
  maxAPR?: boolean;
  minRate?: boolean;
  maxRate?: boolean;
  minRegionRate?: boolean; // for scenarios we want to display the min rate for a region alongside the term rate e.g 5 year alongside the 10 year
  termLength?: boolean;
}) {
  const params = useQueryParams();
  const minAPR = parseRateParam(params.min_apr) || 0;
  const maxAPR = parseRateParam(params.max_apr) || 0;
  const minRate = parseRateParam(params.min_rate) || 0;
  const maxRate = parseRateParam(params.max_rate) || 0;
  const minRegionRate = parseRateParam(params.min_region_rate) || 0;
  const termLength = parseRateParam(params.term_length) || 0;

  if (!minAPR && required.minAPR) {
    LOGGER.warn("min_apr query parameter is required");
  }
  if (!maxAPR && required.maxAPR) {
    LOGGER.warn("max_apr query parameter is required");
  }
  if (!minRate && required.minRate) {
    LOGGER.warn("min_rate query parameter is required");
  }
  if (!maxRate && required.maxRate) {
    LOGGER.warn("max_rate query parameter is required");
  }
  if (!minRegionRate && required.minRegionRate) {
    LOGGER.warn("min_region_rate query parameter is required");
  }
  if (!termLength && required.termLength) {
    LOGGER.warn("term_length query parameter is required");
  }

  return {
    minAPR,
    maxAPR,
    minRate,
    maxRate,
    minRegionRate,
    termLength,
  };
}
