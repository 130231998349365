import { useMemo } from "react";
import fraction from "src/api/fraction";
import { useQuery } from "src/lib";
import { Logger } from "src/log";

import { engine, enums, parsers, selectors } from "@fraction/shared";

const defaultLogger = new Logger("hooks.useCurrentRate");

type UseCurrentRateOptions = Omit<parsers.rate.FetchRateArgs, "onlyCurrent" | "regions"> &
  Required<Pick<parsers.rate.FetchRateArgs, "termLength">> & { logger?: Logger } & {
    allowAnyRegion?: boolean;
    onError?: (err: Error) => void;
  };

/**
 * Fetch a current rate. Assumes a 5-year term but this can be overridden
 */
const useCurrentRate = (administrativeArea: string | undefined, options: UseCurrentRateOptions) => {
  const { allowAnyRegion, ...rateOptions } = options;
  const logger = options.logger || defaultLogger;

  const region = useMemo(() => {
    if (!administrativeArea) {
      return;
    }

    const result = engine.subprocessors.adminAreaValidationSubProcessor(administrativeArea, true);

    if (!allowAnyRegion && result.status !== enums.EngineRuleStatus.PASSED) {
      logger.log(`Administrative area validation ${result.status}, not fetching rate: ${result.message}`);
      return;
    }

    const region_ = selectors.property.selectRegionFromAdministrativeArea(administrativeArea);
    if (!region_) {
      logger.warn(`No region found for administrative area ${administrativeArea}, skipping rate fetch`);
      return;
    }

    return region_;
  }, [administrativeArea, logger, allowAnyRegion]);

  const query = useQuery({
    queryKey: ["rate", administrativeArea, rateOptions.termLength, rateOptions.datesBetween],
    queryFn: async () => {
      try {
        return await fetchRate(region as enums.Region, rateOptions);
      } catch (err) {
        if (options.onError) {
          options.onError(err);
          return null;
        } else {
          throw err;
        }
      }
    },
    staleTime: 1000 * 3600 * 24,
    enabled: !!region,
    retry: 1,
    retryDelay: 3000,
    onError: options.onError,
  });

  return { ...query, rate: query.data };
};

const fetchRate = async (
  region: enums.Region,
  options_: parsers.rate.FetchRateArgs
): Promise<parsers.rate.Rate> => {
  const options = { onlyCurrent: true, regions: [region], ...options_ };
  const fetchedRate = (await fraction.getRates(options))[0];
  if (!fetchedRate) {
    throw new Error(`No rates found for region ${region}; maintaining current rate data`);
  }
  return fetchedRate;
};

export default useCurrentRate;
