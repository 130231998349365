import { DocumentType, types } from "@fraction/shared";
import { CalendarDays, FolderOpenDot, HouseIcon } from "lucide-react";
import { ChecklistLoan, useLoanQuery } from "src/apps/PostFundedDashboard/queries";
import { RouterNavLink } from "src/components";
import { AutoDeposit2 } from "src/icons";
import { selectInsurancePolicyStanding } from "src/selectors/insurancePolicies";

const selectPropertyTaxAndInsuranceIndicator = (loan?: ChecklistLoan) => {
  if (!loan) {
    return false;
  }
  // first check for property taxes
  const needsPropertyTaxes = selectPropertyTaxIndicator(loan);
  const insurancePolicyStanding = selectInsurancePolicyStanding(loan);
  const needsInsurance = insurancePolicyStanding === "to-update";

  return needsPropertyTaxes || needsInsurance;
};

const selectPropertyTaxIndicator = (loan: ChecklistLoan) => {
  if (loan?.application?.deferringPropertyTaxes) {
    return false;
  }

  return !!loan?.application?.checklists?.active?.find(
    (item) =>
      types.isDocumentChecklistItem(item) &&
      item.document === DocumentType.PROPERTY_TAX_PAYMENT_CONFIRMATION &&
      !item.ok
  );
};

export const NavItems = ({ onClick }: { onClick?: () => any }) => {
  const { isLoading, data: loan } = useLoanQuery();
  return (
    <>
      <RouterNavLink onClick={onClick} loading={isLoading} icon={AutoDeposit2} to="/">
        Loan overview
      </RouterNavLink>
      {isLoading || loan?.statements?.length ? (
        <RouterNavLink onClick={onClick} loading={isLoading} icon={CalendarDays} to="/monthly-statements">
          Monthly statements
        </RouterNavLink>
      ) : null}
      <RouterNavLink onClick={onClick} loading={isLoading} icon={FolderOpenDot} to="/documents">
        Loan documents
      </RouterNavLink>
      <RouterNavLink
        indicator={selectPropertyTaxAndInsuranceIndicator(loan)}
        onClick={onClick}
        loading={isLoading}
        icon={HouseIcon}
        to="/tax-insurance"
      >
        Property taxes & insurance
      </RouterNavLink>
    </>
  );
};
