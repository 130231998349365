import { Stats } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/Stats";
import { useTdsAndGds } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";

import { formatters } from "@fraction/shared";
import { useTabs } from "src/hooks";
import { ChartCard } from "../ChartCard";

const decimals = (num: number) => formatters.number.getPercentageFromDecimal(num / 100);

export function TdsAndGds() {
  const { data, isLoading } = useTdsAndGds();
  const { Tabs, activeTab } = useTabs({
    options: [
      {
        value: "tds",
        label: "TDS",
      },
      {
        value: "gds",
        label: "GDS",
      },
    ],
    defaultValue: "tds",
  });

  if (isLoading) {
    return (
      <div className="col-span-3">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  const activeData = data?.[activeTab];

  if (!activeData) {
    return null;
  }

  return (
    <ChartCard
      cardClassName="col-span-3"
      className="min-h-[300px]"
      header="Debt servicing ratios"
      headerComponents={
        <div className="flex row gap-3 items-center flex-wrap">
          <Tabs />
        </div>
      }
    >
      <div>
        <p className="text-md text-gray-900 font-bold font-serif text-center">
          Pre-funding {activeTab.toUpperCase()}
        </p>
        <Stats formatter={decimals} data={activeData.pre} />
      </div>
      <div>
        <p className="text-md text-gray-900 font-bold font-serif text-center">
          Post-funding {activeTab.toUpperCase()}
        </p>
        <Stats formatter={decimals} data={activeData.post} />
      </div>
    </ChartCard>
  );
}
