import { useCallback, useEffect, useMemo, useState } from "react";

export default function useKeyboardListener(shortcutKeys: string[], callback?: () => void) {
  const [holdingDown, setHoldingDown] = useState(false);
  const shortcutKeysId = useMemo(() => shortcutKeys.join(), [shortcutKeys]);
  const lowercaseShortcuts = useMemo(() => shortcutKeys.map((a) => a.toLowerCase()), [shortcutKeys]);

  const keydownListener = useCallback(
    (keydownEvent: any) => {
      const loweredKey = String(keydownEvent.key).toLowerCase();
      if (!(lowercaseShortcuts.indexOf(loweredKey) >= 0)) {
        return;
      }
      callback?.();
      setHoldingDown(true);
    },
    [lowercaseShortcuts, callback]
  );

  const keyupListener = useCallback(
    (keydownEvent: any) => {
      const loweredKey = String(keydownEvent.key).toLowerCase();
      if (!(lowercaseShortcuts.indexOf(loweredKey) >= 0)) {
        return;
      }
      setHoldingDown(false);
    },
    [lowercaseShortcuts]
  );

  useEffect(() => {
    window.addEventListener("keydown", keydownListener);
    window.addEventListener("keyup", keyupListener);
    return () => {
      window.removeEventListener("keydown", keydownListener);
      window.removeEventListener("keyup", keyupListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keydownListener, keyupListener, shortcutKeysId]);

  return holdingDown;
}
