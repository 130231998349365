import CircleSpinner from "src/components/CircleSpinner";
import EmptyPage from "src/components/EmptyPage";
import { createStyles } from "src/styles";

const styles = createStyles({
  emptyPage: { backgroundColor: "white" },
});

export const Loading = () => (
  <EmptyPage css={styles.emptyPage} className="centered simple-padded">
    <CircleSpinner />
  </EmptyPage>
);
