import { ResponsiveBar } from "@nivo/bar";
import { ReactNode } from "react";
import { ChartCard } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/ChartCard";
import { Stats } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/Stats";
import { Skeleton } from "src/components";
import { useToggler } from "src/hooks";
import { cn } from "src/utilities/shadcnUtils";

import { formatters, parsers } from "@fraction/shared";

const MARGIN = { top: 20, right: 0, bottom: 40, left: 40 };

const getLabel = (digits: number = 1) => (d: any) => {
  return formatters.number.getPercentageFromDecimal(d.data.percentage, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export function CountAndStats({
  id,
  header,
  data,
  isLoading,
  xAxisLabel,
  yAxisLabel = "Count",
  className,
  statValueFormatter = (val) => val,
  HeaderComponents,
  labelDigits = 1,
}: {
  id: string;
  header: string;
  data?: parsers.portfolio.DataAndStats;
  isLoading?: boolean;
  xAxisLabel?: string;
  yAxisLabel?: string;
  className?: string;
  statValueFormatter?: (val: number) => string | number;
  HeaderComponents?: ReactNode;
  labelDigits?: number;
}) {
  const { Toggle, on: showStats } = useToggler({
    id,
    label: "Show stats",
    defaultValue: false,
  });

  if (isLoading) {
    return (
      <div className="col-span-2">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  const formattedData =
    data?.data.map((d) => ({
      label: d.label,
      count: d.count,
      percentage: d.percentage,
    })) || [];

  return (
    <ChartCard
      isEmpty={formattedData.length === 0}
      headerComponents={
        <div className="flex row gap-3 flex-wrap">
          {HeaderComponents}
          <Toggle />
        </div>
      }
      cardClassName={cn("col-span-2", className)}
      className="min-h-[300px]"
      header={header}
    >
      {showStats ? (
        <Stats formatter={statValueFormatter} data={data} />
      ) : (
        <ResponsiveBar
          data={formattedData}
          keys={["count"]}
          indexBy="label"
          margin={MARGIN}
          padding={0.3}
          label={getLabel(labelDigits)}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors="#82c882"
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: xAxisLabel,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yAxisLabel,
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#ffffff"
        />
      )}
    </ChartCard>
  );
}
