import { SerializedStyles } from "@emotion/react";
import * as React from "react";
import { createPortal } from "react-dom";
import { usePrevious } from "src/hooks";
import { createStyles } from "src/styles";

export interface DrawerModalProps {
  children?: React.ReactNode;
  style?: SerializedStyles;
  mounted?: boolean;
  transitionDuration?: number; // Duration in seconds (Default: 1s)
  variant?: "full" | "left" | "right";
  className?: string;
}

const styles = createStyles({
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    pointerEvents: "auto",
    zIndex: 1,
    display: "flex",
    maxWidth: "100%",
  },
  shown: {
    transform: "translateX(0%)",
  },
  fullVariant: {
    right: 0,
    left: 0,
    transform: "translateX(-120%)",
  },
  leftVariant: {
    left: 0,
    transform: "translateX(-120%)",
  },
  rightVariant: {
    right: 0,
    transform: "translateX(120%)",
  },
});

const DrawerModal = ({
  children,
  mounted,
  style,
  transitionDuration = 1,
  variant = "full",
  className,
}: DrawerModalProps) => {
  const [last, setLast] = React.useState(children);
  const prevChildren = usePrevious(children);
  const prevMounted = usePrevious(mounted);

  React.useEffect(() => {
    // if beginning to unmount, save the children so that we show it on the way down
    // (actually save the children previously rendered, because if we
    // are unmounting, it's probably too late for the actual children)
    if (!mounted) {
      // default to the children if this is the first render
      setLast(prevChildren || children);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, setLast]);

  const onTransitionEnd = React.useCallback(() => {
    if (!mounted) {
      // once we have fully unmounted, don't keep the saved screen
      setLast(children);
    }
  }, [children, mounted, setLast]);

  const el = document.getElementById("root-layout");
  if (!el) {
    return null;
  }

  return createPortal(
    <div
      className={className}
      onTransitionEnd={onTransitionEnd}
      css={[
        styles.container,
        styles[`${variant}Variant`],
        mounted && styles.shown,
        { transition: `${transitionDuration}s ease-in-out` },
        style,
      ]}
    >
      {mounted ? children : prevMounted ? prevChildren : last}
    </div>,
    el
  );
};

// @ts-ignore
export default React.memo(DrawerModal);
