import alimony_and_child_support from "src/images/illustrative-buttons/alimony-and-child-support-icon.svg?react";
import below_credit from "src/images/illustrative-buttons/below-credit-icon.png";
import bonus_and_commission from "src/images/illustrative-buttons/bonus-and-commission-icon.svg?react";
import canada_child_benefit_income from "src/images/illustrative-buttons/canada-child-benefit-income-icon.svg?react";
import car_allowance from "src/images/illustrative-buttons/car-allowance-icon.svg?react";
import car_loan from "src/images/illustrative-buttons/car-loan-icon.svg?react";
import cash_or_savings from "src/images/illustrative-buttons/cash-or-savings-icon.svg?react";
import checkmark from "src/images/illustrative-buttons/checkmark-icon.svg?react";
import child_support from "src/images/illustrative-buttons/child-support-icon.svg?react";
import commission from "src/images/illustrative-buttons/commission-icon.svg?react";
import condo from "src/images/illustrative-buttons/condo-icon.svg?react";
import coop from "src/images/illustrative-buttons/coop-icon.svg?react";
import credit_card from "src/images/illustrative-buttons/credit-card-icon.svg?react";
import cryptocurrency from "src/images/illustrative-buttons/cryptocurrency-icon.svg?react";
import debt_paydown from "src/images/illustrative-buttons/debt-paydown-icon.svg?react";
import employment_income from "src/images/illustrative-buttons/employment-income-icon.svg?react";
import excellent_credit from "src/images/illustrative-buttons/excellent-credit-icon.png";
import farm from "src/images/illustrative-buttons/farm-icon.svg?react";
import good_credit from "src/images/illustrative-buttons/good-credit-icon.png";
import government_benefits_income from "src/images/illustrative-buttons/government-benefits-income-icon.svg?react";
import investing from "src/images/illustrative-buttons/investing-icon.svg?react";
import investment from "src/images/illustrative-buttons/investment-icon.svg?react";
import investment_income from "src/images/illustrative-buttons/investment-income-icon.svg?react";
import investment_property from "src/images/illustrative-buttons/investment-property-icon.svg?react";
import leasehold from "src/images/illustrative-buttons/leasehold-icon.svg?react";
import line_of_credit from "src/images/illustrative-buttons/line-of-credit-icon.svg?react";
import medical_expenses from "src/images/illustrative-buttons/medical-expenses-icon.svg?react";
import mobile from "src/images/illustrative-buttons/mobile-icon.svg?react";
import other_government_income from "src/images/illustrative-buttons/other-government-income-icon.svg?react";
import other from "src/images/illustrative-buttons/other-icon.svg?react";
import over_four_units from "src/images/illustrative-buttons/over-four-units-icon.svg?react";
import overdraft from "src/images/illustrative-buttons/overdraft-icon.svg?react";
import overtime_and_shift_allowance from "src/images/illustrative-buttons/overtime-and-shift-allowance-icon.svg?react";
import pension_income from "src/images/illustrative-buttons/pension-income-icon.svg?react";
import personal_loan from "src/images/illustrative-buttons/personal-loan-icon.svg?react";
import primary_residence from "src/images/illustrative-buttons/primary-residence-icon.svg?react";
import property_purchase from "src/images/illustrative-buttons/property-purchase-icon.svg?react";
import purchase from "src/images/illustrative-buttons/purchase-icon.svg?react";
import refinancing from "src/images/illustrative-buttons/refinancing-icon.svg?react";
import rental_income from "src/images/illustrative-buttons/rental-income-icon.svg?react";
import savings_and_investments from "src/images/illustrative-buttons/savings-and-investments-icon.svg?react";
import secondary_residence from "src/images/illustrative-buttons/secondary-residence-icon.svg?react";
import self_employment_income from "src/images/illustrative-buttons/self-employment-income-icon.svg?react";
import shares_or_other_investments from "src/images/illustrative-buttons/shares-or-other-investments-icon.svg?react";
import single_family from "src/images/illustrative-buttons/single-family-icon.svg?react";
import student_loan from "src/images/illustrative-buttons/student-loan-icon.svg?react";
import superannuation from "src/images/illustrative-buttons/superannuation-icon.svg?react";
import townhome from "src/images/illustrative-buttons/townhome-icon.svg?react";
import two_to_four_units from "src/images/illustrative-buttons/two-to-four-units-icon.svg?react";

export const imageMap: Record<string, any> = {
  alimony_and_child_support,
  below_credit,
  bonus_and_commission,
  canada_child_benefit_income,
  car_allowance,
  car_loan,
  cash_or_savings,
  checkmark,
  child_support,
  commission,
  condo,
  coop,
  credit_card,
  cryptocurrency,
  debt_paydown,
  employment_income,
  excellent_credit,
  farm,
  good_credit,
  government_benefits_income,
  investing,
  investment,
  investment_income,
  investment_property,
  leasehold,
  line_of_credit,
  medical_expenses,
  mobile,
  other_government_income,
  other,
  over_four_units,
  overdraft,
  overtime_and_shift_allowance,
  pension_income,
  personal_loan,
  primary_residence,
  property_purchase,
  purchase,
  refinancing,
  rental_income,
  savings_and_investments,
  secondary_residence,
  self_employment_income,
  shares_or_other_investments,
  single_family,
  student_loan,
  superannuation,
  townhome,
  two_to_four_units,
};
