import { toast } from "react-toastify";
import { Logger } from "src/log";
import { parseErrorBody, parseErrorMessage } from "src/utilities/errors";

import { FractionError } from "@fraction/shared";

const LOGGER = new Logger("utilities.generateAlert");

export default function generateAlert(
  error: FractionError = new FractionError("Unknown error. Please try again later.")
) {
  const message = generateAlertMessage(error);
  toast.error(message, { autoClose: false });
}

export function generateAlertMessage(
  error: FractionError = new FractionError("Unknown error. Please try again later."),
  defaultError: string = "Unknown error. Please try again later."
) {
  const errorBody = parseErrorBody(error);
  switch (errorBody?.name) {
    case "UserAuthenticationError":
      return "Unable to log you in. Please check your email and password for any errors.";
    case "UserNotRegistered":
      return "Your do not have a registered account with Fraction yet. Please reach out to contact@fraction.com if you believe this is an error.";
  }
  const messageText = parseErrorMessage(error) || defaultError;
  LOGGER.exception(error, messageText);
  return messageText;
}
