import { memo } from "react";
import { RegularText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export interface AnimatedLabelProps {
  textColor: string;
  borderColor: string;
  borderRadius?: number;
  children: string;
  animated: boolean;
}

const styles = createStyles({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    // the 18px is to account for the spacer. If we want to generalize this, we should move this
    // to a prop
    height: "calc(100% - 18px)",
    display: "flex",
    pointerEvents: "none",
  },
  notchedBorderAndLabelContainer: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
  },
  smallLabel: {
    whiteSpace: "nowrap",
    marginRight: 5,
    marginLeft: 5,
    marginTop: -8,
    fontSize: 12,
  },
  fullSizeLabel: {
    color: colors.PLACEHOLDER_TEXT,
    position: "absolute",
    whiteSpace: "nowrap",
    marginRight: 5,
    marginLeft: 24,
    fontSize: 16,
  },
  leftLine: {
    width: 40,
    height: "46px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "transparent",
    boxSizing: "border-box",
    transform: "scaleX(1.05)",
  },
  rightLine: {
    width: "100%",
    height: "46px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "transparent",
    boxSizing: "border-box",
  },
  animatedLeftLine: {
    transform: "scaleX(1.05) translateX(3px)",
  },
});

const VARIANTS = {
  animated: { scale: 0.75, y: -10, x: 2, opacity: 0 },
  unanimated: { scale: 1, y: 12 },
};

const AnimatedLabel = ({ textColor, borderColor, borderRadius, animated, children }: AnimatedLabelProps) => (
  <div css={styles.container}>
    <div css={styles.notchedBorderAndLabelContainer}>
      <div
        css={[
          styles.leftLine,
          { borderTopColor: borderColor, borderTopLeftRadius: (borderRadius || 0) + 1 },
          (borderRadius || 0) > 10 && animated && styles.animatedLeftLine,
        ]}
      />
      {animated && <RegularText style={[styles.smallLabel, { color: textColor }]}>{children}</RegularText>}
      <div
        css={[styles.rightLine, { borderTopColor: borderColor, borderTopRightRadius: borderRadius || 0 }]}
      />
    </div>
    <RegularText
      animate={animated ? "animated" : "unanimated"}
      variants={VARIANTS}
      transition={{ duration: 0.1 }}
      style={[styles.fullSizeLabel, { color: textColor }]}
    >
      {children}
    </RegularText>
  </div>
);

export default memo(AnimatedLabel);
