import { memo } from "react";
import { BoldText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

const styles = createStyles({
  navigation: {
    padding: 0,
    position: "relative",
    marginBottom: 16,
    display: "flex",
    alignItems: "center",
  },
  pageName: {
    cursor: "default",
    margin: "2px 0 3px",
    paddingLeft: 0,
  },
  text: {
    fontSize: 18,
    color: colors.palette.GREEN,
    textAlign: "left",
  },
});

const FlowLayoutHeader = ({ text }: { text: string }) => (
  <div css={styles.navigation}>
    <h3 css={styles.pageName}>
      <BoldText style={styles.text}>{text}</BoldText>
    </h3>
  </div>
);
export default memo(FlowLayoutHeader);
