import { ResponsiveScatterPlotCanvas } from "@nivo/scatterplot";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { Stats } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/Stats";
import { useLoanToValueData } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import usePortfolioDashStore from "src/apps/PortfolioDashboard/store";
import { useAuth } from "src/auth";
import { Skeleton } from "src/components";
import HelpCircle from "src/components/HelpCircle";
import { useTabs, useToggler } from "src/hooks";

import { enums, formatters, parsers } from "@fraction/shared";

import { ChartCard } from "../ChartCard";

const MARGIN = { top: 20, right: 140, bottom: 40, left: 80 };
const SCALE = { type: "linear", min: 0, max: "auto" } as const;
const X_SCALE = { type: "linear", min: "auto", max: "auto" } as const;

const AXIS_LEFT = {
  orient: "left",
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "LTV",
  legendPosition: "middle",
  legendOffset: -60,
  format: formatters.number.getPercentageFromDecimal,
} as const;
const LEGENDS = [
  {
    anchor: "bottom-right",
    direction: "column",
    justify: false,
    translateX: 130,
    translateY: 0,
    itemWidth: 100,
    itemHeight: 12,
    itemsSpacing: 5,
    itemDirection: "left-to-right",
    symbolSize: 12,
    symbolShape: "circle",
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1,
        },
      },
    ],
  } as const,
];

const Info = ({ className }: { className?: string }) => (
  <HelpCircle className={className}>
    <p>
      Loan to value (LTV) at origination is determined by <b>funded amount / home value</b> as determined by
      appraisal.
    </p>
    <br />
    <p>
      The current LTV is determined by taking <b>current balance / current home value</b>.
    </p>
    <br />
    <p>
      The current home value is determined by taking the automated valuation estimate from time of funding and
      finding the <b>% change</b> from that to the most recent automated valuation estimate.
    </p>
    <br />
    <p>
      We then multiplying the original appraised value by that % change. The formula to determine current home
      value is:
      <br />
      <b>
        (current automated valuation estimate / original automated valuation estimate) * original appraised
        value
      </b>
    </p>
  </HelpCircle>
);

const Tooltip = ({
  node,
}: {
  node: {
    serieId: string;
    formattedX: string;
    formattedY: string;
    data: {
      creditScore: number;
      fundedAmount: number;
      currentBalance: number;
    };
  };
}) => {
  return (
    <div className="shadow p-2 bg-white rounded">
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Credit score: </b>
        {node.data.creditScore}
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Funded amount: </b>
        {formatters.number.getCurrencyFromNumber(node.data.fundedAmount)}
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Current balance: </b>
        {formatters.number.getCurrencyFromNumber(node.data.currentBalance)}
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>LTV: </b>
        {node.formattedY}
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Location: </b>
        {node.serieId}
      </p>
    </div>
  );
};

export function LoanToValue() {
  const { Tabs, activeTab } = useTabs({
    options: [
      { value: "atOrigination", label: "At origination" },
      { value: "current", label: "Current" },
    ],
    defaultValue: "atOrigination",
  });
  const { Tabs: DimensionTabs, activeTab: activeDimensionTab } = useTabs({
    options: [
      { value: "loanSize", label: "Loan size" },
      { value: "creditScore", label: "Credit score" },
    ],
    defaultValue: "loanSize",
  });
  const { Toggle, on: showStats } = useToggler({
    id: "ltvStatsToggle",
    label: "Show stats",
    defaultValue: false,
  });

  const { data, isLoading } = useLoanToValueData();

  const mapped = useMemo(() => {
    const groups = _.groupBy(data?.data, "administrativeArea");
    return Object.entries(groups).map(
      ([administrativeArea, values]: [string, parsers.portfolio.LoanToValueData[]]) => ({
        id: administrativeArea,
        data: values.map((value) => ({
          id: value.applicationId,
          fundedAmount: value.fundedAmount,
          currentBalance: value.currentBalance,
          creditScore: value.creditScore,
          x:
            activeDimensionTab === "loanSize"
              ? activeTab === "atOrigination"
                ? value.fundedAmount
                : value.currentBalance
              : value.creditScore,
          y: activeTab === "atOrigination" ? value.originationLtv : value.currentLtv,
        })),
      })
    );

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, activeTab, activeDimensionTab]);

  const openNavDrawer = usePortfolioDashStore((state) => state.openNavDrawer);
  const setSelectedApp = usePortfolioDashStore((state) => state.setSelectedApp);

  const { user } = useAuth();
  const canViewDetailedData = user?.permissions?.includes(enums.Permission.SERVICING_DETAILED_DATA);
  const handleClick = useCallback(
    (point: any, event: any) => {
      event.stopPropagation();

      if (canViewDetailedData) {
        setSelectedApp(point?.data?.id);
        openNavDrawer();
      }
    },
    [setSelectedApp, openNavDrawer, canViewDetailedData]
  );

  if (isLoading) {
    return (
      <div className="col-span-3">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  return (
    <ChartCard
      isEmpty={mapped.length === 0}
      cardClassName="col-span-3"
      className="min-h-[300px]"
      header="Loan to value"
      headerComponents={
        <>
          <Info className="mr-auto ml-2" />
          <div className="flex flex-wrap flex-row gap-2">
            <DimensionTabs />
            <Tabs />
            <Toggle />
          </div>
        </>
      }
    >
      {showStats && activeTab && data?.stats?.[activeTab] ? (
        <Stats formatter={formatters.number.getPercentageFromDecimal} data={data?.stats?.[activeTab]} />
      ) : (
        <ResponsiveScatterPlotCanvas
          data={mapped}
          margin={MARGIN}
          xScale={X_SCALE}
          onClick={handleClick}
          // @ts-ignore
          xFormat={activeDimensionTab === "loanSize" ? formatters.number.getCurrencyFromNumber : undefined}
          yScale={SCALE}
          // @ts-ignore
          yFormat={formatters.number.getPercentageFromDecimal}
          blendMode="multiply"
          axisTop={null}
          axisRight={null}
          // @ts-ignore
          tooltip={Tooltip}
          axisBottom={{
            // @ts-ignore
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: activeDimensionTab === "loanSize" ? "Loan size" : "Credit score",
            legendPosition: "middle",
            legendOffset: 35,
            format: activeDimensionTab === "loanSize" ? formatters.number.getCurrencyWords : undefined,
          }}
          axisLeft={AXIS_LEFT}
          // @ts-ignore
          legends={LEGENDS}
        />
      )}
    </ChartCard>
  );
}
