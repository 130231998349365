import EmployeeCard from "src/components/EmployeeCard";
import { useFractionContacts } from "src/hooks/useFractionContacts";
import { cn } from "src/utilities/shadcnUtils";
import Skeleton from "./Skeleton";

export const BdmContactCard = ({
  className,
  textContainerClassName,
  cardClassName,
}: { className?: string; textContainerClassName?: string; cardClassName?: string }) => {
  const { data: contacts, isLoading } = useFractionContacts();

  if (isLoading) {
    return <Skeleton className="w-[600px] h-40" />;
  }

  if (!contacts?.bdm) {
    return null;
  }

  return (
    <div
      className={cn(
        "bg-green-50 w-fit p-4 rounded-md flex flex-row items-center gap-4 flex-wrap max-w-[100svw]",
        className
      )}
    >
      <div className={cn("lg:w-48", textContainerClassName)}>
        <p className="font-serif-deck">Have questions?</p>
        <p className="text-sm text-gray">Reach out to your BDM to learn more.</p>
      </div>
      <EmployeeCard className={cardClassName} contact={contacts.bdm} />
    </div>
  );
};
