import { getDaysInMonth, getMonth, getYear, setMonth, setYear } from "date-fns";
import { Ref, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import DropdownSelector from "src/components/DropdownSelector";

import { range } from "@fraction/shared";

export interface DatePickerProps {
  value?: Date;
  // This is only called when all the requested types have been set
  onChange?: (val: Date) => void;
  // note - if we ask for day, we have to ask for month
  types: Array<"year" | "month" | "day">;
  labels?: { year?: string; month?: string; day?: string };
  focusRef?: Ref<HTMLInputElement>;
  yearRange?: YearRange;
  placeholders?: { year?: string; month?: string; day?: string };
}

const MONTH_OPTIONS = [
  { label: "January", value: 0 },
  { label: "February", value: 1 },
  { label: "March", value: 2 },
  { label: "April", value: 3 },
  { label: "May", value: 4 },
  { label: "June", value: 5 },
  { label: "July", value: 6 },
  { label: "August", value: 7 },
  { label: "September", value: 8 },
  { label: "October", value: 9 },
  { label: "November", value: 10 },
  { label: "December", value: 11 },
];

interface DatesSet {
  year: boolean;
  month: boolean;
  day: boolean;
}

interface YearRange {
  max?: number;
  min?: number;
}

const CURRENT_YEAR = getYear(new Date());

const DatePicker = ({
  value,
  onChange,
  types,
  focusRef,
  yearRange,
  placeholders,
  labels,
}: DatePickerProps) => {
  const [currentSet, setCurrentSet] = useState<Date | undefined>(undefined);
  const [datesSet, setDatesSet] = useState<DatesSet>({ month: false, day: false, year: false });

  const maxYear = yearRange?.max || CURRENT_YEAR;
  const minYear = yearRange?.min || CURRENT_YEAR - 115;

  const yearOptions = useMemo(
    () =>
      range(maxYear + 1 - minYear)
        .map((year) => {
          const v = year + minYear;
          return { label: v.toString(), value: v };
        })
        .reverse(),
    [maxYear, minYear]
  );

  const yearToShow = datesSet.year ? currentSet : value;
  const monthToShow = datesSet.month ? currentSet : value;
  const dayToShow = datesSet.day ? currentSet : value;

  // this can't be a constant because it's based on the chosen month
  const dayOptions = useMemo(
    () =>
      range(getDaysInMonth(monthToShow || new Date())).map((day) => ({
        label: (day + 1).toString(),
        value: day + 1,
      })),
    [monthToShow]
  );

  const lastSet = useRef<undefined | number>(undefined);
  useEffect(() => {
    // only call onChange if all types required are satisfied
    if (currentSet && types.every((type) => datesSet[type]) && lastSet.current !== currentSet?.getTime()) {
      lastSet.current = currentSet?.getTime();
      onChange?.(currentSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSet, datesSet, onChange]);

  const handleOnChangeYear = useCallback(
    (option: number) => {
      setCurrentSet(setYear(currentSet || new Date(), option));
      setDatesSet((prev) => ({ ...prev, year: true }));
    },
    [setDatesSet, setCurrentSet, currentSet]
  );

  const handleOnChangeMonth = useCallback(
    (option: number) => {
      setCurrentSet(setMonth(currentSet || new Date(), option));
      setDatesSet((prev) => ({ ...prev, month: true }));
    },
    [setDatesSet, setCurrentSet, currentSet]
  );

  const handleOnChangeDay = useCallback(
    (option: number) => {
      setCurrentSet(new Date((currentSet || new Date()).setDate(option)));
      setDatesSet((prev) => ({ ...prev, day: true }));
    },
    [setDatesSet, setCurrentSet, currentSet]
  );

  const toHideYear = !types.includes("year");
  const toHideMonth = !types.includes("month") || (types.includes("year") && !datesSet.year);
  const toHideDay =
    !types.includes("day") ||
    (types.includes("month") && !datesSet.month) ||
    (types.includes("year") && !datesSet.year);

  return (
    <>
      {!toHideYear && (
        <DropdownSelector
          focusRef={!toHideYear ? focusRef : undefined}
          placeholder={placeholders?.year || "1960"}
          label={labels?.year || "Year"}
          options={yearOptions}
          value={yearToShow ? getYear(yearToShow) : undefined}
          onChange={handleOnChangeYear}
        />
      )}
      {!toHideMonth && (
        <DropdownSelector
          focusRef={toHideYear ? focusRef : undefined}
          placeholder={placeholders?.month || "January"}
          label={labels?.year || "Month"}
          options={MONTH_OPTIONS}
          value={monthToShow ? getMonth(monthToShow) : undefined}
          onChange={handleOnChangeMonth}
        />
      )}
      {!toHideDay && (
        <DropdownSelector
          placeholder={placeholders?.day || "10"}
          label={labels?.year || "Day"}
          options={dayOptions}
          value={dayToShow?.getDate()}
          onChange={handleOnChangeDay}
        />
      )}
    </>
  );
};

export default memo(DatePicker);
