import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import FinePrint from "src/components/FinePrint";

export interface IncomeSourcesDisclosureProps {
  style?: SerializedStyles;
}

const IncomeSourcesDisclosure = ({ style }: IncomeSourcesDisclosureProps) => (
  <FinePrint style={style}>
    Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it
    considered as a basis for repaying this loan.
  </FinePrint>
);

export default memo(IncomeSourcesDisclosure);
