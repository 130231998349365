import { FormEvent, useCallback, useState } from "react";
import { ModalInnards } from "src/components/Modal";
import ModalBox from "src/components/ModalBox";
import { TextInput } from "src/components/index";
import useEffectOnce from "src/hooks/useEffectOnce";
import { useEmailVerify } from "src/hooks/useEmailVerify";
import { usePhoneVerify } from "src/hooks/usePhoneVerify";

export type ChannelVerificationState = "enter-channel" | "verify-channel";
export function ChannelVerificationModal({
  open,
  onClose,
  type,
  startState = "enter-channel",
  defaultChannel,
}: {
  open?: boolean;
  onClose?: () => void;
  type: "email" | "phone";
  startState?: ChannelVerificationState;
  defaultChannel?: string;
}) {
  const [state, setState] = useState<ChannelVerificationState>(startState);
  const {
    checkVerification,
    startVerification,
    channel,
    setChannel,
    code,
    setCode,
    invalidMessage,
    acceptable,
    countdown,
    stopCountdown,
  } =
    type === "email"
      ? useEmailVerify({ channel: defaultChannel, onSuccess: onClose })
      : usePhoneVerify({ channel: defaultChannel, onSuccess: onClose });

  const closeModal = useCallback(() => {
    stopCountdown?.();
    onClose?.();
  }, [onClose, stopCountdown]);

  const handleNext = useCallback((evt?: FormEvent<HTMLFormElement> | string) => {
    if (typeof evt === "object") {
      evt?.preventDefault?.();
    }

    (async () => {
      const { ok } = await startVerification.mutateAsync(undefined);
      if (ok) {
        setState("verify-channel");
      }
    })();
  }, []);

  const handleVerify = useCallback((evt?: FormEvent<HTMLFormElement> | string) => {
    if (typeof evt === "object") {
      evt?.preventDefault?.();
    }

    checkVerification.mutate(undefined);
  }, []);

  useEffectOnce(
    () => {
      if (startState !== "verify-channel") {
        return;
      }
      console.log("Running startverification");
      startVerification.mutateAsync({ to: channel || defaultChannel, verifyExisting: true });
    },
    [startState],
    startState !== "verify-channel"
  );

  return (
    <ModalBox open={open} onClose={closeModal} closeButton>
      <ModalInnards
        actions={[
          {
            text: "Close",
            action: closeModal,
          },
          state === "enter-channel"
            ? {
                text: "Next",
                action: handleNext,
                close: false,
                disabled: !acceptable,
                loading: startVerification.isPending,
              }
            : {
                text: "Verify",
                action: () => checkVerification.mutateAsync(undefined),
                close: false,
                disabled: code?.length !== 6,
                loading: checkVerification.isPending,
              },
        ]}
        message={
          state === "enter-channel" ? (
            <p className="font-semibold text-lg mb-2">Enter the {type} you would like to add</p>
          ) : (
            <p className="font-semibold text-lg mb-2">We've sent you a code to verify your {type}.</p>
          )
        }
      >
        {state === "enter-channel" ? (
          <form onSubmit={handleNext}>
            {type === "phone" ? (
              <TextInput
                error={invalidMessage}
                variant="v2"
                required={false}
                mask={["(999) 999-9999"]}
                className="mt-4"
                name="phone"
                label="Phone number"
                inputMode="tel"
                placeholder="(778) 123-4567"
                defaultValue={channel || ""}
                onChange={setChannel}
                type="tel-national"
                autoComplete="tel-national"
                id="phone"
              />
            ) : (
              <TextInput
                error={invalidMessage}
                required={false}
                variant="v2"
                className="mt-4"
                name="email"
                label="Email"
                defaultValue={channel || ""}
                onChange={setChannel}
                type="email"
                autoComplete="email"
                id="email"
              />
            )}
          </form>
        ) : null}
        {state === "verify-channel" ? (
          <form onSubmit={handleVerify}>
            <TextInput
              id="codeValidation"
              variant="v2"
              required={false}
              error={invalidMessage}
              className="mt-4"
              mask={["999999"]}
              name="codeValidation"
              label="Enter the code we sent you"
              inputMode="numeric"
              onChange={setCode}
              defaultValue={code}
              autoComplete="one-time-code"
            />
          </form>
        ) : null}

        {state === "verify-channel" && !startVerification.isPending && !invalidMessage ? (
          <p className="text-black text-sm font-medium">
            {startVerification.isPending
              ? "Restarting verification..."
              : `Resending code in ${Math.max(countdown || 0, 0)}...`}
          </p>
        ) : null}
      </ModalInnards>
    </ModalBox>
  );
}
