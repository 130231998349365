import { entities, formatters, selectors, utilities } from "@fraction/shared";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  parseISO,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import _ from "lodash";
import { ArrowUpRightFromSquare, ChevronLeft, ChevronRight, ListFilterIcon } from "lucide-react";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { KeyValue } from "src/components/KeyValue";
import { ModifiableKeyValue } from "src/components/ModifiableKeyValue";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { MultiSelect } from "src/components/ui/multi-select";
import { useInfiniteApplicationsQuery } from "src/hooks/useApplication";
import { useCachedState } from "src/hooks/useCache";
import { useInputState } from "src/hooks/useInputState";
import { cn } from "src/utilities/shadcnUtils";

const get = (obj: any, path: string | ((item: any) => any)) =>
  typeof path === "string" ? _.get(obj, path) : path(obj);

const NOTEWORTHY_DATES = [
  {
    filter: "closeDate",
    group: "close",
    Component: ({ children }: { children?: ReactNode }) => (
      <div className="bg-green-500 text-[10px] rounded-sm text-white overflow-x-hidden text-ellipsis line-clamp-1 px-2">
        Closing{children}
      </div>
    ),
  },
  {
    filter: "advanceDate",
    group: "close",
    Component: ({ children }: { children?: ReactNode }) => (
      <div className="bg-yellow-500 text-[10px] rounded-sm text-white overflow-x-hidden text-ellipsis line-clamp-1 px-2">
        Advance{children}
      </div>
    ),
  },
  {
    filter: "estimatedCloseDate",
    group: "close",
    Component: ({ children }: { children?: ReactNode }) => (
      <div className="bg-yellow-500 text-[10px] rounded-sm text-white overflow-x-hidden text-ellipsis line-clamp-1 px-2">
        Est. Close{children}
      </div>
    ),
  },
  {
    filter: "requestedCloseDate",
    group: "close",
    Component: ({ children }: { children?: ReactNode }) => (
      <div className="bg-blue-500 text-[10px] rounded-sm text-white overflow-x-hidden text-ellipsis line-clamp-1 px-2">
        Req. Close{children}
      </div>
    ),
  },
  {
    filter: "appraisalAppointmentDate",
    Component: ({ children }: { children?: ReactNode }) => (
      <div className="bg-purple-500 text-[10px] rounded-sm text-white overflow-x-hidden text-ellipsis line-clamp-1 px-2">
        Appraisal{children}
      </div>
    ),
  },
  {
    filter: "inspectionAppointmentDate",
    Component: ({ children }: { children?: ReactNode }) => (
      <div className="bg-purple-500 text-[10px] rounded-sm text-white overflow-x-hidden text-ellipsis line-clamp-1 px-2">
        Inspection{children}
      </div>
    ),
  },
  {
    filter: "targetILRAppointmentDate",
    Component: ({ children }: { children?: ReactNode }) => (
      <div className="bg-green-300 text-[10px] rounded-sm text-white overflow-x-hidden text-ellipsis line-clamp-1 px-2">
        ILR Appt.{children}
      </div>
    ),
  },
  {
    label: "Rates expiring",
    value: "ratesLockedInAt",
    filter: (app: entities.ApplicationT) => {
      if (!app?.ratesLockedInAt) {
        return;
      }
      return addDays(app.ratesLockedInAt, 60);
    },
    Component: ({ children }: { children?: ReactNode }) => (
      <div className="bg-red-500 text-[10px] rounded-sm text-white overflow-x-hidden text-ellipsis line-clamp-1 px-2">
        Rates Exp.{children}
      </div>
    ),
  },
  {
    label: "Appraisal expiring",
    value: "appraisalExpiring",
    filter: (app: entities.ApplicationT) => {
      const appraisal = selectors.application.selectAppraisal(app)?.date;
      if (!appraisal) {
        return;
      }
      return addDays(appraisal, 60);
    },
    Component: ({ children }: { children?: ReactNode }) => (
      <div className="bg-red-600 text-[10px] rounded-sm text-white overflow-x-hidden text-ellipsis line-clamp-1 px-2">
        Appraisal Exp.{children}
      </div>
    ),
  },
];

const filterEventsForDay = (app: entities.ApplicationT, day: Date, q?: string) => {
  if (q?.length && !selectors.application.containsSearchTerm(app, q)) {
    return false;
  }
  const noteworthyDates = _.uniqBy(
    NOTEWORTHY_DATES.filter((d) => get(app, d.filter)),
    (x) => x.group || x.filter
  );
  return noteworthyDates.some((noteworthyDate) =>
    isSameDay(day, new Date(noteworthyDate ? get(app, noteworthyDate.filter) : ""))
  );
};

const getLabel = (nd: any): string =>
  nd.label || (typeof nd.filter === "string" ? _.startCase(nd.filter) : "UNKNOWN");
const getValue = (nd: any): string => nd.value || (typeof nd.filter === "string" ? nd.filter : "UNKNOWN");
const DEFAULT_FILTERS = NOTEWORTHY_DATES.map((nd) => ({ label: getLabel(nd), value: getValue(nd) }));

export function CalendarPage({
  accountType,
}: { accountType?: "employee" | "broker" | "applicant" | "conveyancer" }) {
  const [filters, setFilters] = useCachedState(DEFAULT_FILTERS, "calendarFilters");
  const [q, setQ] = useInputState("");

  const { data: apps } = useInfiniteApplicationsQuery({
    status: "active",
    accountType,
    initialRefetch: false,
  });

  const getEventsForDay = useCallback(
    (day: Date) => {
      const components = apps
        ?.filter((app) => filterEventsForDay(app, day, q))
        ?.map((app) => {
          const noteworthyDates = _.uniqBy(
            NOTEWORTHY_DATES.filter((nd) => filters?.find((f) => f.value === getValue(nd))).filter((d) =>
              isSameDay(get(app, d.filter), day)
            ),
            (x) => x.group || x.filter
          );
          return noteworthyDates.map((noteworthyDate) => {
            return (
              <noteworthyDate.Component>
                {" "}
                - {formatters.application.applicantLastNames(app)}
              </noteworthyDate.Component>
            );
          });
        });

      return <div className="flex w-full text-start flex-col gap-0.5">{components}</div>;
    },
    [apps, q]
  );

  const [selectedDay, setSelectedDay] = useState(new Date());
  const onClickDay = useCallback((day: Date) => {
    setSelectedDay(day);
  }, []);

  const handleCloseDateChange = useCallback((dateStr: string) => {
    const date = parseISO(dateStr);

    const { date: closestPreviousTuesdayOrThursday, day } =
      utilities.bankHolidays.findClosestPreviousTuesdayOrThursday(date);

    if (date && closestPreviousTuesdayOrThursday && !isSameDay(date, closestPreviousTuesdayOrThursday)) {
      toast.info(
        `Fraction only funds on Tuesdays and Thursdays that are not bank holidays. Setting the requested close date to the previous ${day}.`
      );
    }

    return formatters.date.iso8601(closestPreviousTuesdayOrThursday);
  }, []);

  return (
    <div className="h-[82svh] w-full flex flex-row overscroll-contain">
      <Calendar
        onClickDay={onClickDay}
        selectedDay={selectedDay}
        getEventsForDay={getEventsForDay}
        className="h-full"
        headerFilter={
          <div className="flex flex-row gap-2">
            <MultiSelect
              buttonClassName="items-start rounded-full bg-gray-300 text-xs"
              prefixIcon={<ListFilterIcon className="h-4 text-gray mr-1" />}
              onChange={setFilters}
              options={DEFAULT_FILTERS}
              values={filters}
              defaultValues={filters}
            />
            <Input className="rounded-full" placeholder="🔎 Search for app" onChange={setQ} value={q} />
          </div>
        }
      />
      <div className="flex flex-col h-full min-w-[350px] overflow-y-scroll overscroll-contain gap-2">
        <h2 className="font-serif-deck text-lg">Events for {formatters.date.formatDate(selectedDay)}</h2>
        {apps
          ?.filter((app) => filterEventsForDay(app, selectedDay))
          ?.map((app) => (
            <div key={app.id} className="flex flex-col gap-1 bg-gray-300 odd:bg-gray-200 rounded p-2">
              <div className="flex flex-row items-center justify-between mb-1">
                <p className="text-normal font-semibold">{formatters.application.applicantNames(app)}</p>
                <Link target="_blank" to={`/app/${app?.id}`}>
                  <Badge>
                    Go to app <ArrowUpRightFromSquare className="ml-2" height={12} width={12} />
                  </Badge>
                </Link>
              </div>
              {NOTEWORTHY_DATES.filter((d) => isSameDay(get(app, d.filter), selectedDay)).map(
                (noteworthyDate) => {
                  if (typeof noteworthyDate.filter !== "string") {
                    return (
                      <KeyValue
                        value={formatters.date.formatDate(get(app, noteworthyDate.filter))}
                        label={noteworthyDate.label}
                      />
                    );
                  }

                  return (
                    <div>
                      <ModifiableKeyValue
                        className="p-0"
                        appId={app?.id}
                        type="date"
                        path={noteworthyDate.filter}
                        label={_.startCase(noteworthyDate.filter)}
                        formatValue={formatters.date.iso8601}
                        mutateValueBeforeUpdate={handleCloseDateChange}
                      />
                    </div>
                  );
                }
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

const Header = ({
  currentMonth,
  goToPreviousMonth,
  goToNextMonth,
  resetToToday,
  children,
}: {
  currentMonth: Date;
  goToPreviousMonth: () => void;
  goToNextMonth: () => void;
  resetToToday: () => void;
  children?: ReactNode;
}) => {
  return (
    <div className="flex justify-between items-center mb-4">
      <div className="flex flex-row gap-4">
        <Button variant="outline" onClick={resetToToday}>
          Today
        </Button>
        <button onClick={goToPreviousMonth}>
          <ChevronLeft className="w-6 h-6 text-gray" />
        </button>
        <button onClick={goToNextMonth}>
          <ChevronRight className="w-6 h-6 text-gray" />
        </button>
      </div>
      <h2 className="text-xl font-bold">{format(currentMonth, "MMMM yyyy")}</h2>
      {children}
    </div>
  );
};

const dateFormatDayOfWeek = "EEE"; // 'EEE' for abbreviated day of week

const DaysOfWeek = ({ currentMonth }: { currentMonth: Date }) => {
  const days = useMemo(() => {
    const days = [];
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 }); // Sunday as first day

    for (let i = 0; i < 7; i++) {
      days.push(
        <div key={i} className="text-center text-xs uppercase font-medium">
          {format(addDays(startDate, i), dateFormatDayOfWeek)}
        </div>
      );
    }
    return days;
  }, [currentMonth]);

  return <div className="grid grid-cols-7 mb-1">{days}</div>;
};

const dateFormatDay = "d";
const Cells = ({
  getEventsForDay,
  currentMonth,
  onClickDay,
  selectedDay,
}: {
  selectedDay?: Date;
  getEventsForDay?: (day: Date) => ReactNode;
  currentMonth: Date;
  onClickDay?: (day: Date) => void;
}) => {
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart, { weekStartsOn: 0 });
  const endDate = endOfWeek(monthEnd, { weekStartsOn: 0 });

  const rows = useMemo(() => {
    const rows = [];

    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = format(day, dateFormatDay);
        const clonedDay = new Date(day);

        days.push(
          <button
            className={`hover:bg-green-50 overflow-y-scroll overscroll-contain flex flex-col p-1 h-full border-gray-400 border-[0.25px] relative ${
              !isSameMonth(clonedDay, monthStart) ? "bg-gray-100" : ""
            }`}
            key={clonedDay.toString()}
            onClick={() => onClickDay?.(clonedDay)}
          >
            <div className="w-full flex items-center justify-center mb-1">
              <div
                className={`w-10 h-6 min-w-10 min-h-6 flex text-center text-[11px] items-center justify-center ${
                  isSameDay(clonedDay, new Date())
                    ? "bg-green text-white rounded-full"
                    : selectedDay && isSameDay(selectedDay, clonedDay)
                    ? "bg-purple text-white rounded-full"
                    : ""
                }`}
              >
                {formattedDate}
              </div>
            </div>
            {getEventsForDay?.(clonedDay)}
          </button>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="grid grid-cols-7 h-full" key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return rows;
  }, [getEventsForDay, onClickDay, currentMonth, selectedDay]);

  return <div className="grid grid-rows-5 max-h-full h-full">{rows}</div>;
};

export function Calendar({
  className,
  getEventsForDay,
  onClickDay,
  selectedDay,
  headerFilter,
}: {
  selectedDay?: Date;
  className?: string;
  getEventsForDay?: (day: Date) => ReactNode;
  onClickDay?: (day: Date) => void;
  headerFilter?: ReactNode;
}) {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const goToNextMonth = useCallback(() => {
    setCurrentMonth((cur) => addMonths(cur, 1));
  }, []);

  const goToPreviousMonth = useCallback(() => {
    setCurrentMonth((cur) => subMonths(cur, 1));
  }, []);

  const resetToToday = useCallback(() => {
    setCurrentMonth(new Date());
  }, []);

  return (
    <div className={cn("p-4 flex flex-col h-full w-full", className)}>
      <Header
        goToNextMonth={goToNextMonth}
        goToPreviousMonth={goToPreviousMonth}
        resetToToday={resetToToday}
        currentMonth={currentMonth}
      >
        {headerFilter}
      </Header>
      <DaysOfWeek currentMonth={currentMonth} />
      <Cells
        selectedDay={selectedDay}
        onClickDay={onClickDay}
        currentMonth={currentMonth}
        getEventsForDay={getEventsForDay}
      />
    </div>
  );
}
