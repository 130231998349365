import { Dispatch, SetStateAction, useCallback, useState } from "react";

export function useInputState(
  initialValue: string = ""
): [string, (e: React.ChangeEvent<HTMLInputElement>) => void, Dispatch<SetStateAction<string>>] {
  const [value, setValue] = useState<string>(initialValue);
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value as string);
  }, []);
  return [value, onChange, setValue];
}
