import { SerializedStyles } from "@emotion/react";
import Color from "color";
import randomcolor from "randomcolor";
import { ReactNode } from "react";
import { SemiBoldText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";
import { cn } from "src/utilities/shadcnUtils";

export enum Variant {
  BASE = "base",
  GREEN = "green",
  RED = "red",
  YELLOW = "yellow",
}

const styles = createStyles({
  container: {
    display: "inline-flex",
    justifyContent: "center",
  },
  sm: {
    padding: "1px 12px",
  },
  md: {
    padding: "5px 16px",
  },
  smText: {
    // @ts-ignore
    textWrap: "nowrap",
    fontSize: 10,
  },
  mdText: {
    // @ts-ignore
    textWrap: "nowrap",
    fontSize: 10,
  },
  base: {
    backgroundColor: colors.palette.GREY_300,
    color: colors.palette.GREY_700,
  },
  green: {
    backgroundColor: colors.palette.GREEN_300,
    color: colors.palette.GREEN_700,
  },
  red: {
    backgroundColor: colors.palette.RED_300,
    color: colors.palette.RED_700,
  },
  yellow: {
    backgroundColor: colors.palette.YELLOW_100,
    color: colors.palette.YELLOW_600,
  },
  baseText: {
    color: colors.palette.GREY_700,
  },
  greenText: {
    color: colors.palette.GREEN_700,
  },
  redText: {
    color: colors.palette.RED_700,
  },
  yellowText: {
    color: colors.palette.YELLOW_600,
  },
});

const Chip = ({
  children,
  variant,
  style,
  value,
  size = "md",
  className,
}: {
  children: ReactNode;
  variant?: Variant | `${Variant}`;
  style?: SerializedStyles | (SerializedStyles | undefined)[];
  value?: string;
  size?: "sm" | "md";
  className?: string;
}) => {
  const backgroundColor =
    !variant && typeof children === "string"
      ? randomcolor({
          seed: children,
          luminosity: "dark",
        })
      : undefined;

  // if a variant isn't specified, automatically generate a deterministic random color from the children
  const randomStyles = backgroundColor ? { backgroundColor, color: "white" } : {};

  return (
    <div
      className={cn("h-fit rounded-full", className)}
      css={[styles.container, styles[variant || Variant.BASE], styles[size], style, randomStyles]}
    >
      <SemiBoldText
        inline
        value={value}
        style={[
          styles[`${size}Text`],
          styles[`${variant || Variant.BASE}Text`],
          backgroundColor
            ? Color(backgroundColor).isDark()
              ? { color: "white" }
              : { color: "black" }
            : undefined,
        ]}
      >
        {children}
      </SemiBoldText>
    </div>
  );
};

export default Chip;
