import { MutableRefObject, useEffect, useRef, useState } from "react";

export function useIsScrolling(ref: MutableRefObject<HTMLElement | null>, delay = 200) {
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const handleScroll = () => {
      // Set isScrolling to true when scroll event is triggered
      setIsScrolling(true);

      // Clear any existing timeout to avoid triggering the end too early
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      // Set a timeout to detect when scrolling has stopped
      scrollTimeout.current = setTimeout(() => {
        setIsScrolling(false);
      }, delay);
    };

    const element = ref.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, [ref, delay]);

  return isScrolling;
}
