import { MediumText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors, enums } from "@fraction/shared";

const styles = createStyles({
  container: {
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5.5,
    paddingBottom: 5.5,
    width: "fit-content",
  },
  [enums.ApplicationTaskStatus.COMPLETE]: {
    backgroundColor: colors.SUCCESS,
    borderColor: colors.SUCCESS,
    color: "white",
  },
  [enums.ApplicationTaskStatus.TODO]: {
    backgroundColor: "transparent",
    borderColor: colors.IN_PROGRESS,
    color: colors.IN_PROGRESS,
  },
  [enums.ApplicationTaskStatus.IN_PROGRESS]: {
    backgroundColor: "transparent",
    borderColor: colors.SUCCESS,
    color: colors.SUCCESS,
  },
  [enums.ApplicationTaskStatus.NOT_APPLICABLE]: {
    backgroundColor: "transparent",
    borderColor: colors.DISABLED,
    color: colors.DISABLED,
  },
  [enums.ApplicationTaskStatus.FAILED]: {
    backgroundColor: colors.ERROR,
    borderColor: colors.ERROR,
    color: "white",
  },
  [enums.ApplicationTaskStatus.TO_FIX]: {
    backgroundColor: "transparent",
    borderColor: colors.ERROR,
    color: colors.ERROR,
  },
  [enums.ApplicationTaskStatus.DEPRECATED]: {
    backgroundColor: "transparent",
    borderColor: colors.ERROR,
    color: colors.ERROR,
  },
  text: {
    fontSize: 10,
    textAlign: "center",
  },
});

export const TASK_STATUS_CONVERTER = {
  [enums.ApplicationTaskStatus.COMPLETE]: "Complete",
  [enums.ApplicationTaskStatus.TODO]: "To do",
  [enums.ApplicationTaskStatus.IN_PROGRESS]: "In progress",
  [enums.ApplicationTaskStatus.NOT_APPLICABLE]: "N/A",
  [enums.ApplicationTaskStatus.FAILED]: "Failed",
  [enums.ApplicationTaskStatus.TO_FIX]: "To fix",
  [enums.ApplicationTaskStatus.DEPRECATED]: "",
};

const TaskStatus = ({ status }: { status: enums.ApplicationTaskStatus }) => (
  <div css={[styles.container, styles[status]]}>
    <MediumText style={[styles.text, styles[status]]}>{TASK_STATUS_CONVERTER[status]}</MediumText>
  </div>
);

export default TaskStatus;
