import { SerializedStyles } from "@emotion/react";
import { memo } from "react";
import FinePrint from "src/components/FinePrint";

export interface WaCivilUnionDisclosureProps {
  style?: SerializedStyles;
}

const WaCivilUnionDisclosure = ({ style }: WaCivilUnionDisclosureProps) => (
  <FinePrint style={style}>
    Persons who enter into a Civil Union or Domestic Partnership may have all the same rights, obligations,
    and responsibilities of spouses in a marriage. As a result, Lender may require that Civil Union partners
    or Domestic Partners sign the security instrument to ensure enforceability of the Lender's lien. Also,
    non-borrowing spouses may be required to sign the security instrument to ensure the enforceability of the
    Lender's lien as well. You should consult an attorney for specific legal advice regarding your property
    rights and benefits, protections, and responsibilities.
  </FinePrint>
);

export default memo(WaCivilUnionDisclosure);
