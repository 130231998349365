import { ResponsiveLineCanvas } from "@nivo/line";
import { OverTimeTooltip } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/components/OverTimeTooltip";
import {
  GroupingOverTimeTabs,
  useGroupingTabs,
  useMemoizedGroupingOverTime,
  usePropertyValueOverTimeData,
} from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";

import { formatters, parsers, selectors } from "@fraction/shared";
import { useToggler } from "src/hooks";

import { useCallback } from "react";
import { ChartCard } from "../ChartCard";

const MARGIN = { top: 20, right: 140, bottom: 50, left: 80 };
const SCALE = { type: "linear", min: 0.05, max: "auto" } as const;
const AXIS_BOTTOM = {
  orient: "bottom",
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "Month",
  legendPosition: "middle",
  legendOffset: 40,
  format: (d: any) => formatters.date.iso8601(new Date(d), "month").slice(2).replace("-", "/"),
} as const;

const AXIS_LEFT = (showAverageValue?: boolean) =>
  ({
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: showAverageValue ? "Average Home Value" : "Total Home Value of Portfolio",
    legendPosition: "middle",
    legendOffset: -65,
    format: (d: number) => formatters.number.getCurrencyWords(d, { short: true }),
  }) as const;

const LEGENDS = [
  {
    anchor: "bottom-right",
    direction: "column",
    justify: false,
    translateX: 130,
    translateY: 0,
    itemWidth: 100,
    itemHeight: 12,
    itemsSpacing: 5,
    itemDirection: "left-to-right",
    symbolSize: 12,
    symbolShape: "circle",
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1,
        },
      },
    ],
  } as const,
];

export function PropertyValueOverTime() {
  const { Tabs, activeTab } = useGroupingTabs();
  const { Toggle, on: showAverage } = useToggler({
    id: "showAverageValue",
    label: "Show average",
    defaultValue: false,
  });

  const { data, isLoading } = usePropertyValueOverTimeData();

  const mapToDataExpected = useCallback(
    (activeTab: GroupingOverTimeTabs) =>
      ([id, adminAreaData]: [string, parsers.portfolio.PropertyValueOverTimeData[]]) => ({
        id:
          activeTab === "fsa"
            ? `${id} (${selectors.property.convertToISOAdminArea(adminAreaData[0].administrativeAreas?.[0])})`
            : id,
        data: adminAreaData
          .filter((item) => item.sumValue)
          .map((item: parsers.portfolio.PropertyValueOverTimeData) => ({
            x: new Date(item.date),
            y: showAverage ? item.averageValue : item.sumValue,
            activeTab,
            count: item.count,
            meta: {
              "Average Home Value": item.averageValue
                ? formatters.number.getCurrencyWords(item.averageValue, {
                    short: true,
                  })
                : undefined,
              "Total Home Value in Portfolio": item.sumValue
                ? formatters.number.getCurrencyWords(item.sumValue, {
                    short: true,
                  })
                : undefined,
            },
          })),
      }),
    [showAverage]
  );

  const mapped = useMemoizedGroupingOverTime({
    activeTab,
    data,
    isLoading,
    mapping: mapToDataExpected,
  });

  if (isLoading) {
    return (
      <div className="col-span-3">
        <Skeleton width="100%" height={389} />
      </div>
    );
  }

  if (!mapped) {
    return null;
  }

  return (
    <ChartCard
      isEmpty={mapped.length === 0}
      cardClassName="col-span-3"
      className="min-h-[300px]"
      header="Home value over time"
      headerComponents={
        <div className="flex row gap-3 items-center flex-wrap">
          <Tabs />
          <Toggle />
        </div>
      }
    >
      {/* @ts-ignore */}
      <ResponsiveLineCanvas
        data={mapped}
        margin={MARGIN}
        tooltip={OverTimeTooltip}
        // @ts-ignore
        xFormat={(d) => formatters.date.iso8601(new Date(d), "month")}
        yScale={SCALE}
        xScale={{ type: "time" }}
        // @ts-ignore
        yFormat={formatters.number.getCurrencyFromNumber}
        axisBottom={AXIS_BOTTOM}
        axisLeft={AXIS_LEFT(showAverage)}
        // @ts-ignore
        legends={LEGENDS}
      />
    </ChartCard>
  );
}
