import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import * as React from "react";
import { useCallback } from "react";

import { Button } from "src/components/ui/button";
import { Calendar } from "src/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { cn } from "src/utilities/shadcnUtils";

interface DateRangeProps {
  setDateFrom: (d: Date) => void;
  setDateTo: (d: Date) => void;
  dateTo?: Date;
  dateFrom?: Date;
}

export function DateRange({
  className,
  setDateFrom,
  setDateTo,
  dateTo,
  dateFrom,
}: React.HTMLAttributes<HTMLDivElement> & DateRangeProps) {
  const handleSelect = useCallback(
    (date?: { to?: Date; from?: Date }) => {
      if (date?.to) {
        setDateTo(date.to);
      }
      if (date?.from) {
        setDateFrom(date.from);
      }
    },
    [setDateTo, setDateFrom]
  );

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal items-start",
              !dateFrom && !dateTo && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateFrom ? (
              dateTo ? (
                <>
                  {format(dateFrom, "LLL dd, y")} - {format(dateTo, "LLL dd, y")}
                </>
              ) : (
                format(dateFrom, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateFrom}
            selected={{ from: dateFrom, to: dateTo }}
            onSelect={handleSelect}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
