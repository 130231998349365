import { CountAndStats } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/CountAndStats";
import { useLoanSizes } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";

import { formatters } from "@fraction/shared";

export function LoanSizeChart() {
  const { data, isLoading } = useLoanSizes();
  return (
    <CountAndStats
      statValueFormatter={formatters.number.getCurrencyFromNumber}
      xAxisLabel="Loan size"
      data={data}
      isLoading={isLoading}
      header="Loan sizes"
      id="loanSizes"
    />
  );
}
