import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

const INPUT_MARGIN_BOTTOM = 12;

export const styles = createStyles({
  alert: {
    marginBottom: 48,
    maxWidth: "100%",
  },
  checkbox: {
    marginBottom: 30,
  },
  checkboxLabel: {
    fontSize: 16,
    maxWidth: "100%",
  },
  checkboxLabelSmall: {
    maxWidth: "100%",
  },
  topInput: {
    // need to account for error spacer, which is 18px high, so if we want 25px, just put 7px here
    marginBottom: 7,
  },
  icon: {
    marginRight: 17,
  },
  input: {
    // need to account for error spacer, which is 18px high. So, e.g., if want 25px, just put 7px here
    marginBottom: INPUT_MARGIN_BOTTOM,
  },
  slider: {
    // This doesn't have an error spacer, so if we want to match input, we need to take input's value and add 18px
    marginBottom: INPUT_MARGIN_BOTTOM + 18,
  },
  sliderLabel: {
    fontSize: 14,
    color: colors.LIGHT_TEXT,
    marginBottom: 8,
  },
  sliderContainer: {
    marginTop: 30,
    marginBottom: 30,
    width: "100%",
    maxWidth: 340,
    "@media(max-width: 600px)": {
      marginTop: 0,
    },
  },
  scroller: {
    backgroundColor: "white",
    display: "flex",
    maxWidth: "100%",
    flexDirection: "column",
    paddingLeft: 32,
    paddingRight: 32,
  },
  page: {
    minHeight: "100vh",
    "@media(max-width: 600px)": {
      // There seems to be a bug on Chrome (at least) where fit-content doesn't play nice with
      // vh units when using height/minHeight combos. Therefore, on desktop, set to 100vh (since
      // height will usually be less than 100vh), and on mobile, set to fit-content, since content
      // will generally be more than 100vh.
      minHeight: "fit-content",
    },
    maxWidth: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
    "@media(max-width: 600px)": {
      maxWidth: "100%",
      minHeight: "100%",
    },
  },
  text: {
    fontSize: 16,
    marginTop: 24,
    marginBottom: 24,
    color: colors.BLACK_TEXT,
  },
  centeredHeader: {
    maxWidth: 300,
  },
  header: {
    color: colors.BLACK_TEXT,
  },
  checkmarkTree: {
    marginBottom: 22,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 600,
    "@media(max-width: 600px)": {
      maxWidth: "100%",
    },
  },
  centeredButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  centeredButton: {
    width: "100%",
    maxWidth: 205,
  },
  centeredContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 700,
    "@media(max-width: 600px)": {
      maxWidth: "100%",
      overflowY: "hidden",
    },
  },
  centeredInnerHeaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 600,
    width: "100%",
    "@media(max-width: 600px)": {
      maxWidth: "100%",
    },
  },
  centeredText: {
    textAlign: "center",
  },
  visualizationContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "@media(max-width: 600px)": {
      justifyContent: "center",
    },
  },
  legend: {
    marginTop: 30,
    marginBottom: 45,
  },
  chartContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  wrappingVisualizationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%",
    "@media(max-width: 600px)": {
      flexDirection: "column-reverse",
      overflowY: "hidden",
    },
  },
  visualizationExplainerContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 60,
    "@media(max-width: 600px)": {
      marginLeft: 0,
      marginTop: 40,
    },
  },
  visualizationHeader: {
    fontSize: 20,
    color: colors.palette.GREEN,
    marginBottom: 25,
    textTransform: "uppercase",
  },
  visualizationParagraph: {
    width: 320,
    maxWidth: "100%",
    lineHeight: "160%",
  },
});
