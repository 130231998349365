import { withLDProvider } from "launchdarkly-react-client-sdk";
import { ReactNode } from "react";
import ReactToastifyRoot from "src/components/root/ReactToastifyRoot";
import config from "src/config";
import { ModalProvider } from "src/hooks/useModal";

import DrawerModalRoot from "../DrawerModalRoot";
import ReactQueryRoot from "./ReactQueryRoot";

const AppRoot = ({ children }: { children?: ReactNode }) => (
  <>
    <ReactQueryRoot>
      <ModalProvider>{children}</ModalProvider>
    </ReactQueryRoot>
    <ReactToastifyRoot />
    <DrawerModalRoot />
  </>
);

export default withLDProvider({
  clientSideID: config.launchDarklyClientID,
  options: {
    streaming: false,
  },
})(AppRoot);
