import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { parsers } from "@fraction/shared";

/*
 * NOTE: react-router v6 has this built-in (useSearchParams) so we can consider
 * deleting this hook when we upgrade.
 *
 * Also note from Josh: we are using react-router's useLocation hook, but recognize that it cause
 * rerendering on every "next" in the app flow. This is probably because we change the URL to include
 * the #pageName on each "next", which doesn't affect our params, but does affect the URL.
 *
 * Since this won't result in any changed output, it shouldn't really cause nested rerenderings, but
 * it will be a bit noisy when trying to track changes, so something to be aware of.
 *
 * We could (and I did try) to hand-roll our own URL listener, but I think react-router has its own
 * internal system for emitting changes to events that then trigger their hooks.
 * If we only cared about the URL at the start, we could use the window.location.search and just parse
 * that, but that wouldn't listen to changes to the search parameters. So, in summary, to avoid any
 * footguns when we would expect the location search params to change and they don't, I'm just going
 * to continue playing in the react-router sandbox and live with the (minor) performance hit.
 *
 */
const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => parsers.querystring.parse(search), [search]);
};

export default useQueryParams;
