import { enums } from "@fraction/shared";
import _ from "lodash";
import { Mail, SendHorizontal, XIcon } from "lucide-react";
import { ReactNode, useCallback, useState } from "react";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { useApplicationBrokers } from "src/hooks/useApplicationBrokers";
import { useInputState } from "src/hooks/useInputState";
import { useInvites } from "src/hooks/useInvite";
import useModal from "src/hooks/useModal";
import { cn } from "src/utilities/shadcnUtils";

export function BrokerInviteModalButton({
  applicationId,
  children,
  type,
  className,
}: { applicationId?: string; children?: ReactNode; type?: enums.InviteType; className?: string }) {
  const { showModal } = useModal();

  const handleClickButton = useCallback(() => {
    showModal({
      message: <InviteModalContent applicationId={applicationId} type={type} />,
      actions: [],
      closeOnClickBackdrop: true,
      closeButton: true,
    });
  }, [applicationId, type]);

  return (
    <button
      onClick={handleClickButton}
      className={cn(
        "text-left flex flex-row items-center bg-black text-white border rounded-full px-3 py-1 gap-1 group hover:brightness-95 relative text-xs",
        className
      )}
    >
      <span className="line-clamp-1 truncate text-xs font-medium mr-0.5">
        {children ? children : "Invite new brokers"}
      </span>
      <Mail className="group-hover:scale-105 group-hover:brightness-110 inline h-3 w-3" />
    </button>
  );
}

const InviteModalContent = ({
  applicationId,
  onClose,
  type = enums.InviteType.BROKER_TO_BROKER,
}: { applicationId?: string; onClose?: () => void; type?: enums.InviteType }) => {
  const [input, setInput, setInputRaw] = useInputState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { inviteBrokersToApp } = useApplicationBrokers(applicationId);
  const { invites, sendBrokerToBrokerInvite } = useInvites();

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e?.preventDefault?.();
      if (!input) {
        setErrorMessage("Please enter an email address");
        return;
      }
      if (!input.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        setErrorMessage("Please enter a valid email address");
        return;
      }
      setEmails((prev) => [...prev, input]);
      setInputRaw("");
    },
    [input]
  );

  const handleInput = useCallback((e: any) => {
    setErrorMessage(null);
    setInput(e);
  }, []);

  const addEmail = useCallback((email: string) => {
    setEmails((prev) => _.uniq([...prev, email]));
  }, []);

  const handleRemoveEmail = useCallback((email: string) => {
    setEmails((prev) => prev.filter((e) => e !== email));
  }, []);

  const handleSendInvites = useCallback(() => {
    if (!emails?.length) {
      return;
    }
    (async () => {
      if (applicationId) {
        await inviteBrokersToApp.mutateAsync({ emails, type });
      } else {
        await sendBrokerToBrokerInvite.mutateAsync({ emails, type });
      }
      onClose?.();
    })();
  }, [emails, inviteBrokersToApp.mutateAsync, onClose, type]);

  const brokerInvites = _.sortBy(
    _.uniqBy(
      invites?.filter(
        (invite) =>
          invite.type &&
          [enums.InviteType.BROKER_TEAM_CONNECTION, enums.InviteType.BROKER_TO_BROKER].includes(
            invite.type
          ) &&
          invite?.invitee?.email
      ),
      "invitee.email"
    ),
    "createdAt"
  ).reverse();

  return (
    <div>
      <h2 className="text-lg font-semibold">
        Invite new broker(s) to {applicationId ? "this app" : "your deal team"}
      </h2>
      {brokerInvites?.length ? (
        <div className="mb-4">
          <b className="text-sm">Invite people you've invited before</b>
          <div
            className={cn(
              "flex flex-row gap-1 w-full overflow-y-scroll max-h-[75px] flex-wrap",
              brokerInvites.length > 4 && "pb-1 border-b"
            )}
          >
            {brokerInvites?.map((invite) => (
              <Button
                onClick={(e) => {
                  e?.preventDefault?.();
                  addEmail(invite?.invitee?.email!);
                }}
                variant="outline"
                size="sm"
              >
                {invite?.invitee?.email}
              </Button>
            ))}
          </div>
        </div>
      ) : null}
      <form onSubmit={handleSubmit} className="my-2">
        <div className="flex flex-row gap-1 items-center">
          <Input
            type="email"
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect="off"
            className={cn(errorMessage && "border-red")}
            placeholder="Type in an email"
            onChange={handleInput}
            data-1p-ignore
            data-lpignore="true"
            value={input}
          />
          <Button
            onClick={handleSubmit}
            disabled={!input.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
            variant="green"
          >
            Add email
          </Button>
        </div>
        {errorMessage ? (
          <p className="text-red mt-1 text-xs ring-red focus-visible:ring-red">{errorMessage}</p>
        ) : null}
      </form>
      <div className="flex flex-row gap-0.5 mb-2 w-full flex-wrap max-h-[75px] overflow-y-scroll">
        {emails.map((email) => (
          <div key={email} className="text-[11px] bg-gray-300 px-2 py-0.5 rounded font-medium lowercase">
            <p>
              {email}
              <XIcon
                onClick={() => handleRemoveEmail(email)}
                className="h-3.5 w-3.5 inline cursor-pointer ml-1"
              />
            </p>
          </div>
        ))}
      </div>
      <div className="flex flex-row items-center justify-between">
        <Button onClick={onClose} variant="red">
          Never mind
        </Button>
        <Button
          pending={inviteBrokersToApp.isPending || sendBrokerToBrokerInvite.isPending}
          disabled={!emails?.length}
          onClick={handleSendInvites}
          variant="green"
        >
          Send {emails?.length ? emails.length : ""} invite{emails?.length === 1 ? "" : "s"}{" "}
          <SendHorizontal className="ml-1.5 text-white h-3.5 w-3.5" />
        </Button>
      </div>
    </div>
  );
};
