import { Bar } from "@nivo/bar";
import { format } from "date-fns";
import { usePortfolioSize } from "src/apps/PortfolioDashboard/screens/PortfolioAnalytics/hooks";
import { Skeleton } from "src/components";
import { useToggler } from "src/hooks";

import { formatters } from "@fraction/shared";
import { useCsvDownload } from "src/hooks/useCsvDownload";

import { DownloadCloudIcon } from "lucide-react";
import { Button } from "src/components/ui/button";
import { ChartCard } from "../ChartCard";

const MARGIN = { top: 20, right: 0, bottom: 40, left: 0 };
const AXIS_BOTTOM = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "Month",
  legendPosition: "middle",
  legendOffset: 32,
  format: (value: string) => format(new Date(value), "MMM yyyy"),
} as const;

const Tooltip = (props: any) => {
  return (
    <div className="shadow p-2 bg-white rounded">
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Portfolio: </b>
        {formatters.number.getCurrencyFromNumber(props.data?.totalSum)} ({props.data?.totalCount || 0})
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Fundings: </b>
        {formatters.number.getCurrencyFromNumber(props.data?.fundingsSum)} ({props.data?.fundingsCount || 0})
      </p>
      <p className="text-sm text-gray-800 mt-0.5 font-sans">
        <b>Repayments: </b>
        {formatters.number.getCurrencyFromNumber(props.data?.payoffsSum)} ({props.data?.payoffsCount || 0})
      </p>
    </div>
  );
};

export function PortfolioSizeChart() {
  const { data, isLoading } = usePortfolioSize();
  const { Toggle, on: showCount } = useToggler({
    id: "portfolioSizeToggle",
    label: "Show count",
    defaultValue: false,
  });
  const runDownload = useCsvDownload(data, "portfolio-size.csv", (x) => ({
    ...x,
    month: x.month.slice(0, 7),
    totalSum: formatters.number.getDollars(x.totalSum),
    fundingsSum: formatters.number.getDollars(x.fundingsSum),
    payoffsSum: formatters.number.getDollars(x.payoffsSum),
  }));

  if (isLoading) {
    return (
      <div className="col-span-3">
        <Skeleton width="100%" height={200} />
      </div>
    );
  }

  return (
    <ChartCard
      isEmpty={data.length === 0}
      cardClassName="col-span-3"
      header="Portfolio size"
      headerComponents={
        <div className="flex row gap-3">
          <Toggle />
          <Button variant="ghost" pending={runDownload.isPending} onClick={runDownload.mutateAsync}>
            Download <DownloadCloudIcon className="ml-1.5" height={14} width={14} />
          </Button>
        </div>
      }
    >
      <Bar
        data={data}
        keys={
          showCount
            ? ["totalCount", "fundingsCount", "payoffsCount"]
            : ["totalSum", "fundingsSum", "payoffsSum"]
        }
        layers={["axes"]}
        axisBottom={null}
        width={50}
        height={400}
        margin={{ ...MARGIN, left: 40 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          format: showCount ? undefined : (x) => formatters.number.getCurrencyWords(x, { short: true }),
        }}
      />
      <div className="overflow-x-scroll w-full">
        <Bar
          data={data}
          keys={
            showCount
              ? ["totalCount", "fundingsCount", "payoffsCount"]
              : ["totalSum", "fundingsSum", "payoffsSum"]
          }
          indexBy="month"
          margin={MARGIN}
          width={10000}
          height={400}
          axisLeft={null}
          padding={0.3}
          valueScale={{ type: "linear" }}
          tooltip={Tooltip}
          indexScale={{ type: "band", round: true }}
          groupMode="grouped"
          axisBottom={AXIS_BOTTOM}
          enableLabel={showCount}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#ffffff"
        />
      </div>
    </ChartCard>
  );
}
