import { colors, notUndefinedOrNull } from "@fraction/shared";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  infoContainer: {
    height: 160,
    width: 180,
    borderRadius: 5,
    backgroundColor: colors.palette.GREEN_50,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
});

export const textStyles = StyleSheet.create({
  bold: {
    fontWeight: 700,
    fontSize: 11,
  },
  giant: {
    fontWeight: 700,
    fontSize: 16,
  },
  small: {
    fontSize: 8,
  },
  lighter: {
    color: colors.palette.GREY_800,
  },
  centered: {
    textAlign: "center",
  },
});

export const InfoContainer = ({
  top,
  bottom,
  miniBottom,
  style,
  img,
}: { top: string; bottom: string; miniBottom?: string; img?: any; style?: any }) => (
  <View style={[styles.infoContainer, style].filter(notUndefinedOrNull)}>
    <Text style={[textStyles.bold, textStyles.centered, { marginBottom: 2 }]}>{top}</Text>
    <Image style={{ height: "50%", marginVertical: 8 }} src={img} />
    <Text style={[textStyles.centered, textStyles.giant]}>{bottom}</Text>
    {miniBottom ? (
      <Text style={[textStyles.small, textStyles.centered, textStyles.lighter, { marginTop: 3 }]}>
        {miniBottom}
      </Text>
    ) : null}
  </View>
);
