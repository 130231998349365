import { LucideIcon } from "lucide-react";
import * as React from "react";
import { SVGProps, forwardRef } from "react";
import Skeleton from "src/components/Skeleton";
import { Text } from "src/components/Text";
import Touchable from "src/components/Touchable";
import { createStyles } from "src/styles";
import { cn } from "src/utilities/shadcnUtils";

export interface NavItemProps {
  children: string;
  icon?: React.FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }> | LucideIcon;
  onClick?: () => void;
  active?: boolean;
  loading?: boolean;
  iconClassName?: string;
  indicator?: boolean;
}

const styles = createStyles(({ spacing, colors, borderRadius }) => ({
  container: {
    padding: spacing[2],
    borderRadius: borderRadius[1],
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  icon: {
    marginRight: 20,
    color: colors.grey500,
  },
  text: {
    color: colors.grey500,
  },
  active: {
    color: colors.green600,
  },
  bgActive: {
    backgroundColor: colors.green100,
  },
}));

const NavItem = forwardRef<HTMLButtonElement, NavItemProps>(
  ({ icon: Icon, onClick, children, active, loading, iconClassName, indicator }: NavItemProps, ref) =>
    loading ? (
      <Skeleton height={58} />
    ) : (
      <Touchable
        ref={ref}
        className={cn(active ? "hover:bg-green-200 text-left" : "hover:bg-gray-300 text-left")}
        onClick={onClick}
        css={[styles.container, active && styles.bgActive]}
      >
        <div className="relative">
          {Icon && (
            <Icon
              width={24}
              height={24}
              css={[styles.icon, active && styles.active]}
              className={cn("min-w-[24px] min-h-[24px]", iconClassName)}
            />
          )}
          {indicator ? (
            <div className="absolute -top-0.5 right-[16px] bg-orange rounded-full w-2 h-2 group-hover:bg-orange-700" />
          ) : null}
        </div>

        <Text size="sm" weight="medium" style={[styles.text, active && styles.active]}>
          {children}
        </Text>
      </Touchable>
    )
);

export default NavItem;
