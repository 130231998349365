import { enums, selectors } from "@fraction/shared";
import { useCallback } from "react";
import { getCurrentStatusBlock } from "src/components/AppStatusBadge";
import Chip from "src/components/Chip";
import Skeleton from "src/components/Skeleton";
import { useModal } from "src/hooks";
import { useApplication } from "src/hooks/useApplication";
import { cn } from "src/utilities/shadcnUtils";

export const CancelOrReopenApp = ({
  appId,
  status,
  className,
  loading,
}: { appId?: string; status?: enums.ApplicationStatus; className?: string; loading?: boolean }) => {
  const { showModal: showCancelModal, closeModal: closeCancelModal } = useModal();
  const { showModal: showReopenModal, closeModal: closeReopenModal } = useModal();
  const { rescindDeal, reopenDeal } = useApplication({ fetchEnabled: false, id: appId });

  const handleOpenCancel = useCallback(() => {
    showCancelModal({
      prompt: "Reason for withdrawal",
      message: "Are you sure you want to cancel this deal?",
      header: "Cancel deal",
      actions: [
        {
          text: "Never mind",
          action: closeCancelModal,
          type: "inverse",
        },
        {
          type: "urgent",
          text: "Yes, cancel deal",
          action: (input) => {
            return input && rescindDeal.mutateAsync({ withdrawnReason: input });
          },
          promptRequired: true,
        },
      ],
    });
  }, []);

  const handleOpenReopen = useCallback(() => {
    showReopenModal({
      message: "Are you sure you want to reopen this deal?",
      header: "Reopen deal",
      actions: [
        {
          text: "Never mind",
          action: closeReopenModal,
          type: "inverse",
        },
        {
          text: "Yes, reopen deal",
          action: reopenDeal.mutateAsync,
          type: "primary",
        },
      ],
    });
  }, []);

  if (loading) {
    return <Skeleton className={cn("rounded-full inline-flex", className)} height={25} width={70} />;
  }

  if (!appId) {
    return null;
  }

  if (status === enums.ApplicationStatus.HOMEOWNER_RESCINDED) {
    return (
      <button className="m-0" onClick={handleOpenReopen}>
        <Chip className="relative group min-w-[100px]" variant="green">
          <p className="group-hover:invisible">{getCurrentStatusBlock(status)?.label}</p>
          <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 invisible group-hover:visible text-white">
            Reopen deal
          </p>
        </Chip>
      </button>
    );
  }

  // <Chip variant="red">Cancel deal</Chip>
  if (status && selectors.application.isActiveDeal({ status })) {
    return (
      <button className="m-0" onClick={handleOpenCancel}>
        <Chip
          className={cn(className, "relative group hover:bg-red transition-colors duration-200")}
          variant={status && getCurrentStatusBlock(status)?.color}
        >
          <p className="group-hover:invisible">{getCurrentStatusBlock(status)?.label}</p>
          <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 invisible group-hover:visible text-white">
            Rescind deal
          </p>
        </Chip>
      </button>
    );
  }

  if (status) {
    return (
      <Chip className={className} variant={getCurrentStatusBlock(status)?.color}>
        {getCurrentStatusBlock(status)?.label}
      </Chip>
    );
  }

  return null;
};
