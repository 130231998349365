import { Route, Routes, useParams } from "react-router-dom";
import { AppDetailsOverview } from "src/components/AppDetailsOverview";
import { AppDocumentCreation } from "src/components/AppDocumentCreation";
import { AppOverviewShell } from "src/components/AppOverviewShell";
import { useApplicationAuthed } from "src/hooks/useApplication";

/**
 * inner tabs w/ routes
 */
export function BrokerAppOverviewShell() {
  const { id } = useParams();

  const { data: app, isLoading: loading } = useApplicationAuthed({
    id,
    initialRefetch: true,
  });

  return <AppOverviewShell app={app || undefined} loading={loading} tabs={["overview"]} />;
}

export function AppDetailsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<BrokerAppOverviewShell />}>
        <Route path="/" element={<AppDetailsOverview />} />
        <Route path="/docs" element={<AppDocumentCreation />} />
      </Route>
    </Routes>
  );
}
