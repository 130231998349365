import { Global, css } from "@emotion/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createStyles } from "src/styles";

const styles = createStyles({
  ".Toastify .Toastify__toast": {
    fontFamily: "Montserrat",
    borderRadius: 8,
    padding: "12.5px 24px",
  },

  ".Toastify .Toastify__toast-body": {
    fontSize: 14,
    lineHeight: 1.6,
    padding: 0,
  },

  ".Toastify .Toastify__close-button": {
    alignSelf: "auto",
    marginLeft: 24,
  },

  ".Toastify .Toastify__progress-bar.Toastify__progress-bar-theme--colored.Toastify__progress-bar--default": {
    background: "rgba(255, 255, 255, 0.5)",
  },
});

const CSS_VARIABLES = css(`
  .Toastify {
    --toastify-color-light: #18181b;
    --toastify-text-color-light: #fff;
    --toastify-toast-width: 420px;
    --toastify-toast-min-height: 44px 420px;

    --toastify-color-info: #18181b;
    --toastify-color-success: #82c882;
    --toastify-color-warning: #e5b04a;
    --toastify-color-error: #c95456;
  }`);

const ReactToastifyRoot = () => (
  <>
    <Global styles={[styles, CSS_VARIABLES]} />
    <ToastContainer
      position="top-center"
      autoClose={5000}
      closeOnClick={false}
      draggable={false}
      pauseOnFocusLoss
      pauseOnHover
      theme="colored"
    />
  </>
);

export default ReactToastifyRoot;
