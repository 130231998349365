import cookie, { CookieSerializeOptions } from "cookie";
import config from "src/config";

import { isObject, parseJSONSafe, parsers } from "@fraction/shared";

// store the cookies for three weeks
const ONE_MONTH = 60 * 24 * 30;

const COOKIE_SETTINGS_DEFAULT: CookieSerializeOptions = {
  maxAge: ONE_MONTH,
  domain: config.cookieDomain,
  path: "/",
  sameSite: "lax",
};

export function parseGoogleOptimizeCookie(optimizeCookie?: string): {
  google_optimize_experiment?: string;
  google_optimize_experiment_variant?: string;
} {
  if (!optimizeCookie) {
    return {};
  }

  const split = optimizeCookie.split(".");
  return {
    google_optimize_experiment: split[2],
    google_optimize_experiment_variant: split[4],
  };
}

export function getStoredCookies(): {
  url_params?: string;
  initial_href?: string;
  initial_referrer?: string;
  google_optimize_experiment?: string;
  google_optimize_experiment_variant?: string;
} {
  const existing = cookie.parse(document.cookie);

  return {
    ...parseGoogleOptimizeCookie(existing?._gaexp),
    url_params: existing._url_params,
    initial_href: existing.initial_href,
    initial_referrer: existing.initial_referrer,
  };
}

export function getTrackingContext(app: parsers.application.FullApplication) {
  const cookies = getStoredCookies();
  if (!cookies) {
    return;
  }
  const params = parseJSONSafe(cookies.url_params || "{}");
  return {
    context: {
      referrer: {
        type: "impactRadius",
        id: params.irclickid,
        url: cookies.initial_referrer,
      },
      campaign: {
        name: params.utm_campaign,
        source: params.utm_source,
        medium: params.utm_medium,
      },
      location: {
        city: app.property?.location?.locality,
        country: app.property?.location?.country,
        latitude: app.property?.location?.geo?.lat,
        longitude: app.property?.location?.geo?.lng,
        region: app.property?.location?.administrativeArea,
      },
    },
  };
}

export function saveCookie(key: string, value: any, cookieSettings?: CookieSerializeOptions) {
  document.cookie = cookie.serialize(key, isObject(value) ? JSON.stringify(value) : value, {
    ...COOKIE_SETTINGS_DEFAULT,
    ...cookieSettings,
  });
}

export function getCookie(key: string) {
  const value = cookie.parse(document.cookie)[key];
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
}
