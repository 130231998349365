import { SerializedStyles } from "@emotion/react";
import { AnchorHTMLAttributes, ReactElement, ReactNode, memo } from "react";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

const styles = createStyles({
  link: {
    width: "fit-content",
    display: "inline",
    color: colors.palette.GREY_700,
    "&:visited": {
      color: colors.palette.GREY_700,
      "&:hover": {
        color: colors.palette.GREEN,
      },
    },
    "&:hover": {
      color: colors.palette.GREEN,
    },
  },
  underlined: {
    textDecoration: "underline",
  },
  notUnderlined: {
    textDecoration: "none",
  },
});

export interface TextLinkProps extends Omit<AnchorHTMLAttributes<any>, "style"> {
  style?: SerializedStyles;
  children: string | ReactElement | Array<string | ReactElement> | ReactNode;
  underlined?: boolean;
}

const TextLink = ({ style, children, underlined, ...props }: TextLinkProps) => (
  <a
    target="_blank"
    css={[styles.link, underlined ? styles.underlined : styles.notUnderlined, style]}
    {...props}
  >
    {children}
  </a>
);

export default memo(TextLink);
