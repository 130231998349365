import { SerializedStyles } from "@emotion/react";
import React, { ReactNode, useCallback } from "react";
import Checkbox from "src/components/Checkbox";
import Skeleton from "src/components/Skeleton";
import { SemiBoldText } from "src/components/v1/Text";
import { createStyles } from "src/styles";

import { colors, enums } from "@fraction/shared";
import { imageMap } from "./Images";

// only some of those enums have a corresponding illust svg, refer to
// https://app.clickup.com/t/1zhpn5j or https://www.figma.com/file/3tHMTslIoZ7n4YbOlyud3V/Icons-Redesign for available enums
export type IllustrativeOptionType =
  | enums.PropertyType
  | enums.PropertyUsage
  | enums.UseOfFunds
  | enums.IncomeType
  | enums.DebtType
  | enums.FinancialAssetType
  | enums.CreditScoreGuess
  | "checkmark";

export type IllustrativeOptionProps = {
  value: IllustrativeOptionType;
  label: string;
  isMulti?: boolean;
  selected?: boolean;
  onClick: () => void;
  style?: SerializedStyles;
  disabled?: boolean;
  image?: ReactNode;
};

const styles = createStyles({
  button: {
    backgroundColor: "white",
    border: `1px solid ${colors.palette.GREY_300}`,
    borderRadius: "10px",
    boxSizing: "border-box",
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    width: "calc(105.75px * 1.4)",
    height: "calc(126px * 1.4)",
    "&:hover": {
      borderColor: colors.palette.GREEN_400,
    },
    ["&.selected" as any]: {
      borderColor: colors.palette.GREEN_500,
      outline: `3px solid ${colors.palette.GREEN_500}`,
      outlineOffset: "-4px",
    },
  },
  innerContainer: {
    top: "19%",
    position: "relative",
    width: "100%",
    [".isLongLabel &" as any]: {
      top: "10%",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  svg: {
    width: "54%",
    aspectRatio: "100/102",
  },
  checkbox: {
    position: "absolute",
    top: "16px",
    right: "-3px",
    zIndex: 1,
  },
  p: {
    lineHeight: "1.2em",
    margin: "0.8em 0.8em 0",
    fontSize: "13px",
  },
  name: {
    fontSize: "inherit",
  },
  disabled: {
    opacity: 0.8,
    "&:hover": {
      borderColor: colors.palette.GREY_300,
    },
    cursor: "not-allowed",
  },
});

const IllustrativeOption = ({
  value,
  label,
  image,
  selected,
  isMulti = false,
  onClick,
  style,
  disabled,
}: IllustrativeOptionProps) => {
  const moduleName = value;

  const Svg = image ? null : imageMap[moduleName];

  const handleSelect = useCallback(() => {
    if (!disabled) {
      onClick?.();
    }
  }, [disabled, onClick]);

  const onKeyPress = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      if (evt.code === "Enter") {
        handleSelect();
      }
    },
    [handleSelect]
  );

  return image || Svg ? (
    <div
      role="button"
      tabIndex={0}
      css={[styles.button, style, disabled && styles.disabled]}
      className={[
        selected ? "selected" : "",
        isMulti ? "isMulti" : "",
        label.length > 28 ? "isLongLabel" : "", // some labels could take up 3 lines, we don't want those options be visually broken
      ].join(" ")}
      onClick={handleSelect}
      onKeyPress={onKeyPress}
    >
      {isMulti && <Checkbox checked={selected} label="" style={styles.checkbox} />}
      <div css={styles.innerContainer}>
        {image ? (
          <img src={image as any} css={styles.svg} alt={value} />
        ) : Svg ? (
          <Svg width="54%" height="100%" css={styles.svg} alt={value} />
        ) : null}
        <p css={styles.p}>
          <SemiBoldText style={styles.name}>{label}</SemiBoldText>
        </p>
      </div>
    </div>
  ) : (
    <Skeleton width={105.75 * 1.2} height={126 * 1.2} />
  );
};

export default React.memo(IllustrativeOption);
