import { RefObject } from "react";
import { noInput } from "src/utilities/input";

import { colors } from "@fraction/shared";

import useFocusTracker from "./useFocusTracker";
import useHoverTracker from "./useHoverTracker";

interface InputProps {
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onFocus: React.FocusEventHandler<HTMLInputElement>;
  onMouseEnter: React.MouseEventHandler<HTMLInputElement>;
  onMouseLeave: React.MouseEventHandler<HTMLInputElement>;
  placeholder?: string;
}

interface LabelProps {
  label?: string;
  textColor: string;
  borderColor: string;
  animated: boolean;
}

/**
 * A hook that, when given an input component's props,
 * will track the focus/fill of that input and return modified handlers,
 * modified placeholders, a modified label, animation, etc.
 *
 * You can either have it be controlled by passing `value` and `onChange`,
 * or you can pass the ref to `inputRef` to have it be uncontrolled.
 */
export default function useLabel({
  inputRef,
  error,
  label,
  disabled,
  value,
  defaultValue,
  placeholder,
  ...props
}: {
  inputRef?: RefObject<HTMLInputElement> | null;
  error?: string;
  disabled?: boolean;
  label?: string;
  value?: string | number;
  defaultValue?: string | readonly string[] | number;
} & Partial<InputProps>): [InputProps, LabelProps] {
  const { focused, onBlur, onFocus } = useFocusTracker(props);
  const { hovering, onMouseEnter, onMouseLeave } = useHoverTracker(props);
  const textColor = error
    ? colors.ERROR
    : focused
    ? colors.SELECTED
    : disabled
    ? colors.DISABLED_LINES
    : colors.PLACEHOLDER_TEXT;

  const borderColor = error
    ? colors.ERROR
    : focused
    ? colors.SELECTED
    : disabled
    ? colors.DISABLED_LINES
    : hovering
    ? colors.HOVERED_LINES
    : colors.LINES;
  const animated = focused || !noInput(value) || !noInput(defaultValue) || !noInput(inputRef?.current?.value);

  return [
    { onBlur, onFocus, placeholder: !focused && label ? undefined : placeholder, onMouseEnter, onMouseLeave },
    { label, textColor, borderColor, animated },
  ];
}
