import { MotionValue, useTransform } from "framer-motion";
import { ReactNode, RefObject, useCallback, useEffect, useMemo, useState } from "react";
import { useWindowSize } from "src/hooks";
import useScrollDirection from "src/hooks/useScrollDirection";
import { focusWhenPossible } from "src/utilities/react";

export interface NavSection {
  sectionHeader: string | ReactNode;
  pageId: string;
  pageIndex: number;
}

export interface useFlowNavScrollParams {
  pageRefs: RefObject<HTMLElement>[];
  navSections: NavSection[];
  scrollElement?: RefObject<any>;
  scrollY: MotionValue;
  pageHeight?: number;
  onIntersection?: (activeSection: number) => void;
}

export default function useFlowNavScroll({
  pageRefs,
  navSections,
  scrollY,
  scrollElement,
  pageHeight,
  onIntersection,
}: useFlowNavScrollParams) {
  const [activeSection, setActiveSection] = useState(0);
  const [backtrackSection, setBacktrackSection] = useState(0); // index of the highest section reached

  const { height: windowHeight } = useWindowSize();
  const height = pageHeight || windowHeight;

  const { yRangeStart, yRangeEnd } = useMemo(
    () => ({
      yRangeStart: height * navSections[activeSection]?.pageIndex || 0,
      // If there is no nextPageRef (i.e you're on the last Section), use the activeSection yRangeStart + 1 pixel
      yRangeEnd: height * navSections[activeSection + 1]?.pageIndex || 1,
    }),
    [height, navSections, activeSection]
  );

  const progress = useTransform(scrollY, [yRangeStart, yRangeEnd], [0, 1]);

  const scrollPageIntoView = useCallback(
    (pageIndex: number) => focusWhenPossible(pageRefs[pageIndex]),
    [pageRefs]
  );

  const handleClickNavItem = useCallback(
    (navIdx: number) => {
      scrollPageIntoView(navSections[navIdx].pageIndex);
    },
    [scrollPageIntoView, navSections]
  );

  const reset = useCallback(() => {
    setActiveSection(0);
    setBacktrackSection(0);
    scrollPageIntoView(0);
  }, [scrollPageIntoView]);

  const { isScrollingUp, isScrollingDown } = useScrollDirection(scrollElement);

  /**
   * Detects when the user or program scrolls and intersects with the beginning
   * of a new section, forward or back.
   */
  useEffect(() => {
    const scrollContainer = scrollElement?.current ? scrollElement.current : window;
    const scrollDistanceAttribute = scrollElement?.current ? "scrollTop" : "pageYOffset";

    const handleScroll = () => {
      const position = scrollContainer[scrollDistanceAttribute];

      if (position >= yRangeEnd && isScrollingDown && activeSection < navSections.length - 1) {
        setActiveSection((prev) => prev + 1);
        onIntersection?.(activeSection);
      } else if (position < yRangeStart && isScrollingUp && activeSection > 0) {
        setActiveSection((prev) => prev - 1);
        onIntersection?.(activeSection);
      }
    };

    scrollContainer.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [
    scrollElement,
    yRangeEnd,
    yRangeStart,
    activeSection,
    isScrollingUp,
    isScrollingDown,
    navSections,
    onIntersection,
  ]);

  useEffect(() => {
    if (activeSection >= backtrackSection) {
      setBacktrackSection(activeSection);
    }
  }, [activeSection, backtrackSection]);

  return { progress, activeSection, backtrackSection, reset, handleClickNavItem };
}
