import Skeleton from "react-loading-skeleton";
import MaleAvatar from "src/images/male-avatar.png";
import { createStyles } from "src/styles";

const styles = createStyles({
  avatar: {
    borderRadius: 37,
    height: 74,
    width: 74,
    objectFit: "cover",
    objectPosition: "50% 0%",
  },
});

const PersonAvatar = ({
  loading,
  avatarUrl,
  firstName,
}: {
  loading?: boolean;
  avatarUrl?: string;
  firstName?: string;
}) => {
  if (loading) {
    return <Skeleton circle height={74} width={74} />;
  }

  return <img css={styles.avatar} alt="Applicant" height={74} width={74} src={avatarUrl || MaleAvatar} />;
};

export default PersonAvatar;
