import { SerializedStyles } from "@emotion/react";
import { MotionValue, motion, useTransform } from "framer-motion";
import { ReactNode, RefObject, useCallback } from "react";
import { RegularText } from "src/components/v1";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

interface NavStep {
  sectionHeader: string | ReactNode;
  onClick: (idx: number) => void;
  pageId: string;
}

enum StepStatus {
  ACTIVE = "active",
  BACKTRACKED = "backtracked",
  DISABLED = "disabled",
}

export interface BarProps {
  progressHeight: MotionValue | number;
  color: string;
  opacity: MotionValue;
  barHeight: number;
}

export interface StepProps extends NavStep {
  includeBar?: boolean;
  idx: number;
  activeStep: number;
  backtrackStep: number;
  barProps: {
    opacity: MotionValue;
    progressHeight: MotionValue | number;
    barHeight: number;
  };
  headerRef?: RefObject<HTMLDivElement>;
}

export interface FlowNavigationBarProps {
  navSections: NavStep[];
  activeStep: number;
  backtrackStep: number;
  progress: MotionValue;
  isMobile?: boolean;
  style?: SerializedStyles;
}

const COLOR_STATUS_MAP = {
  [StepStatus.ACTIVE]: colors.palette.GREEN,
  [StepStatus.BACKTRACKED]: colors.palette.GREEN_200,
  [StepStatus.DISABLED]: colors.palette.GREY_400,
};

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  step: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  clickableArea: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    outline: "none",
  },
  circle: {
    borderRadius: "100%",
    width: 24,
    height: 24,
    cursor: "pointer",
    "@media(max-width: 1100px)": {
      width: 36,
      height: 36,
    },
  },
  content: {
    position: "absolute",
    paddingLeft: 40,
    "@media(max-width: 1100px)": {
      paddingLeft: 75,
    },
  },
  text: {
    width: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",

    "@media(max-width: 1100px)": {
      width: "100%",
      fontSize: "1.5rem",
      fontWeight: 500,
    },
  },

  bar: {
    width: 2,
    "@media(max-width: 1100px)": {
      width: 6,
    },
  },
});

const Bar = ({ progressHeight, opacity, color, barHeight }: BarProps) => (
  <div css={[styles.bar, { backgroundColor: color, height: barHeight }]}>
    <motion.div style={{ height: progressHeight, opacity, backgroundColor: colors.palette.GREEN }} />
  </div>
);

const Step = ({
  sectionHeader,
  onClick,
  includeBar = true,
  activeStep,
  backtrackStep,
  idx,
  barProps,
  headerRef,
}: StepProps) => {
  const getStepStatus = useCallback(() => {
    if (idx <= activeStep) {
      return StepStatus.ACTIVE;
    }
    if (idx <= backtrackStep) {
      return StepStatus.BACKTRACKED;
    }
    return StepStatus.DISABLED;
  }, [idx, activeStep, backtrackStep]);

  const color = COLOR_STATUS_MAP[getStepStatus()];
  const enabled = getStepStatus() !== StepStatus.DISABLED;
  const cursor = enabled ? "pointer" : "inherit";

  const handleClick = useCallback(() => enabled && onClick(idx), [enabled, onClick, idx]);

  return (
    <div css={styles.step}>
      {includeBar && <Bar color={color} {...barProps} />}
      <div
        onClick={handleClick}
        onKeyDown={handleClick}
        tabIndex={0}
        role="link"
        css={[styles.clickableArea, { cursor }]}
      >
        <div css={[styles.circle, { backgroundColor: color }]} />
        <div ref={headerRef} css={[styles.content]}>
          <RegularText style={[styles.text, { color }]}>{sectionHeader}</RegularText>
        </div>
      </div>
    </div>
  );
};

const FlowNavigationBar = ({
  navSections,
  activeStep,
  backtrackStep,
  progress,
  isMobile = false,
  style,
}: FlowNavigationBarProps) => {
  const barHeight = isMobile ? 120 : 92;

  const height = useTransform(progress, [0, 1], [0, barHeight]);

  // this prevents the next sections progress bar from quickly flashing green when the previous section reaches 100%
  const opacity = useTransform(progress, (y) => (y === 1 ? 0 : 1));

  return (
    <div css={[styles.container, style]}>
      {navSections.map((step, idx) => (
        <Step
          activeStep={activeStep}
          backtrackStep={backtrackStep}
          includeBar={idx !== 0}
          key={idx}
          idx={idx}
          barProps={{
            progressHeight: idx === activeStep + 1 ? height : 0,
            opacity,
            barHeight,
          }}
          {...step}
        />
      ))}
    </div>
  );
};

export default FlowNavigationBar;
