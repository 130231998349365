import { SerializedStyles } from "@emotion/react";
import React, { ReactNode, Ref, useCallback } from "react";
import { createStyles } from "src/styles";

import { hasDuplicates } from "@fraction/shared";

import IllustrativeOption, { IllustrativeOptionType } from "./IllustrativeOption";

export type IllustrativeOptionsProps = {
  options: {
    value: IllustrativeOptionType;
    label: string;
    image?: ReactNode;
  }[];
  isMulti?: boolean;
  label: string; // to be used in the fallback <DropdownSelector>
  placeholder?: string; // same as label, only used in <DropdownSelector>
  value: IllustrativeOptionType | IllustrativeOptionType[] | undefined;
  onChange: (value: any) => void;
  containerStyle?: SerializedStyles;
  focusRef?: Ref<HTMLInputElement>;
};

const styles = createStyles({
  list: {
    display: "flex",
    flexWrap: "wrap",
    gap: "6px 6px",
    ["&.isMulti" as any]: {
      gap: "6px 6px",
    },
  },
});

const IllustrativeOptions = ({
  options,
  isMulti = false,
  value,
  onChange,
  containerStyle,
  focusRef,
}: IllustrativeOptionsProps) => {
  if (hasDuplicates(options.map((o) => o.value))) {
    throw new Error("Duplication found in given option values, make sure they're unique");
  }

  const onClick = useCallback(
    (clickedValue: IllustrativeOptionType) => {
      if (!isMulti) {
        if (value === clickedValue) {
          onChange(undefined);
        } else {
          onChange(clickedValue);
        }
      } else {
        const value_ = Array.isArray(value) ? [...(value as IllustrativeOptionType[])] : [];
        const index = value_.indexOf(clickedValue);
        if (index > -1) {
          value_.splice(index, 1);
        } else {
          value_.push(clickedValue);
        }
        onChange(value_);
      }
    },
    [isMulti, value, onChange]
  );

  return (
    <div css={[styles.list, containerStyle]} className={isMulti ? "isMulti" : ""} ref={focusRef}>
      {options.map((option) => (
        <IllustrativeOption
          image={option.image}
          key={option.label}
          value={option.value}
          label={option.label}
          isMulti={isMulti}
          selected={Array.isArray(value) ? value.includes(option.value) : value === option.value}
          onClick={() => onClick(option.value)}
        />
      ))}
    </div>
  );
};

export default React.memo(IllustrativeOptions);
