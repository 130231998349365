import { useState } from "react";
import fraction from "src/api/fraction";
import { PrimaryButton } from "src/components";
import { BdmBrokerageSearchLayout } from "src/embeds/BdmBrokerageSearch/Layout";
import { useMutation } from "src/lib";

import { BdmFinderLayout } from "./Layout";

export default function BdmFinder() {
  const postalCodeQuery = useMutation({
    mutationFn: async (postalCode: string) => fraction.getPostalScore(postalCode),
  });

  const brokerageSearch = useMutation({
    mutationFn: async (brokerage: string) => fraction.searchForBrokerage(brokerage),
  });

  const bdm = useMutation({
    mutationFn: async (brokerageId: string) => fraction.getBdmForBrokerage(brokerageId),
  });

  const [usePostalCode, setUsePostalCode] = useState(false);

  return (
    <div className="flex flex-col items-center gap-5 w-full">
      {usePostalCode || bdm?.data ? (
        <BdmFinderLayout
          bdm={postalCodeQuery.data?.bdm || bdm?.data}
          onSubmit={postalCodeQuery.mutate}
          loading={postalCodeQuery.isPending}
          errorMessage={postalCodeQuery.error?.message}
        />
      ) : null}
      {!usePostalCode && !postalCodeQuery.data && !bdm.data ? (
        <BdmBrokerageSearchLayout
          onChooseBrokerage={bdm.mutate}
          brokerages={brokerageSearch?.data}
          onSubmit={brokerageSearch.mutate}
          loading={brokerageSearch.isPending || bdm.isPending}
          errorMessage={brokerageSearch.error?.message}
        />
      ) : null}
      {!postalCodeQuery.data && !bdm.data && !usePostalCode ? (
        <PrimaryButton onClick={() => setUsePostalCode(true)} variant="inverted" size="small">
          Can't find your brokerage?
        </PrimaryButton>
      ) : null}
    </div>
  );
}
