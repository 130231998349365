import { SerializedStyles } from "@emotion/react";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import { useAnimatedSelect } from "src/hooks";
import { createStyles } from "src/styles";

import { colors } from "@fraction/shared";

export enum Variant {
  BASE = "base",
  GREEN = "green",
}

const styles = createStyles({
  button: {
    padding: "16px 24px",
    border: "solid",
    borderRadius: 6,
    width: "100%",
    cursor: "pointer",
    "&:focus-visible": {
      outline: "none",
    },
  },
  base: {
    borderColor: colors.BORDER,
    backgroundColor: colors.palette.GREY_100,
    "&:hover": {
      backgroundColor: colors.palette.GREY_200,
    },
    "&:focus-visible": {
      backgroundColor: colors.palette.GREY_200,
    },
  },
  green: {
    borderColor: colors.palette.GREEN_500,
    backgroundColor: colors.palette.GREEN_100,
    "&:hover": {
      backgroundColor: colors.palette.GREEN_200,
    },
    "&:focus-visible": {
      backgroundColor: colors.palette.GREEN_200,
    },
  },
});

const BigButton = ({
  children,
  onClick,
  variant = Variant.BASE,
  style,
  animatedSelect,
  disabled,
}: {
  children: ReactNode;
  onClick?: () => void;
  variant?: Variant;
  style?: SerializedStyles;
  animatedSelect?: boolean;
  disabled?: boolean;
}) => {
  const {
    variants,
    variant: animate,
    onClickButton,
  } = useAnimatedSelect({ onChange: onClick, animate: animatedSelect });

  // an intermediate variant so that we get nice "click" styling
  const finalVariant = animate === "clicked" ? Variant.GREEN : variant;

  return (
    <motion.button
      disabled={disabled}
      variants={variants}
      animate={animate}
      type="button"
      css={[styles.button, styles[finalVariant], style]}
      onClick={onClickButton}
    >
      {children}
    </motion.button>
  );
};

export default BigButton;
